import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { GLAccountApiService } from '../../Services/GLAccount-api.service';

@Component({
    selector: 'app-glAccount-form',
    templateUrl: './GLAccount-form.component.html',
    styleUrls: ['./GLAccount-form.component.css']
})
export class GLAccountFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected GLAccountApiService: GLAccountApiService,
        protected _injector: Injector,
    ) {
        super(GLAccountApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            accountNature: new FormControl('', [Validators.required]),
            accountGroup: new FormControl('', [Validators.required]),
            postOnly: new FormControl(''),
            userRef: new FormControl(''),
            kmphiRef: new FormControl(''),
            customerRef: new FormControl(''),
            vendorRef: new FormControl(''),
            coUnitRef: new FormControl(''),
            departmentRef: new FormControl(''),
            contractRef: new FormControl(''),
            productRef: new FormControl(''),
            otherRef: new FormControl(''),
            vatRef: new FormControl(''),
            faTrReasonRef: new FormControl(''),
            modified: new FormControl(''),
            modifiedBy: new FormControl(''),
            status: new FormControl(''),
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.GLAccountApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

