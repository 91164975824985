<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Cần xuất' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '640px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="col-12 md:col-12 p-fluid formgrid grid">
                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Mã cần xuất ' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" id="pumpCode" pInputText [(ngModel)]="itemDetail.pumpCode"
                            formControlName="pumpCode" placeholder="Định dạng text, 2 ký tự số">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Tên cần xuất ' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.pumpName" formControlName="pumpName"
                            placeholder="Định dạng text, tối đa 30 ký tự">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Đơn vị' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="branchId" [options]="arrBranch" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.branchId"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Bể chứa' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="tankId" [options]="arrTank" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.tankId"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Mã thiết bị TĐH' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.iADeviceId" formControlName="iADeviceId"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Mã TĐH' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.iANozzleId" formControlName="iANozzleId"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Nhà cung cấp TĐH' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="iAManufacture" [options]="arrManufacture"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.iAManufacture" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Số serial' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.serial" formControlName="serial"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'PriceFactor' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.priceFactor" formControlName="priceFactor"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'VolumeFactor' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.volumeFactor"
                            formControlName="volumeFactor" placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'AmountFactor' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.amountFactor"
                            formControlName="amountFactor" placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'TotalVolumeFactor' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.totalVolumeFactor"
                            formControlName="totalVolumeFactor" placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'TotalAmountFactor' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.totalAmountFactor"
                            formControlName="totalAmountFactor" placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'PumpAddress' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.pumpAddress" formControlName="pumpAddress"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'StationNo' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.stationNo" formControlName="stationNo"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Áp dụng TĐH' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="isAuto" [options]="arrTHD" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isAuto"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'TĐH đổi giá' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="isAutoPrice" [options]="arrTHDPrice" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isAutoPrice"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Có Scada' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="isViewScada" [options]="arrScadar" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isViewScada"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Ngày dừng' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-calendar [showIcon]="true" [(ngModel)]="itemDetail.stopDate" formControlName="stopDate"
                            appendTo="body" icon="ci ci-calendar" dateFormat="dd/mm/yy" placeholder="dd/mm/yyy"
                            showTime="true" hourFormat="24">
                        </p-calendar>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Hạch toán theo số điện tử' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="isElec" [options]="arrElec" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isElec"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Ghi chú' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <textarea pInputTextarea [(ngModel)]="itemDetail.remarks" formControlName="remarks"
                            placeholder="Định dạng text" [autoResize]="true"></textarea>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-trash"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>