<p-dialog #dialog header="{{ 'Cập nhật thông tin' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '640px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã từ điền' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="field">
                        <input type="text" id="mdCode" pInputText [(ngModel)]="itemDetail.mdCode"
                            formControlName="mdCode" placeholder="4 ký tự ">
                    </div>
                </div>
                <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên từ điền' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <input type="text" id="mdName" pInputText [(ngModel)]="itemDetail.mdName"
                            formControlName="mdName" placeholder="Định dạng text, tối đa 30 ký tự">
                    </div>
                </div>
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên tiếng việt' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="mdNameVn" pInputText [(ngModel)]="itemDetail.mdNameVn"
                            formControlName="mdNameVn" placeholder="Định dạng text, tối đa 30 ký tự">
                    </div>
                </div>
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Column Id' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="columnId" pInputText [(ngModel)]="itemDetail.columnId"
                            formControlName="columnId" placeholder="Định dạng text, tối đa 10 ký tự">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Column Code' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="columnCode" pInputText [(ngModel)]="itemDetail.columnCode"
                            formControlName="columnCode" placeholder="Định dạng text, tối đa 100 ký tự">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Column Name' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="columnName" pInputText [(ngModel)]="itemDetail.columnName"
                            formControlName="columnName" placeholder="Định dạng text, tối đa 100 ký tự">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Where Clause' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="whereClause" pInputText [(ngModel)]="itemDetail.whereClause"
                            formControlName="whereClause" placeholder="Định dạng text, tối đa 100 ký tự">
                    </div>
                </div>
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Order By Clause' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="orderByClause" pInputText [(ngModel)]="itemDetail.orderByClause"
                            formControlName="orderByClause" placeholder="Định dạng text, tối đa 100 ký tự">
                    </div>
                </div>
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Order No' | translate}}</div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="field">
                        <input type="text" id="orderNo" pInputText [(ngModel)]="itemDetail.orderNo"
                            formControlName="orderNo" placeholder="Định dạng text, tối đa 100 ký tự">
                    </div>
                </div>
                <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>