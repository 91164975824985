<div class="pages-body login-page flex flex-column">
    <div class="topbar p-3 flex justify-content-between flex-row align-items-center">
        <div class="topbar-left ml-3 flex w-full align-items-center" routerLink="/">
            <img class="logo align-self-center mr-3" src="assets/images/logo.png" alt="" />
            <!-- <div>
                <h4 class="text-center" style="color: white; margin-bottom: -1px;">
                    HỆ THỐNG QUẢN TRỊ KINH DOANH XĂNG DẦU
                </h4>
            </div> -->
        </div>
        <div class="topbar-right mr-3 flex">
            <!-- <button pButton pRipple type="button" label="DASHBOARD" [routerLink]="['/']"
                    class="p-button-text p-button-plain topbar-button"></button> -->
        </div>
    </div>

    <div class="align-self-center mt-auto mb-auto w-20rem" *ngIf="enableLogin" style="z-index: 2;">
        <div class="pages-panel flex flex-column">
            <!-- <div class="pages-header px-3 py-1">
                <h2>{{'LOGIN.TITLE' | translate}}</h2>
            </div> -->

            <!-- <div class="pages-detail mb-3 px-3 w-m368">
                <div class="mt-3" style="color: black; font-size: large;">ĐĂNG NHẬP</div>
            p-button styleClass="p-button-text  mr-2 mb-2" *ngFor="let item of dsLanguage"
                    pTooltip=" {{item.code | translate}}" (click)="changeLang(item.code)">
                    <ng-template pTemplate="content">
                        <img src="{{item.imageUrl}}" alt="">
                    </ng-template>
                </p-button
        </div> -->

            <div class="input-panel flex flex-column">
                <form [formGroup]="formGroup">
                    <div class="p-inputgroup p-input-icon-right">
                        <i class="pi pi-user"></i>
                        <input type="text" pInputText [(ngModel)]="model.username" formControlName="username"
                            [ngClass]="{'ng-invalid ng-dirty': (formGroup.get('username').errors?.required) || error}"
                            placeholder="Tên đăng nhập">
                    </div>
                    <div class="p-inputgroup mt-3 p-input-icon-right">
                        <p-password [toggleMask]="true" [feedback]="false" formControlName="password"
                            [(ngModel)]="model.password" (keyup.enter)="clickLogin()" (focus)="onFocus()"
                            [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}"
                            styleClass="p-component p-inputwrapper p-input-icon-right"
                            placeholder="Mật khẩu"></p-password>
                    </div>
                </form>
            </div>
            <button label="{{'LOGIN.FORGOTPASSWORD' | translate}}?" class="p-button-link" style="text-align: right;"
                (click)="goForgotPass()" pButton>
            </button>
            <p-button class="login-button align-self-center" label="{{'LOGIN.BTNLOGIN' | translate}}"
                (click)="clickLogin()">
            </p-button>
        </div>
    </div>

    <div class="align-self-center mt-auto mb-auto w-20rem" *ngIf="!enableLogin" style="z-index: 2;">
        <div class="pages-panel flex flex-column">
            <!-- <div class="pages-header px-3 py-1">
                <h2>{{'LOGIN.FORGOTPASSWORD.TITLE' | translate}}</h2>
            </div> -->

            <!-- <div class="pages-detail mb-3 px-3 w-m368">
                <div class="mt-3" style="color: black;">{{'Liên hệ quản trị hệ thống để được cấp lại mật khẩu.' |
                    translate}}</div>
            </div> -->

            <div class="input-panel flex flex-column">
                <div class="p-inputgroup p-input-icon-right">
                    <i class="pi pi-envelope"></i>
                    <input type="email" (focus)="onFocus()" [(ngModel)]="model.email"
                        [ngClass]="{'ng-invalid ng-dirty': (!model.email) || error}" (keyup.enter)="onResetPassword()"
                        [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}"
                        placeholder="{{'LOGIN.EMAIL' | translate}}" pInputText>
                </div>
                <div class="p-inputgroup mt-3 mb-3 p-input-icon-right">
                    <i class="pi pi-user"></i>
                    <input type="text" pInputText [(ngModel)]="model.username"
                        [ngClass]="{'ng-invalid ng-dirty': (!model.username) || error}" [style]="{'width': '100%'}"
                        [inputStyle]="{'width': '100%'}" placeholder="{{'LOGIN.USERNAME' | translate}}">
                </div>
            </div>
            <p-button class="login-button align-self-center reset-password-button"
                label="{{'LOGIN.RESETPASS' | translate}}" (click)="onResetPassword()">
            </p-button>
            <p-button label="{{'LOGIN.RETURN' | translate}}" styleClass="p-button-outlined bg-white"
                (click)="goForgotPass()" class="mt-2 login-button align-self-center reset-password-button">
            </p-button>
        </div>
    </div>
    <footer class="align-self-center z-2 text-white mb-2 text-center">{{ "COPYRIGHT" | translate }}</footer>
    <div class="bg-overlay"></div>
</div>