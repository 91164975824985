import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ConsignmentFeeApiService } from '../../Services/ConsignmentFee-api.service';
import {CustomerApiService} from "../../Services/Customer-api.service";
import {ProductApiService} from "../../Services/Product-api.service";
import {ProductUnitApiService} from "../../Services/ProductUnit-api.service";

@Component({
    selector: 'app-consignmentFee-form',
    templateUrl: './ConsignmentFee-form.component.html',
    styleUrls: ['./ConsignmentFee-form.component.css']
})
export class ConsignmentFeeFormComponent extends SecondPageEditBase implements OnInit {
    customerList = [];
    productList = [];
    unitList = [];

    constructor(
        protected ConsignmentFeeApiService: ConsignmentFeeApiService,
        protected CustomerApiService: CustomerApiService,
        protected ProductApiService: ProductApiService,
        protected ProductUnitApiService: ProductUnitApiService,
        protected _injector: Injector,
    ) {
        super(ConsignmentFeeApiService, _injector);

        this.formGroup = new FormGroup({
            validFrom: new FormControl(),
            customerId: new FormControl(),
            docNumber: new FormControl(),
            productId: new FormControl(),
            unitId: new FormControl(),
            importFee: new FormControl(),
            roadPumpingFee: new FormControl(),
            waterwayPumpingFee: new FormControl(),
            maintenanceFee: new FormControl(),
            warehouseRentalFee: new FormControl(),
            status: new FormControl()
        });

    }

    ngOnInit() {
        this.getData();
    }

    productUnits(id: number) {
        // return this.productList.find(p => p.id === id)?.productUnits;
        return this.unitList;
    }

    getData() {
        this.CustomerApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.customerList, rs.data, "customerCode,customerName", "customerCode");
            }
        })
        this.ProductApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.productList, rs.data, "productCode,productName", "productCode");
            }
        })
        this.ProductUnitApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.unitList, rs.data, "shortName", "unitCode");
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.ConsignmentFeeApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.itemDetail.validFrom = new Date(this.itemDetail.validFrom);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

