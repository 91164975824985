import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { SecondPageIndexBase } from "nabit-shared";
import { OrgUnitSettingApiService } from "../../Services/OrgUnitSetting-api.service";
import { OrgUnitSettingFormComponent } from "../form/OrgUnitSetting-form.component";

@Component({
    selector: "app-orgUnitSetting-index",
    templateUrl: "./OrgUnitSetting-index.component.html",
    styleUrls: ["./OrgUnitSetting-index.component.css"]
})
export class OrgUnitSettingIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild("pEdit") pEdit: OrgUnitSettingFormComponent;
    tabMenuItems = [];

    constructor(
        protected _OrgUnitSettingApiService: OrgUnitSettingApiService,
        protected _injector: Injector,
    ) {
        super(_OrgUnitSettingApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant("Cấu hình"), icon: "pi pi-book", routerLink: "../OrgUnitSetting"},
        ];
        this.cols = [
            {field: "idOrgUnit", header: this._translateService.instant("Cửa hàng"), visible: true, width: "auto", sort: true,},
            {field: "settingName", header: this._translateService.instant("Nhóm cấu hình"), visible: true, width: "auto", sort: true,},
            {field: "settingParam", header: this._translateService.instant("Cấu hình"), visible: true, width: "auto", sort: true,},
            {field: "settingValue", header: this._translateService.instant("Giá trị"), visible: true, width: "auto", sort: true,},
            {field: "status", header: this._translateService.instant("Trạng thái"), visible: true, width: "auto", sort: true,},
        ];

        this.getData();
    }

    add() {
        this.pEdit.showPopup();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    getData() {
        this._OrgUnitSettingApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.dataSource = rs.data;
                    this.totalRecord = rs.totalRecord;
                    this.dataExport = rs.data;
                }
            }
        );
    }
}
