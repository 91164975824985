import { Component, OnInit } from '@angular/core';
import { EChartsOption } from "echarts";
import { getBgColor } from "../dashboard/lib";
import { ReportApiService } from "../RPT/Services/Report-api.service";

@Component({
    selector: 'app-dashboard2',
    templateUrl: './dashboard2.component.html',
    styleUrls: ['./dashboard2.component.scss']
})
export class Dashboard2Component implements OnInit {
    constructor(private reportApiService: ReportApiService) {
    }

    ngOnInit(): void {
        this.reportApiService.ExportJson()
            .then(rs => {
                let d = rs.data.dashboard02;
                let labels = d[0].inventoryLabel.split(', ');
                let options = {
                    ...this.options,
                    xAxis: [
                        {
                            type: 'category',
                            data: labels
                        }
                    ],
                    series: d.map(p => {
                        return {
                            name: p.productName,
                            itemStyle: { color: getBgColor(p.productCode) },
                            type: 'line',
                            data: p.inventoryArr.split(', ').map(a => +a)
                        }
                    })
                };
                this.options = options as any;
            })

        let d = new Date(), y = d.getFullYear(), m = d.getMonth() + 1;
        let day = new Date(new Date().setMonth(m + 1)).getDate();
        for (let dir of [1, -1]) {
            this.reportApiService.ExportJsonWithParam(
                "DASHBOARD.03",
                `{fromDate:'${y}/${m.toString().padStart(2, '0')}/01', toDate:'${y}/${m.toString().padStart(2, '0')}/${day}', trDir:${dir}}`
            )
                .then(rs => {
                    let { data: { dashboard3 } } = rs;
                    this.remapData(dashboard3, dir)
                })
        }
    }

    remapData(dashboardData, dir) {
        let options = { ...this.optionsIn };

        let keys = new Set<string>();
        let flattened = [];
        for (let r of dashboardData) {
            let d = r.data.split(',').map(a => +a);
            let zipped = r.serial.split(',').map((a, i) => [a, d[i]]);
            for (let label of r.serial.split(',')) {
                keys.add(label);
            }

            for (let [date, value] of zipped) {
                flattened.push({ ...r, timestamp: date, value: value / 1000 });
            }
        }

        let m = new Map();
        for (let record of flattened) {
            let key = record.productCode + '-' + record.productSourceCode;
            let a = m.get(key) ?? [];
            a.push(record);
            m.set(key, a);
        }

        let final = [];
        for (let [_, row] of m) {
            let m = new Map(row.map(p => [p.timestamp, p.value]));
            let data = [...keys].map(k => m.get(k));
            final.push({
                name: row[0].productName,
                type: 'bar',
                data,
                stack: row[0].productSourceCode,
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor(row[0].productCode) }
            })
        }
        options.yAxis[0] = {
            type: 'category',
            data: [...keys].map(r => {
                let p = r.split('-');
                return p.slice(1).join('-');
            })
        }
        options.series = final;
        if (dir === 1) {
            this.optionsIn = options;
        } else {
            this.optionsOut = options;
        }
    }

    options: EChartsOption = {
        tooltip: {
            trigger: 'item'
        },
        legend: {},
        xAxis: [],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: []
    }

    optionsIn: EChartsOption = {
        tooltip: {
            trigger: 'item'
        },
        legend: {},
        yAxis: [
            {
                type: 'category',
                data: ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5']
            }
        ],
        xAxis: [
            {
                type: 'value',
                name: 'Thể tích',
                axisLabel: {
                    formatter: '{value} m3'
                }
            }
        ],
        series: [
            {
                name: 'DO', type: 'bar', stack: 'Nhập khẩu',
                data: [100, 100, 100, 130],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1103001') }
            },
            {
                name: '92', type: 'bar', stack: 'Nhập khẩu',
                data: [50, 75, 125, 115],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1102001') }
            },
            {
                name: '95', type: 'bar', stack: 'Nhập khẩu',
                data: [50, 125, 95, 85],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1101001') }
            },
            {
                name: 'DO', type: 'bar', stack: 'Nhà máy',
                data: [30, 45, 55, 75],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1103001') }
            },
            {
                name: '92', type: 'bar', stack: 'Nhà máy',
                data: [25, 95, 85, 65],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1102001') }
            },
            {
                name: '95', type: 'bar', stack: 'Nhà máy',
                data: [15, 35, 65, 40],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1101001') }
            },
            {
                name: 'DO', type: 'bar', stack: 'Nội địa',
                data: [105, 25, 70, 80],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1103001') }
            },
            {
                name: '92', type: 'bar', stack: 'Nội địa',
                data: [50, 45, 90, 60],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1102001') }
            },
            {
                name: '95', type: 'bar', stack: 'Nội địa',
                data: [30, 40, 20, 55],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1101001') }
            }
        ]
    }

    optionsOut: EChartsOption = {
        tooltip: {
            trigger: 'item'
        },
        legend: {},
        xAxis: [
            {
                type: 'category',
                data: ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5']
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Thể tích',
                axisLabel: {
                    formatter: '{value} lít'
                }
            },
            {
                type: 'value',
                name: 'Doanh thu',
                min: 0,
                max: 200,
                interval: 20,
                axisLabel: {
                    formatter: '{value}M'
                }
            }
        ],
        series: [
            {
                name: 'DO', type: 'bar', stack: 'NK',
                data: [100, 100, 100, 130],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1103001') }
            },
            {
                name: '92', type: 'bar', stack: 'NK',
                data: [50, 75, 125, 115],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1102001') }
            },
            {
                name: '95', type: 'bar', stack: 'NK',
                data: [50, 125, 95, 85],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1101001') }
            },
            {
                name: 'DO', type: 'bar', stack: 'NM',
                data: [30, 45, 55, 75],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1103001') }
            },
            {
                name: '92', type: 'bar', stack: 'NM',
                data: [25, 95, 85, 65],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1102001') }
            },
            {
                name: '95', type: 'bar', stack: 'NM',
                data: [15, 35, 65, 40],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1101001') }
            },
            {
                name: 'DO', type: 'bar', stack: 'ND',
                data: [105, 25, 70, 80],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1103001') }
            },
            {
                name: '92', type: 'bar', stack: 'ND',
                data: [50, 45, 90, 60],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1102001') }
            },
            {
                name: '95', type: 'bar', stack: 'ND',
                data: [30, 40, 20, 55],
                emphasis: { focus: 'series' },
                itemStyle: { color: getBgColor('1101001') }
            },
            {
                name: 'Doanh thu',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' M';
                    }
                },
                data: [120, 80, 165, 190]
            }
        ]
    }

    options4: EChartsOption = {
        tooltip: {
            trigger: 'item'
        },
        legend: {},
        series: [
            {
                type: 'pie',
                radius: ['50%', '80%'],
                startAngle: 180,
                label: {
                    color: '#000',
                    fontSize: '30',
                    position: 'center',
                    formatter: () => {
                        return "500M";
                    },
                },
                data: [
                    { value: 200, name: 'Phải thu', itemStyle: { color: '#A1C03A' } },
                    { value: 300, name: 'Phải trả', itemStyle: { color: '#ABC1BA' } },
                ]
            }
        ]
    }
}
