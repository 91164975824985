<div class="card p-0">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <p-tabView [(activeIndex)]="activeIndex">
                <p-tabPanel header="Thông tin chung">
                    <div class="formgrid grid ">
                        <div class="formgrid grid col-12 md:col-4">
                            <div class="field col-12 md:col-6">
                                <label for="code">{{'Mã báo cáo' | translate}}</label>
                                <input type="text" pInputText [(ngModel)]="itemDetail.reportCode"
                                    formControlName="reportCode" class="w-full">
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="title">{{'Tên tắt' | translate}}</label>
                                <input type="text" pInputText [(ngModel)]="itemDetail.shortName"
                                    formControlName="shortName" class="w-full">
                            </div>
                            <div class="field col-12">
                                <label for="title">{{'Tên báo cáo' | translate}}</label>
                                <input type="text" pInputText [(ngModel)]="itemDetail.reportName"
                                    formControlName="reportName" class="w-full">
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="status">{{'Nhóm B/C' | translate}}</label>
                                <p-dropdown formControlName="reportGroup" [options]="arrReportGroup"
                                    [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                    [(ngModel)]="itemDetail.reportGroupId" [filter]="true" filterBy="label,value"
                                    styleClass="w-full">
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="status">{{'Phân loại' | translate}}</label>
                                <p-dropdown formControlName="reportType" [options]="arrReportType"
                                    [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                    [(ngModel)]="itemDetail.reportTypeId" [filter]="true" filterBy="label,value"
                                    styleClass="w-full">
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-12">
                                <label for="title">{{'File templates' | translate}}</label>
                                <input type="text" pInputText [(ngModel)]="itemDetail.templateURL"
                                    formControlName="templateURL" class="w-full">
                            </div>
                            <div class="field col-12">
                                <label for="title">{{'Tables Name' | translate}}</label>
                                <input type="text" pInputText [(ngModel)]="itemDetail.tableNames"
                                    formControlName="tableNames" class="w-full">
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="title">{{'Loại SQL' | translate}}</label>
                                <p-dropdown formControlName="sqlType" [options]="arrSqlType" [autoDisplayFirst]="false"
                                    dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.sqlType"
                                    [filter]="true" filterBy="label,value" styleClass="w-full">
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="status">{{'Trạng thái' | translate}}</label>
                                <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                                    dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                                    [filter]="true" filterBy="label,value" styleClass="w-full">
                                </p-dropdown>
                            </div>
                            <div class="field col-6">
                                <p-checkbox label="{{'Lưu&nbsp;Log' | translate}}" formControlName="isLogReport"
                                    [(ngModel)]="itemDetail.isLogReport" [binary]="1" value="1" inputId="isLogReport"
                                    styleClass="w-full">
                                </p-checkbox>
                            </div>
                            <div class="field col-6">
                                <p-checkbox label="{{'Lưu&nbsp;Cache' | translate}}" formControlName="isCache"
                                    [(ngModel)]="itemDetail.isCache" [binary]="1" value="1" inputId="isCache"
                                    styleClass="w-full">
                                </p-checkbox>
                            </div>
                            <div class="col-12">
                                <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}"
                                    icon="pi pi-save" [disabled]="submitting" class="p-button"
                                    (click)="saveAndClose()"></button>
                                <button pButton pRipple type="button" label="{{'BUTTON.SAVE_CONTINUE' | translate}}"
                                    icon="pi pi-save" *ngIf="itemDetail.id == 0" [disabled]="submitting"
                                    class="p-button float-right" (click)="save()"></button>
                                <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}"
                                    icon="pi pi-times" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                                    class="p-button-outlined" (click)="cancel()">
                                </button>
                                <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}"
                                    icon="pi pi-copy" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                                    class="p-button float-right" (click)="saveAsCopy()"></button>
                            </div>
                        </div>
                        <div class="col-12 md:col-8">
                            <label for="SQL">{{'SQL' | translate}}</label>
                            <textarea inputId="textarea" rows="28" cols="30" formControlName="sql"
                                [(ngModel)]="itemDetail.sql" pInputTextarea class="w-full"></textarea>
                            <button pButton pRipple type="button" label="{{'Xem báo cáo' | translate}}"
                                icon="pi pi-copy" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                                class="p-button float-right mt-1" (click)="viewReport()"></button>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Điều kiện lọc" *ngIf="!isLoading">
                    <app-filterCondition-index [itemId]="itemDetail.id"></app-filterCondition-index>
                </p-tabPanel>
            </p-tabView>
        </form>
    </div>
</div>
