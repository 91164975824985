import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ReportFormComponent } from '../form/Report-form.component';
import { ReportApiService } from '../../Services/Report-api.service';
import { AppBreadcrumbService } from '../../../layouts/main-layout/app.breadcrumb.service';
import { ReportGroupApiService } from '../../Services/ReportGroup-api.service';
@Component({
    selector: 'app-report-index',
    templateUrl: './Report-index.component.html',
    styleUrls: ['./Report-index.component.css']
})
export class ReportIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild('pEdit') pEdit: ReportFormComponent;
    tabMenuItems = [];
    reportGroupId: number = 0;
    reportTypeId: number;
    arrReportGroup = [];

    constructor(
        protected _ReportApiService: ReportApiService,
        protected _ReportGroupApiService: ReportGroupApiService,
        protected _injector: Injector,
        public app: AppComponent,
        private breadcrumbService: AppBreadcrumbService
    ) {
        super(_ReportApiService, _injector);
        this._ReportApiService = _ReportApiService;
        this.breadcrumbService.setItems([
            { label: 'RPT' },
            { label: 'Report', routerLink: ['/RPT/Report'] }
        ]);
    }

    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant('Báo cáo'), icon: 'pi pi-book', routerLink: '../Report' },
            { label: this._translateService.instant('Nhóm báo cáo'), icon: 'pi pi-book', routerLink: '../ReportGroup' },
            { label: this._translateService.instant('Danh mục lọc'), icon: 'pi pi-book', routerLink: '../MasterData' }
        ];
        this.cols = [
            { field: 'reportCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true },
            { field: 'reportName', header: this._translateService.instant('FORM.TITLE'), visible: true, width: 'auto', sort: true },
            { field: 'templateURL', header: this._translateService.instant('FORM.URL'), visible: true, width: 'auto', },
            { field: 'tableNames', header: this._translateService.instant('FORM.TABLENAMES'), visible: true, width: 'auto', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }
    seardOpen() {
        this.openSearchAdv = !this.openSearchAdv;
    }
    edit(id?: number) {
        this._router.navigateByUrl(`RPT/ReportForm/${id}`);
    }

    add() {
        this._router.navigateByUrl(`RPT/ReportForm`);
    }
    viewFilterData() {
        this._router.navigate([`/RPT/FilterCondition/${this.selectedItems[0].id}`]);
    }
    getData() {
        this.dataSource = [];
        this.isLoading = true;
        this.resetBulkSelect();
        this._ReportGroupApiService.getsInUse().then(response => {
            if (response.success) {
                this.arrReportGroup = response.data.map(data => {
                    return {
                        label: data.groupName,
                        value: data.id,
                    }
                });
                this.arrReportGroup.push({
                    label: "Tất cả",
                    value: 0,
                });
            }
        })
        var model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            reportGroupId: this.reportGroupId,
            reportGroupTypeId: this.reportTypeId,
        }
        this._ReportApiService.find(model)
            .then(response => {
                if (response.success) {
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }
}
