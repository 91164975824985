import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'nabit-shared';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndpoint}/language`);
    }

    getsAll() {
        const queryString = `${this.serviceUri}/GetsAll`;
        return this.defaultGet(queryString);
    }
}
