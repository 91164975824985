import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService, } from 'primeng/api';

@Component({

    // tslint:disable-next-line: component-selector
    selector: 'validation-summary',
    templateUrl: './validation-summary.component.html',
    styleUrls: ['./validation-summary.component.scss'],
    providers: [MessageService]
})
export class ValidationSummaryComponent implements OnInit, OnDestroy {


    @Input() formGroup: FormGroup;
    @Input() formElement: any;
    @Input() ngClass = 'ui-g-12 ui-md-12';

    errors: Message[] = [];

    errorString = '';

    constructor(
        protected _translateService: TranslateService
    ) { }

    ngOnInit(
    ) {
    }

    ngOnDestroy(): void {
        this.errors.length = 0;
    }


    resetErrorMessages() {
        this.errors.length = 0;
        this.errors = [];
    }

    generateErrorMessages(formGroup: FormGroup) {
        this.errorString = '';

        for (const controlName in formGroup.controls) {
            const control = formGroup.controls[controlName];
            const errors = control.errors;

            if (errors === null || errors.count === 0) {
                continue;
            }
            // Handle the 'required' case
            const controlObj = this.formElement.querySelector(`[for='${controlName}']`);
            const controlText = controlObj ? controlObj.innerText : controlName;

            if (errors.pattern) {
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: this._translateService.instant('Validation.pattern') });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.pattern')} <br />`;
            }

            if (errors.incorrect) {
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: this._translateService.instant('Validation.incorrect') });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.incorrect')}<br />`;
            }

            if (errors.duplicate) {
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: this._translateService.instant('Validation.duplicate') });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.duplicate')} <br />`;
            }

            if (errors.nomatch) {
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: this._translateService.instant('Validation.nomatch') });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.nomatch')} <br />`;
            }

            if (errors.required) { 
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: this._translateService.instant('Validation.required') });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.required')} <br />`;
            }

            // Handle 'minlength' case
            if (errors.minlength) {
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: this._translateService.instant('Validation.minlength', { minlength: errors.minlength.requiredLength }) });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.minlength', { minlength: errors.minlength.requiredLength })} <br />`;
            }

            if (errors.email) {
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: this._translateService.instant('Validation.email') });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.email')} <br />`;
            }

            if (errors.min) {
                this.errors.push({ severity: 'error', summary: this._translateService.instant(controlText), detail: `${this._translateService.instant('Validation.min')}: ${errors.min.min}` });
                this.errorString += `${controlText} ${this._translateService.instant('Validation.min')}: ${errors.min.min} <br />`;
            }
        }

    }

    showValidationSummary() {
        this.resetErrorMessages();
        this.generateErrorMessages(this.formGroup);
    }

    getLengthOfErrors() {
        return this.errors;
    }
}
