export enum PermissionTypes {
    PAGE = 1,
    CONTROL = 2
}


export enum NotificationObjectType {
    CATEGORY_UPDATED = '-1'  // Cập nhật mới danh mục
}


export enum TemplateType {
    EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    DOC_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8'
}

export enum Extension {
    EXCEL_EXTENSION = '.xlsx',
    DOC_EXTENSION = '.docx'
}

export enum Pattern {
    datetime = '^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$'
}

export enum NotificationIsRead {
    Readed = 1,
    UnRead = 0
}

export enum PlanPeriodType {
    Year = 1,
    Quarter = 2,
    Month = 3
}

export enum TrDirection {
    Export = -1,
    Import = 1
}

export enum RequirementType {
    NotAvailable = "N",
    Optional = "O",
    Required = "Y",
    Batch = "B",
    Fund = "F",

    Warehouse = "W",
    Place = "P"
}
export enum SelectType{
    NotAvailable = "N",
    Default = "-1",
    Manual = "M",
}
export enum UnitType {
    LTT = 1,
    L15,
    KG
}

export enum PriceRequire{
    NotEditAble = "",
    CalFromBasePrice = "1",
    CalFromUnitPrice = "2"
}

export enum PriceCategory {
    NotAvailable = -1
}

export enum InvoiceType {
    C = 1,
    I = 2,
    F = 3,
    O = 4,
    T = 5
}

export enum DeliveryType {
    CFR = 1,
    CIF = 2,
    FOB = 3
}

export const AllowedInvoiceTypes = {
    [DeliveryType.CFR]: [InvoiceType.C, InvoiceType.O, InvoiceType.T, InvoiceType.I],
    [DeliveryType.CIF]: [InvoiceType.C, InvoiceType.O, InvoiceType.T],
    [DeliveryType.FOB]: [InvoiceType.C, InvoiceType.O, InvoiceType.T, InvoiceType.I, InvoiceType.F],
}

export enum Currency {
    USD = 2,
    VND = 1
}

export enum CostPriceType {
    MOPS = 1,
    Trigger = 2,
    Other
}

export const WarehouseStatusList = [
    { id: 0, label: '[trống]' },
    { id: 1, label: 'Chưa nhập kho' },
    { id: 2, label: 'Đang nhập kho' },
    { id: 3, label: 'Đã nhập kho' },
]

export type ForwardReference = typeof ForwardReference2[0][0];

export const ForwardReference2 = [
    [
        {
            base: '1NB101',
            forward: '1NB102',
            referenceDefault: true,
            referenceWarehouse: true,
            referenceProductId: true,
        },
        {
            base: '1NB102',
            forward: '1XB102',
            referenceDefault: false,
            referenceWarehouse: true,
            referenceProductId: true,
        },
    ],
    // 1XB101, 1XB401, 1XB501, 1XB601, 1XB901
    [
        {
            base: '1NB101',
            forward: '1XB101',
            referenceDefault: false,
            referenceWarehouse: true,
            referenceProductId: true,
        },
        {
            base: '1NB101',
            forward: '1XB401',
            referenceDefault: false,
            referenceWarehouse: true,
            referenceProductId: true,
        },
        {
            base: '1NB101',
            forward: '1XB501',
            referenceDefault: false,
            referenceWarehouse: true,
            referenceProductId: true,
        },
        {
            base: '1NB101',
            forward: '1XB601',
            referenceDefault: false,
            referenceWarehouse: true,
            referenceProductId: true,
        },
        {
            base: '1NB101',
            forward: '1XB901',
            referenceDefault: false,
            referenceWarehouse: true,
            referenceProductId: true,
        },
    ]
]
