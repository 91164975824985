<div class="layout-topbar shadow-4" style="z-index: 1000">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo" routerLink="/">
            <img id="app-logo" src="{{ logoUrl }}" alt="ultima-layout" style="height: 3.25rem">
        </a>

        <a class="layout-menu-button shadow-6" (click)="appMain.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-chevron-right"></i>
        </a>

        <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>

    <div class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
        <div class="layout-topbar-actions-left">
            <b class="text-2xl" *ngIf="subMenuActive">{{ subMenuTitle }}</b>
        </div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item notifications" *ngIf="environment.menuConfig?.useNotification || currentUser.isSuperUser">
                    <a class="layout-topbar-action" (click)="appMain.onTopbarItemClick($event, 'notifications')"
                        pRipple>
                        <span class="p-overlay-badge">
                            <i class="pi pi-bell fs-large" pBadge *ngIf="dataSource.length"
                                [value]="dataSource.length!.toString()"></i>
                            <i class="pi pi-bell fs-large" *ngIf="!dataSource.length"></i>
                        </span>
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'"
                        *ngIf="appMain.activeTopbarItem === 'notifications'">
                        <li class="mb-3">
                            <p-button label="{{'USERINFO.NOTIFICATIONS.COUNT' | translate : {count: totalUnRead} }}"
                                (click)="goNotification()" styleClass="p-button-link mr-2 p-px-3 fs-small"></p-button>
                        </li>
                        <ng-template ngFor let-item [ngForOf]="dataSource">
                            <li class="layout-topbar-action-item">
                                <div class="flex flex-row align-items-center" (click)="readItem(item)">
                                    <div class="ci ci-chat"></div>
                                    <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}"
                                        style="flex-grow: 1;">
                                        <div class="flex align-items-center justify-content-between mb-1">
                                            <span class="fs-small" style="font-weight: bold;"
                                                [class.p-text-bold]="!item.isRead">
                                                {{ item.title }}
                                            </span>
                                            <span class="fs-small">{{item.created | timeAgo}}</span>
                                        </div>
                                        <span class="fs-small">{{ item.body }}</span>
                                    </div>
                                </div>
                            </li>
                        </ng-template>
                    </ul>
                </li>
                <li class="layout-topbar-item app" *ngIf="environment.menuConfig.useMasterMenu || currentUser.issuperuser">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'apps')"
                        pRipple>
                        <i class="pi pi-table fs-large"></i>
                    </a>

                    <div class="layout-topbar-action-panel shadow-6 p-0"
                        [style.min-width]="(topLevelMenus.length * 18) + 'rem'"
                        [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'apps'">
                        <div class="grid grid-nogutter">
                            <div *ngFor="let item of topLevelMenus; let last = last"
                                class="p-3 col border-right-1 border-300">
                                <div class="flex align-items-center flex-column text-color">
                                    <b>{{ item.label }}</b>
                                </div>
                                <br>
                                <ng-container *ngIf="item.items">
                                    <div class="col-12 grid row-gap-2 ml-0">
                                        <a *ngFor="let submenu of item.items"
                                            class="col-6 flex align-items-center flex-column layout-topbar-action-item {{ submenu.disabled ? 'pointer-events-none' : '' }}"
                                            (click)="(!submenu.disabled) && changeMenuRoot(submenu.id, item.label, true)">
                                            <i
                                                class="{{submenu.icon}} action {{ submenu.disabled ? 'opacity-50' : '' }} {{ submenu.backgroundColor }} white-color h-3rem w-3rem flex align-items-center justify-content-center border-circle"></i>
                                            <div class="{{ submenu.disabled ? 'text-500' : '' }}">
                                                {{ submenu.title }}
                                            </div>
                                        </a>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="layout-topbar-item">
                    <a class="layout-topbar-action flex flex-row justify-center align-items-center px-2 rounded-circle"
                        (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple>
                        <img [src]="staff.imageURL || user.avatar" class="rounded-circle" alt="avatar"
                            style="width: 32px; height: 32px;">
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'"
                        *ngIf="appMain.activeTopbarItem === 'profile'"
                        style="min-width: 25rem; padding: 0 0; margin-left: 2rem; margin-right: 2rem;">
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row justify-content-between align-items-center px-2"
                                [routerLink]="['/core/myprofile']" pRipple>
                                <div></div>
                                <div class="flex flex-row w-full align-items-center justify-content-between">
                                    <img [src]="staff.imageURL" class="rounded-circle layout-topbar-action" alt="avatar"
                                        style="width: 64px; height: auto;">
                                    <div class="flex align-items-center flex-grow-1">
                                        <h3 class="mb-0">{{ this.currentUser.displayName }}</h3>
                                    </div>
                                </div>
                                <div></div>
                            </a>
                        </li>
                        <p-divider styleClass="my-1"></p-divider>
                        <li class="layout-topbar-action-item line-height-3">
                            <a class="flex flex-row align-items-center" pRipple [routerLink]="['/core/myprofile']">
                                <i class="pi pi-user text-lg" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span class="text-lg">{{'USERINFO.MNU_INFO' | translate}}</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item line-height-3">
                            <a class="flex flex-row align-items-center" pRipple [routerLink]="['/core/myprofile2']">
                                <i class="pi pi-home text-lg" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span class="text-lg">{{'USERINFO.MNU_INFO_QLCH' | translate}}</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item line-height-3">
                            <a class="flex flex-row align-items-center" pRipple
                                href="http://oilpy.nabit.vn/BOS/Helpers/PVOilPY_QLCHXD_TaiLieuHuongDanSuDung_v1.0.pdf">
                                <i class="pi pi-question text-lg"
                                    [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span class="text-lg">{{'Hướng dẫn sử dụng' | translate}}</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item" (click)="themeConfigActive = true">
                            <a class="flex flex-row align-items-center line-height-3">
                                <i class="pi pi-cog text-lg" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span class="text-lg">{{'Tùy chỉnh giao diện' | translate}}</span>
                            </a>
                        </li>
                        <!--li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple [routerLink]="['/core/notifications']">
                                <i class="pi pi-bell" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>{{'USERINFO.NOTIFICATIONS' | translate}}</span>
                            </a>
                        </li-->
                        <p-divider styleClass="my-1"></p-divider>
                        <li class="layout-topbar-action-item line-height-3" (click)="changepasswdActive = true">
                            <a class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-lock text-lg" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span class="text-lg">{{'Đổi mật khẩu' | translate}}</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item line-height-3">
                            <a class="flex flex-row align-items-center" pRipple (click)="appMain.onLogout()">
                                <i class="pi pi-power-off text-lg" style="color: red"
                                    [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span class="text-lg" style="color: red">{{'USERINFO.MNU_LOGOUT' | translate}}</span>
                            </a>
                        </li>
                        <!--li class="layout-topbar-action-item"  style="padding: 0 0;">
                            <p-button styleClass="p-button-text  mr-2 mb-2" *ngFor="let item of dsLanguage"
                                pTooltip=" {{item.code | translate}}" (click)="changeLang(item.code)">
                                <ng-template pTemplate="content">
                                    <img src="{{item.imageUrl}}" alt="">
                                </ng-template>
                            </p-button>
                        </li-->
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
<app-config [configActive]="themeConfigActive" (onHide)="themeConfigActive = false"></app-config>
<app-changepasswd [active]="changepasswdActive" (onHide)="changepasswdActive = false"></app-changepasswd>
