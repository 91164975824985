import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ProductGroupFormComponent } from '../form/ProductGroup-form.component';
import { ProductGroupApiService } from '../../Services/ProductGroup-api.service';

@Component({
    selector: 'app-productgroup-index',
    templateUrl: './ProductGroup-index.component.html',
    styleUrls: ['./ProductGroup-index.component.css']
})
export class ProductGroupIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ProductGroupFormComponent;
    tabMenuItems = [];
    constructor(
        protected _ProductGroupApiService: ProductGroupApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_ProductGroupApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Hàng hóa'), icon: 'pi pi-book', routerLink: '../Product'},
            {label: this._translateService.instant('Nhóm hàng hóa'), icon: 'pi pi-book', routerLink: '../ProductGroup'},
            {label: this._translateService.instant('Đơn vị tính'), icon: 'pi pi-book', routerLink: '../ProductUnit'}
        ];
        this.cols = [
            { field: 'productGroupCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'productGroupName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'shortName', header: this._translateService.instant('TABLEDATA.SHORTNAME'), visible: true, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];
        this.getData();
    } 

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,  
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._ProductGroupApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }
}