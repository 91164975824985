import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { FundApiService } from '../../Services/Fund-api.service';

@Component({
    selector: 'app-fund-form',
    templateUrl: './Fund-form.component.html',
    styleUrls: ['./Fund-form.component.css']
})
export class FundFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected FundApiService: FundApiService,
        protected _injector: Injector,
    ) {
        super(FundApiService, _injector);

        this.formGroup = new FormGroup({
            fundCode: new FormControl('', [Validators.required]),
            fundName: new FormControl('', [Validators.required]),
            fundGroup: new FormControl('', [Validators.required]),
            belongTo: new FormControl('')
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.FundApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

