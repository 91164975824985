import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ContractTypeFormComponent } from '../contractType-form/contractType-form.component';
import { ContractTypeApiService } from '../../../Services/ContractType-api.service';

@Component({
  selector: 'app-contractType-index',
  templateUrl: './contractType-index.component.html',
  styleUrls: ['./contractType-index.component.scss']
})
export class ContractTypeIndexComponent extends SecondPageIndexBase implements OnInit {
  @ViewChild('pEdit') pEdit: ContractTypeFormComponent;
  tabMenuItems = [];
  constructor(
    protected _ContractTypeApiService: ContractTypeApiService,
    protected _injector: Injector,
    public app: AppComponent,
  ) {
    super(_ContractTypeApiService, _injector);
  }

  ngOnInit() {
    this.cols = [
      { field: 'typeCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true, },
      { field: 'typeName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true, },
      { field: 'shortName', header: this._translateService.instant('TABLEDATA.SHORTNAME'), visible: true, width: 'auto', sort: true, },
      { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
      { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
      { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
    ];

    this.getData();
  }
  edit(id?: number) {
    this.pEdit.showPopup(id);
  }

  add() {
    this.pEdit.showPopup();
  }

  getData() {
    this.isLoading = true;
    this.resetBulkSelect();
    this.dataSource = [];

    this._ContractTypeApiService.getsAll().then(response => {
      if (response.success) {
        this.dataSource = response.data;
        this.totalRecord = response.totalRecord;
        this.dataExport = response.data;
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this._notifierService.showHttpUnknowError();
    });
  }
}