import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VehicleApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/Vehicle`);
    }
    GetAll(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetAll`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, null).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    find(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetByTrType(trType: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetByTrType/${trType}`;
        return this.defaultGet(apiUrl);
    }
}
