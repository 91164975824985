import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SecondPageIndexBase} from 'nabit-shared';
import {SerialParamApiService} from '../../../../EInvoice/Services/SerialParam-api.service';
import {StoreInvoiceFormComponent} from '../form/Store-invoice-form.component';
import {BranchConfigApiService} from '../../../../EInvoice/Services/BranchConfig-api.service';

@Component({
    selector: 'app-store-invoice-index',
    templateUrl: './Store-invoice-index.component.html',
    styleUrls: ['./Store-invoice-index.component.css'],
})
export class StoreInvoiceIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild('pEdit') pEdit: StoreInvoiceFormComponent;
    arrBranchConfig = [];

    constructor(
        protected _SerialParamApiService: SerialParamApiService,
        protected _injector: Injector,
        protected _BranchConfigApiService: BranchConfigApiService,
    ) {
        super(_SerialParamApiService, _injector);
    }

    ngOnInit() {
        this.cols = [
            {field: 'idBranchConfig', header: this._translateService.instant('idBranchConfig'), visible: true, width: 'auto', sort: true,},
            {field: 'invoiceSerial', header: this._translateService.instant('invoiceSerial'), visible: true, width: 'auto', sort: true,},
            {field: 'invoicePattern', header: this._translateService.instant('invoicePattern'), visible: true, width: 'auto', sort: true,},
            {field: 'businessId', header: this._translateService.instant('businessId'), visible: true, width: 'auto', sort: true,},
            {field: 'trTypes', header: this._translateService.instant('trTypes'), visible: true, width: 'auto', sort: true,},
            {
                field: 'validFrom',
                header: this._translateService.instant('validFrom'),
                visible: true,
                width: 'auto',
                sort: true,
                dateFormat: 'dd/MM/yyyy',
            },
            {field: 'startNumber', header: this._translateService.instant('startNumber'), visible: true, width: 'auto', sort: true,},
            {field: 'endNumber', header: this._translateService.instant('endNumber'), visible: true, width: 'auto', sort: true,},
            {
                field: 'modifiedBy',
                header: this._translateService.instant('TABLEDATA.MODIFIED_BY'),
                visible: false,
                width: 'auto',
                sort: true,
            },
            {
                field: 'modified',
                header: this._translateService.instant('TABLEDATA.MODIFIED'),
                visible: false,
                width: 'auto',
                sort: true,
                dateFormat: 'dd/MM/yyyy HH:mm',
            },
            {field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true,},
        ];
        this.getData();
        this.getBranchConfig();
    }

    add() {
        this.pEdit.showPopup();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    getData() {
        this.isLoading = true;
        this._SerialParamApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
                this.totalRecord = rs.totalRecord;
                this.dataExport = rs.data;
            }
            this.isLoading = false;
        }, err => {
            this._notifierService.showHttpUnknowError();
            this.isLoading = false;
        });
    }

    async getBranchConfig() {
        this.isLoading = true;
        this._BranchConfigApiService.getsInUse().then(rs => {
            this.isLoading = false;
            if (rs.success) {
                this.convertDataToOptions(this.arrBranchConfig, rs.data, 'fKeyPrecode');
            }
        }, error => {
            this._notifierService.showHttpUnknowError();
            this.isLoading = false;
        });
    }
}
