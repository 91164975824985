import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { FinTypeApiService } from '../../Services/FinType-api.service';
import {FinGroupApiService} from "../../Services/FinGroup-api.service";
import {FundApiService} from "../../Services/Fund-api.service";
import {GLAccountApiService} from "../../Services/GLAccount-api.service";
import {GLGroupApiService} from "../../Services/GLGroup-api.service";
import {PaymentTypeApiService} from "../../Services/PaymentType-api.service";

@Component({
    selector: 'app-finType-form',
    templateUrl: './FinType-form.component.html',
    styleUrls: ['./FinType-form.component.css']
})
export class FinTypeFormComponent extends SecondPageEditBase implements OnInit {
    finGroupList = [];
    fundList = [];
    accountList = [];
    groupList = [];
    paymentList = [];

    selectList = [
        { label: 'N/A', value: '0' },
        { label: 'Quỹ' , value: '1' },
        { label: 'T/K Nội bộ' , value: '2' },
    ]

    targetSelect = [
        { label: 'N/A', value: '0' },
        { label: 'CBCNV', value: '1' },
        { label: 'Product Group', value: '2' },
        { label: 'Product', value: '3' },
    ]

    constructor(
        protected FinTypeApiService: FinTypeApiService,
        protected FinGroupApiService: FinGroupApiService,
        protected FundApiService: FundApiService,
        protected GLAccountApiService: GLAccountApiService,
        protected GLGroupApiService: GLGroupApiService,
        protected PaymentTypeApiService: PaymentTypeApiService,
        protected _injector: Injector,
    ) {
        super(FinTypeApiService, _injector);

        this.formGroup = new FormGroup({
            finCode: new FormControl('', [Validators.required]),
            finName: new FormControl('', [Validators.required]),
            finGroupId: new FormControl(''),
            srcAcct: new FormControl(''),
            srcFund: new FormControl(''),
            srcSelect: new FormControl(''),
            srcAcctGrp: new FormControl(''),
            dstAcct: new FormControl(''),
            dstFund: new FormControl(''),
            dstSelect: new FormControl(''),
            dstAcctGrp: new FormControl(''),
            targetSelect: new FormControl(''),
            trTypeRef: new FormControl(''),
            trTypeRefStatus: new FormControl('')
        });
    }

    ngOnInit() {
        this.load();
    }

    async load() {
        this.submitting = true;
        await this.FinGroupApiService.getsAll().then(rs => {
            if (rs.success) {
                this.finGroupList = rs.data;
            }
        })
        await this.FundApiService.getsAll().then(rs => {
            if (rs.success) {
                this.fundList = [
                    { fundName: 'N/A', fundCode: null },
                    ...rs.data
                ];
            }
        })

        await this.GLAccountApiService.getsAll().then(rs => {
            if (rs.success) {
                this.accountList = [
                    { name: 'N/A', code: null },
                    ...rs.data
                ];
            }
        })

        await this.GLGroupApiService.getsAll().then(rs => {
            if (rs.success) {
                this.groupList = rs.data;
            }
        })

        await this.PaymentTypeApiService.getsAll().then(rs => {
            if (rs.success) {
                this.paymentList = [
                    { code: '0', name: 'N/A' },
                    ...rs.data
                ];
            }
        })

        this.submitting = false;
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.FinTypeApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

