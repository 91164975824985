import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageEditBase, CorePublicService, HtMenuService } from 'nabit-shared';
import { FormBuilder, Validators } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../core/services/language.service';
import { MenuRootService } from "../services/menu-root.service";

@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
    styleUrls: ['./app.login.component.css']
})
export class AppLoginComponent extends SecondPageEditBase implements OnInit {
    model: LoginModel = new LoginModel();
    error = false;
    returnUrl: string;
    enableLogin = true;
    dsLanguage = [];
    showPassword = false;

    constructor(
        protected _injector: Injector,
        private _router: ActivatedRoute,
        private formBuilder: FormBuilder,
        private _oauthService: OAuthService,
        private _menuRootService: MenuRootService,
        private _menuService: HtMenuService,
        private _corePublicService: CorePublicService,
        private translate: TranslateService,
        private _languageService: LanguageService,
    ) {
        super(null, _injector);

        this.formGroup = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required]
        });
    }

    async ngOnInit() {
        this.getLangs();
        this.returnUrl = this._router.queryParams['returnUrl'] || '/';
        this._oauthService.requireHttps = false;
        if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
            // top.location.href = this.returnUrl;
        } else {
            var userLang = localStorage.getItem("language");
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('language', userLang);
        }
    }

    getLangs() {
        this._languageService.getsAll().then(rs => {
            if (rs.success) {
                this.dsLanguage = rs.data;
            }
        })
    }

    changeLang(lang: string) {
        var userLang = lang.match(/vi|en-US/) ? lang : 'vi';
        this.translate.use(userLang);
        localStorage.setItem('language', userLang);
    }

    clickLogin() {
        this.submitting = true;
        if (!this.formGroup.invalid) {
            this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
                if (!rs) {
                    this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.username, this.model.password).then(result => {
                        this._oauthService.setupAutomaticSilentRefresh();
                        this._notifierService.showSuccess(this._translateService.instant('LOGIN.MESSAGE_OK'));
                        setTimeout(() => {
                            this.submitting = false;
                            top.location.href = this.returnUrl;
                        }, 500);
                    },
                        error => {
                            this._notifierService.showWarning(this._translateService.instant(error.error.error));
                            setTimeout(() => {
                                this.submitting = false;
                            }, 500);
                        }
                    );
                }
            });

        } else {
            console.log('invalid');
        }

    }

    onFocus() {
        this.submitting = false;
        this.error = false;
    }

    goForgotPass() {
        this.enableLogin = !this.enableLogin;
    }

    passwordToggle() {
        this.showPassword = !this.showPassword;
    }

    onResetPassword() {
        if (this.model.username !== undefined && this.model.email !== undefined) {
            this._corePublicService.resetPassword(this.model).then(rs => {
                if (rs.success) {
                    this._notifierService.showSuccess(this._translateService.instant("LOGIN.MESSAGE_SENDPASSOK"));
                    this.enableLogin = true;
                } else {
                    this._notifierService.showWarning(rs.message);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant(error.error.error));
            });
        } else {
            this._notifierService.showWarning(this._translateService.instant("LOGIN.FORGOTPASSWORD.DETAIL"))
        }
    }
}


class LoginModel {
    username: string;
    password: string;
    remember: boolean;
    email: string;
}
