<p-dialog #dialog header="{{ 'Cập nhật thông tin' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="field col-4 md:col-2">
                    <div class="label font-medium mt-25">{{"FORM.CODE" | translate}}</div>
                </div>
                <div class="field col-8 md:col-4">
                    <input type="text" pInputText [(ngModel)]="itemDetail.expenseTypeCode" formControlName="code">
                </div>
                <div class="field col-4 md:col-2">
                    <div class="label font-medium mt-25">{{"FORM.TITLE" | translate}}</div>
                </div>
                <div class="field col-8 md:col-4">
                    <input type="text" pInputText [(ngModel)]="itemDetail.expenseTypeName" formControlName="title">
                </div>
                <div class="field col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="field col-8 md:col-4">
                    <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_CONTINUE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2 float-right"
            (click)="save()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2 float-right"
            (click)="saveAsCopy()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.DELETE' | translate}}" icon="pi pi-trash"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button-danger mr-0 float-right"
            (click)="delete()"></button>
    </p-footer>
</p-dialog>