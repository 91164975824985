<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Hao hụt định mức' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '640px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Mã' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" id="docNum" pInputText [(ngModel)]="itemDetail.docNum"
                            formControlName="docNum" placeholder="8 kí tự chữ và số">
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'ValidFrom' | translate}}
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <p-calendar [showIcon]="true" [(ngModel)]="itemDetail.validFrom" formControlName="validFrom"
                            appendTo="body" icon="ci ci-calendar" dateFormat="dd/mm/yy" placeholder="dd/mm/yyy"
                            showTime="true" hourFormat="24">
                        </p-calendar>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Đơn vị' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-10 field">
                    <p-dropdown formControlName="branchId" [options]="arrBranch" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.branchId"
                        [filter]="true" filterBy="label,value" placeholder="Bắt buộc">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Hàng hóa' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <p-dropdown formControlName="productId" [options]="arrProduct" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.productId"
                            [filter]="true" filterBy="label,value" placeholder="Bắt buộc">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'HH nhập' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber formControlName="percentHH_In" [(ngModel)]="itemDetail.percentHH_In"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="4" [min]="0" [max]="100"
                            placeholder="%Hao hụt nhập">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'HH xuất' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber formControlName="percentHH_Out" [(ngModel)]="itemDetail.percentHH_Out"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="4" [min]="0" [max]="100"
                            placeholder="%Hao hụt xuất">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'HH tồn chứa' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber formControlName="percentHH_Store" [(ngModel)]="itemDetail.percentHH_Store"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="4" [min]="0" [max]="100"
                            placeholder="%Hao hụt tồn chứa">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'HH Sục rửa' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber formControlName="percentHH_Clean" [(ngModel)]="itemDetail.percentHH_Clean"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="4" [min]="0" [max]="100"
                            placeholder="%Hao hụt sục rửa">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Type' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber formControlName="lossType" [(ngModel)]="itemDetail.lossType" mode="decimal"
                            placeholder="Định dạnh số">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'TankType' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber formControlName="tankType" [(ngModel)]="itemDetail.tankType" mode="decimal"
                            placeholder="">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-trash"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>