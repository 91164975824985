import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import {PriceRegionApiService} from "../../Services/PriceRegion-api.service";

@Component({
    selector: 'app-priceRegion-form',
    templateUrl: './PriceRegion-form.component.html',
    styleUrls: ['./PriceRegion-form.component.css']
})
export class PriceRegionFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected _priceRegionApiService: PriceRegionApiService,
        protected _injector: Injector,
    ) {
        super(_priceRegionApiService, _injector);

        this.formGroup = new FormGroup({
            regionCode: new FormControl('', [Validators.required]),
            regionName: new FormControl('', [Validators.required]),
            shortName: new FormControl(''),
            status: new FormControl('', [Validators.required]),
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._priceRegionApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

