import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { FilterConditionFormComponent } from '../form/FilterCondition-form.component';
import { FilterConditionApiService } from '../../Services/FilterCondition-api.service';
import { AppBreadcrumbService } from '../../../layouts/main-layout/app.breadcrumb.service';
import { map, takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-filterCondition-index',
    templateUrl: './FilterCondition-index.component.html',
    styleUrls: ['./FilterCondition-index.component.css']
})
export class FilterConditionIndexComponent extends SecondPageIndexBase implements OnInit {

    idReport = 1;
    @Input() itemId: number;
    get itemDetail() {
        return this.itemId;
    }
    @ViewChild('pEdit') pEdit: FilterConditionFormComponent;

    constructor(
        protected _FilterConditionApiService: FilterConditionApiService,
        protected _injector: Injector,
        public app: AppComponent
    ) {
        super(_FilterConditionApiService, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'mdCode', header: this._translateService.instant('MASTERDATA.CODE'), visible: true, width: 'auto', sort: true },
            { field: 'mdNameVn', header: this._translateService.instant('MASTERDATA.TITLE'), visible: true, width: 'auto', sort: true },
            { field: 'masterDataTable', header: this._translateService.instant('MASTERDATA.TABLENAME'), visible: true, width: 'auto', sort: true },
            { field: 'parameterName', header: this._translateService.instant('FILTERCONDITION.PARAMETERNAME'), visible: true, width: 'auto', },
            { field: 'whereClause', header: this._translateService.instant('TABLEDATA.WHERECLAUSE'), visible: true, width: 'auto', sort: true },
            { field: 'orderNo', header: this._translateService.instant('TABLEDATA.ORDERNO'), visible: true, width: 'auto' },
        ];
        this.idReport = this.itemId;
        this.getData();
        // console.log(this.itemId);
        // this._activatedRoute.params
        //     .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
        //     .subscribe(pid => {
        //         console.log(pid);
        //         this.idReport = pid;
        //         this.getData();
        //     });
    }
    seardOpen() {
        this.openSearchAdv = !this.openSearchAdv;
    }
    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }
    getData() {
        if (this.idReport) {
            this._FilterConditionApiService.GetByReportId(this.idReport)
                .then(response => {
                    if (response.success) {
                        this.dataSource = response.data;
                        if (response.totalRecord || response.totalRecord === 0) {
                            this.totalRecord = response.totalRecord;
                        }

                        this.dataExport = response.data;
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this._notifierService.showHttpUnknowError();
                });
        }
    }
    back() {
        this._router.navigate([`/RPT/Report`]);
    }
}