import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase, UserService } from 'nabit-shared';
import { DepartmentApiService } from '../../../Services/Department-api.service';
import { StaffApiService } from '../../../Services/Staff-api.service';

@Component({
    selector: 'app-store-staff-form',
    templateUrl: './Store-staff-form.component.html',
    styleUrls: ['./Store-staff-form.component.css']
})
export class StoreStaffFormComponent extends SecondPageEditBase implements OnInit {

    arrStaffGroup = [];
    arrUserId = [];
    arrPositionId = [];
    arrDepartmentId = [];

    RegexCode = "^[a-z0-9A-Z._-]{1,8}$";
    RegexNumber = "^[0-9]{1,12}$";
    Regextaxcode = "^[0-9]{10,14}$";
    RegexPhone = "^[0-9\.]{1,12}$";
    RegexEmail = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    Regexadd = "^[a-z0-9A-Z.,_-]{1,100}$";

    constructor(
        protected _userService: UserService,
        protected _StaffApiService: StaffApiService,
        protected _injector: Injector,
        private _departmentAPIService: DepartmentApiService,
    ) {
        super(_StaffApiService, _injector);

        this.formGroup = new FormGroup({
            staffCode: new FormControl('', [Validators.required]),
            staffName: new FormControl('', [Validators.required]),
            userId: new FormControl(''),
            positionId: new FormControl('',),
            departmentId: new FormControl(''),
            isManager: new FormControl(''),
            isSaler: new FormControl(''),
            isSealer: new FormControl(''),
            isWorker: new FormControl(''),
            isGuard: new FormControl(''),
            doB: new FormControl(''),
            email: new FormControl(''),
            mobile: new FormControl(''),
            imageURL: new FormControl(''),
            startPageId: new FormControl(''),
            status: new FormControl('', [Validators.required]),
        });
        this.arrPositionId = [
            { label: 'Chuyên viên', value: 1 },
            { label: 'Lãnh đạo', value: 2 },
            { label: 'Phó phòng', value: 3 },
            { label: 'Trưởng phòng', value: 4 },
            { label: 'Phó GĐ', value: 5 },
            { label: 'Giám đốc', value: 6 },
            { label: 'Chủ tịch HĐQT', value: 7 },
        ];
        this.getsUsers();
    }

    ngOnInit() {
    }

    async onShowPopup(id: number) {
        this.submitting = true;

        if (this.arrDepartmentId.length == 0) {
            await this.getsDepartment();
        }

        if (this.arrUserId.length == 0) {
            await this.getsUsers();
        }


        if (id > 0) {
            this._StaffApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                    if (this.itemDetail.startDate) {
                        this.itemDetail.startDate = new Date(this.itemDetail.startDate);
                    }

                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsDepartment() {
        var model = {
            keyword: "",
            status: 1,
            userId: 0,
            positionId: 0,
            departmentId: 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrDepartmentId = [];
        this.arrDepartmentId = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._departmentAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrDepartmentId, rs.data, 'name');
            }
        })
    }

    async getsUsers() {

        var model = {
            keyword: "",
            typeId: 0,
            status: 1,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: "",
            isDesc: true
        }
        this.arrUserId = [];
        this.arrUserId = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._userService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrUserId, rs.data, 'userName');
            }
        })
    }
}
