import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { StabilizationFundFormComponent } from '../form/StabilizationFund-form.component';
import { StabilizationFundApiService } from '../../Services/StabilizationFund-api.service';

@Component({
    selector: 'app-stabilizationFund-index',
    templateUrl: './StabilizationFund-index.component.html',
    styleUrls: ['./StabilizationFund-index.component.css']
})
export class StabilizationFundIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: StabilizationFundFormComponent;
    productId: number =0;
    tabMenuItems = [];
    constructor(
        protected _stabilizationFundApiService: StabilizationFundApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_stabilizationFundApiService, _injector);
    }
    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Khai quỹ bình ổn'), icon: 'pi pi-book', ROUTERLINK: '../StabilizationFund'},
        ];
        this.cols = [
            { field: 'validFrom', header: this._translateService.instant('TABLEDATA.validFrom'), visible: true, width: 'auto', sort: true, style:'text-align:center', dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'productName', header: this._translateService.instant('TABLEDATA.PRODUCT'), visible: true, width: 'auto', },
            { field: 'deductionAmount', header: this._translateService.instant('TAX.DEDUCTIONAMOUNT'), visible: true, width: 'auto',  style:'text-align:right'},
            { field: 'usageAmount', header: this._translateService.instant('TAX.USAGEAMOUNT'), visible: true, width: 'auto',  style:'text-align:right'},
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true,  style:'text-align:center'},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }

    edit(id?:number) {
        console.log(id);
        if(id!=null){
            this.pEdit.showPopup(id);
        }else{
            this.pEdit.showPopup(this.selectedItems[0].id);
        }
    }

    add() {
        this.pEdit.showPopup();
    }
    getData() {
        this.isLoading = true;
        this.selectedItems = [];
        this.ids = [];
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,
            productId: this.productId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderColl: this.orderCol,
            isDesc: this.isDesc
        };
        this._baseService.Find(model)
            .then(response => {
                this.dataSource = response.data;

                if (response.totalRecord || response.totalRecord === 0) {
                    this.totalRecord = response.totalRecord;
                }

                this.afterGetData();
                this.isLoading = false;
            }, error => {
                this._notifierService.showHttpUnknowError();
                this.isLoading = false;
            });
    }
}