<div class="p-formgrid grid">
    <div class="p-fluid md:col-9 col-12">
        <div class="flex flex-row align-items-center gap-4 px-3">
            <b class="h-fit w-fit">
                CHXD
            </b>
            <div>
                <p-dropdown [options]="data?.Branchs?.List ?? []" optionLabel="BranchName" optionValue="No">
                </p-dropdown>
            </div>
        </div>
    </div>
</div>
<div class="p-formgrid grid">
    <div class="p-fluid md:col-6 lg:col-4 col-12">
        <div class="card overview-box p-0 flex flex-column h-full">
            <div class="flex flex-row align-items-center py-3 px-4 gap-3 bg-green-200">
                <div>
                    <i class="pi pi-home mr-1 text-4xl text-white bg-teal-400 p-3 border-circle"></i>
                </div>
                <div class="flex flex-column gap-1">
                    <b class="text-2xl">
                        {{ data?.Branchs?.NumOfPos | number: '1.0-2' }}
                    </b>
                    <div class="text-lg">
                        Cửa hàng
                    </div>
                </div>
            </div>
            <div class="my-3 p-formgrid grid h-full p-2 px-4">
                <div class="col-6 md:col-4 flex flex-row align-items-center text-green-500 justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ data?.Branchs?.Active | number: '1.0-2' }}</b>
                        </div>
                        <div>Hoạt động</div>
                    </div>
                </div>
                <div class="col-6 md:col-4 flex flex-row align-items-center text-yellow-600 justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ data?.Branchs?.Expire | number: '1.0-2' }}</b>
                        </div>
                        <div>Hết hạn</div>
                    </div>
                </div>
                <div class="col-6 md:col-4 flex flex-row align-items-center text-pink-400 justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ data?.Branchs?.Locked | number: '1.0-2' }}</b>
                        </div>
                        <div>Tạm khóa</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-fluid md:col-6 lg:col-4 col-12">
        <div class="card overview-box p-0 flex flex-column h-full">
            <div class="flex flex-row align-items-center py-3 px-4 gap-3 bg-green-200">
                <div>
                    <i class="pi pi-megaphone mr-1 text-4xl text-white bg-teal-400 p-3 border-circle"></i>
                </div>
                <div class="flex flex-column gap-1">
                    <b class="text-2xl">
                        {{ (pumpData.pumpCountXang95 ?? 0) + (pumpData.pumpCountXangE5 ?? 0) + (pumpData.pumpCountDauDo ?? 0) | number: '1.0-0' }}
                    </b>
                    <div class="text-lg">
                        Vòi bơm
                    </div>
                </div>
            </div>
            <div class="my-3 p-formgrid grid h-full p-2 px-4">
                <div class="col-6 md:col-4 flex flex-row align-items-center text-black justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ pumpData.pumpCountXang95 | number: '1.0-0' }}</b>
                        </div>
                        <div>Xăng A95</div>
                    </div>
                </div>

                <div class="col-6 md:col-4 flex flex-row align-items-center text-black justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ pumpData.pumpCountXangE5 | number: '1.0-0' }}</b>
                        </div>
                        <div>Xăng E5</div>
                    </div>
                </div>

                <div class="col-6 md:col-4 flex flex-row align-items-center text-black justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ pumpData.pumpCountDauDo | number: '1.0-0' }}</b>
                        </div>
                        <div>Dầu DO</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-fluid md:col-6 lg:col-4 col-12">
        <div class="card overview-box p-0 flex flex-column h-full">
            <div class="flex flex-row align-items-center py-3 px-4 gap-3 bg-green-200">
                <div>
                    <i class="pi pi-folder mr-1 text-4xl text-white bg-teal-400 p-3 border-circle"></i>
                </div>
                <div class="flex flex-column gap-1">
                    <b class="text-2xl">
                        {{ (tankData.isAutoCount ?? 0) + (tankData.isNotAutoCount ?? 0) | number: '1.0-2' }}
                    </b>
                    <div class="text-lg">
                        Bể
                    </div>
                </div>
            </div>
            <div class="my-3 p-formgrid grid h-full p-2 px-4">
                <div class="col-6 md:col-6 flex flex-row align-items-center text-green-500 justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ (tankData.isAutoCount ?? 0) | number: '1.0-0' }}</b>
                        </div>
                        <div>Có que đo</div>
                    </div>
                </div>
                <div class="col-6 md:col-6 flex flex-row align-items-center text-green-500 justify-content-center p-2 gap-3">
                    <div class="flex flex-row align-items-center gap-2">
                        <div class="value type-green text-6xl">
                            <b>{{ (tankData.isNotAutoCount) | number: '1.0-2' }}</b>
                        </div>
                        <div>Không có que đo</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="p-formgrid grid pt-2">
    <div class="p-fluid md:col-12 lg:col-12 col-12">
        <div class="card overview-box flex flex-column pt-2 h-full">
            <div class="p-formgrid grid">
                <div class="p-fluid md:col-12 lg:col-7 col-7">
                    <div class="flex flex-row gap-4">
                        <span class="p-float-label">
                            <p-calendar [ngModel]="from" (ngModelChange)="from = getDate($event, false); this.getDataSanLuong();"
                                        [view]="filter" [dateFormat]="dateFormat"></p-calendar>
                            <label>
                                Từ
                            </label>
                        </span>
                        <span class="p-float-label">
                            <p-calendar [(ngModel)]="to" (ngModelChange)="to = getDate($event, true); this.getDataSanLuong();"
                                        [view]="filter" [dateFormat]="dateFormat"></p-calendar>
                            <label>
                                Đến
                            </label>
                        </span>
                    </div>
                </div>
                <div class="p-fluid md:col-12 lg:col-2 col-2">
                    <p-selectButton [options]="options" [ngModel]="filter"
                                    styleClass="p-button-primary"
                                    (ngModelChange)="this.filter = $event; changeFilter()">
                    </p-selectButton>
                </div>
                <div class="p-fluid md:col-12 lg:col-3 col-3">
                    <p-selectButton [options]="optionsType" [ngModel]="type"
                                    (ngModelChange)="type = $event"
                                    styleClass="p-button-primary">
                    </p-selectButton>
                </div>
            </div>
            <div class="mt-3">
                <div echarts [options]="optionsOut"></div>
            </div>
        </div>
    </div>
</div>
