import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ExpenseItemsApiService } from '../../Services/ExpenseItems-api.service';
import { ExpenseTypeApiService } from '../../Services/ExpenseType-api.service';
@Component({
    selector: 'app-expenseItems-form',
    templateUrl: './expenseItems-form.component.html',
    styleUrls: ['./expenseItems-form.component.css']
})
export class ExpenseItemsFormComponent extends SecondPageEditBase implements OnInit {
    arrExpenseType:any = [];
    constructor(
        protected _expenseTypeApiService:ExpenseTypeApiService, 
        protected _expenseItemsApiService: ExpenseItemsApiService,
        protected _injector: Injector,
    ) {
        super(_expenseItemsApiService, _injector);

        this.formGroup = new FormGroup({
            expenseCode: new FormControl('', Validators.required),
            expenseName: new FormControl('', Validators.required),
            expenseTypeId: new FormControl('', Validators.required),
            status: new FormControl(''),
        });
    }

    ngOnInit() {
        this._expenseTypeApiService.getsInUse().then((rs)=>{
            if(rs.success){
                this.convertDataToOptions(this.arrExpenseType, rs.data, 'expenseTypeCode,expenseTypeName', 'expenseTypeCode');
            }
            
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._expenseItemsApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

