import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ContractFormComponent } from '../form/Contract-form.component';
import { ContractApiService } from '../../Services/Contract-api.service';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";

@Component({
    selector: 'app-contract-index',
    templateUrl: './Contract-index.component.html',
    styleUrls: ['./Contract-index.component.css']
})
export class ContractIndexComponent extends SecondPageIndexBase implements OnInit, OnChanges {
    // hợp đồng nhập khẩu
    isImport = false;
    get contractOnly() { return this.isImport; }

    constructor(
        protected _ContractApiService: ContractApiService,
        protected _injector: Injector,
        protected route: ActivatedRoute,
        protected router: Router,
        public app: AppComponent,
    ) {
        super(_ContractApiService, _injector);
        route.paramMap.subscribe(q => this.refreshQuery(q));
        this.refreshQuery(route.snapshot.paramMap);
    }

    refreshQuery(q: ParamMap) {
        this.isImport = !!+q.get('import');
        this.getData();
    }

    ngOnInit() {
        this.setCols();
        this.getData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isImport?.currentValue !== changes.isImport?.previousValue) {
            this.setCols();
            this.getData();
        }
    }

    setCols() {
        if (this.isImport) {
            ` + Ngày hợp đồng -> Số hợp đồng -> Nhà cung cấp -> Kho nhập -> Hàng hóa -> Số lượng thùng -> LitTT -> Lit15`
            this.cols = [
                { field: 'dateFrom', header: this._translateService.instant('Ngày hợp đồng'), visible: true, width: 'auto', sort: true, },
                { field: 'contractCode', header: this._translateService.instant('Số hợp đồng'), visible: true, width: 'auto', sort: true, },
                { field: 'dateFrom', header: this._translateService.instant('Nhà cung cấp'), visible: true, width: 'auto', sort: true, },
                { field: 'contractCode', header: this._translateService.instant('Kho nhập'), visible: true, width: 'auto', sort: true, },
                { field: 'contractCode', header: this._translateService.instant('Hàng hóa'), visible: true, width: 'auto', sort: true, },
                { field: 'contractCode', header: this._translateService.instant('LitTT'), visible: true, width: 'auto', sort: true, },
                { field: 'contractCode', header: this._translateService.instant('Lit15'), visible: true, width: 'auto', sort: true, },
            ]
        } else {
            this.cols = [
                { field: 'contractCode', header: this._translateService.instant('Số hợp đồng'), visible: true, width: 'auto', sort: true, },
                { field: 'customerName', header: this._translateService.instant('Khách hàng'), visible: true, width: 'auto', sort: true, },
                { field: 'dateFrom', header: this._translateService.instant('Ngày hiệu lực'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yy' },
                { field: 'dateTo', header: this._translateService.instant('Đến ngày'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yy' },
                { field: 'description', header: this._translateService.instant('Ghi chú'), visible: true, width: 'auto', sort: true, },
                { field: 'circulatingState', header: this._translateService.instant('Trạng thái'), visible: true, width: 'auto', sort: true, },
                { field: 'debtTypeText', header: this._translateService.instant('Hình thức thanh toán'), visible: true, width: 'auto', sort: false, },
                { field: 'creditLimit', header: this._translateService.instant('Hạn mức nợ'), visible: true, width: 'auto', sort: true, },
                { field: 'paymentDebtText', header: this._translateService.instant('Hình thức thanh toán'), visible: true, width: 'auto', sort: false, },
                { field: 'creditPeriod', header: this._translateService.instant('Số ngày nợ / ngày thanh toán'), visible: true, width: 'auto', sort: true, },
                { field: 'quanLyHanMuc', header: this._translateService.instant('Cách lấy ngày'), visible: true, width: 'auto', sort: false, },
                // { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
                // { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
                // { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
            ];
        }
    }

    edit(id?: number) {
        this._router.navigateByUrl(`MD/ContractForm/${id}`);
    }

    add() {
        this._router.navigateByUrl("MD/ContractForm/");
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        if (this.isImport) {
            (model as any).isImportContract = +this.isImport;
        }
        this._baseService.Find(model).then(response => {
            if (response.success) {
                let data = response.data;
                data = data.map(d => {
                    // d.dateFrom = new Date(d.dateFrom);
                    // d.dateTo = new Date(d.dateTo);
                    d.customerName = d.customer?.customerName;
                    d.quanLyHanMuc = d.isQuanLyHanMuc ? 'Lấy hóa đơn' : 'Chứng từ';
                    d.debtTypeText = d.debtType?.name;
                    d.paymentDebtText = d.paymentDept?.name;
                    return d;
                })
                this.dataSource = data;
                this.totalRecord = response.totalRecord;
                this.dataExport = data;
            } else {
                this._notifierService.showHttpUnknowError();
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
