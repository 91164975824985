import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { VehicleApiService } from '../../Services/Vehicle-api.service';
import { VehicleClassApiService } from '../../Services/vehicle-class-api.service';
import { VehicleTypeApiService } from '../../Services/vehicle-type-api.service';
import { CustomerApiService } from '../../Services/Customer-api.service';
@Component({
    selector: 'app-vehicle-form',
    templateUrl: './Vehicle-form.component.html',
    styleUrls: ['./Vehicle-form.component.css']
})
export class VehicleFormComponent extends SecondPageEditBase implements OnInit {

    arrVehicleTypeId = [];
    arrVehicleClassId = [];
    arrCustomer = [];
    RegexCode = "^[a-z0-9A-Z._-\\s]{1,10}$";
    RegexText = "^[a-z0-9A-Z_-\\s]{1,15}$";
    RegexDriver = "^[a-z0-9A-Z_-\\s]{1,30}$";
    RegexNumber = "^[0-9]{1,12}$";
    RegexPhone = "^[0-9\.]{1,12}$";

    constructor(
        protected _CustomerApiService: CustomerApiService,
        protected _VehicleApiService: VehicleApiService,
        protected _VehicleClass:VehicleClassApiService,
        protected _VehicleType: VehicleTypeApiService,
        protected _injector: Injector,
    ) {
        super(_VehicleApiService, _injector);

        this.formGroup = new FormGroup({
            vehicleCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            vehicleName: new FormControl('', [Validators.required]),
            numberPlate: new FormControl('', [Validators.pattern(this.RegexText)]),
            vehicleTypeId: new FormControl(''),
            vehicleClassId: new FormControl(''),
            customerId: new FormControl(''),
            driverName: new FormControl('', [Validators.required]),
            driverIdCard: new FormControl('', [Validators.pattern(this.RegexText)]),
            idCardDate: new FormControl(''),
            inspectionExpire: new FormControl(''),
            fPAFExpire: new FormControl(''),
            cell1: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell2: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell3: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell4: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell5: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell6: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell7: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell8: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell9: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            cell10: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            status: new FormControl(''),
       });
    }

    ngOnInit() {
        this._VehicleClass.getsInUse().then(rs=>{
            if(rs.success){
                this.convertDataToOptions(this.arrVehicleClassId, rs.data, "classCode,className", "classCode");
            }
        })
        this._VehicleType.getsInUse().then(rs=>{
            if(rs.success){
                this.convertDataToOptions(this.arrVehicleTypeId, rs.data, "code,name", "code");
            }
        })
        this._CustomerApiService.getsInUse().then(rs=>{
            if(rs.success){
                this.convertDataToOptions(this.arrCustomer, rs.data, "customerCode,customerName", "customerCode");
            }
        })
    }

    onAfterShowPopUp() {
        super.onAfterShowPopUp();
        let dateKeys = ['inspectionExpire', 'fPAFExpire', 'idCardDate'];
        for (let key of dateKeys) {
            if (this.itemDetail[key]) {
                let d = new Date(this.itemDetail[key]);
                if (d) this.itemDetail[key] = d;
            }
        }
    }
    outOfDateClass(d:any){
        return (new Date(d) < new Date())?"ng-dirty ng-invalid":"";
    }
    totalCell(){
        return parseInt((this.itemDetail.cell1??0)==""?0:this.itemDetail.cell1??0)
        + parseInt((this.itemDetail.cell2??0)==""?0:this.itemDetail.cell2??0)
        + parseInt((this.itemDetail.cell3??0)==""?0:this.itemDetail.cell3??0)
        + parseInt((this.itemDetail.cell4??0)==""?0:this.itemDetail.cell4??0)
        + parseInt((this.itemDetail.cell5??0)==""?0:this.itemDetail.cell5??0)
        + parseInt((this.itemDetail.cell6??0)==""?0:this.itemDetail.cell6??0)
        + parseInt((this.itemDetail.cell7??0)==""?0:this.itemDetail.cell7??0)
        + parseInt((this.itemDetail.cell8??0)==""?0:this.itemDetail.cell8??0)
        + parseInt((this.itemDetail.cell9??0)==""?0:this.itemDetail.cell9??0)
        + parseInt((this.itemDetail.cell10??0)==""?0:this.itemDetail.cell10??0)
    }
}
