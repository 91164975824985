import {Component, Input, OnInit} from '@angular/core';
import {getBgColor, getFgColor} from '../lib';

@Component({
  selector: 'app-tank-truck',
  templateUrl: './tank-truck.component.html',
  styleUrls: ['./tank-truck.css']
})
export class TankTruckComponent implements OnInit {
    @Input() data?: any;
    @Input() reg = "";
    parsedData = [];

    width = 164;
    height = 25;
    top = 15;
    left = 23;

    ngOnInit() {
        let {
            cell_1, cell_2, cell_3, cell_4, cell_5, cell_6, cell_7, cell_8,
            pR_1, pR_2, pR_3, pR_4, pR_5, pR_6, pR_7, pR_8
        } = this.data;
        this.parsedData = [
            [cell_1, pR_1],
            [cell_2, pR_2],
            [cell_3, pR_3],
            [cell_4, pR_4],
            [cell_5, pR_5],
            [cell_6, pR_6],
            [cell_7, pR_7],
            [cell_8, pR_8]
        ]
            .reverse()
            .filter(pair => pair[0] !== -1);
    }
}
