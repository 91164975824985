import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';
import { CustomerGroupApiService } from '../../Services/CustomerGroup-api.service';
import { BusinessTypeApiService } from '../../Services/BusinessType-api.service';

@Component({
    selector: 'app-orgUnit-form',
    templateUrl: './OrgUnit-form.component.html',
    styleUrls: ['./OrgUnit-form.component.css']
})
export class OrgUnitFormComponent extends SecondPageEditBase implements OnInit {

    arrCustomerGroup = [];
    arrBussinessType = [];
    arridParent = [];
    arridBranch = [];

    RegexCode = "^[a-z0-9A-Z._-]{1,8}$";
    RegexNumber = "^[0-9]{1,12}$";
    Regextaxcode = "^([0-9]{10}(-{1}[0-9]{3}){0,1}){0,1}$";
    RegexPhone = "^[0-9\.]{1,12}$";
    RegexEmail = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    Regexadd = "^[a-z0-9A-Z.,_-]{1,100}$";

    constructor(
        protected _OrgUnitApiService: OrgUnitApiService,
        protected _injector: Injector,
        protected _customerGroupAPIService: CustomerGroupApiService,
        public _businessTypeAPIService: BusinessTypeApiService,
    ) {
        super(_OrgUnitApiService, _injector);

        this.formGroup = new FormGroup({
            customerCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            customerName: new FormControl('', [Validators.required]),
            shortName: new FormControl(''),
            address: new FormControl('', ),
            taxNumber: new FormControl('', [Validators.pattern(this.Regextaxcode)]),
            postCode: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            tel: new FormControl('', [Validators.pattern(this.RegexPhone)]),
            fax: new FormControl('', [Validators.pattern(this.RegexPhone)]),
            email: new FormControl('', [Validators.pattern(this.RegexEmail)]),
            startDate: new FormControl(''),
            idCustomerType: new FormControl(''),
            idCustomerGroup: new FormControl(''),
            idBussinessType: new FormControl(''),
            idBusinessNature: new FormControl('', [Validators.pattern(this.RegexNumber)]),
            idParent: new FormControl(''),
            idBranch: new FormControl(''),
            status: new FormControl('', [Validators.required]),
        });

        this.getsCustomerGroup();

        this.getsBusinessType();

        this.arridBranch = [
            { label: 'Hà Nội', value: 1 },
            { label: 'Bắc Giang', value: 2 },
        ];


    }

    ngOnInit() {
        this.reloadParent();
    }

    reloadParent() {
        let model = {
            keyword: "",
            status: 1,
            CustomerGroup : 0,
            BussinessType : 0,
            Branch : 0,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: "",
            isDesc: false
        }
        this._OrgUnitApiService.find(model).then(rs => {
            if (rs.success) {
                this.arridParent = rs.data;
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._OrgUnitApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                    if (this.itemDetail.startDate) {
                        this.itemDetail.startDate = new Date(this.itemDetail.startDate);
                    }

                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsCustomerGroup() {
        var model = {
            keyword: "",
            status: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrCustomerGroup = [];
        this.arrCustomerGroup = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._customerGroupAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrCustomerGroup, rs.data, 'groupName');
            }
        })
    }

    async getsBusinessType() {
        var model = {
            keyword: "",
            status: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrBussinessType = [];
        this.arrBussinessType = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._businessTypeAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBussinessType, rs.data, 'name');
            }
        })
    }

    onAfterSave() {
        this.reloadParent();
    }
}

