import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../../../src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class PermissionService extends BaseService {

    readonly storage = sessionStorage;
    setupSignalr = false;
    constructor(
        http: HttpClient,
        injector: Injector,
        private _authenticationService: AuthenticationService,
    ) {
        super(http, injector, `${environment.apiDomain.coreEndpoint}/AzPermissions`);
    }

    async refreshPermissions() {
        for (const key in this.storage) {
            if (key.indexOf('AUTHORIZATION') >= 0) {
                await this.storage.removeItem(key);
            }
            if (key.indexOf('htMenu') >= 0) {
                this.storage.removeItem(key);
            }
        }
    }

    isAuthorized(service, permission): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const currUser = this._authenticationService.getCurrentUser();
            if (currUser.issuperuser === 'True') {
                resolve(true);
            }
            this.getPermissions(service).then(permissions => {
                const isAuthorized = permissions.includes(permission);
                resolve(isAuthorized);
            }, error => {
                reject(false);
            });
        });
    }

    getPermissions(service): Promise<any> {
        return new Promise((resolve, reject) => {
            if(service==undefined||service==null||service==""){
                resolve("");
            }else{
                const url = `${this.serviceUri}/GetsPermissionByMaAzService/${service}`;
                const key = `AUTHORIZATION.${service.toUpperCase()}`;
                const storageItem = this.storage.getItem(key);
                if (storageItem) {
                    const result = JSON.parse(storageItem);
                    resolve(result);
                } else {
                    this.defaultGet(url).then(response => {
                        this.storage.setItem(key, JSON.stringify(response.data));
                        resolve(response.data);
                    }, error => {
                        reject();
                    });
                }
            }
        });
    }
}
