<div class="card p-0">
    <p-tabView>
        <p-tabPanel header="{{ 'Cửa hàng' }}" leftIcon="pi pi-plus">
            <div class="p-formgrid grid">
                <div class="p-fluid col-12 md:col-9">
                    <div class="p-formgrid grid">
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.customerCode" class="p-inputtext-sm" pInputText
                                    type="text">
                                <label>
                                    Mã cửa hàng
                                    <span style="color:red;">*</span>
                                </label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.taxNumber" class="p-inputtext-sm" pInputText type="text">
                                <label>
                                    Mã số thuế
                                    <span style="color:red;">*</span>
                                </label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.customerName" class="p-inputtext-sm" pInputText
                                    type="text">
                                <label>Tên</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="itemDetail.startDate" [showIcon]="true" appendTo="body"
                                    dateFormat="dd/mm/yy" hourFormat="24" icon="ci ci-calendar" placeholder="dd/mm/yyy"
                                    showTime="true" styleClass="p-inputtext-sm">
                                </p-calendar>
                                <label>Ngày bắt đầu hoạt động</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.shortName" class="p-inputtext-sm" pInputText type="text">
                                <label>Tên ngắn</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <p-dropdown [(ngModel)]="itemDetail.idCustomerGroup" [autoDisplayFirst]="false"
                                    [filter]="true" [options]="arrCustomerGroup" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1" filterBy="label,value"
                                    styleClass="p-inputtext-sm">
                                </p-dropdown>
                                <label>
                                    Nhóm
                                    <span style="color:red;">*</span>
                                </label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <p-dropdown [(ngModel)]="itemDetail.priceRegionId" [autoDisplayFirst]="false"
                                    [filter]="true" [options]="arridPriceRegion" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1" filterBy="label,value" optionValue="id"
                                    styleClass="p-inputtext-sm"></p-dropdown>
                                <label>
                                    Vùng giá
                                    <span style="color:red;">*</span>
                                </label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <p-dropdown [(ngModel)]="itemDetail.idBussinessType" [autoDisplayFirst]="false"
                                    [filter]="true" [options]="arrBussinessType" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1" filterBy="label,value"
                                    styleClass="p-inputtext-sm">
                                </p-dropdown>
                                <label>Loại hình DN</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.address" class="p-inputtext-sm" pInputText type="text">
                                <label>Địa chỉ</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.manager" class="p-inputtext-sm" pInputText type="text">
                                <label>Cửa hàng trưởng</label>
                            </span>
                        </div>
                        <!-- <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input type="text" class="p-inputtext-sm" pInputText [(ngModel)]="itemDetail.shortName">
                                <label>Tỉnh thành</label>
                            </span>
                        </div> -->
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.tel" class="p-inputtext-sm" pInputText type="text">
                                <label>Tel</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.fax" class="p-inputtext-sm" pInputText type="text">
                                <label>Fax</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <p-dropdown [(ngModel)]="itemDetail.autoManufacturer" [autoDisplayFirst]="false"
                                    [filter]="true" [options]="arrManufacture" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1" filterBy="label,value"
                                    styleClass="p-inputtext-sm">
                                </p-dropdown>
                                <label>Nhà cung cấp TĐH</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.pumpControllerId" class="p-inputtext-sm" pInputText
                                    type="text">
                                <label>Mã controller TĐH vòi bơm</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.tankControllerId" class="p-inputtext-sm" pInputText
                                    type="text">
                                <label>Mã controller TĐH bể</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-6">
                            <span class="p-float-label">
                                <p-dropdown [(ngModel)]="itemDetail.status" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrStatus" appendTo="body" dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" styleClass="p-inputtext-sm">
                                </p-dropdown>
                                <label>
                                    Trạng thái
                                    <span style="color:red;">*</span>
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid col-3 md:col-3">
                    <div class="p-formgrid grid">
                        <!-- stuff for client lease dates, bind to api later -->
                        <div class="p-fluid col-12 md:col-12">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.softwareUsingStartDate" pInputText type="text"
                                    class="p-inputtext-sm" disabled="true">
                                <label>Ngày bắt đầu sử dụng</label>
                            </span>
                        </div>
                        <div class="p-fluid col-12 md:col-12">
                            <span class="p-float-label">
                                <input [(ngModel)]="itemDetail.softwareUsingExpiredDate" pInputText type="text"
                                    class="p-inputtext-sm" disabled="true">
                                <label>Ngày hết hạn</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-formgrid grid flex flex-row pt-2 justify-content-between">
                <div class="col-12 md:col-8 flex flex-row gap-2">
                    <button (click)="save()" [disabled]="submitting" class="p-button" icon="pi pi-save"
                        label="{{ 'BUTTON.SAVE' | translate }}" pButton pRipple type="button">
                    </button>
                    <button (click)="cancelEdit()" class="p-button p-button-outlined" icon="pi pi-times"
                        label="{{ 'BUTTON.CANCEL' | translate }}" pButton pRipple type="button">
                    </button>
                </div>
                <div class="p-2">
                    <button (click)="delete()" *ngIf="doingSaved" [disabled]="!doingSaved" class="p-button"
                        icon="pi pi-trash" label="{{ 'BUTTON.DELETE' | translate }}" pButton pRipple type="button">
                    </button>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
    <p-tabView>
        <p-tabPanel header="{{ 'Bể' }}" leftIcon="pi pi-database">
            <app-store-tank-index [idBranch]="itemDetail.id"></app-store-tank-index>
        </p-tabPanel>
        <p-tabPanel header="{{ 'Vòi bơm' }}" leftIcon="pi pi-plus">
            <app-store-pump-index [branchId]="itemDetail.id"></app-store-pump-index>
        </p-tabPanel>
        <p-tabPanel header="{{ 'Nhân viên' }}" leftIcon="pi pi-users">
            <app-store-staff-index [branchId]="itemDetail.id"></app-store-staff-index>
        </p-tabPanel>
        <p-tabPanel header="{{ 'Dải HĐ' }}" leftIcon="pi pi-save">
            <app-store-invoice-index></app-store-invoice-index>
        </p-tabPanel>
    </p-tabView>
</div>