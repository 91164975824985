import { Component, Injector, OnInit } from "@angular/core";
import { SecondPageEditBase } from "nabit-shared";
import { OrgUnitApiService } from "../../Services/OrgUnit-api.service";
import { CustomerGroupApiService } from "../../Services/CustomerGroup-api.service";
import { BusinessTypeApiService } from "../../Services/BusinessType-api.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { PriceRegionApiService } from "../../Services/PriceRegion-api.service";
import { OrgUnitSettingApiService } from "../../Services/OrgUnitSetting-api.service";
import { ManufactureApiService } from "../../Services/Manufacture-api.service";

@Component({
    selector: "app-store-form",
    templateUrl: "./Store-form.component.html",
    styleUrls: ["./Store-form.component.css"],
})
export class StoreFormComponent extends SecondPageEditBase implements OnInit {
    arrCustomerGroup = [];
    arrBussinessType = [];
    arridPriceRegion = [];
    arridParent = [];
    arridBranch = [];
    arrManufacture = [];

    constructor(
        protected _OrgUnitApiService: OrgUnitApiService,
        protected _injector: Injector,
        protected _customerGroupAPIService: CustomerGroupApiService,
        protected _router: Router,
        protected _route: ActivatedRoute,
        public _businessTypeAPIService: BusinessTypeApiService,
        public _priceRegionAPIService: PriceRegionApiService,
        public _orgUnitSettingApiService: OrgUnitSettingApiService,
        public _manufactureApiService: ManufactureApiService,
    ) {
        super(_OrgUnitApiService, _injector);
        this._route.paramMap.subscribe((s) => {
            this.refreshRoute(s);
        });
    }

    ngOnInit(): void {
        this.refreshRoute(this._route.snapshot.paramMap);
        Promise.all([
            this.getsCustomerGroup(),
            this.getsBusinessType(),
            this.getsPriceRegion(),
            this.getsManufacturer(),
        ]);
        this.getData();
    }

    refreshRoute(s: ParamMap) {
        this.itemDetail.id = +(s.get("id") || 0);
    }

    save() {
        super.save();

        let pumpManufacturer = {
            idOrgUnit: this.itemDetail.id,
            settingName: "PumpController",
            settingParam: "Manufacturer",
            settingValue: this.itemDetail.autoManufacturer
        };
        let tankManufacturer = {
            idOrgUnit: this.itemDetail.id,
            settingName: "TankController",
            settingParam: "Manufacturer",
            settingValue: this.itemDetail.autoManufacturer
        };
        let pumpDeviceId = {
            idOrgUnit: this.itemDetail.id,
            settingName: "PumpController",
            settingParam: "DeviceId",
            settingValue: this.itemDetail.pumpControllerId,
        };
        let tankDeviceId = {
            idOrgUnit: this.itemDetail.id,
            settingName: "TankController",
            settingParam: "DeviceId",
            settingValue: this.itemDetail.tankControllerId,
        };

        [pumpManufacturer, tankManufacturer, pumpDeviceId, tankDeviceId].map(item => {
            this._orgUnitSettingApiService.post(item).then(rs => {
                if (!rs.success) {
                    this._notifierService.showInsertDataFailed();
                }
            }, error => {
                this._notifierService.showHttpUnknowError();
            });
        });
    }

    getData() {
        this._OrgUnitApiService.getDetail(this.itemDetail.id).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
                this._orgUnitSettingApiService.GetsBySettingFilter({
                    idOrgUnit: +this.itemDetail.id,
                    settingName: "TankController",
                    settingParam: "Manufacturer",
                }).then(r => {
                    if (r.success && r.data?.length) {
                        this.itemDetail.autoManufacturer = +r.data[0].settingValue;
                    }
                });
                this._orgUnitSettingApiService.GetsBySettingFilter({
                    idOrgUnit: +this.itemDetail.id,
                    settingName: "PumpController",
                    settingParam: "DeviceId",
                }).then(r => {
                    if (r.success && r.data?.length) {
                        this.itemDetail.pumpControllerId = r.data[0].settingValue;
                    }
                }, error => {
                    this._notifierService.showHttpUnknowError();
                });
                this._orgUnitSettingApiService.GetsBySettingFilter({
                    idOrgUnit: +this.itemDetail.id,
                    settingName: "TankController",
                    settingParam: "DeviceId",
                }).then(r => {
                    if (r.success && r.data?.length) {
                        this.itemDetail.tankControllerId = r.data[0].settingValue;
                    }
                }, error => {
                    this._notifierService.showHttpUnknowError();
                });
            }
        });
    }

    async getsCustomerGroup() {
        var model = {
            keyword: "",
            status: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        };
        this.arrCustomerGroup = [];
        await this._customerGroupAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrCustomerGroup, rs.data, "groupName");
            }
        });
    }

    async getsBusinessType() {
        var model = {
            keyword: "",
            status: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        };
        this.arrBussinessType = [];
        await this._businessTypeAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBussinessType, rs.data, "name");
            }
        });
    }

    async getsPriceRegion() {
        this.arridPriceRegion = [];
        await this._priceRegionAPIService.getsInUse().then(rs => {
            if (rs.success) {
                console.log(rs.data);
                this.convertDataToOptions(this.arridPriceRegion, rs.data, "regionName");
            }
        });
    }

    async getsManufacturer() {
        this.arrManufacture = [];
        await this._manufactureApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrManufacture, rs.data, "manufactureName");
            }
        });
    }
}
