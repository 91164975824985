<p-toolbar>
    <ng-template pTemplate="left">
        <h5>
            {{ 'Lịch sử đo bể' | translate }}
        </h5>
    </ng-template>
    <ng-template pTemplate="right">
        <span class="p-float-label">
            <p-calendar (ngModelChange)="this.from = $event; getData()" [(ngModel)]="from" showTime="true"
                [showIcon]="true" hourFormat="24" inputId="from-picker"></p-calendar>
            <label for="from-picker">Từ ngày</label>
        </span>
        <span class="p-float-label">
            <p-calendar (ngModelChange)="this.to = $event; getData()" [(ngModel)]="to" showTime="true" [showIcon]="true"
                hourFormat="24" inputId="to-picker"></p-calendar>
            <label for="to-picker">đến ngày</label>
        </span>
        <span class="select-tank">
            <p-dropdown [options]="tankOptions" (ngModelChange)="this.chosenTank = $event; getData()"
                        [(ngModel)]="chosenTank" optionLabel="name" optionValue="code" inputId="tank-picker">
            </p-dropdown>
        </span>
    </ng-template>
</p-toolbar>
<p-table [columns]="cols" [value]="dataSource"
    class="p-datatable p-datatable-gridlines p-datatable-sm p-datatable-striped" [rows]="pageSize" [paginator]="true"
    [totalRecords]="totalRecord" [customSort]="true" (onSort)="onSort($event)" (onPage)="onPage($event)"
    [loading]="isLoading" [lazy]="true">
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width: 50px">
            <ng-container *ngFor="let col of cols">
                <col [hidden]="!col.visible" [style.width]="col.width">
            </ng-container>
        </colgroup>
    </ng-template>
    <ng-template let-columns pTemplate="header">
        <tr>
            <th rowspan="2" class="text-center" width="40">STT</th>
            <th rowspan="2" class="text-center" width="200">Tên bể</th>
            <th colspan="5" class="text-center">Hàng hóa</th>
            <th colspan="3" class="text-center">Nước</th>
            <th colspan="2" class="text-center">Trống bể</th>
            <th rowspan="2" class="text-center" width="200">Thời gian</th>
        </tr>
        <tr>
            <ng-container *ngFor="let col of columns">
                <th *ngIf="col.sort" [hidden]="(!col.visible || col.spanned)" [pSortableColumn]="col.field"
                    class="text-center">
                    <div class="h-flex-el -h-space-between">
                        <div class="h-overflow-break-word">{{col.header}}</div>
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </div>
                </th>

                <th *ngIf="!col.sort" [hidden]="(!col.visible || col.spanned)" class="text-center">
                    <div class="h-overflow-break-word">{{col.header}}</div>
                </th>
            </ng-container>
        </tr>
    </ng-template>

    <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
        <tr [class.ag-tr-status-danger]="rowData.status === 3" [class.ag-tr-status-success]="rowData.status === 1"
            [class.ag-tr-status-warning]="rowData.status === 2" [class.ag-tr-status]="rowData.status">
            <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field"
                [style.text-align]="col.align">
                <span *ngSwitchCase="'modified'">{{rowData.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                <span *ngSwitchDefault>{{rowData[col.field]}}</span>
            </td>
        </tr>
    </ng-template>
</p-table>
