import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { SecondPageIndexBase } from "nabit-shared";
import { TrTypeApiService } from "../../Services/TrType-api.service";
import { AppComponent } from "../../../app.component";
import { TrType2CustGroupFormComponent } from "../form/TrType2CustGroup-form.component";
import { TrType2CustGroupApiService } from "../../Services/TrType2CustGroupApi.service";
import { CustomerGroupApiService } from "../../Services/CustomerGroup-api.service";

@Component({
    selector: "app-trType2CustGroup-index",
    templateUrl: "./TrType2CustGroup-index.component.html",
    styleUrls: ["./TrType2CustGroup-index.component.scss"]
})
export class TrType2CustGroupIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild("pEdit") pEdit: TrType2CustGroupFormComponent;
    tabMenuItems = [];
    customerGroups = [];

    constructor(
        protected _TrTypeApiService: TrTypeApiService,
        protected _TrType2CustGroupApiService: TrType2CustGroupApiService,
        protected _CustomerGroupApiService: CustomerGroupApiService,
        protected _injector: Injector,
        public app: AppComponent
    ) {
        super(_TrTypeApiService, _injector);
        this._TrType2CustGroupApiService = _TrType2CustGroupApiService;
        this._CustomerGroupApiService = _CustomerGroupApiService;
    }

    ngOnInit(): void {
        this.tabMenuItems = [
            { label: this._translateService.instant('Phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrType' },
            { label: this._translateService.instant('Nhóm phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrGroup' },
            { label: this._translateService.instant('Lọc đối tượng'), icon: 'pi pi-search', routerLink: '../TrType2CustGroup' },
            { label: this._translateService.instant('Lọc báo cáo'), icon: 'pi pi-search', routerLink: '../TrType2Report' },
        ];

        this.cols = [
            { field: "trCode", header: this._translateService.instant("Mã N/X"), visible: true, width: "auto", sort: true },
            { field: "trName", header: this._translateService.instant("Tên N/X"), visible: true, width: "auto", sort: true },
            { field: "trDirection", header: this._translateService.instant("TR Direction"), visible: true, width: "auto", sort: true },
            { field: "shortName", header: this._translateService.instant("Tên ngắn"), visible: true, width: "auto", sort: true },
            { field: "customerGroups", header: this._translateService.instant("Nhóm đối tượng"), visible: true, width: "auto", sort: false },
            { field: "modified", header: this._translateService.instant("TABLEDATA.MODIFIED"), visible: false, width: "auto", sort: true, dateFormat: "dd/MM/yyyy HH:mm" },
            { field: "modifiedBy", header: this._translateService.instant("TABLEDATA.MODIFIED_BY"), visible: false, width: "auto", sort: true, },
            { field: "status", header: this._translateService.instant("TABLEDATA.STATUS"), visible: true, width: "auto", sort: true, }
        ]

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    getData(): void {
        this.isLoading = true;
        this.resetBulkSelect;
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
        };

        let promises = [this._TrTypeApiService.getsBySearch(model), this._CustomerGroupApiService.getsInUse()];

        Promise.all(promises).then(([trTypeApiResponse, custGroupApiResponse]) => {
            if (trTypeApiResponse.success) {
                this.dataSource = trTypeApiResponse.data;
                this.totalRecord = trTypeApiResponse.totalRecord;
                this.dataExport = trTypeApiResponse.data;
            }

            if (custGroupApiResponse.success) {
                let customerGroups = custGroupApiResponse.data.map(data => {
                    return {
                        id: data.id,
                        groupName: data.groupName
                    }
                })
                this.customerGroups = customerGroups;
            }

            for (const item of this.dataSource) {
                this._TrType2CustGroupApiService.getsByTrCode(item.trCode).then(response => {
                    item.customerGroupIds = response.data.customerGroupIds;
                    item.selectedCustomerGroups = [];

                    for (const customerGroup of this.customerGroups) {
                        if (item.customerGroupIds.includes(customerGroup.id)) {
                            item.selectedCustomerGroups = [...item.selectedCustomerGroups, customerGroup];
                        }
                    }
                }, error => {
                    this.isLoading = false;
                    this._notifierService.showHttpUnknowError();
                });
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }
}
