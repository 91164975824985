import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MenuRootService {
    private parentSource = new Subject<number>();
    private _current : number = 0;
    public handler = this.parentSource.asObservable();
    public get current() { return this._current; }

    constructor() {
        this.parentSource.next(1);
    }

    setParent(parent: number) {
        this.parentSource.next(parent);
        this._current = parent;
    }
}
