import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ExpenseItemsFormComponent } from '../form/expenseItems-form.component';
import { ExpenseItemsApiService } from '../../Services/ExpenseItems-api.service';

@Component({
    selector: 'app-expenseItems-index',
    templateUrl: './expenseItems-index.component.html',
    styleUrls: ['./expenseItems-index.component.css']
})
export class ExpenseItemsIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ExpenseItemsFormComponent;
    tabMenuItems = [];
    constructor(
        protected _expenseItemsApiService: ExpenseItemsApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_expenseItemsApiService, _injector);
    }
    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Tên chức năng'), icon: 'pi pi-book', routerLink: '../client-template'},
        ];
        this.cols = [
            { field: 'expenseCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', },
            { field: 'expenseName', header: this._translateService.instant('FORM.TITLE'), visible: true, width: 'auto', },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }

    edit(id?:number) {
        console.log(id);
        if(id!=null){
            this.pEdit.showPopup(id);
        }else{
            this.pEdit.showPopup(this.selectedItems[0].id);
        }
    }

    add() {
        this.pEdit.showPopup();
    }
    
}
