import {Component, OnInit, Injector, ViewEncapsulation, Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportViewerApiService } from '../Services/ReportViewer-api.service';
import { Report } from '../Models/Report';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecondPageEditBase } from 'nabit-shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductApiService } from '../../MD/Services/Product-api.service';
import { MenuItem } from 'primeng/api';
import { number } from 'echarts';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-ReportViewer',
    templateUrl: './ReportViewer.component.html',
    styleUrls: ['./ReportViewer.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ReportViewerComponent extends SecondPageEditBase implements OnInit {
    from: Date = new Date();
    to: Date = new Date();
    arrParams = [];
    activeIndex: number;
    htmlContent: SafeHtml;
    hiddenFilter: boolean;
    hiddenExport: boolean;
    filterdata: any;
    selectedMonth: number;
    monthList = [
        { id: 0, label: '[Tùy chọn]' },
        { id: 1, label: 'Tháng 01' },
        { id: 2, label: 'Tháng 02' },
        { id: 3, label: 'Tháng 03' },
        { id: 4, label: 'Tháng 04' },
        { id: 5, label: 'Tháng 05' },
        { id: 6, label: 'Tháng 06' },
        { id: 7, label: 'Tháng 07' },
        { id: 8, label: 'Tháng 08' },
        { id: 9, label: 'Tháng 09' },
        { id: 10, label: 'Tháng 10' },
        { id: 11, label: 'Tháng 11' },
        { id: 12, label: 'Tháng 12' },
        { id: 13, label: 'Quý 1' },
        { id: 14, label: 'Quý 2' },
        { id: 15, label: 'Quý 3' },
        { id: 16, label: 'Quý 4' },
        { id: 17, label: 'Cả năm' }
    ];
    arrProduct = [];
    arrCustomer = [];
    reportName: string = "Báo cáo";
    isLoading: any;
    protected _router: Router;

    @Input() embed = false;

    constructor(
        protected _ReportViewerApiService: ReportViewerApiService,
        protected _injector: Injector,
        private _route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private spinner: NgxSpinnerService,
        private productService: ProductApiService,
        private titleService: Title
    ) {
        super(_ReportViewerApiService, _injector);
        this._router = this._injector.get(Router);
        this.from.setDate(1);
        this.from.setHours(0);
        this.from.setMinutes(0);
        this.to.setHours(23);
        this.to.setMinutes(59);
        this.itemDetail = { filterConditions: [] };
    }
    ngOnInit() {
        //console.log(this.itemDetail);
        //this.reportCode = this._route.snapshot.paramMap.get('ReportCode')
        this.hiddenFilter = true;
        this.hiddenExport = true;
        this.getData();
    }
    isSuperAdmin() {
        return this.currentUser.issuperuser;
    }
    getData(): void {
        //Sử dụng Routing trong Angular, khi thay đổi last Route, thì request ko đổi => Do đó phải subcribe.
        //Tham khảo thêm https://viblo.asia/p/nhung-dieu-ban-nen-biet-ve-angular-parameters-bWrZn7qYlxw
        this._route.paramMap.subscribe(params => {
            if (this.embed) {
                return;
            }

            const reportCode = this._route.snapshot.paramMap.get('ReportCode');
            const reportParams = this._route.snapshot.paramMap.get('Params');
            this.arrParams = reportParams==null?[]:reportParams.split("&");
            if(reportParams!=null){
                this.arrParams.forEach(param => {
                    const value = param.split("$");
                    if(value[0].toLowerCase()=="fromdate"){
                        try{
                            if(!isNaN(Date.parse(value[1])))
                                this.from = new Date(value[1]);
                        }catch(ex){}
                    }else if(value[0].toLowerCase()=="todate"){
                        try{
                            if(!isNaN(Date.parse(value[1])))
                                this.to = new Date(value[1]);
                        }catch(ex){}
                    }
                });
            }
            this.htmlContent = "";
            this.isLoading = true;
            this.activeIndex = null;
            this.load(reportCode, reportParams);
        });
    }

    async load(reportCode: string, reportParams?: string) {
        console.trace();
        await this._ReportViewerApiService.GetByReportCode(reportCode)
            .then(Response => {
                if (Response.success) {
                    this.itemDetail = Response?.data;
                    this.hiddenFilter = true;
                    this.reportName = this.itemDetail.reportName;
                    this.titleService.setTitle(this.itemDetail.reportName);
                    this.activeIndex = 0;
                    this.isLoading = false;
                    for (const item of this.itemDetail.filterConditions) {
                        item.isRequired = !!item.isRequired;
                        item.isRequired = item.isRequired ? 0 : 1;
                        this.arrParams.forEach(param => {
                            const value = param.split("$");
                            if(value[0]==item.mdName){
                                try{
                                    item.selectedValue = value[1].split(",").map(Number);
                                }catch(ex){}
                            }
                        });
                        item.filterData.unshift({ id: -1, dataToFilter: "(chưa chọn)" });
                    }
                    if(reportParams!=null){
                        this.loadReport();
                    }
                } else {
                    this.isLoading = false;
                }
            }, () => {
                this.isLoading = false;
            });
    }

    getDateFilter(){
        let dataFilter = JSON.parse(JSON.stringify(this.itemDetail));
        this.hiddenFilter = true;
        dataFilter.fromDate = this.from;
        dataFilter.toDate = this.to;
        this.arrParams.forEach(param => {
            const value = param.split("$");
            if(value[0].toLowerCase()=="trid"){
                try{
                    dataFilter.trId = parseInt(value[1], 10);
                }catch(ex){}
            }
        });
        for (const item of dataFilter.filterConditions) {
            item.filterData = null;
            if (item.selectedValue == -1) {
                item.selectedValue = null;
            } else if (typeof (item.selectedValue) != "object") {
                item.selectedValue = [item.selectedValue];
            }
        }
        for (const item of this.itemDetail.filterConditions) {
            if (item.selectedValue == -1) {
                item.selectedValue = null;
            } else if (typeof (item.selectedValue) != "object") {
                item.selectedValue = [item.selectedValue];
            }
        }
        return dataFilter;
    }
    loadReport() {
        if (!this.reportName) return;
        this.isLoading = true;
        this._ReportViewerApiService.ExportHtml(this.getDateFilter())
            .then(response => {
                //this.hiddenFilter = true;
                response = response.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(response);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
        for (const item of this.itemDetail.filterConditions) {
            if ((item.isMultiple != 1) && (item.selectedValue != null) && (item.selectedValue.length != 0)) {
                item.selectedValue = item.selectedValue[0];
            }
        }
    }
    debugReport() {
        this.isLoading = true;
        this._ReportViewerApiService.ExportSQL(this.getDateFilter())
            .then(response => {
                //this.hiddenFilter = true;
                response.data.sql = response.data.sql.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                this.htmlContent = this.sanitizer.bypassSecurityTrustHtml("<pre>" + response.data.sql + "</pre>");
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
        for (const item of this.itemDetail.filterConditions) {
            if ((item.isMultiple != 1) && (item.selectedValue != null) && (item.selectedValue.length != 0)) {
                item.selectedValue = item.selectedValue[0];
            }
        }
    }
    viewFilter() {
        this.hiddenFilter = !this.hiddenFilter;
        if (!this.hiddenExport) this.hiddenExport = true;
    }

    viewExport() {
        this.hiddenExport = !this.hiddenExport;
        if (!this.hiddenFilter) this.hiddenFilter = true;
    }

    /* Calling the `ExportPdf` method of the `ReportApiService` service. */
    exportPdf() {
        this.isLoading = true;
        this._ReportViewerApiService.ExportPdf(this.getDateFilter());
        this.isLoading = false;
        for (const item of this.itemDetail.filterConditions) {
            if ((item.isMultiple != 1) && (item.selectedValue.length != 0)) {
                item.selectedValue = item.selectedValue[0];
            }
        }
    }

    exportExcel() {
        this.isLoading = true;
        this._ReportViewerApiService.ExportExcel(this.getDateFilter());
        this.isLoading = false;
        for (const item of this.itemDetail.filterConditions) {
            if ((item.isMultiple != 1) && (item.selectedValue.length != 0)) {
                item.selectedValue = item.selectedValue[0];
            }
        }
    }
    editReport() {
        this._router.navigateByUrl(`RPT/ReportForm/${this.itemDetail.id}`);
    }
    changeMonth(e) {
        var now = new Date();
        if (this.selectedMonth > 0 && this.selectedMonth < 13) {
            this.from = new Date(now.getFullYear(), this.selectedMonth - 1, 1);
            this.to = new Date(now.getFullYear(), this.selectedMonth, 0);
        } else {
            switch (this.selectedMonth) {
                case 13:
                    this.from = new Date(now.getFullYear(), 0, 1);
                    this.to = new Date(now.getFullYear(), 2, 31);
                    break;
                case 14:
                    this.from = new Date(now.getFullYear(), 3, 1);
                    this.to = new Date(now.getFullYear(), 5, 31);
                    break;
                case 15:
                    this.from = new Date(now.getFullYear(), 6, 1);
                    this.to = new Date(now.getFullYear(), 8, 31);
                    break;
                case 16:
                    this.from = new Date(now.getFullYear(), 9, 1);
                    this.to = new Date(now.getFullYear(), 11, 30);
                    break;
                default:
                    this.from = new Date(now.getFullYear(), 1, 1);
                    this.to = new Date(now.getFullYear(), 11, 30);
                    break;
            }
        }
    }
}
