import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { Report, reportDto } from '../Models/Report';
import { CommonService } from 'nabit-shared';
import { Observable } from 'rxjs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const WORD_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8';
const PDF_TYPE = 'application/pdf;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const WORD_EXTENSION = '.docx';
const PDF_EXTENSION = '.pdf';
const apiEndpoint = environment.apiDomain.coreEndpoint + '/Export';
@Injectable({
    providedIn: 'root'
})
export class ReportApiService extends BaseService {
    constructor(
        http: HttpClient,
        private _commonService: CommonService,
        injector: Injector) {
        super(http, injector, `${environment.apiDomain.reportEndpoint}/Report`);
    }
    GetAll(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetAll`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    find(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    GetByReportCode(reportCode: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetByReportCode/${reportCode}`;
        return this.defaultGet(apiUrl);
    }
    saveAsPdfFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: PDF_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + PDF_EXTENSION);
    }
    saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    ExportHtml(rpt: Report): Promise<string> {
        const apiUrl = `${this.serviceUri}/ExportHtml`;
        return this._http.post(apiUrl, rpt, { responseType: 'text' })
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    ExportPdf(reportId: number, fileName: string, parameters: string) {
        const apiUrl = `${this.serviceUri}/ExportPdf`;
        var rptDto: reportDto;
        rptDto = new reportDto();
        rptDto.reportId = reportId;
        rptDto.parameters = parameters;
        this._http.post(apiUrl, rptDto, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .subscribe(response => { this.saveAsPdfFile(response, fileName) });
    }
    ExportExcel(reportId: number, fileName: string, parameters: string) {
        const apiUrl = `${this.serviceUri}/ExportExcel`;
        var rptDto: reportDto;
        rptDto = new reportDto();
        rptDto.reportId = reportId;
        rptDto.parameters = parameters;
        this._http.post(apiUrl, rptDto, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .subscribe(response => { this.saveAsExcelFile(response, fileName) });
    }
    ExportJson(reportCode = "DASHBOARD.01") {
        const apiUrl = `${this.serviceUri}/ExportJSON`;
        return this._http.post(apiUrl, {
            "reportCode": reportCode,
            "parameters": "{}"
        })
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise() as Promise<ResponseResult>;
    }
    ExportJsonWithParam(reportCode, params) {
        const apiUrl = `${this.serviceUri}/ExportJSON`;
        return this._http.post(apiUrl, {
            reportId: 0,
            "reportCode": reportCode,
            "parameters": params
        })
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise() as Promise<ResponseResult>;
    }
}
