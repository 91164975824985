import {Injectable, Injector} from '@angular/core';
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {BaseService, ResponseResult} from "nabit-shared";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class StorageFileItemService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.storageEndpoint}/StorageFileItem`);
    }

    upload(file: File) {
        const apiUrl = this.serviceUri + '/UploadFiles';
        let form = new FormData();
        form.append('file', file, file.name);
        return this._http.post(apiUrl, form)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise() as Promise<ResponseResult>;
    }

    getFileByIdLink(id: number) {
        return this.serviceUri + '/GetFileById/' + id;
    }

    getFileById(id: number) {
        const apiUrl = this.serviceUri + '/GetFileById/' + id;
        return this._http.get(apiUrl, {
            responseType: 'blob'
        })
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise() as Promise<Blob>;
    }
}
