import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StorageAttachmentApiService } from "../../Storage/Services/StorageAttachment.service";
import { NotifierService } from "nabit-shared";
import { StorageFileItemService } from "../../Storage/Services/StorageFileItem.service";
import { FileUpload } from "primeng/fileupload";
import { CategoriesService } from '../../core/services/categories.service';
@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnChanges {
    @Input() embed = false;
    @Input() objectId?: number;
    @Input() view = false;
    @Input() fullWidth = false;

    @Input() objectName = 'TR';
    @ViewChild('fileUpload') fileUpload: FileUpload;

    visible = false;
    submitting = false;

    @Output() onChange = new EventEmitter;

    get title() {
        return this.view
            ? 'Xem ' + this.referencedFiles.length + ' tập tin đính kèm'
            : 'Đính kèm ' + this.files.length + ' tập tin'
    }

    dialogOpen = false;
    files: File[] = [];
    referencedFiles = [];
    arrCategory: any = [];
    constructor(
        private StorageAttachmentApiService: StorageAttachmentApiService,
        private StorageFileItemService: StorageFileItemService,
        private _categoriesService: CategoriesService,
        private notifierService: NotifierService
    ) {
    }

    ngOnInit(): void {
        this._categoriesService.getTreeTableByIdParent(3, true, false).then(rs => {
            if (rs.success) {
                this.arrCategory = rs.data;
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.view?.previousValue !== changes.view?.currentValue) {
            this.loadReferencedFiles();
        }
        if (changes.objectId?.previousValue !== changes.objectId?.currentValue) {
            this.loadReferencedFiles();
        }
    }

    async loadReferencedFiles() {
        if (!this.objectId) {
            return;
        }
        let m = {
            "keyword": "",
            "status": 1,
            "pageIndex": 1,
            "pageSize": 10000,
            "orderCol": "",
            "isDesc": true,
            "objectName": this.objectName,
            "objectId": this.objectId,
            "objectValue": ""
        };

        await this.StorageAttachmentApiService.Find(m)
            .then(rs => {
                if (rs.success) {
                    this.referencedFiles = rs.data.map(r => {
                        if (r.documentDate) {
                            r.documentDate = new Date(r.documentDate);
                        }

                        return r;
                    });
                }
            })
    }

    async save(rec) {
        this.submitting = true;
        await this.StorageAttachmentApiService.put(rec.id, rec)
            .then(rs => {
                if (rs.success) {
                    this.loadReferencedFiles();
                    this.notifierService.showSuccess('Cập nhật thành công!', '', 1000);
                } else {
                    this.notifierService.showError('Có lỗi xảy ra khi lưu ' + rec.fileTitle, '', 1000);
                }
            })
            .catch((e) => {
                console.log(e);
                this.notifierService.showError('Có lỗi xảy ra khi lưu ' + rec.fileTitle, '', 1000);
            })
            .finally(() => {
                this.submitting = false;
            })
    }

    async upload() {
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.uploadInternal()
            .catch((e: Error) => {
                this.notifierService.showError(e.message, '', 1000);
            })
            .finally(() => {
                this.submitting = false;
            })
    }

    async uploadInternal() {
        if (this.fileUpload) {
            let p = this.fileUpload?.files.map(async r => {
                return await this.StorageFileItemService.upload(r)
                    .then(rs => {
                        if (rs.success) {
                            let { data: { data } } = rs;
                            return [data.id, data.title];
                        }

                        throw new Error('Upload file failed : ' + rs.error);
                    });
            })

            let ids = await Promise.all(p ?? []);

            let upload = ids.map(([id, title]) => {
                let model = {
                    "fileTitle": title,
                    "storageFileId": id,
                    "objectName": this.objectName,
                    "oBjectId": this.objectId,
                    "objectValue": ""
                };

                return this.StorageAttachmentApiService.post(model)
                    .then(rs => {
                        if (rs.success) {
                            this.notifierService.showSuccess(`Đã tải lên ${title}.`, '', 1000)
                        }
                    })
            })

            await Promise.all(upload);
            this.fileUpload.files = [];
            this.loadReferencedFiles()
                .then(() => {
                    this.visible = false;
                });
        }
    }

    download(id) {
        let link = this.StorageFileItemService.getFileByIdLink(id);
        window.open(link);
    }

    handleSelect(e) {
        this.files.push(...e.currentFiles);
        this.files = this.files.slice();
    }

    isNewFile(f) {
        return f instanceof File;
    }

    delete(id) {
        this.submitting = true;
        this.StorageAttachmentApiService.delete(id)
            .then(rs => {
                if (rs.success) {
                    this.notifierService.showSuccess(`Đã xóa tập tin.`, '', 1000);
                    this.loadReferencedFiles();
                } else {
                    this.notifierService.showDeleteDataError();
                }
            })
            .catch(() => {
                this.notifierService.showDeleteDataError();
            })
            .finally(() => {
                this.submitting = false;
            })
    }
}
