<p-dialog [(visible)]="visible" [modal]="true" [style]="{'width': '800px', 'height': '400px'}">
    <p-fileUpload #fileUpload [auto]="false" [multiple]="true" (uploadHandler)="upload()" [customUpload]="true"
        [showUploadButton]="true" [showCancelButton]="true" [disabled]="submitting" chooseIcon="pi pi-paperclip"
        chooseLabel="Đính kèm tập tin">
    </p-fileUpload>
</p-dialog>
<p-button label="Đính kèm tập tin" icon="pi pi-upload" (click)="visible = true;" *ngIf="!embed"></p-button>
<div class="p-formgrid grid">
    <div class="p-fluid col-12 {{ fullWidth ? '' : 'md:col-9' }}">
        <p-table [value]="referencedFiles" class=" p-datatable-customers p-datatable-striped p-datatable-sm"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th class="w-4rem">STT</th>
                    <th class='w-40rem'>File đính kèm</th>
                    <th class="w-20rem">Loại tài liệu</th>
                    <th class="w-8rem">Ngày cấp</th>
                    <th>Ghi chú</th>
                    <th class="w-6rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-f let-rowIndex="rowIndex">
                <tr>
                    <td class="text-center">{{ rowIndex + 1 }}</td>
                    <td class="text-left">
                        <p-button label="{{ isNewFile(f) ? f.name : f.fileTitle }}" styleClass="p-button-link text-left"
                            (click)="download(f.storageFileId)"></p-button>
                    </td>
                    <td class="text-left">
                        <p-dropdown [options]="arrCategory" [(ngModel)]="f.storageCategoryId" [virtualScroll]="true"
                            [virtualScrollItemSize]="30" [autoDisplayFirst]="false" optionLabel="label" optionValue="id"
                            [filter]="true" filterBy="name" appendTo="body" class="p-inputtext-sm1">
                        </p-dropdown>
                    </td>
                    <td>
                        <p-calendar inputStyleClass="p-inputtext-sm w-full" [(ngModel)]="f.documentDate" appendTo="body"
                            dateFormat="dd/mm/yy">
                        </p-calendar>
                    </td>
                    <td>
                        <input pInputText [(ngModel)]="f.description" class="p-inputtext-sm w-full">
                    </td>
                    <td>
                        <div class="flex flex-row justify-content-between gap-1">
                            <ng-container *ngIf="isNewFile(f)">
                                <!--                            <p-button (click)="upload(f)" icon="pi pi-upload"></p-button>-->
                                <p-button styleClass="p-button-danger mr-1"
                                    (click)="files.splice(rowIndex - referencedFiles.length, 1)"
                                    icon="pi pi-trash"></p-button>
                            </ng-container>
                            <ng-container *ngIf="!isNewFile(f)">
                                <p-button (click)="save(f)" icon="pi pi-save"
                                    styleClass="p-button-outlined p-button-success" [disabled]="submitting">
                                </p-button>
                                <!--p-button (click)="download(f.storageFileId)" icon="pi pi-download" [disabled]="submitting">
                                </p-button-->
                                <p-button styleClass="p-button-danger" (click)="delete(referencedFiles[rowIndex].id)"
                                    [disabled]="submitting" icon="pi pi-trash">
                                </p-button>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>