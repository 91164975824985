import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { SecondPageIndexBase } from "nabit-shared";
import { TrTypeApiService } from "../../Services/TrType-api.service";
import { AppComponent } from "../../../app.component";
import { TrType2ReportFormComponent } from "../form/TrType2Report-form.component";
import { TrType2ReportApiService } from "../../Services/TrType2Report-api.service";
import { ReportApiService } from "../../../RPT/Services/Report-api.service";

@Component({
    selector: "app-trType2Report-index",
    templateUrl: "./TrType2Report-index.component.html",
    styleUrls: ["./TrType2Report-index.component.scss"]
})
export class TrType2ReportIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild("pEdit") pEdit: TrType2ReportFormComponent;
    tabMenuItems = [];
    reports = [];

    constructor(
        protected _TrTypeApiService: TrTypeApiService,
        protected _TrType2ReportApiService: TrType2ReportApiService,
        protected _ReportApiService: ReportApiService,
        protected _injector: Injector,
        public app: AppComponent
    ) {
        super(_TrTypeApiService, _injector);
        this._TrType2ReportApiService = _TrType2ReportApiService;
        this._ReportApiService = _ReportApiService;
    }

    ngOnInit(): void {
        this.tabMenuItems = [
            { label: this._translateService.instant('Phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrType' },
            { label: this._translateService.instant('Nhóm phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrGroup' },
            { label: this._translateService.instant('Lọc đối tượng'), icon: 'pi pi-search', routerLink: '../TrType2CustGroup' },
            { label: this._translateService.instant('Lọc báo cáo'), icon: 'pi pi-search', routerLink: '../TrType2Report' },
        ];

        this.cols = [
            { field: "trCode", header: this._translateService.instant("Mã N/X"), visible: true, width: "auto", sort: true },
            { field: "trName", header: this._translateService.instant("Tên N/X"), visible: true, width: "auto", sort: true },
            { field: "trDirection", header: this._translateService.instant("TR Direction"), visible: true, width: "auto", sort: true },
            { field: "shortName", header: this._translateService.instant("Tên ngắn"), visible: true, width: "auto", sort: true },
            { field: "reports", header: this._translateService.instant("Báo cáo"), visible: true, width: "auto", sort: false },
            { field: "modified", header: this._translateService.instant("TABLEDATA.MODIFIED"), visible: false, width: "auto", sort: true, dateFormat: "dd/MM/yyyy HH:mm" },
            { field: "modifiedBy", header: this._translateService.instant("TABLEDATA.MODIFIED_BY"), visible: false, width: "auto", sort: true, },
            { field: "status", header: this._translateService.instant("TABLEDATA.STATUS"), visible: true, width: "auto", sort: true, }
        ]

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    getData(): void {
        this.isLoading = true;
        this.resetBulkSelect;
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
        };

        let promises = [this._TrTypeApiService.getsBySearch(model), this._ReportApiService.getsInUse()];

        Promise.all(promises).then(([trTypeApiResponse, reportApiResponse]) => {
            if (trTypeApiResponse.success) {
                this.dataSource = trTypeApiResponse.data;
                this.totalRecord = trTypeApiResponse.totalRecord;
                this.dataExport = trTypeApiResponse.data;
            }

            if (reportApiResponse.success) {
                let reports = reportApiResponse.data.map(data => {
                    return {
                        id: data.id,
                        reportName: data.reportName,
                        reportCode: data.reportCode,
                    }
                })
                this.reports = reports;
            }

            for (const item of this.dataSource) {
                this._TrType2ReportApiService.getsByTrCode(item.trCode).then(response => {
                    if (response.success) {
                        item.reportIds = response.data.reportIds;
                        item.selectedReports = [];

                        for (const report of this.reports) {
                            if (item.reportIds.includes(report.id)) {
                                item.selectedReports = [...item.selectedReports, report];
                            }
                        }
                    }
                }, error => {
                    this.isLoading = false;
                    this._notifierService.showHttpUnknowError();
                });
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }
}
