import { Component, Injector } from "@angular/core";
import { SecondPageEditBase } from "nabit-shared";
import { SoftwarePaymentApiService } from "../../Services/SoftwarePayment-api.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OrgUnitApiService } from "../../Services/OrgUnit-api.service";

@Component({
    selector: 'app-software-payment-form',
    templateUrl: './SoftwarePayment-form.component.html',
    styleUrls: ['./SoftwarePayment-form.component.css']
})
export class SoftwarePaymentFormComponent extends SecondPageEditBase {
    arrOrgUnit = [];
    orgUnitList = [];

    constructor(
        protected _SoftwarePaymentApiService: SoftwarePaymentApiService,
        protected _OrgUnitApiService: OrgUnitApiService,
        protected _injector: Injector,
    ) {
        super(_SoftwarePaymentApiService, _injector);
        this.formGroup = new FormGroup({
            orgUnitId: new FormControl('', [Validators.required]),
            paymentDate: new FormControl(new Date(Date.now()), [Validators.required]),
            paymentAmount: new FormControl('', [Validators.required]),
            additionDay: new FormControl('', [Validators.required]),
            oldExpiredDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
            newExpiredDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
            status: new FormControl('', [Validators.required]),
        });

        this.getOrgUnit();
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._baseService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.setDate();
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1, paymentDate: new Date() };
            this.submitting = false;
        }
    }

    onAfterShowPopUp() {
        this.setDate();
    }

    onAfterSave(isUpdate: boolean = true) {
        super.onAfterSave(isUpdate);
        this.setDate();
        this.getOrgUnit();
    }

    setDate() {
        let dateFields = ['newExpiredDate', 'oldExpiredDate', 'paymentDate'];
        for (let field of dateFields) {
            if (this.itemDetail[field] && typeof this.itemDetail[field] === 'string') {
                this.itemDetail[field] = new Date(this.itemDetail[field]);
            }
        }
    }

    changeOrgUnit(id: number) {
        let orgUnit = this.orgUnitList.find(r => r.id === id);
        if (orgUnit) {
            this.itemDetail.oldExpiredDate = new Date(orgUnit.softwareUsingExpiredDate);
            if (+this.itemDetail.oldExpiredDate < 0) {
                this.itemDetail.oldExpiredDate = new Date(0);
            }
            this.updateNewExpiredDate()
        }
    }

    updateNewExpiredDate() {
        let base = Math.max(
            +this.itemDetail.oldExpiredDate,
            +(this.itemDetail.paymentDate ?? 0)
        );

        let d = (this.itemDetail.additionDay ?? 0) * 86400 * 1000;
        base += d;

        this.itemDetail.newExpiredDate = new Date(base);
    }

    onBeforeSave(): boolean | void {
        return super.onBeforeSave();
    }

    getOrgUnit() {
        this._OrgUnitApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.arrOrgUnit = [];
                this.convertDataToOptions(this.arrOrgUnit, rs.data, "customerName");
                this.orgUnitList = rs.data;
            }
        }, error => {
            this._notifierService.showHttpUnknowError();
        })
    }
}
