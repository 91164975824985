<div class="p-formgrid grid">
    <div class="col-12 lg:col-8 md:col-6">
        <span class="p-buttonset">
            <button pButton pRipple type="button" icon="pi pi-print" label=" {{'Print' | translate}}" (click)="print()"
                class="p-button-outlined p-button-primary"></button>
            <button pButton pRipple type="button" icon="pi pi-file-pdf" label=" {{'pdf' | translate}}"
                (click)="exportPdf()" class="p-button-outlined p-button-primary"></button>
            <button pButton pRipple type="button" icon="pi pi-file-excel" label=" {{'Excel' | translate}}"
                (click)="exportExcel()" class="p-button-outlined p-button-primary"></button>
        </span>
    </div>
</div>
<div class="col-12" class="flex flex-wrap">
    <div [innerHtml]="htmlContent" class="mx-auto print" id="print_content"></div>
</div>
