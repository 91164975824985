import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {AuthenticationService, CoreUser, UserService} from 'nabit-shared';
import { PrimeNGConfig } from 'primeng/api';
import { authConfig } from './config/oidc-auth.config';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { OAuthService } from 'angular-oauth2-oidc';
import { LanguageService } from './core/services/language.service';
import {UserSettingsService} from "../../projects/nabit-shared/src/lib/services/usersettings.service";
import {AppConfigComponent} from "./layouts/main-layout/app.config.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    readonly settingKey = 'themesave';
    readonly settingSubject = 'theme';

    topbarTheme: string = 'blue';
    scale = 14;
    theme = 'indigo';
    menuTheme: string = 'light';
    layoutMode: string = 'light';
    inputStyle: string = 'outlined';
    serverTheme = {}
    currentSetting : any = {};

    menuMode: string = 'static';
    inlineMenuPosition: string = 'bottom';
    ripple: boolean = true;
    isRTL: boolean = false;
    dsLanguage = [];
    user = new CoreUser;
    constructor(
        private primengConfig: PrimeNGConfig,
        private translate: TranslateService,
        private _oauthService: OAuthService,
        private _userService: UserService,
        public _userSettings: UserSettingsService,
        private _languageService: LanguageService,
    ) {
        // Config for authentication
        this.configureWithNewConfigApi();
    }

    async ngOnInit() {
        this.primengConfig.ripple = true;

        await this.getLangs();
        await this.getTheme();
        //const arrLang = ['vi', 'en-US'];
        const userLang = navigator.language;
        this.translate.addLangs(this.dsLanguage);
        if (localStorage.getItem('language') !== null) {
            this.translate.use(localStorage.getItem('language'));
        } else {
            const lang = userLang.match(/vi|en-US/) ? userLang : 'vi';
            this.translate.use(lang);
            localStorage.setItem('language', lang);
        }
    }

    changeTheme(theme) {
        this.theme = theme;

        const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
        const themeHref = 'assets/theme/' + theme + '/theme-' + this.layoutMode + '.css';
        this.replaceLink(themeLink, themeHref);
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    public replaceLink(linkElement, href, callback?) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    }

    async getLangs() {
        this.dsLanguage = [];
        await this._languageService.getsAll().then(rs => {
            if (rs.success) {
                rs.data.map(item =>
                    this.dsLanguage.push(item.code)
                );
            }
        })
    }

    async getTheme() {
        if (this._userService.isReady) {
            await this._userSettings.getUserSetting(this.settingSubject, this.settingKey)
                .then(rs => {
                    if (rs.success) {
                        if (rs.data) {
                            this.currentSetting = rs.data;
                            try {
                                let values = JSON.parse(rs.data.value);
                                this.applyThemeObject(values);
                            } catch {} finally {
                                this.serverTheme = this.getCurrentThemeObject();
                            }
                        }
                    }
                })
        }
    }

    async saveTheme() {
        let baseSetting = this.currentSetting;
        let theme = this.getCurrentThemeObject();

        if (!baseSetting.id) {
            let user = await this._userService.getCurrentUser();
            baseSetting = {
                userId: +user.userId,
                subject: this.settingSubject,
                key: this.settingKey,
                status: 1
            }
        }
        let newItem = {
            ...baseSetting,
            value: JSON.stringify(theme)
        };
        let savingOperation = baseSetting.id
            ? this._userSettings.put(this.currentSetting?.id, newItem)
            : this._userSettings.post(newItem);
        await savingOperation
            .then(rs => {
                if (rs.success) {
                    this.serverTheme = theme;
                    this.applyThemeObject(theme);
                }
            })
    }

    applyThemeObject(values: any) {
        this.menuTheme = values.menuTheme;
        this.inputStyle = values.inputStyle;
        this.layoutMode = values.layoutMode;
        this.scale = values.scale;
        this.theme = values.theme;
        this.topbarTheme = values.topbarTheme;
        AppConfigComponent.applyScale(this.scale);
        this.changeTheme(this.theme);
    }

    getCurrentThemeObject() {
        return {
            scale: this.scale,
            layoutMode: this.layoutMode,
            inputStyle: this.inputStyle,
            menuTheme: this.menuTheme,
            topbarTheme: this.topbarTheme,
            theme: this.theme
        };
    }

    private configureWithNewConfigApi() {
        this._oauthService.setStorage(localStorage);
        this._oauthService.configure(authConfig);
        this._oauthService.tokenValidationHandler = new JwksValidationHandler();

        if (!this._oauthService.hasValidAccessToken()) {
            this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
                if (!rs) {
                    // this._oauthService.initImplicitFlow(`${environment.clientDomain.appDomain}`);
                } else {
                    this._userService.returnPromises();
                }
            });
        } else {
            this._userService.returnPromises();
            this._oauthService.loadDiscoveryDocument();
        }
    }
}
