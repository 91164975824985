<p-dialog #dialog header="{{ 'TITLE.EDIT' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true" styleClass="p-fluid">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
            </validation-summary>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <label for="code">{{'Báo cáo' | translate}}</label>
                    <p-dropdown formControlName="idReport" [options]="arrReport" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idReport"
                        [filter]="true" styleClass="w-full">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="title">{{'Danh mục' | translate}}</label>
                    <p-dropdown formControlName="masterDataId" [options]="arrMasterData" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.masterDataId"
                        [filter]="true" filterBy="label,value" styleClass="w-full">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="title">{{'Report Parameter' | translate}}</label>
                    <input type="text" pInputText [(ngModel)]="itemDetail.parameterName" formControlName="parameterName"
                        class="w-full">
                </div>
                <div class="field col-12 md:col-6">
                    <label for="status">{{'Trạng thái' | translate}}</label>
                    <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="true"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                        [filter]="true" filterBy="label,value" styleClass="w-full">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="isMultiple">{{'Filter Control' | translate}}</label>
                    <p-dropdown formControlName="isMultiple" [options]="arrIsMultiple" [autoDisplayFirst]="true"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isMultiple"
                        [filter]="true" filterBy="label,value" styleClass="w-full">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="isRequired">{{'Filter Required' | translate}}</label>
                    <p-dropdown formControlName="isRequired" [options]="arrIsRequired" [autoDisplayFirst]="true"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isRequired"
                        [filter]="true" filterBy="label,value" styleClass="w-full">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            [disabled]="submitting" class="p-button-outlined" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_CONTINUE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button-outlined" (click)="save()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id != 0" [disabled]="submitting" class="p-button-outlined" (click)="saveAsCopy()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button-outlined p-button-secondary" (click)="cancel()"></button>
    </ng-template>
</p-dialog>