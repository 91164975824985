<div class="card p-0">
    <p-tabView>
        <p-tabPanel header="{{ 'Bể' }}" leftIcon="pi pi-database">
            <app-store-tank-index></app-store-tank-index>
        </p-tabPanel>
        <p-tabPanel header="{{ 'Vòi bơm' }}" leftIcon="pi pi-plus">
            <app-store-pump-index></app-store-pump-index>
        </p-tabPanel>
        <p-tabPanel header="{{ 'Dải HĐ' }}" leftIcon="pi pi-save">
            <app-store-invoice-index></app-store-invoice-index>
        </p-tabPanel>
    </p-tabView>
</div>
