import { Component, Injector, Input, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "../../../../app.component";
import { AuthenticationService, SecondPageIndexBase } from "nabit-shared";
import { TankApiService } from "../../../Services/Tank-api.service";
import { ProductApiService } from "../../../Services/Product-api.service";
import { OrgUnitApiService } from "../../../Services/OrgUnit-api.service";
import { StoreTankFormComponent } from "../form/Store-tank-form.component";

@Component({
    selector: "app-store-tank-index",
    templateUrl: "./Store-tank-index.component.html",
    styleUrls: ["./Store-tank-index.component.css"]
})
export class StoreTankIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild("pEdit") pEdit: StoreTankFormComponent;
    arrBranch = [];
    arrTHD = [];
    arrProduct = [];

    productId = 0;
    isAuto = 0;
    @Input() idBranch = 0;
    tabMenuItems = [];

    constructor(
        protected _TankApiService: TankApiService,
        protected _injector: Injector,
        public app: AppComponent,
        private _productAPIService: ProductApiService,
        private _orgUnitAPIService: OrgUnitApiService,
        private _authenticationService: AuthenticationService,
    ) {
        super(_TankApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant("Bể chứa"), icon: "pi pi-book", routerLink: "../Tank" },
        ];
        this.cols = [
            { field: "tankCode", header: this._translateService.instant("FORM.CODE"), visible: true, width: "auto", sort: true, },
            { field: "tankName", header: this._translateService.instant("TABLEDATA.NAME"), visible: true, width: "auto", sort: true, },
            { field: "productId", header: this._translateService.instant("TABLEDATA.PRODUCT"), visible: true, width: "auto", sort: true, },
            { field: "vTotal", header: this._translateService.instant("TABLEDATA.VTOTAL"), visible: true, width: "auto", sort: true, },
            { field: "vExtra", header: this._translateService.instant("TABLEDATA.VEXTRA"), visible: false, width: "auto", sort: true, },

            { field: "isAuto", header: this._translateService.instant("TABLEDATA.isAuto"), visible: false, width: "auto", sort: true, },
            { field: "idBranch", header: this._translateService.instant("TABLEDATA.Branch"), visible: false, width: "auto", sort: true, },
            { field: "remarks", header: this._translateService.instant("TABLEDATA.remarks"), visible: false, width: "auto", sort: true, },

            { field: "tankType", header: this._translateService.instant("TABLEDATA.TANKTYPE"), visible: false, width: "auto", sort: true, },
            {
                field: "modifiedBy",
                header: this._translateService.instant("TABLEDATA.MODIFIED_BY"),
                visible: false,
                width: "auto",
                sort: true,
            },
            {
                field: "modified",
                header: this._translateService.instant("TABLEDATA.MODIFIED"),
                visible: false,
                width: "auto",
                sort: true,
                dateFormat: "dd/MM/yyyy HH:mm"
            },
            { field: "status", header: this._translateService.instant("TABLEDATA.STATUS"), visible: false, width: "auto", sort: true, },
        ];
        this.getData();
        this.getsProduct();
        this.getsBranch();

        this.arrTHD = [
            { label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 },
            { label: "Có TĐH", value: 1 },
            { label: "Không", value: 2 },
        ];
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
        this.pEdit.tankAmount = this.totalRecord + 1;
    }

    getData() {
        this.idBranch = this._authenticationService.getCurrentUser().branchId;
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        this._TankApiService.getsByBranchId(this.idBranch)
            .then(response => {
                if (response.success) {
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

    async getsProduct() {
        this.arrProduct = [];
        this.arrProduct = [{ label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 }];
        await this._productAPIService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProduct, rs.data, "productName");
            }
        });
    }

    async getsBranch() {
        var model = {
            keyword: "",
            status: 1,
            CustomerGroup: 0,
            BussinessType: 0,
            Branch: 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, "customerName");
            }
        });
    }
}
