import {Component, Input, OnInit} from '@angular/core';
import {requirements, selectValues} from "../../shared";
import {PriceCategoryApiService} from "../../../../Services/PriceCategory-api.service";
import {PriceCategory, PriceRequire, RequirementType} from "nabit-shared";

@Component({
    selector: 'app-trtype-form-product',
    templateUrl: './trtype-form-product.component.html',
    styleUrls: ['./trtype-form-product.component.scss']
})
export class TrtypeFormProductComponent implements OnInit {
    @Input() item: any;
    get itemDetail() { return this.item; }

    priceCategoryList = [];
    priceRequiredList = [
        { value: PriceRequire.NotEditAble, label: "Không được sửa giá" },
        { value: PriceRequire.CalFromBasePrice, label: "Tính từ giá trước thuế" },
        { value: PriceRequire.CalFromUnitPrice, label: "Tính từ giá bán sau thuế" },
    ];
    constructor(
        private PriceCategoryApiService: PriceCategoryApiService
    ) {
    }
    ngOnInit(): void {
        this.PriceCategoryApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.priceCategoryList = [
                        { categoryName: 'N/A', id: PriceCategory.NotAvailable },
                        ...rs.data
                    ];
                }
            })
    }
    protected readonly selectVal = selectValues;
    protected readonly requirements = requirements;

    protected readonly whdstRequirements = [
        { label: "N/A", value: RequirementType.NotAvailable },
        { label: "Kho đến", value: RequirementType.Warehouse },
        { label: "Địa bàn tiêu thụ", value: RequirementType.Place },
    ]
}
