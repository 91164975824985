import { Directive, ElementRef, Input, OnInit, AfterContentChecked } from '@angular/core';
import { PermissionTypes } from '../classes/constants';
import { PermissionService } from '../services/permission.service';
import { UserService } from '../services/user.service';

@Directive({
    selector: '[authorize]'
})
export class AuthorizeDirective implements OnInit, AfterContentChecked {
    protected _permissionRequired: any;
    protected _element: HTMLElement;
    protected _label: HTMLElement;
    protected _bind: boolean;
    protected _permissionTypes: PermissionTypes = PermissionTypes.CONTROL;

    @Input() set authorize(value: any) {
        this._permissionRequired = value;
    }

    @Input() set permissionType(value: any) {
        this._permissionTypes = value;
    }

    @Input() isNavigationPermission = false;

    @Input() controller: string;
    @Input() permission: string;
    @Input('keepHTML') keepHTML: boolean;

    constructor(
        private _el: ElementRef,
        private _permissionService: PermissionService,
        private _userService: UserService
    ) {
        this._element = _el.nativeElement;
    }

    async ngOnInit() {
        const currentUser = await this._userService.getCurrentUser();
        if (currentUser.issuperuser === 'True') {
            return;
        }
        if (this._permissionRequired) {
            let isAuthorized = false;
            try {
                this._permissionRequired = JSON.parse(this._permissionRequired.toString().replace(/'/g, '"'));
                for (const i in this._permissionRequired) {
                    const key = Object.keys(this._permissionRequired[i])[0];
                    const value = this._permissionRequired[i][key];
                    isAuthorized = await this._permissionService.isAuthorized(key, value);
                    if (isAuthorized) { break; }
                }
            }
            catch {
            }
            if (!isAuthorized) {
                if (this.keepHTML) {
                    this._label = document.createElement('span');

                    if (this._element.parentElement) {
                        this._element.parentElement.appendChild(this._label);
                        this._element.remove();
                    }
                } else {
                    this._element.remove();
                }
            }
        }

        const isAuthorized = await this._permissionService.isAuthorized(this._permissionRequired, this.permission);
        if (!isAuthorized) {
            if (this.keepHTML) {
                this._label = document.createElement('span');

                if (this._element.parentElement) {
                    this._element.parentElement.appendChild(this._label);
                    this._element.remove();
                }
            } else {
                this._element.remove();
            }
        }

    }

    ngAfterContentChecked() {
        if (this._label && !this._bind && this._element.innerHTML !== '' && this.keepHTML) {
            this._label.innerHTML = this._element.innerHTML;
            this._bind = true;
        }
    }
}
