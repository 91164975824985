import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ProductFormComponent } from '../form/Product-form.component';
import { ProductApiService } from '../../Services/Product-api.service';
import { ProductModel } from '../../Models/Product';
import { ProductGroupApiService } from '../../Services/ProductGroup-api.service';

@Component({
    selector: 'app-product-index',
    templateUrl: './Product-index.component.html',
    styleUrls: ['./Product-index.component.css']
})
export class ProductIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ProductFormComponent;
    arrProductGroup = [];
    arrPackage = [];
    arrCorrectionTable = [];
    productGroup = 0;
    package = 0;
    tabMenuItems = [];
    constructor(
        protected _ProductApiService: ProductApiService,
        protected _injector: Injector,
        public app: AppComponent,
        private _productGroupAPIService: ProductGroupApiService,
    ) {
        super(_ProductApiService, _injector);
    }

    async ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant('Hàng hóa'), icon: 'pi pi-book', routerLink: '../Product' },
            { label: this._translateService.instant('Nhóm hàng hóa'), icon: 'pi pi-book', routerLink: '../ProductGroup' },
            { label: this._translateService.instant('Đơn vị tính'), icon: 'pi pi-book', routerLink: '../ProductUnit' }
        ];
        this.cols = [
            { field: 'productCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true, },
            { field: 'productName', header: this._translateService.instant('FORM.TITLE'), visible: true, width: 'auto', sort: true, },
            { field: 'shortName', header: this._translateService.instant('TABLEDATA.SHORTNAME'), visible: false, width: 'auto', sort: true, },
            { field: 'productGroup', header: this._translateService.instant('TABLEDATA.ProductGroup'), visible: true, width: 'auto', sort: true, },
            { field: 'package', header: this._translateService.instant('TABLEDATA.Package'), visible: true, width: 'auto', sort: true, },
            { field: 'unit', header: this._translateService.instant('TABLEDATA.unit'), visible: false, width: 'auto', sort: true, },
            { field: 'oddUnitInPackage', header: this._translateService.instant('TABLEDATA.oddUnitInPackage'), visible: false, width: 'auto', sort: true, },
            { field: 'correctionTable', header: this._translateService.instant('TABLEDATA.correctionTable'), visible: false, width: 'auto', sort: true, },
            { field: 'lossIn_Percent', header: this._translateService.instant('TABLEDATA.lossIn_Percent'), visible: false, width: 'auto', sort: true, },
            { field: 'lossOut_Percent', header: this._translateService.instant('TABLEDATA.lossOut_Percent'), visible: false, width: 'auto', sort: true, },
            { field: 'lossStore_Percent', header: this._translateService.instant('TABLEDATA.lossStore_Percent'), visible: false, width: 'auto', sort: true, },
            { field: 'description', header: this._translateService.instant('TABLEDATA.Description'), visible: true, width: 'auto', sort: true, },
            { field: 'quality', header: this._translateService.instant('TABLEDATA.quality'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },            
        ];
        this.getData();
        this.getsProductGroup();
        this.arrPackage = [
            { label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 },
            { label: 'Lit', value: 1 },
            { label: 'Lit 15', value: 2 },
            { label: 'KG', value: 3 },
            { label: 'Chiếc', value: 4 },
            { label: 'Hộp', value: 5 },
            { label: 'Can', value: 6 },
            { label: 'Lon', value: 7 },
            { label: 'Thùng', value: 8 },
            { label: 'Phuy', value: 9 },
            { label: 'Tấn', value: 10 },
            { label: 'Xô', value: 11 },
            { label: 'Phi', value: 12 },
            { label: 'Tuýp', value: 13 },
            { label: 'Gói', value: 14 },
            { label: 'Đồng', value: 15 },
            { label: 'Ngày', value: 16 },
            { label: 'Tháng', value: 17 },
            { label: 'Năm', value: 18 },
            { label: 'Chai', value: 19 },
            { label: 'Cái', value: 20 },
            { label: 'Bộ', value: 21 },
            { label: 'Mét', value: 22 },
            { label: 'Bình', value: 23 },
            { label: 'Lít', value: 24 },
        ];

        this.arrCorrectionTable = [
            { label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 },
            { label: 'XDS', value: "1" },
            { label: 'DMN', value: "2" },
        ];
    }

    async edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    async add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        this.orderCol = "productCode";
        var model = {
            keyword: this.query,
            status: this.status,
            productGroup: this.productGroup,
            package: this.package,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._ProductApiService.find(model)
            .then(response => {
                if (response.success) {
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }
    async getsProductGroup() {
        this.arrProductGroup = [];
        this.arrProductGroup = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._productGroupAPIService.getsAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProductGroup, rs.data, 'productGroupName');
            }
        })
    }


}