import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { GLAccountFormComponent } from '../form/GLAccount-form.component';
import { GLAccountApiService } from '../../Services/GLAccount-api.service';

@Component({
    selector: 'app-glAccount-index',
    templateUrl: './GLAccount-index.component.html',
    styleUrls: ['./GLAccount-index.component.css']
})
export class GLAccountIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: GLAccountFormComponent;
    tabMenuItems = [];
    constructor(
        protected _GLAccountApiService: GLAccountApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_GLAccountApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('GLAccount'), icon: 'pi pi-book', routerLink: '../GLAccount'}
        ];
        this.cols = [
            { field: 'code', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'name', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'accountNature', header: this._translateService.instant('accountNature'), visible: true, width: 'auto', sort: true,},
            { field: 'accountGroup', header: this._translateService.instant('accountGroup'), visible: true, width: 'auto', sort: true,},
            { field: 'postOnly', header: this._translateService.instant('postOnly'), visible: true, width: 'auto', sort: true,},
            { field: 'userRef', header: this._translateService.instant('userRef'), visible: true, width: 'auto', sort: true,},
            { field: 'kmphiRef', header: this._translateService.instant('kmphiRef'), visible: true, width: 'auto', sort: true,},
            { field: 'customerRef', header: this._translateService.instant('customerRef'), visible: true, width: 'auto', sort: true,},
            { field: 'vendorRef', header: this._translateService.instant('vendorRef'), visible: true, width: 'auto', sort: true,},
            { field: 'coUnitRef', header: this._translateService.instant('coUnitRef'), visible: true, width: 'auto', sort: true,},
            { field: 'departmentRef', header: this._translateService.instant('departmentRef'), visible: true, width: 'auto', sort: true,},
            { field: 'contractRef', header: this._translateService.instant('contractRef'), visible: true, width: 'auto', sort: true,},
            { field: 'productRef', header: this._translateService.instant('productRef'), visible: true, width: 'auto', sort: true,},
            { field: 'otherRef', header: this._translateService.instant('otherRef'), visible: true, width: 'auto', sort: true,},
            { field: 'vatRef', header: this._translateService.instant('vatRef'), visible: true, width: 'auto', sort: true,},
            { field: 'faTrReasonRef', header: this._translateService.instant('faTrReasonRef'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this._GLAccountApiService.getsAll().then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
