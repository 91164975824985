import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ExpenseTypeFormComponent } from '../form/expenseType-form.component';
import { ExpenseTypeApiService } from '../../Services/ExpenseType-api.service';

@Component({
    selector: 'app-expenseType-index',
    templateUrl: './expenseType-index.component.html',
    styleUrls: ['./expenseType-index.component.css']
})
export class ExpenseTypeIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ExpenseTypeFormComponent;
    tabMenuItems = [];
    constructor(
        protected _expenseTypeApiService: ExpenseTypeApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_expenseTypeApiService, _injector);
    }
    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Loại phí'), icon: 'pi pi-book', routerLink: '../ExpenseType'},
        ];
        this.cols = [
            { field: 'expenseTypeCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', },
            { field: 'expenseTypeName', header: this._translateService.instant('FORM.TITLE'), visible: true, width: 'auto', },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }

    edit(id?:number) {
        console.log(id);
        if(id!=null){
            this.pEdit.showPopup(id);
        }else{
            this.pEdit.showPopup(this.selectedItems[0].id);
        }
    }

    add() {
        this.pEdit.showPopup();
    }
}
