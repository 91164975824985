import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {SecondPageIndexBase} from 'nabit-shared';
import {PriceListFormComponent} from '../form/PriceList-form.component';
import {PriceListApiService} from '../../Services/PriceList-api.service';
import {PriceRegionApiService} from '../../Services/PriceRegion-api.service';
import {ProductGroupApiService} from '../../Services/ProductGroup-api.service';
import {ProductSourceApiService} from '../../Services/ProductSource-api.service';
import {PriceCategoryApiService} from '../../Services/PriceCategory-api.service';

@Component({
    selector: 'app-priceList-index',
    templateUrl: './PriceList-index.component.html',
    styleUrls: ['./PriceList-index.component.css']
})
export class PriceListIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: PriceListFormComponent;
    tabMenuItems = [];
    listTime = [];
    chosenListTime = '';
    priceRegionList = [];
    chosenPriceRegionId?: number = 0;
    productGroupList = [];
    chosenProductGroupId?: number = 0;
    productSourceList = [];
    chosenProductSourceId?: number = 0;
    priceCategoryList = [];
    chosenPriceCategoryId?: number = 0;

    constructor(
        protected _PriceListApiService: PriceListApiService,
        protected _PriceRegionApiService: PriceRegionApiService,
        protected _ProductGroupApiService: ProductGroupApiService,
        protected _ProductSourceApiService: ProductSourceApiService,
        protected _PriceCategoryApiService: PriceCategoryApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_PriceListApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Áp giá'), icon: 'pi pi-book', routerLink: '../PriceSetting'},
            {label: this._translateService.instant('Hồ sơ giá'), icon: 'pi pi-book', routerLink: '../PriceList'},
            // {label: this._translateService.instant('Loại giá'), icon: 'pi pi-book', routerLink: '../PriceCategory'},
            // {label: this._translateService.instant('Vùng giá'), icon: 'pi pi-book', routerLink: '../PriceRegion'}

        ];
        this.cols = [
            {field: 'productId', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            {field: 'unitName', header: this._translateService.instant('ĐVT'), visible: true, width: 'auto', sort: true,},
            {field: 'validFrom', header: this._translateService.instant('Ngày hiệu lực'), visible: true, width: 'auto', sort: true,},
            {
                field: 'basePrice',
                header: this._translateService.instant('Đơn giá'),
                visible: true,
                width: 'auto',
                sort: true,
                align: 'right',
                formatNumber: true
            },
            {
                field: 'vat',
                header: this._translateService.instant('VAT %'),
                visible: true,
                width: 'auto',
                sort: true,
                align: 'right',
                formatNumber: true
            },
            {
                field: 'thueVAT',
                header: this._translateService.instant('Thuế VAT'),
                visible: true,
                width: 'auto',
                sort: true,
                align: 'right',
                formatNumber: true
            },
            {
                field: 'pxd',
                header: this._translateService.instant('Phí BVMT'),
                visible: true,
                width: 'auto',
                sort: true,
                align: 'right',
                formatNumber: true
            },
            {
                field: 'unitPrice',
                header: this._translateService.instant('Tổng cộng'),
                visible: true,
                width: 'auto',
                sort: false,
                align: 'right',
                formatNumber: true
            },
            {
                field: 'modified',
                header: this._translateService.instant('TABLEDATA.MODIFIED'),
                visible: false,
                width: 'auto',
                sort: true,
                dateFormat: 'dd/MM/yyyy HH:mm'
            },
            {
                field: 'modifiedBy',
                header: this._translateService.instant('TABLEDATA.MODIFIED_BY'),
                visible: false,
                width: 'auto',
                sort: true,
            },
            {field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true,},
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    async getListTime() {
        await this._PriceListApiService.GetListTime().then(rs => {
            if (rs.success) {
                this.listTime = rs.data.map(r => {
                    return {
                        label: new Date(r).toLocaleString('vi-VN'),
                        value: r
                    };
                });
                if (!this.chosenListTime) {
                    this.chosenListTime = this.listTime[0]?.value ?? '';
                }
            }
        });
    }

    async getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        if (this.listTime.length === 0) {
            await this.getListTime();
        }

        if (this.priceRegionList.length === 0) {
            await this._PriceRegionApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.priceRegionList = rs.data;
                }
                if (!this.chosenPriceRegionId) {
                    this.chosenPriceRegionId = this.priceRegionList[0]?.id;
                }
            });
        }

        if (this.productGroupList.length === 0) {
            await this._ProductGroupApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.productGroupList = rs.data;
                }
                if (!this.chosenProductGroupId) {
                    this.chosenProductGroupId = this.productGroupList[0]?.id;
                }
            });
        }

        if (this.productSourceList.length === 0) {
            await this._ProductSourceApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.productSourceList = rs.data;
                }
            });
        }

        if (this.priceCategoryList.length === 0) {
            await this._PriceCategoryApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.priceCategoryList = rs.data;
                }
                if (!this.chosenPriceCategoryId) {
                    this.chosenPriceCategoryId = this.priceCategoryList[0]?.id;
                }
            });
        }

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            validFrom: this.chosenListTime,
            'priceRegionId': this.chosenPriceRegionId,
            'productSourceId': this.chosenProductSourceId,
            'priceCategoryId': this.chosenPriceCategoryId,
            'productGroupId': this.chosenProductGroupId,
        };
        this._baseService.Find(model).then(response => {
            if (response.success) {
                this.dataSource = response.data.map(r => {
                    r.total = r.unitPrice + r.thueVAT + r.pxd;
                    return r;
                });
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
