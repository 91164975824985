import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { BusinessTypeFormComponent } from '../form/BusinessType-form.component';
import { BusinessTypeApiService } from '../../Services/BusinessType-api.service';

@Component({
    selector: 'app-businessType-index',
    templateUrl: './BusinessType-index.component.html',
    styleUrls: ['./BusinessType-index.component.css']
})
export class BusinessTypeIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: BusinessTypeFormComponent; 
    tabMenuItems = [];
    constructor(
        protected _BusinessTypeApiService: BusinessTypeApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_BusinessTypeApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Khách hàng'), icon: 'pi pi-book', routerLink: '../Customer'},
            {label: this._translateService.instant('Nhóm khách hàng'), icon: 'pi pi-book', routerLink: '../CustomerGroup'},
            {label: this._translateService.instant('Loại hình kinh doanh'), icon: 'pi pi-book', routerLink: '../BusinessType'}
        ];
        this.cols = [
            { field: 'code', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'name', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'shortName', header: this._translateService.instant('TABLEDATA.ShortName'), visible: true, width: 'auto', sort: true,}, 
            
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();


    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,  
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._BusinessTypeApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

}