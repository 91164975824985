import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ProductGroupApiService } from '../../Services/ProductGroup-api.service';

@Component({
    selector: 'app-productgroup-form',
    templateUrl: './ProductGroup-form.component.html',
    styleUrls: ['./ProductGroup-form.component.css']
})
export class ProductGroupFormComponent extends SecondPageEditBase implements OnInit {

    RegexCode = "^[a-z0-9A-Z]{3}$";
    RegexName = "^[a-z0-9A-Z._-]{1,30}$";
    productGroupCode = ""

    constructor(
        protected _ProductGroupApiService: ProductGroupApiService,
        protected _injector: Injector,
    ) {
        super(_ProductGroupApiService, _injector);

        this.formGroup = new FormGroup({
            productGroupCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            productGroupName: new FormControl('', [Validators.required]),
            shortName: new FormControl(''),
            parentGroup: new FormControl(''),
            parentName: new FormControl(''),
            status: new FormControl(''),
        });
    }

    async ngOnInit() {
    } 

    async onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._ProductGroupApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async validatorProductGroupCode() {
        const productGroupCode = this.formGroup.get('productGroupCode').validator({} as AbstractControl);
        if (productGroupCode) {
            this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
            this.submitting = false;
        }

    }
}

