import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {formatNumber} from '@angular/common';
import {Tank, TankLog} from "../Scada-interfaces";
import {TankLogApiService} from "../../../Services/TankLog-api.service";
import {SecondPageIndexBase} from "nabit-shared";

@Component({
    selector: 'app-scada-tank-log-table',
    templateUrl: './Scada-tank-log-table.component.html',
    styleUrls: ['./Scada-tank-log-table.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ScadaTankLogTableComponent extends SecondPageIndexBase implements OnInit, OnChanges {
    public tankLogs: TankLog[] = [];
    @Input() tank: Tank[] = [];
    public to: Date = new Date();
    public from: Date = new Date(+this.to - 86400 * 1000);
    public chosenTank?: number;
    public tankOptions: { name: string, code: number | undefined }[] = [];

    constructor(
        private _TankLogApiService: TankLogApiService,
        protected _injector: Injector,
    ) {
        super(_TankLogApiService, _injector);
    }

    remapDataSource() {
        let tankMap = new Map(
            this.tank.map(t => [t.iaDeviceId + t.iaTankId, t])
        );
        this.dataSource = this.tankLogs.map((tankLog, i) => {
            let tank = tankMap.get(tankLog.deviceId + tankLog.tankId) || {} as Tank;
            return {
                ...tankLog,
                ...tank,
                num: i + 1,
                temperature: tankLog.temperature.toFixed(2),
                fuelLevel: formatNumber(tankLog.fuelLevel, 'en-US', "1.0-2"),
                fuelVolume: formatNumber(tankLog.fuelVolume, 'en-US', "1.0-2"),
                fuelVolume15: formatNumber(tankLog.fuelVolume15, 'en-US', "1.0-2"),
                remainingFuelVolume: formatNumber(tankLog.remainingFuelVolume, 'en-US', "1.0-2"),
                fuelPercentage: ((1 - (tankLog.remainingFuelVolume / tank.vTotal)) * 100).toFixed(0) + '%',
                waterPercentage: (tankLog.waterVolume / tank.vTotal * 100).toFixed(0) + '%',
                remainingPercentage: (tankLog.remainingFuelVolume / tank.vTotal * 100).toFixed(0) + '%',
                time: new Date(tankLog.logTime).toLocaleString('vi-VN')
            };
        });
    }

    ngOnInit(): void {
        this.cols = [
            { field: 'num', header: this._translateService.instant('STT'), sort: true, visible: true, width: 'auto', spanned: true, align: 'center' },
            { field: 'tankName', header: this._translateService.instant('Tên bể'), visible: true, width: 'auto', spanned: true },
            { field: 'temperature', header: this._translateService.instant('Nhiệt độ'), visible: true, width: 'auto', align: 'right' },
            { field: 'fuelLevel', header: this._translateService.instant('Mức (mm)'), visible: true, width: 'auto', align: 'right' },
            { field: 'fuelVolume', header: this._translateService.instant('Lượng (L)'), visible: true, width: 'auto', align: 'right' },
            { field: 'fuelVolume15', header: this._translateService.instant('Lượng (L15)'), visible: true, width: 'auto', align: 'right' },
            { field: 'fuelPercentage', header: this._translateService.instant('%'), visible: true, width: 'auto', align: 'right' },
            { field: 'waterLevel', header: this._translateService.instant('Mức (mm)'), visible: true, width: 'auto', align: 'right' },
            { field: 'waterVolume', header: this._translateService.instant('Lượng (L)'), visible: true, width: 'auto', align: 'right' },
            { field: 'waterPercentage', header: this._translateService.instant('%'), visible: true, width: 'auto', align: 'right' },
            { field: 'remainingFuelVolume', header: this._translateService.instant('Lượng (L)'), visible: true, width: 'auto', align: 'right' },
            { field: 'remainingPercentage', header: this._translateService.instant('%'), visible: true, width: 'auto', align: 'right' },
            { field: 'time', header: this._translateService.instant('Thời gian'), sort: true, visible: true, width: 'auto', spanned: true, align: 'center' },
        ];
        this.pageSize = 20;
        this.getData();
    }

    getData(limit?: number) {
        this.dataSource = [];
        this.isLoading = true;
        this.resetBulkSelect();
        this._TankLogApiService.GetByTankId({
            tankId: this.chosenTank,
            page: this.pageIndex,
            fromDate: this.from,
            toDate: this.to
        })
            .then(response => {
                if (response.success) {
                    this.dataSource = response.data;
                    this.tankLogs = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                    this.remapDataSource();
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        let options = this.tank.map(t => ({
            name: t.tankName,
            code: t.id
        }));

        options = [{ name: 'Chọn bể', code: undefined }, ...options];
        this.tankOptions = options;
    }
}
