import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ExceptionHandlerService {

    constructor() { }

    handleError(error: HttpErrorResponse, injector: Injector) {
        return Promise.reject(error);
    }
}
