import {Component, OnInit, Injector, ViewEncapsulation, Input, OnChanges, SimpleChanges} from '@angular/core';
import { ReportViewerApiService } from '../Services/ReportViewer-api.service';
import { ActivatedRoute } from '@angular/router';
import { TRApiService } from '../../TR/Services/TR-api.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SecondPageEditBase } from 'nabit-shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductApiService } from '../../MD/Services/Product-api.service';
@Component({
  selector: 'app-DocViewer',
  templateUrl: './DocViewer.component.html',
  styleUrls: ['./DocViewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocViewerComponent implements OnInit, OnChanges {
  @Input() reportCode?: string;
  @Input() trId?: string;

  htmlContent: SafeHtml;
  reportData: any = [];
  isLoading: any;
  constructor(
    private _TRApiService: TRApiService,
    protected _injector: Injector,
    private sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _ReportViewerService: ReportViewerApiService
  ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    this._route.paramMap.subscribe(params => {
      this.reportCode = this._route.snapshot.paramMap.get('ReportCode');
      this.trId = this._route.snapshot.paramMap.get('TrId');
      this.htmlContent = "";
      this.load();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes.reportCode?.previousValue !== changes.reportCode?.currentValue || changes.trId?.previousValue !== changes.trId?.currentValue) {
          this.load();
      }
  }

    async load() {
      this.isLoading = true;
      this.htmlContent = '';
      await this._ReportViewerService.ExportHtmlSimple(this.reportCode, "TRID", [this.trId])
          .then(rs => {
              this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(rs);
          });
  }

  async print(){
    var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
    win.document.body.innerHTML = document.getElementById("print_content").innerHTML;
    win.print();
    win.close();
    //console.log(printContents);
    //var originalContents = document.body.innerHTML;
    //document.body.innerHTML = this.htmlContent.toString();
    //window.print();
    //document.body.innerHTML = originalContents;
  }
  exportPdf() {
    var model: any;
    model = {
      reportCode: this.reportCode,
      fromDate: new Date(),
      toDate: new Date(),
      filterConditions: [
        {
          parameterName: "TRID",
          selectedValue: [this.trId]
        }
      ],
    }
    this._ReportViewerService.ExportPdf(model);
  }
  exportExcel() {
    var model: any;
    model = {
      reportCode: this.reportCode,
      fromDate: new Date(),
      toDate: new Date(),
      filterConditions: [
        {
          parameterName: "TRID",
          selectedValue: [this.trId]
        }
      ],
    }
    this._ReportViewerService.ExportExcel(model);
  }
}
