import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SecondPageEditBase} from 'nabit-shared';
import {SerialParamApiService} from '../../../../EInvoice/Services/SerialParam-api.service';
import {BranchConfigApiService} from '../../../../EInvoice/Services/BranchConfig-api.service';

@Component({
    selector: 'app-store-invoice-form',
    templateUrl: './Store-invoice-form.component.html',
    styleUrls: ['./Store-invoice-form.component.css']
})
export class StoreInvoiceFormComponent extends SecondPageEditBase implements OnInit {
    arrBranchConfig = [];

    constructor(
        protected _SerialParamApiService: SerialParamApiService,
        protected _injector: Injector,
        protected _BranchConfigApiService: BranchConfigApiService,
    ) {
        super(_SerialParamApiService, _injector);

        this.formGroup = new FormGroup({
            idBranchConfig: new FormControl(''),
            invoiceSerial: new FormControl(''),
            invoicePattern: new FormControl(''),
            businessId: new FormControl(''),
            trTypes: new FormControl(''),
            validFrom: new FormControl(''),
            startNumber: new FormControl(''),
            endNumber: new FormControl(''),
            status: new FormControl(''),
        });
    }

    async ngOnInit() {
        this.getBranchConfig();
    }

    async onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._SerialParamApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.validFrom) {
                        this.itemDetail.validFrom = new Date(this.itemDetail.validFrom);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = {id: 0, status: 1};
            this.submitting = false;
        }
    }

    async getBranchConfig() {
        this.submitting = true;
        this._BranchConfigApiService.getsInUse().then(rs => {
            this.submitting = false;
            if (rs.success) {
                this.convertDataToOptions(this.arrBranchConfig, rs.data, 'fKeyPrecode');
            }
        }, error => {
            this._notifierService.showHttpUnknowError();
            this.submitting = false;
        });
    }
}
