import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-config',
    template: `
        <p-dialog #sidebar [visible]="configActive" modal="true" (visibleChange)="hide()"
                   [blockScroll]="true" [baseZIndex]="1000"
                  header="{{ 'Tùy biến giao diện' | translate }}"
                   styleClass="layout-config p-sidebar-sm fs-small p-0">
            <div class="layout-config-panel flex flex-column">
                <div class="layout-config-options p-3">
                    <h6>Cỡ chữ</h6>
                    <div class="flex align-items-center">
                        <button pButton pRipple type="button" icon="pi pi-minus" (click)="decrementScale()"
                                class="p-button-rounded p-button-text" [disabled]="app.scale === scales[0]"></button>
                        <i class="pi pi-circle-on m-1 scale-icon" *ngFor="let s of scales"
                           [ngClass]="{'scale-active': s === app.scale}"></i>
                        <button pButton pRipple type="button" icon="pi pi-plus" (click)="incrementScale()"
                                class="p-button-rounded p-button-text"
                                [disabled]="app.scale === scales[scales.length - 1]"></button>
                    </div>

                    <h6>Giao diện</h6>
                    <div class="flex">
                        <div class="flex align-items-center">
                            <p-radioButton name="layoutMode" value="light" [(ngModel)]="app.layoutMode"
                                           inputId="layoutMode1"
                                           (onClick)="onLayoutModeChange($event, 'light')"></p-radioButton>
                            <label for="layoutMode1" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">Sáng</label>
                        </div>
                        <div class="flex align-items-center" [ngClass]="{'ml-4': !app.isRTL, 'mr-4': app.isRTL}">
                            <p-radioButton name="layoutMode" value="dark" [(ngModel)]="app.layoutMode"
                                           inputId="layoutMode2"
                                           (onClick)="onLayoutModeChange($event, 'dark')"></p-radioButton>
                            <label for="layoutMode2" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">Tối</label>
                        </div>
                    </div>
                    <h6>Ô nhập liệu</h6>
                    <div class="flex">
                        <div class="flex align-items-center">
                            <p-radioButton name="inputStyle" value="outlined" [(ngModel)]="app.inputStyle"
                                           inputId="inputStyle1" (onClick)="onInputStyleClick()"></p-radioButton>
                            <label for="inputStyle1"
                                   [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">Bo viền</label>
                        </div>
                        <div class="flex align-items-center" [ngClass]="{'ml-4': !app.isRTL, 'mr-4': app.isRTL}">
                            <p-radioButton name="inputStyle" value="filled" [(ngModel)]="app.inputStyle"
                                           inputId="inputStyle2" (onClick)="onInputStyleClick()"></p-radioButton>
                            <label for="inputStyle2" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">Đậm chân</label>
                        </div>
                    </div>
                    <ng-container *ngIf="app.layoutMode !== 'dark'">
                        <h6>Màu menu trái</h6>
                        <div *ngIf="app.layoutMode!=='dark'" class="grid">
                            <div *ngFor="let t of menuThemes" class="col col-fixed">
                                <a style="cursor: pointer" (click)="changeMenuTheme(t)" class="layout-config-color-option"
                                   [title]="t.name">
                                    <span class="color border-circle" [ngStyle]="{'background-color': t.color}"></span>
                                    <span class="check flex align-items-center justify-content-center"
                                          *ngIf="app.menuTheme === t.name">
                                    <i class="pi pi-check border-circle" style="color: var(--menu-text-color)"></i>
                                </span>
                                </a>
                            </div>
                        </div>
                    </ng-container>

                    <h6>Màu menu trên</h6>
                    <div class="grid">
                        <div *ngFor="let t of topbarThemes" class="col col-fixed">
                            <a style="cursor: pointer" (click)="changeTopbarTheme(t)" class="layout-config-color-option"
                               [title]="t.name">
                                <span class="color border-circle" [ngStyle]="{'background-color': t.color}"></span>
                                <span class="check flex align-items-center justify-content-center"
                                      *ngIf="app.topbarTheme === t.name">
                                    <i class="pi pi-check" style="color: var(--topbar-text-color)"></i>
                                </span>
                            </a>
                        </div>
                    </div>

                    <h6>Màu nút</h6>
                    <div class="grid">
                        <div *ngFor="let t of themes" class="col col-fixed">
                            <a style="cursor: pointer" (click)="app.changeTheme(t.name)" class="layout-config-color-option"
                               [title]="t.name">
                                <span class="color border-circle" [ngStyle]="{'background-color': t.color}"></span>
                                <span class="check flex align-items-center justify-content-center"
                                      *ngIf="app.theme === t.name">
                                    <i class="pi pi-check" style="color: var(--primary-color-text)"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
                        class="p-button mr-2 mb-2"
                        (click)="saveAndClose()">

                </button>
            </div>
        </p-dialog>
    `
})
export class AppConfigComponent implements OnInit {
    scales: number[] = [12, 13, 14, 15, 16];

    themes: any[];

    menuThemes: any[];

    menuTheme = 'light';

    topbarThemes: any[];

    topbarTheme = 'blue';

    matchingMenuTheme = false;

    matchingTopbarTheme = false;

    selectedMenuTheme: any;

    selectedTopbarTheme: any;

    @Input() configActive = false;
    @Output() onHide = new EventEmitter();

    isInputBackgroundChanged = false;

    constructor(
        public appMain: AppMainComponent,
        public app: AppComponent
    ) { }

    ngOnInit() {
        this.themes = [
            { name: 'indigo', color: '#3F51B5' },
            { name: 'pink', color: '#E91E63' },
            { name: 'purple', color: '#9C27B0' },
            { name: 'deeppurple', color: '#673AB7' },
            { name: 'blue', color: '#2196F3' },
            { name: 'lightblue', color: '#03A9F4' },
            { name: 'cyan', color: '#00BCD4' },
            { name: 'teal', color: '#009688' },
            { name: 'green', color: '#4CAF50' },
            { name: 'lightgreen', color: '#8BC34A' },
            { name: 'lime', color: '#CDDC39' },
            { name: 'yellow', color: '#FFEB3B' },
            { name: 'amber', color: '#FFC107' },
            { name: 'orange', color: '#FF9800' },
            { name: 'deeporange', color: '#FF5722' },
            { name: 'brown', color: '#795548' },
            { name: 'bluegrey', color: '#607D8B' }
        ];

        this.menuThemes = [
            { name: 'light', color: '#FDFEFF' },
            { name: 'dark', color: '#434B54' },
            { name: 'indigo', color: '#1A237E' },
            { name: 'bluegrey', color: '#37474F' },
            { name: 'brown', color: '#4E342E' },
            { name: 'cyan', color: '#006064' },
            { name: 'green', color: '#2E7D32' },
            { name: 'deeppurple', color: '#4527A0' },
            { name: 'deeporange', color: '#BF360C' },
            { name: 'pink', color: '#880E4F' },
            { name: 'purple', color: '#6A1B9A' },
            { name: 'teal', color: '#00695C' }
        ];

        this.topbarThemes = [
            { name: 'lightblue', color: '#2E88FF' },
            { name: 'dark', color: '#363636' },
            { name: 'white', color: '#FDFEFF' },
            { name: 'blue', color: '#1565C0' },
            { name: 'deeppurple', color: '#4527A0' },
            { name: 'purple', color: '#6A1B9A' },
            { name: 'pink', color: '#AD1457' },
            { name: 'cyan', color: '#0097A7' },
            { name: 'teal', color: '#00796B' },
            { name: 'green', color: '#43A047' },
            { name: 'lightgreen', color: '#689F38' },
            { name: 'lime', color: '#AFB42B' },
            { name: 'yellow', color: '#FBC02D' },
            { name: 'amber', color: '#FFA000' },
            { name: 'orange', color: '#FB8C00' },
            { name: 'deeporange', color: '#D84315' },
            { name: 'brown', color: '#5D4037' },
            { name: 'grey', color: '#616161' },
            { name: 'bluegrey', color: '#546E7A' },
            { name: 'indigo', color: '#3F51B5' }
        ];

        this.selectedMenuTheme = this.menuThemes.find(theme => theme.name === this.menuTheme);
        this.selectedTopbarTheme = this.topbarThemes.find(theme => theme.name === this.topbarTheme);
    }

    decrementScale() {
        this.app.scale--;
        AppConfigComponent.applyScale(this.app.scale);
    }

    incrementScale() {
        this.app.scale++;
        AppConfigComponent.applyScale(this.app.scale);
    }

    static applyScale(scale: number) {
        document.documentElement.style.fontSize = scale + 'px';
    }

    onInputStyleClick() {
        this.isInputBackgroundChanged = true;
    }

    onLayoutModeChange(event, mode) {
        const appLogoLink: HTMLImageElement = document.getElementById('app-logo') as HTMLImageElement;
        this.app.layoutMode = mode;

        if (!this.isInputBackgroundChanged) {
            this.app.inputStyle = mode === 'dark' ? 'filled' : 'outlined';
        }

        if (mode === 'dark') {
            this.app.menuTheme = 'dark';
            this.app.topbarTheme = 'dark';
            // appLogoLink.src = 'assets/layout/images/logo-light.svg';
        }
        else {
            this.app.menuTheme = 'light';
            this.app.topbarTheme = 'blue';
            // appLogoLink.src = 'assets/layout/images/logo-light.svg';
        }

        const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
        const layoutHref = 'assets/layout/css/layout-' + this.app.layoutMode + '.css';
        this.app.replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById('theme-css');
        const urlTokens = themeLink.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = 'theme-' + this.app.layoutMode + '.css';
        const newURL = urlTokens.join('/');

        this.app.replaceLink(themeLink, newURL, this.appMain['refreshChart']);
    }

    changeMenuTheme(theme) {
        this.selectedMenuTheme = theme;
        this.app.menuTheme = theme.name;
    }

    changeTopbarTheme(theme) {
        this.selectedTopbarTheme = theme;
        this.app.topbarTheme = theme.name;

        const appLogoLink: HTMLImageElement = document.getElementById('app-logo') as HTMLImageElement;

        // if (theme.name === 'white' || theme.name === 'yellow' || theme.name === 'amber'
        //     || theme.name === 'orange' || theme.name === 'lime') {
        //     appLogoLink.src = 'assets/layout/images/logo-dark.svg';
        // }
        // else {
        //     appLogoLink.src = 'assets/layout/images/logo-light.svg';
        // }
    }

    saveAndClose() {
        this.app.saveTheme()
            .then(() => this.hide())
    }

    hide() {
        this.app.applyThemeObject(this.app.serverTheme)
        this.onHide.emit();
    }
}
