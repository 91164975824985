<div class="table-wrapper">
    <p-table [columns]="cols" [value]="dataSource"
        class="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines"
        [rows]="pageSize" [totalRecords]="totalRecord" [customSort]="true" (onSort)="onSort($event)"
        (onPage)="onPage($event)" [loading]="isLoading" [lazy]="true">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width: 50px">
                <ng-container *ngFor="let col of cols">
                    <col [hidden]="!col.visible" [style.width]="col.width">
                </ng-container>
            </colgroup>
        </ng-template>
        <ng-template let-columns pTemplate="header">
            <tr>
                <th rowspan="2" class="text-center" width="40">STT</th>
                <th rowspan="2" class="text-center" width="200">Tên bể</th>
                <th colspan="5" class="text-center">Hàng hóa</th>
                <th colspan="3" class="text-center">Nước</th>
                <th colspan="3" class="text-center">Trống bể</th>
            </tr>
            <tr>
                <ng-container *ngFor="let col of columns">
                    <th *ngIf="col.sort" [hidden]="(!col.visible || col.spanned)" [pSortableColumn]="col.field"
                        class="text-center">
                        <div class="h-flex-el -h-space-between">
                            <div class="h-overflow-break-word">{{col.header}}</div>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </div>
                    </th>

                    <th *ngIf="!col.sort" [hidden]="(!col.visible || col.spanned)" class="text-center">
                        <div class="h-overflow-break-word">{{col.header}}</div>
                    </th>
                </ng-container>
                <th class="text-center" width="200"></th>
            </tr>
        </ng-template>

        <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
            <tr [class.ag-tr-status-danger]="rowData.status === 3" [class.ag-tr-status-success]="rowData.status === 1"
                [class.ag-tr-status-warning]="rowData.status === 2" [class.ag-tr-status]="rowData.status">
                <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field"
                    [style.text-align]="col.align">
                    <span *ngSwitchCase="'modified'">
                        {{rowData.modified | date:'dd/MM/yyyy HH:mm'}}
                    </span>
                    <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                </td>
                <td colspan="3">
                    <div *ngIf="rowData.fillPercentage"
                        style="height: 1.2rem; border-radius: 5px; background-color: darkgrey; display: flex; flex-direction: row;">
                        <div [style.text-align]="'center'" [style.height]="'1.2rem'"
                            [style.width]="rowData.fillPercentage + '%'"
                            [style]="{ 'border-radius': rowData.fillPercentage === 100 ? '5px 5px 5px 5px' : '5px 0 0 5px', 'background-color': 'lightgreen' }">
                            {{ rowData.fillPercentage | number: '1.0-0' }}%
                        </div>
                        <div [style.text-align]="'center'" [style.height]="'1.2rem'"
                            [style.width]="(100 - rowData.fillPercentage) + '%'"
                            [style]="{ 'border-radius': rowData.fillPercentage === 0 ? '5px 5px 5px 5px' : '0 5px 5px 0' }">
                            {{ 100 - rowData.fillPercentage | number: '1.0-0' }}%
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>