import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ProductSourceFormComponent } from '../form/ProductSource-form.component';
import { ProductSourceApiService } from '../../Services/ProductSource-api.service';

@Component({
    selector: 'app-productsource-index',
    templateUrl: './ProductSource-index.component.html',
    styleUrls: ['./ProductSource-index.component.css']
})
export class ProductSourceIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ProductSourceFormComponent;
    tabMenuItems = [];
    constructor(
        protected _ProductSourceApiService: ProductSourceApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_ProductSourceApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Nguồn hàng'), icon: 'pi pi-book', routerLink: '../ProductSource'}
        ];
        this.cols = [
            { field: 'productSourceCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', },
            { field: 'productSourceName', header: this._translateService.instant('FORM.TITLE'), visible: true, width: 'auto', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }

    edit(id?:number) {
        if(id==null){
            this.pEdit.showPopup(this.selectedItems[0].id);
        }else{
            this.pEdit.showPopup(id);
        }
        
    }

    add() {
        this.pEdit.showPopup();
    }

}
