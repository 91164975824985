import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { Console } from 'console';
import { WarehouseApiService } from '../../Services/Warehouse-api.service';
import { ProductSourceApiService } from '../../Services/ProductSource-api.service';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';

@Component({
    selector: 'app-warehouse-form',
    templateUrl: './Warehouse-form.component.html',
    styleUrls: ['./Warehouse-form.component.css']
})
export class WarehouseFormComponent extends SecondPageEditBase implements OnInit {

    arrProductSource = [];
    arrBranchId = [];
    RegexCode = "^[a-z0-9A-Z._-]{1,4}$";
    Regexname = "^[a-z0-9A-Z_-]{1,50}$";
    RegexAdd = "^[a-z0-9A-Z._-]{1,100}$";
    Regexshort = "^[a-z0-9A-Z._-]{1,30}$";

    constructor(
        protected _WarehouseApiService: WarehouseApiService,
        protected _injector: Injector,
        private _productSourceAPIService: ProductSourceApiService,
        private _orgUnitApiService: OrgUnitApiService
    ) {
        super(_WarehouseApiService, _injector);

        this.formGroup = new FormGroup({
            warehouseCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            warehouseName: new FormControl('', [Validators.required]),
            address: new FormControl(''),
            branchId: new FormControl(''),
            productSourceId: new FormControl(''),
            synchRunner: new FormControl(''),
            shortName: new FormControl(''),
            status: new FormControl('', [Validators.required]),
        });
        this.arrBranchId = [];
    }

    async ngOnInit() {
        let model = {
            keyword: "",
            status: 1,
            CustomerGroup : 22,
            BussinessType : 0,
            Branch : 0,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: "",
            isDesc: true
        }
        this._orgUnitApiService.find(model).then(rs => {
            if (rs.success) {
                this.arrBranchId = rs.data;
            }
        })
    }

    async onShowPopup(id: number) {

        if (this.arrProductSource.length == 0) {
            await this.getsProductSource();
        }

        this.submitting = true;

        if (id > 0) {
            this._WarehouseApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsProductSource() {
        this.arrProductSource = [];
        await this._productSourceAPIService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProductSource, rs.data, 'productSourceName');
                console.log(this.arrProductSource);
                console.log(rs.data);
            }
        })
    }
}