import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';
import { OrgUnitFormComponent } from '../../OrgUnit/form/OrgUnit-form.component';
import { StorePopupComponent } from '../popup/Store-popup.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-store-index',
    templateUrl: './Store-index.component.html',
    styleUrls: ['./Store-index.component.css']
})
export class StoreIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild("pEdit") pEdit: StorePopupComponent;
    tabMenuItems = [];
    constructor(
        protected _orgUnitApiService: OrgUnitApiService,
        protected _injector: Injector,
        public app: AppComponent,
        protected router: Router,
    ) {
        super(_orgUnitApiService, _injector);
    }
    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant('Cửa hàng'), icon: 'pi pi-book', routerLink: '../Store' },
        ];
        this.cols = [
            { field: 'customerCode', header: this._translateService.instant('Mã'), visible: true, width: 'auto', sort: true, style: 'text-align: center' },
            { field: 'customerName', header: this._translateService.instant('Tên'), visible: true, width: 'auto', style: 'text-align: center' },
            { field: 'shortName', header: this._translateService.instant('Tên ngắn'), visible: true, width: 'auto', style: 'text-align: center' },
            { field: 'priceGroup', header: this._translateService.instant('Vùng giá'), visible: true, width: 'auto', style: 'text-align: center' },
            { field: 'address', header: this._translateService.instant('Địa chỉ'), visible: true, width: 'auto', sort: true, style: 'text-align: center' },
            { field: 'tel', header: this._translateService.instant('Số điện thoại'), visible: true, width: 'auto', sort: true },
            { field: 'status', header: this._translateService.instant('Trạng thái'), visible: true, width: 'auto' },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto' },
        ];
        this.getData();
    }

    edit(id?: number) {
        console.log(id);
        if (id != null) {
            this.router.navigateByUrl(`MD/Store/${id}`);
        } else {
            this.router.navigateByUrl(`MD/Store/${this.selectedItems[0].id}`);
        }
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.selectedItems = [];
        this.ids = [];
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderColl: this.orderCol,
            isDesc: this.isDesc
        };
        this._baseService.Find(model)
            .then(response => {
                this.dataSource = response.data;

                if (response.totalRecord || response.totalRecord === 0) {
                    this.totalRecord = response.totalRecord;
                }

                this.afterGetData();
                this.isLoading = false;
            }, error => {
                this._notifierService.showHttpUnknowError();
                this.isLoading = false;
            });
    }
}
