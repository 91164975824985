<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Phương tiện vận chuyển' | translate }}" [(visible)]="isShow"
    [modal]="true" [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25 mt-25">{{'Mã phương tiện' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.vehicleCode" formControlName="vehicleCode"
                        placeholder="Định dạng text, tối đa 10 ký tự VD: '30E 56789' ">
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25 mt-25">{{'Biển số' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.numberPlate" formControlName="numberPlate"
                            placeholder="Định dạng text, tối đa 9 ký tự VD: '30E 56789'">
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25">{{'Tên phương tiện' | translate}}<span
                            style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.vehicleName" formControlName="vehicleName"
                            placeholder="Định dạng text, tối đa 100 ký tự">
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25">{{'Hạn đăng kiểm' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-calendar [showIcon]="true" styleClass="w-full" [(ngModel)]="itemDetail.inspectionExpire"
                        dateFormat="dd/mm/yy" formControlName="inspectionExpire" placeholder="dd/mm/yyy">
                    </p-calendar>
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25">{{'Hạn Barem' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-calendar [showIcon]="true" styleClass="w-full" [(ngModel)]="itemDetail.fPAFExpire"
                        dateFormat="dd/mm/yy" formControlName="fPAFExpire" placeholder="dd/mm/yyy" appendTo="body">
                    </p-calendar>
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25">{{'Lái xe' | translate}}</div>
                </div>
                <div class="col-8 md:col-10 field">
                    <input type="text" id="driverName" pInputText [(ngModel)]="itemDetail.driverName"
                        formControlName="driverName" placeholder="Định dạng text">
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25">{{'Số CMDN/CCCD' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" id="driverIdCard" pInputText [(ngModel)]="itemDetail.driverIdCard"
                        formControlName="driverIdCard" placeholder="Định dạng text">
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25">{{'Ngày cấp' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-calendar [showIcon]="true" styleClass="w-full" [(ngModel)]="itemDetail.idCardDate"
                        dateFormat="dd/mm/yy" formControlName="idCardDate" placeholder="dd/mm/yyy" appendTo="body">
                    </p-calendar>
                </div>
                <div class="col-4 md:col-2">
                    <div class="label font-medium mt-25">{{'Loại phương tiện' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="vehicleTypeId" [options]="arrVehicleTypeId" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.vehicleTypeId"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 2px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Phương thức V/C' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="vehicleClassId" [options]="arrVehicleClassId"
                        [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                        [(ngModel)]="itemDetail.vehicleClassId" [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-2">
                    <div class="label font-medium mt-25">{{'Nhà CC' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="customerId" [options]="arrCustomer" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.customerId"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-2">
                    <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12" style="font-weight: 450;">
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">Thông tin hầm hàng</div>
                    </div>
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 1' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell1" pInputText [(ngModel)]="itemDetail.cell1" formControlName="cell1"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 2' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell2" pInputText [(ngModel)]="itemDetail.cell2" formControlName="cell2"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1 field">
                    <div class="label font-medium mt-25">{{'Hầm 3' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell3" pInputText [(ngModel)]="itemDetail.cell3" formControlName="cell3"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 4' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell4" pInputText [(ngModel)]="itemDetail.cell4" formControlName="cell4"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 5' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell5" pInputText [(ngModel)]="itemDetail.cell5" formControlName="cell5"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 6' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell6" pInputText [(ngModel)]="itemDetail.cell6" formControlName="cell6"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 7' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell7" pInputText [(ngModel)]="itemDetail.cell7" formControlName="cell7"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 8' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell8" pInputText [(ngModel)]="itemDetail.cell8" formControlName="cell8"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 9' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell9" pInputText [(ngModel)]="itemDetail.cell9" formControlName="cell9"
                        class="text-right">
                </div>
                <div class="col-2 md:col-1">
                    <div class="label font-medium mt-25">{{'Hầm 10' | translate}}</div>
                </div>
                <div class="col-2 md:col-2 field">
                    <input type="text" id="cell10" pInputText [(ngModel)]="itemDetail.cell10" formControlName="cell10"
                        class="text-right">
                </div>
                <div class="col-2 md:col-3">
                    <div class="label font-medium mt-25">{{'Tổng dung tích' | translate}}
                    </div>
                </div>
                <div class="col-2 md:col-2 field font-bold">
                    <div class="mt-25">{{totalCell() | number: '1.0-0'}}</div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>
