import { Component, Injector, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SecondPageEditBase } from "nabit-shared";
import { TankApiService } from "../../../Services/Tank-api.service";
import { OrgUnitApiService } from "../../../Services/OrgUnit-api.service";
import { ProductApiService } from "../../../Services/Product-api.service";
import { OrgUnitSettingApiService } from "../../../Services/OrgUnitSetting-api.service";
import { ManufactureApiService } from "../../../Services/Manufacture-api.service";

@Component({
    selector: "app-store-tank-form",
    templateUrl: "./Store-tank-form.component.html",
    styleUrls: ["./Store-tank-form.component.css"]
})
export class StoreTankFormComponent extends SecondPageEditBase implements OnInit {

    arrProduct = [];
    arrBranch = [];
    arrTankType = [];
    arrTHD = [];
    arrManufacture = [];
    arrextraStatus = [];
    @Input() branchId = 0;
    tankAmount = 0;

    RegexCode = "^[a-z0-9A-Z._-]{1,8}$";
    RegexNumber = "^[0-9]{1,12}$";
    RegexPhone = "^[0-9\.]{1,12}$";
    RegexEmail = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

    constructor(
        protected _TankApiService: TankApiService,
        protected _injector: Injector,
        private _productAPIService: ProductApiService,
        private _orgUnitAPIService: OrgUnitApiService,
        private _orgUnitSettingApiService: OrgUnitSettingApiService,
        private _manufactureApiService: ManufactureApiService,
    ) {
        super(_TankApiService, _injector);

        this.formGroup = new FormGroup({
            tankCode: new FormControl({ value: "", disabled: true }, [Validators.required, Validators.pattern(this.RegexCode)]),
            tankName: new FormControl(""),
            idBranch: new FormControl(""),
            productId: new FormControl(""),
            iaManufactureId: new FormControl({ value: "", disabled: true }),
            iADeviceId: new FormControl({ value: "", disabled: true }),
            iATankId: new FormControl(""),
            isAuto: new FormControl(""),
            minHeight: new FormControl(""),
            minVolume: new FormControl(""),
            maxHeight: new FormControl(""),
            maxVolume: new FormControl(""),
            minAlarmPercent: new FormControl("", [Validators.max(100)]),
            lowAlarmPercent: new FormControl("", [Validators.max(100)]),
            maxAlarmPercent: new FormControl("", [Validators.max(100)]),
            highAlarmPercent: new FormControl("", [Validators.max(100)]),
            remarks: new FormControl(""),
            vTotal: new FormControl(""),
            vExtra: new FormControl(""),
            extraStatus: new FormControl(""),
            tankType: new FormControl(""),
            fullFactor: new FormControl(""),
            order: new FormControl(""),
            created: new FormControl(""),
            logTime: new FormControl(""),
            status: new FormControl(""),
        });


        this.arrTankType = [
            { label: "Bể trụ đứng", value: 1 },
            { label: "Bể trụ ngang", value: 2 },
        ];

        this.arrTHD = [
            { label: "Có TĐH", value: 1 },
            { label: "Không TĐH", value: 2 },
        ];

        this.arrextraStatus = [
            { label: "on", value: 1 },
            { label: "off", value: 2 },
        ];
    }

    async ngOnInit() {
        this.branchId = this._authenticationService.getCurrentUser().branchId;
    }

    async onShowPopup(id: number) {
        if (this.arrProduct.length == 0) {
            await this.getsProduct();
        }

        if (this.arrBranch.length == 0) {
            await this.getsBranch();
        }

        await this.getsManufacturer();

        this.submitting = true;
        if (id > 0) {
            this._TankApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this._orgUnitSettingApiService.GetsBySettingFilter({
                        idOrgUnit: +this.branchId,
                        settingName: "TankController",
                        settingParam: "Manufacturer",
                    }).then(r => {
                        if (r.success && r.data?.length) {
                            this.itemDetail.iaManufactureId = +r.data[0].settingValue;
                        }
                    });
                    this._orgUnitSettingApiService.GetsBySettingFilter({
                        idOrgUnit: +this.branchId,
                        settingName: "TankController",
                        settingParam: "DeviceId",
                    }).then(r => {
                        if (r.success && r.data?.length) {
                            this.itemDetail.iADeviceId = r.data[0].settingValue;
                        }
                    }, error => {
                        this._notifierService.showHttpUnknowError();
                    });
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                this.submitting = false;
            });
        } else {
            this.itemDetail = {
                id: 0,
                status: 1,
                idBranch: +this.branchId,
                tankCode: this.tankAmount < 10 ? `B0${this.tankAmount}` : `B${this.tankAmount}`
            };
            this.submitting = false;
        }
    }

    async getsProduct() {
        this.arrProduct = [];
        await this._productAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProduct, rs.data, "productName");
            }
        });
    }

    async getsManufacturer() {
        this.arrManufacture = [];
        await this._manufactureApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrManufacture, rs.data, "manufactureName");
            }
        });
    }

    async getsBranch() {
        let model = {
            keyword: "",
            status: 1,
            CustomerGroup: 0,
            BussinessType: 0,
            Branch: 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        };
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, "customerName");
            }
        });
    }
}

