import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SerialParamApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.einvoiceEndpoint}/SerialParam`);
    }

    GetSerialPattern(validTime: Date): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetSerialParttern`;
        let model = {
            validTime: validTime
        }
        return this._http.post<ResponseResult>(apiUrl, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
