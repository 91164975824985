import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MopsPriceApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/MopsPrice`);
    }

    getsBySearch(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    getByValidFrom(validFrom:Date, productId: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/getByValidFrom`;
        return this._http
            .post<ResponseResult>(apiUrl, {validFrom, productId})
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
