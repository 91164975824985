import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ProvinceApiService } from '../../Services/Province-api.service';

@Component({
    selector: 'app-province-form',
    templateUrl: './Province-form.component.html',
    styleUrls: ['./Province-form.component.css']
})
export class ProvinceFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected ProvinceApiService: ProvinceApiService,
        protected _injector: Injector,
    ) {
        super(ProvinceApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            order: new FormControl(''),
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.ProvinceApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

