import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { StaffFormComponent } from '../form/Staff-form.component';
import { StaffApiService } from '../../Services/Staff-api.service';
import { DepartmentApiService } from '../../Services/Department-api.service';
import { UserService } from 'nabit-shared';
@Component({
    selector: 'app-staff-index',
    templateUrl: './Staff-index.component.html',
    styleUrls: ['./Staff-index.component.css']
})
export class StaffIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: StaffFormComponent;

    arrUserId = [];
    userId = 0;
    positionId = 0;
    departmentId = 0;
    arrPositionId = [];
    arrDepartmentId = [];
    tabMenuItems = [];
    constructor(
        protected _StaffApiService: StaffApiService,
        protected _userService: UserService,
        protected _injector: Injector,
        public app: AppComponent,
        private _departmentAPIService: DepartmentApiService,
    ) {
        super(_StaffApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [ 
            {label: this._translateService.instant('Cán bộ nhân viên'), icon: 'pi pi-book', routerLink: '../Staff'}
        ];
        this.cols = [
            { field: 'staffCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'staffName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'userId', header: this._translateService.instant('TABLEDATA.User'), visible: true, width: 'auto', sort: true,},
            { field: 'positionId', header: this._translateService.instant('TABLEDATA.PositionId'), visible: true, width: 'auto', sort: true,},
            { field: 'departmentId', header: this._translateService.instant('TABLEDATA.DepartmentId'), visible: true, width: 'auto', sort: true,},
            
            { field: 'isManager', header: this._translateService.instant('TABLEDATA.IsManager'), visible: false, width: 'auto', sort: true,},
            { field: 'isSaler', header: this._translateService.instant('TABLEDATA.IsSaler'), visible: false, width: 'auto', sort: true,},
            { field: 'isSealer', header: this._translateService.instant('TABLEDATA.IsSealer'), visible: false, width: 'auto', sort: true,},

            { field: 'isWorker', header: this._translateService.instant('TABLEDATA.IsWorker'), visible: false, width: 'auto', sort: true,},
            { field: 'isGuard', header: this._translateService.instant('TABLEDATA.IsGuard'), visible: false, width: 'auto', sort: true,},
            { field: 'doB', header: this._translateService.instant('TABLEDATA.DoB'), visible: false, width: 'auto', sort: true,},

            { field: 'email', header: this._translateService.instant('TABLEDATA.Email'), visible: true, width: 'auto', sort: true,},
            { field: 'mobile', header: this._translateService.instant('TABLEDATA.Mobile'), visible: false, width: 'auto', sort: true,},
            { field: 'imageURL', header: this._translateService.instant('TABLEDATA.ImageURL'), visible: false, width: 'auto', sort: true,},
            { field: 'startPageId', header: this._translateService.instant('TABLEDATA.StartPageId'), visible: false, width: 'auto', sort: true,},

            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];  

        this.arrPositionId = [
            { label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 },
            { label: 'Chuyên viên', value: 1 },
            { label: 'Lãnh đạo', value: 2 },
        ];  

        this.getsDepartment();
        this.getsUsers();
        this.getData();


    }
    async getsUsers() { 

        var model = {
            keyword: "",
            typeId: 0,
            status: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrUserId = [];
        this.arrUserId = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._userService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrUserId, rs.data, 'userName');
            }
        })
    }
    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status, 
            userId : this.userId,
            positionId : this.positionId,
            departmentId : this.departmentId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._StaffApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

    async getsDepartment() {
        var model = {
            keyword: "",
            status: 1, 
            userId : 0,
            positionId : 0,
            departmentId : 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrDepartmentId = [];
        this.arrDepartmentId = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._departmentAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrDepartmentId, rs.data, 'name');
            }
        })
    }

}