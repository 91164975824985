<div class="card p-0">
    <p-tabView>
        <p-tabPanel header="Hợp đồng" leftIcon="pi pi-book">
            <p-table [columns]="cols" [value]="dataSource" [paginator]="true" responsiveLayout="scroll"
                styleClass=" p-datatable-customers p-datatable-striped p-datatable-sm" [rows]="pageSize"
                [totalRecords]="totalRecord" [customSort]="true" (onSort)="onSort($event)" (onPage)="onPage($event)"
                [loading]="isLoading" [lazy]="true" [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between table-header pt-1">
                        <div>
                            <button pButton pRipple type="button" icon="pi pi-plus" label="{{'BUTTON.ADD' | translate}}"
                                class="p-button-primary mr-1" (click)="add()"></button>
                            <button pButton pRipple type="button" icon="ci ci-delete"
                                [disabled]="selectedItems && !selectedItems.length" class="p-button-danger"
                                (click)="deletelstMany()" pTooltip="{{'BUTTON.DELETE' | translate }}"></button>
                        </div>
                        <div class="formgrid grid">
                            <div (clickOutside)="closeTableFilter()" [class.-show-clear]="query"
                                class="ag-search-box -size-small -has-search-adv ag-custom-overlay-panel-wrapper">
                                <input class="ag-search-box__input" type="text" (keyup.enter)="onSearch()"
                                    placeholder="{{'LIST.SEARCH_PLACEHODE' | translate}}" [(ngModel)]="query" />
                                <span class="ci ci-search ag-search-box__search-icon"></span>
                                <span tabindex="0" class="ci ci-close ag-search-box__clear-icon"
                                    (click)="query = ''"></span>
                                <span tabindex="0" (click)="openSearchAdv = !openSearchAdv"
                                    class="ag-search-box__adv-toggle ci ci-chevron-down-1"></span>

                                <div [hidden]="!openSearchAdv"
                                    class="ag-custom-overlay-panel -bottom-left ag-table-filter-dropdown p-3"
                                    style="min-width: 320px;">
                                    <div class="formgrid grid">
                                        <div class="field col-12 md:col-12 p-fluid">
                                            <div class="p-float-label">
                                                <p-dropdown id="search_Status" [filter]="true" [options]="arrStatus"
                                                    [(ngModel)]="status"></p-dropdown>
                                                <label for="search_Status">{{ 'status' | translate }}</label>
                                            </div>
                                        </div>
                                        <div class="col-6 md:col-6 text-left">
                                            <button pButton pRipple type="button"
                                                label="{{'BUTTON.SEARCH_CANCEL' | translate}}" icon="pi pi-trash"
                                                class="p-button-danger mr-1" (click)="onCancelSearch()"></button>
                                        </div>
                                        <div class="col-6 md:col-6 text-right">
                                            <button pButton pRipple type="button"
                                                label="{{'BUTTON.SEARCH' | translate}}" icon="pi pi-search"
                                                class="p-button-info" (click)="onSearch()"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button pButton pRipple type="button" icon="ci ci-data-1"
                                (click)="visibleCols.toggle($event)" class="p-button-rounded p-button-text"></button>
                            <p-overlayPanel #visibleCols [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'0ms'"
                                appendTo="body" styleClass="ag-overlay-menu style-2">
                                <div class="ag-overlay-menu__title">
                                    <span (click)="resetColsVisibility()" class="ag-overlay-menu__clear-btn">
                                        {{getVisibleColsNumber()}}
                                        <span class="ag-overlay-menu__clear-icon ci ci-close"></span>
                                    </span>

                                    <span class="__text">
                                        {{'LIST.COLS_SELECTED' | translate}}
                                    </span>
                                </div>

                                <div *ngFor="let col of cols" [class.-activated]="col.visible"
                                    class="ag-overlay-menu__item-wrapper">
                                    <div class="ag-overlay-menu__item">
                                        <p-checkbox [(ngModel)]="col.visible" [label]="col.header" binary="true">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </p-overlayPanel>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-columns pTemplate="header">
                    <tr>
                        <th style="width: 3rem" class="text-center">
                            <p-triStateCheckbox (onChange)="onChangeBulkSelection()" [(ngModel)]="isCheckAll">
                            </p-triStateCheckbox>
                        </th>
                        <ng-container *ngFor="let col of columns">
                            <th *ngIf="col.sort" [hidden]="!col.visible" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th *ngIf="!col.sort" [hidden]="!col.visible">
                                {{col.header}}
                            </th>
                        </ng-container>
                        <th style="width: 8rem" class="text-center"></th>
                    </tr>
                </ng-template>
                <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
                    <tr [class.ag-tr-status-danger]="rowData.status === 3"
                        [class.ag-tr-status-success]="rowData.status === 1"
                        [class.ag-tr-status-warning]="rowData.status === 2" [class.ag-tr-status]="rowData.status">
                        <td class="text-center">
                            <p-checkbox (onChange)="checkSelectionState()" [(ngModel)]="selectedItems" [value]="rowData"
                                name="group">
                            </p-checkbox>
                        </td>
                        <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field">
                            <span *ngSwitchCase="'dateFrom'">
                                {{rowData.dateFrom | date:'dd/MM/yyyy'}}
                            </span>
                            <span *ngSwitchCase="'dateTo'">
                                {{rowData.dateTo | date:'dd/MM/yyyy'}}
                            </span>
                            <span *ngSwitchCase="'creditLimit'">
                                {{rowData.creditLimit | number: '1.2-2' }}
                            </span>
                            <span *ngSwitchDefault>
                                {{rowData.invoiceDate | date:'dd/MM/yy'}}
                            </span>
                            <span *ngSwitchCase="'modified'">
                                {{rowData.modified | date:'dd/MM/yyyy HH:mm'}}
                            </span>
                            <span *ngSwitchCase="'modifiedBy'">
                                <div [ngSwitch]="rowData.modifiedBy">
                                    <div *ngSwitchCase="1">
                                        root
                                    </div>
                                </div>
                            </span>

                            <span *ngSwitchCase="'status'">
                                <div [ngSwitch]="rowData.status">
                                    <div *ngSwitchCase="'1'">
                                        <i _ngcontent-kmb-c131=""
                                            class="pi true-icon pi-check-circle text-green-500"></i>
                                    </div>
                                    <div *ngSwitchCase="'2'">
                                        <i _ngcontent-kmb-c131=""
                                            class="pi false-icon pi-times-circle text-pink-500"></i>
                                    </div>
                                </div>
                            </span>
                            <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                        </td>
                        <td class="text-center">
                            <button pButton pRipple icon="pi pi-pencil"
                                class="p-button-sm p-button-rounded p-button-primary mr-1"
                                (click)="edit(rowData.id)"></button>
                            <button pButton pRipple icon="pi pi-trash"
                                class="p-button-sm p-button-rounded p-button-danger"
                                (click)="delete(rowData.id)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Loại hợp đồng" leftIcon="pi pi-book" *ngIf="!contractOnly">
            <app-contractType-index></app-contractType-index>
        </p-tabPanel>
        <p-tabPanel header="Hình thức thanh toán" leftIcon="pi pi-book" *ngIf="!contractOnly">
            <app-debtType-index></app-debtType-index>
        </p-tabPanel>
        <p-tabPanel header="Kênh bán hàng" leftIcon="pi pi-book" *ngIf="!contractOnly">
            <app-salesChanel-index></app-salesChanel-index>
        </p-tabPanel>
    </p-tabView>
</div>