<form #formElement [formGroup]="formGroup">
    <div class="col-12 md:col-12">
        <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
        </validation-summary>
    </div>
    <div class="card p-0">
        <p-tabView>
            <p-tabPanel header="Thông tin chung">
                <div class="p-fluid formgrid grid">
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Số hợp đồng' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <input type="text" id="code" pInputText [(ngModel)]="itemDetail.contractCode"
                                formControlName="contractCode" placeholder="Định dạng text">
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Loại hợp đồng' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="contractTypeId" [options]="contractTypeList"
                                [(ngModel)]="itemDetail.contractTypeId" [virtualScroll]="true"
                                [virtualScrollItemSize]="30" autoDisplayFirst="false" optionLabel="typeName"
                                optionValue="id" [filter]="true" filterBy="typeName,shortName">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Tên hợp đồng' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-10">
                        <div class="field">
                            <input type="text" id="code" pInputText [(ngModel)]="itemDetail.contractName"
                                formControlName="contractName" placeholder="Định dạng text">
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Hiệu lực từ ngày' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <p-calendar appendTo="body" formControlName="dateFrom" dateFormat="dd/mm/yy"
                                [(ngModel)]="itemDetail.dateFrom" showIcon="true">
                            </p-calendar>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Đến ngày' | translate}}
                                <span style="color:red;" *ngIf="!isImport">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <p-calendar appendTo="body" formControlName="dateTo" dateFormat="dd/mm/yy"
                                [(ngModel)]="itemDetail.dateTo" showIcon="true">
                            </p-calendar>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Khách hàng' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-10">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="customerId" [options]="customerList"
                                [(ngModel)]="itemDetail.customerId" [virtualScroll]="true" [virtualScrollItemSize]="30"
                                autoDisplayFirst="false" optionLabel="displayName" optionValue="id" [filter]="true"
                                filterBy="displayName">
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Người liên hệ' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-10">
                        <div class="field">
                            <input type="text" id="contactPerson" pInputText [(ngModel)]="itemDetail.contactPerson"
                                formControlName="contactPerson" placeholder="Định dạng text">
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Ghi chú' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-10">
                        <div class="field">
                            <input type="text" id="description" pInputText [(ngModel)]="itemDetail.description"
                                formControlName="description" placeholder="Định dạng text">
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-10">
                        <div class="field">
                            <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                                [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </p-tabPanel>

            <p-tabPanel header="Công nợ">
                <div class="p-fluid formgrid grid">
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Hình thức T.Toán' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="debtType" [options]="debtTypes"
                                [(ngModel)]="itemDetail.debtTypeId" [virtualScroll]="true" [virtualScrollItemSize]="30"
                                autoDisplayFirst="false" optionLabel="name" optionValue="id" [filter]="true"
                                filterBy="name">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Sản lượng đăng ký' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <input type="number" id="qtyMonthAvg" pInputText [(ngModel)]="itemDetail.qtyMonthAvg"
                                formControlName="qtyMonthAvg" class="text-right" min="0">
                        </div>
                    </div>
                    <div class="col-12 md:col-2" *ngIf="!isImport" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Hạn mức nợ' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4" *ngIf="!isImport">
                        <div class="field">
                            <input type="number" id="creditLimit" pInputText [(ngModel)]="itemDetail.creditLimit"
                                formControlName="creditLimit" class="text-right">
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Số ngày nợ' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <input type="number" id="creditPeriod" pInputText [(ngModel)]="itemDetail.creditPeriod"
                                formControlName="creditPeriod" class="text-right">
                        </div>
                    </div>
                    <div class="col-12 md:col-2" *ngIf="!isImport" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Hạn mức bổ sung' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4" *ngIf="!isImport">
                        <div class="field">
                            <input type="number" id="creditLimitExtend" pInputText
                                [(ngModel)]="itemDetail.creditLimitExtend" formControlName="creditLimitExtend"
                                class="text-right">
                        </div>
                    </div>

                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Hạn mức bảo lãnh' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <input type="number" id="creditLimitPos" pInputText [(ngModel)]="itemDetail.creditLimitPos"
                                formControlName="creditLimitPos" class="text-right">
                        </div>
                    </div>
                    <!--div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Xuất qua trụ' | translate}}</div>
                        </div>
                    </!--div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="isQuaTru" [options]="binaryOptions"
                                [(ngModel)]="itemDetail.isQuaTru" autoDisplayFirst="false" optionValue="value">
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Xuất chuyển thẳng' | translate}}</div>
                        </div>
                    </div>
                    <div-- class="col-12 md:col-4">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="isChuyenThang" [options]="binaryOptions"
                                [(ngModel)]="itemDetail.isChuyenThang" autoDisplayFirst="false" optionValue="value">
                            </p-dropdown>
                        </div>
                    </div-->
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Hình thức công nợ' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="paymentDebt" [options]="paymentDebt"
                                [(ngModel)]="itemDetail.paymentDebtId" [virtualScroll]="true"
                                [virtualScrollItemSize]="30" autoDisplayFirst="false" optionLabel="name"
                                optionValue="id" [filter]="true" filterBy="name">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" *ngIf="!isImport" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Cách lấy ngày' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4" *ngIf="!isImport">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="isQuanLyHanMuc" [options]="quanLyHanMuc"
                                [(ngModel)]="itemDetail.isQuanLyHanMuc" autoDisplayFirst="false" optionValue="value">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" *ngIf="!isImport" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                        <div class="field">
                            <div class="label mt-1">{{'Kênh bán hàng' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4" *ngIf="!isImport">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="salesChannelId" [options]="salesChannelList"
                                [(ngModel)]="itemDetail.salesChannelId" autoDisplayFirst="false" optionValue="id"
                                optionLabel="name">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;" *ngIf="isImport">
                        <div class="field">
                            <div class="label mt-1">{{'Hình thức giao nhận' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4" *ngIf="isImport">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="deliveryTypeId" [options]="deliveryTypeList"
                                [(ngModel)]="itemDetail.deliveryTypeId" [virtualScroll]="true"
                                [virtualScrollItemSize]="30" autoDisplayFirst="false" optionLabel="deliveryTypeName"
                                optionValue="id" [filter]="true" filterBy="name">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;" *ngIf="isImport">
                        <div class="field">
                            <div class="label mt-1">{{'Loại giá' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4" *ngIf="isImport">
                        <div class="field">
                            <p-dropdown appendTo="body" formControlName="costPriceTypeId" [options]="costPriceTypes"
                                [(ngModel)]="itemDetail.costPriceTypeId" [virtualScroll]="true"
                                [virtualScrollItemSize]="30" optionValue="id" [filter]="true" filterBy="name">
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;" *ngIf="isImport">
                        <div class="field">
                            <div class="label mt-1">{{'BL = 1' | translate}}</div>
                        </div>
                    </div>

                    <div class="col-12 md:col-4" *ngIf="isImport">
                        <div class="field">
                            <p-checkbox [(ngModel)]="itemDetail.includeBillDate" formControlName="includeBillDate"
                                [binary]="true" [trueValue]="1" [falseValue]="0" class="mt-25">
                            </p-checkbox>
                        </div>
                    </div>


                    <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;" *ngIf="isImport">
                        <div class="field">
                            <div class="label mt-1">{{'Giá trị LC' | translate}}</div>
                        </div>
                    </div>

                    <div class="col-12 md:col-4" *ngIf="isImport">
                        <div class="field">
                            <p-inputNumber id="LC" [(ngModel)]="itemDetail.LC" formControlName="LC"
                                class="text-right"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-0 md:col-6"></div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Đính kèm">
                <app-upload [objectId]="itemDetail?.id" objectName="Contract" [view]="false"></app-upload>
            </p-tabPanel>
            <div class="p-formgrid grid flex flex-row pt-2 justify-content-between">
                <div class="col-12 md:col-8 flex flex-row gap-2">
                    <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
                        *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2"
                        (click)="saveAndClose()"></button>
                    <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
                        *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2"
                        (click)="saveAndClose()"></button>
                    <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
                        [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
                </div>
                <div class="p-2">
                    <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}"
                        icon="pi pi-copy" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                        class="p-button mr-0 float-right" (click)="saveAsCopy()"></button>
                </div>
            </div>
        </p-tabView>
    </div>
</form>