import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ExchangeRateApiService } from '../../Services/ExchangeRate-api.service';
import {BankApiService} from "../../Services/Bank-api.service";
import {CurrencyApiService} from "../../Services/Currency-api.service";

@Component({
    selector: 'app-ExchangeRate-form',
    templateUrl: './ExchangeRate-form.component.html',
    styleUrls: ['./ExchangeRate-form.component.css']
})
export class ExchangeRateFormComponent extends SecondPageEditBase implements OnInit {

    bankList = []
    currencyList = []

    constructor(
        protected ExchangeRateApiService: ExchangeRateApiService,
        protected BankApiService: BankApiService,
        protected CurrencyApiService: CurrencyApiService,
        protected _injector: Injector,
    ) {
        super(ExchangeRateApiService, _injector);

        this.formGroup = new FormGroup({
            bankId: new FormControl('', [Validators.required]),
            currencyId: new FormControl('', [Validators.required]),
            validFrom: new FormControl(new Date(), [Validators.required]),
            sellingRate: new FormControl('', [Validators.required, Validators.min(0)]),
            averageRate: new FormControl('', [Validators.required, Validators.min(0)]),
            buyingRate: new FormControl('', [Validators.required, Validators.min(0)]),
            status: new FormControl(1),
        });

    }

    ngOnInit() {
        this.BankApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.bankList = rs.data;
                }
            })
        this.CurrencyApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.convertDataToOptions(this.currencyList, rs.data, "code,name", "code");
                    this.currencyList = rs.data;
                }
            })
    }

    updatePrice() {
        this.itemDetail.averageRate = Math.floor(
            ((this.itemDetail.sellingRate ?? 0) + (this.itemDetail.buyingRate ?? 0)) / 2
        );
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.ExchangeRateApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.itemDetail.validFrom = new Date(rs.data.validFrom);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1, validFrom: new Date() };
            this.submitting = false;
        }
        console.log(this.itemDetail);
    }
}