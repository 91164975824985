import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { TaxPolicyApiService } from '../../Services/TaxPolicy-api.service';
import { ProductApiService } from '../../Services/Product-api.service';
@Component({
    selector: 'app-taxPolicy-form',
    templateUrl: './taxPolicy-form.component.html',
    styleUrls: ['./taxPolicy-form.component.css']
})
export class TaxPolicyFormComponent extends SecondPageEditBase implements OnInit {
    arrProduct:any = [];
    constructor(
        protected _productApiService: ProductApiService,
        protected _taxPolicyApiService: TaxPolicyApiService,
        protected _injector: Injector,
    ) {
        super(_taxPolicyApiService, _injector);

        this.formGroup = new FormGroup({
            validFrom: new FormControl('', Validators.required),
            productId: new FormControl('', Validators.required),
            importTax: new FormControl(''),
            specialTax: new FormControl(''),
            importVAT: new FormControl(''),
            vat: new FormControl(''),
            pxd: new FormControl(''),
            status: new FormControl(''),
        });
    }

    ngOnInit() {
        this._productApiService.getsInUse().then((rs) => {
            if(rs.success) {
                this.convertDataToOptions(this.arrProduct, rs.data, "productCode,productName", "productCode");
            }else{
                this._notifierService.showWarning(rs.message);
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._taxPolicyApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.validFrom) {
                        this.itemDetail.validFrom = new Date(this.itemDetail.validFrom);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, validFrom:new Date(), status: 1 };
            this.submitting = false;
        }
    }
}

