<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Bể chứa' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '640px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="col-12 md:col-12 p-fluid formgrid grid">
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Mã bể' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="tankCode" pInputText [(ngModel)]="itemDetail.tankCode"
                            formControlName="tankCode" placeholder="4 ký tự (VD: TK01)">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Tên bể' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="tankName" pInputText [(ngModel)]="itemDetail.tankName"
                            formControlName="tankName" placeholder="Định dạng text, tối đa 30 ký tự">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Hàng hóa' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <p-dropdown formControlName="productId" [options]="arrProduct" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.productId"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Đơn vị' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <p-dropdown formControlName="idBranch" [options]="arrBranch" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idBranch"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Loại bể' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <p-dropdown formControlName="tankType" [options]="arrTankType" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.tankType"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Ghi chú' | translate}}</div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <textarea pInputTextarea [(ngModel)]="itemDetail.remarks" formControlName="remarks"
                            placeholder="Ghi chú" [autoResize]="true"></textarea>
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Áp dụng TĐH' | translate}}</div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="field">
                        <p-dropdown formControlName="isAuto" [options]="arrTHD" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isAuto"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                </div>

                <div class="col-12 md:col-12" style="font-weight: 450;">
                    <br>
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">
                            Các thông tin khác
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Nhà cung cấp TĐH' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-dropdown formControlName="iAManufactureId" [options]="arrManufacture"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.iAManufactureId" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Mã thiết bị TĐH' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.iADeviceId" formControlName="iADeviceId"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Mã TĐH bể' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.iATankId" formControlName="iATankId"
                            placeholder="Định dạng text">
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Chiều cao tối thiểu (mm)' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="minHeight" [(ngModel)]="itemDetail.minHeight" mode="decimal"
                            [minFractionDigits]="2" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Dung tích tối thiểu (lít)' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="minVolume" [(ngModel)]="itemDetail.minVolume" mode="decimal"
                            [minFractionDigits]="2" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Chiều cao tối đa (mm)' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="maxHeight" [(ngModel)]="itemDetail.maxHeight" mode="decimal"
                            [minFractionDigits]="2" placeholder="Định dạng số ">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Dung tích tối đa (lít)' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="maxVolume" [(ngModel)]="itemDetail.maxVolume" mode="decimal"
                            [minFractionDigits]="2" placeholder="Định dạng số ">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Cảnh báo mức hàng thấp (%)' | translate}}</div>
                </div>
                <div class="col-12 md:col-7" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="field">
                        <p-inputNumber formControlName="minAlarmPercent" [(ngModel)]="itemDetail.minAlarmPercent"
                            mode="decimal" [minFractionDigits]="2" [min]="0" [max]="100" placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Cảnh báo hết hàng (%)' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="lowAlarmPercent" [(ngModel)]="itemDetail.lowAlarmPercent"
                            mode="decimal" [minFractionDigits]="2" [min]="0" [max]="100" placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Thể tích' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="vTotal" [(ngModel)]="itemDetail.vTotal" mode="decimal"
                            [minFractionDigits]="2" [min]="0" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Thể tích ống' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="vExtra" [(ngModel)]="itemDetail.vExtra" mode="decimal"
                            [minFractionDigits]="2" [min]="0" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Trạng thái ống' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-dropdown formControlName="extraStatus" [options]="arrextraStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.extraStatus"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Cảnh báo mức hàng cao' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="maxAlarmPercent" [(ngModel)]="itemDetail.maxAlarmPercent"
                            mode="decimal" [minFractionDigits]="2" [min]="0" [max]="100" placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Cảnh báo tràn bể (%)' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="highAlarmPercent" [(ngModel)]="itemDetail.highAlarmPercent"
                            mode="decimal" [minFractionDigits]="2" [min]="0" [max]="100" placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Barem' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-inputNumber formControlName="fullFactor" [(ngModel)]="itemDetail.fullFactor" mode="decimal"
                            [minFractionDigits]="2" [min]="0" placeholder="Định dạng số ">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-5" style="font-weight: 450;margin: 5px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-12 md:col-7">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-trash"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>