<div class="grid col-12">
    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2 h-full">
            <div class="flex align-items-center muted-text pb-2 card-header">
                <h6 class="m-0 pl-2">
                    <i class="pi pi-book mr-1"></i>
                    <span class="text-lg">PXK</span>&nbsp;<span class="text-sm font-normal">(Đã xuất/Tổng)</span>
                </h6>
                <p-calendar [(ngModel)]="datesDashboard1" selectionMode="range" [readonlyInput]="true"
                    (ngModelChange)="onDashboard1Change()" dateFormat="yy/mm/dd"></p-calendar>
            </div>
            <div class="mt-3 flex flex-column justify-content-between woke h-full">
                <ng-container *ngFor="let item of dashboard1">
                    <div
                        class="flex flex-row align-items-center justify-content-between p-3 P{{ item.productCode }}_bgColor P{{ item.productCode }}_fgColor h-full">
                        <div>{{ item.productName }}</div>
                        <div class="value type-green text-lg">
                            <b>{{ item.deliveredOrder }}/{{ item.totalOrder }}</b>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-9">
        <div class="card overview-box flex flex-column pt-2 h-full">
            <div class="flex align-items-center muted-text pb-2 card-header">
                <h6 class="m-0 pl-2">
                    <i class="pi pi-database mr-1"></i>
                    <span class="text-lg">Tồn kho</span>
                </h6>
                <p-calendar [(ngModel)]="datesDashboard2" selectionMode="range" [readonlyInput]="true"
                    (ngModelChange)="onDashboard2Change()" dateFormat="yy/mm/dd"></p-calendar>
            </div>
            <div class="flex flex-row">
                <div *ngFor="let item of optionsInventory" class="w-full">
                    <div echarts [options]="item"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="grid col-12" [style.height]="'60vh'">
    <div class="col-12 lg:col-6">
        <div class="card height-100">
            <div class="card-header flex flex-row justify-content-between align-items-center muted-text pb-2">
                <h5 class="m-0 pl-2">
                    <i class="pi pi-truck mr-1"></i>
                    <span class="text-lg">Phương tiện vận chuyển</span>
                </h5>
                <div>Tổng số: {{ vehicles.length }}</div>
            </div>
            <p-table
                [value]="vehicles.slice(currentPage * productCountPerPage, (currentPage + 1) * productCountPerPage)"
                responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <!--                        <th class="w-7rem text-center">Số xe</th>-->
                        <!--                        <th class="w-7rem text-center">BKS</th>-->
                        <th class="w-15rem text-center">Hàng hóa</th>
                        <th class="w-15rem text-center">Số phiếu xuất</th>
                        <th class="w-15rem text-center">Ngày phiếu xuất</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <!--                        <td class="w-10rem text-center">{{product.transportDriver}}</td>-->
                        <!--                        <td class="text-center">{{product.transportReg}}</td>-->
                        <td class="flex flex-row justify-content-center">
                            <app-tank-truck [reg]="product.transportReg" [data]="product"></app-tank-truck>
                        </td>
                        <td class="text-center">{{product.subType}} / {{ product.invoiceNumber }}</td>
                        <td class="text-center">{{ getDateString(product.trDate) }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="productCountPerPage" [pageLinkSize]="3" [totalRecords]="vehicles.length"
                (onPageChange)="setCurrentPage($event)"></p-paginator>
        </div>
    </div>
    <div class="col-12 lg:col-6">
        <div class="card height-100">
            <div class="card-header flex flex-row justify-content-between align-items-center muted-text pb-2">
                <h5 class="m-0 pl-2">
                    <i class="pi pi-chart-line mr-1"></i>
                    <span class="text-lg">Lượng xuất trong tháng</span>
                </h5>
                <p-calendar [(ngModel)]="datesDashboard4" selectionMode="range" [readonlyInput]="true"
                    (ngModelChange)="onDashboard4Change()" dateFormat="yy/mm/dd"></p-calendar>
            </div>
            <div echarts [options]="optionsOut"></div>
        </div>
    </div>
</div>