<p-dialog #dialog header="{{ 'MD.Discount' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Số văn bản' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input pInputText class="p-inputtext-sm" formControlName="documentCode"
                        [(ngModel)]="itemDetail.documentCode">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Ngày hiệu lực' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-calendar formControlName="validFrom" showTime="true" [(ngModel)]="itemDetail.validFrom"
                        appendTo="body" icon="ci ci-calendar" showIcon="true" hourFormat="24">
                    </p-calendar>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Khách hàng' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10 field">
                    <p-dropdown formControlName="customerId" [options]="customerList" [filter]="true"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.customerId">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Kho hàng' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="warehouseId" [options]="warehouseList" [filter]="true"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.warehouseId">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Điểm đến' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="destinationId" [options]="locationList" [filter]="true"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.destinationId">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Hàng hóa' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="productId" [options]="productList" [filter]="true"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.productId">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Mức chiết khấu' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <div class="p-inputgroup">
                        <p-inputNumber [(ngModel)]="itemDetail.discount" formControlName="discount"
                            inputStyleClass="text-right" (ngModelChange)="itemDetail.discount = $event"></p-inputNumber>
                        <span class="p-inputgroup-addon">Đồng/Lít</span>
                    </div>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-1 font-medium">{{'Nhóm chiết khấu' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="discountGroupId" [options]="discountGroupList"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.discountGroupId"
                        [filter]="true">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label mt-25 font-medium">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="status" [options]="arrStatus" dropdownIcon="ci ci-chevron-down-1"
                        appendTo="body" [(ngModel)]="itemDetail.status" [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>