import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { TrTypeFormComponent } from '../form/TrType-form.component';
import { TrTypeApiService } from '../../Services/TrType-api.service';
import { trDirections } from '../components/shared';

@Component({
    selector: 'app-trType-index',
    templateUrl: './TrType-index.component.html',
    styleUrls: ['./TrType-index.component.css']
})
export class TrTypeIndexComponent extends SecondPageIndexBase implements OnInit {
    tabMenuItems = [];
    trDirections = trDirections;
    trDirection: number;
    constructor(
        protected _TrTypeApiService: TrTypeApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_TrTypeApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant('Phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrType' },
            { label: this._translateService.instant('Nhóm phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrGroup' },
            { label: this._translateService.instant('Lọc đối tượng'), icon: 'pi pi-search', routerLink: '../TrType2CustGroup' },
            { label: this._translateService.instant('Lọc báo cáo'), icon: 'pi pi-search', routerLink: '../TrType2Report' },

        ];
        this.cols = [
            { field: 'trCode', header: this._translateService.instant('Mã N/X'), visible: true, width: 'auto', sort: true, },
            { field: 'trName', header: this._translateService.instant('Tên N/X'), visible: true, width: 'auto', sort: true, },
            { field: 'trDirection', header: this._translateService.instant('TR Direction'), visible: true, width: 'auto', sort: true, },
            { field: 'idTrGroup', header: this._translateService.instant('Nhóm'), visible: true, width: 'auto', sort: true, },
            { field: 'parentReq', header: this._translateService.instant('Tr Cha'), visible: true, width: 'auto', sort: true, },
            { field: 'parentType', header: this._translateService.instant('Loại Tr Cha'), visible: true, width: 'auto', sort: true, },
            { field: 'shortName', header: this._translateService.instant('Tên ngắn'), visible: true, width: 'auto', sort: true, },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this._router.navigateByUrl(`MD/TrTypeForm/${id}`);
    }

    add() {
        this._router.navigateByUrl("MD/TrTypeForm/");
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            trDirection: this.trDirection,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this._TrTypeApiService.getsBySearch(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}

