<div class="table-wrapper">
    <p-table [columns]="cols" [value]="dataSource"
        class="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines"
        [rows]="pageSize" [totalRecords]="totalRecord" [customSort]="true" (onSort)="onSort($event)"
        (onPage)="onPage($event)" [loading]="isLoading" [lazy]="true">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width: 50px">
                <ng-container *ngFor="let col of cols">
                    <col [hidden]="!col.visible" [style.width]="col.width">
                </ng-container>
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <ng-container *ngFor="let col of cols">
                    <th *ngIf="col.sort" [hidden]="(!col.visible)" [pSortableColumn]="col.field" class="text-center">
                        <div class="h-flex-el -h-space-between">
                            <div class="h-overflow-break-word">{{col.header}}</div>
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </div>
                    </th>

                    <th *ngIf="!col.sort" [hidden]="(!col.visible)" class="text-center">
                        <div class="h-overflow-break-word">{{col.header}}</div>
                    </th>
                </ng-container>
            </tr>
        </ng-template>

        <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
            <tr [class.ag-tr-status-danger]="rowData.status === 3" [class.ag-tr-status-success]="rowData.status === 1"
                [class.ag-tr-status-warning]="rowData.status === 2" [class.ag-tr-status]="rowData.status">
                <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field"
                    [style.text-align]="col.align">
                    <span *ngSwitchCase="'modified'">
                        {{rowData.modified | date:'dd/MM/yyyy HH:mm'}}
                    </span>
                    <span *ngSwitchCase="'NozzleState'">
                        <span *ngIf="rowData.NozzleState == 1">Gác cò</span>
                        <span *ngIf="rowData.NozzleState == 2">Nhấc cò</span>
                        <span *ngIf="rowData.NozzleState == 3">Đang bơm</span>
                        <span *ngIf="rowData.NozzleState == 4">Bơm xong</span>
                        <span *ngIf="rowData.NozzleState == 5">Khóa vòi</span>
                        <span *ngIf="rowData.NozzleState == 9">Lỗi</span>
                    </span>
                    <span *ngSwitchCase="'PresetType'">
                        <span *ngIf="rowData.PresetType == 0">Theo lượng</span>
                        <span *ngIf="rowData.PresetType == 1">Theo tiền</span>
                    </span>
                    <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>