<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Đơn vị' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '880px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã đơn vị' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="customerCode" pInputText [(ngModel)]="itemDetail.customerCode"
                            formControlName="customerCode" placeholder="Định dạng text, tối đa 7 kí tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên ngắn' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.shortName" formControlName="shortName">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã số thuế' | translate}}
<!--                        <span style="color:red;">*</span>-->
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.taxNumber" formControlName="taxNumber"
                            placeholder="Mã số thuế">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Ngày cấp' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-calendar [showIcon]="true" [(ngModel)]="itemDetail.startDate" formControlName="startDate"
                            appendTo="body" icon="ci ci-calendar" dateFormat="dd/mm/yy" placeholder="dd/mm/yyy"
                            showTime="true" hourFormat="24">
                        </p-calendar>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên đơn vị' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.customerName"
                            formControlName="customerName" placeholder="Định dạng text, tối đa 100 kí tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Địa chỉ' | translate}}</div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <textarea pInputTextarea [(ngModel)]="itemDetail.address" formControlName="address"
                            placeholder="Địa chỉ"></textarea>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Nhóm' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="idCustomerGroup" [options]="arrCustomerGroup"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.idCustomerGroup" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Loại hình DN' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="idBussinessType" [options]="arrBussinessType"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.idBussinessType" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Email' | translate}}</div>
                </div>
                <div class="col-8 md:col-3">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.email" formControlName="email"
                            placeholder="địa chỉ email của bạn example@gmail.com, @domail.com.vn ">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Số điện thoại' | translate}}</div>
                </div>
                <div class="col-8 md:col-2">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.tel" formControlName="tel"
                            placeholder="Số điện thoại">
                    </div>
                </div>
                <div class="col-2 md:col-1" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Số fax' | translate}}</div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.fax" formControlName="fax"
                            placeholder="Số fax">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trực thuộc' | translate}}</div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="idParent" [options]="arridParent" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idParent"
                            optionValue="id" optionLabel="customerName" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id != 0" [disabled]="submitting" class="p-button p-button mr-2 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>
