<p-dialog #dialog header="Cập nhật thông tin/{{ 'Giao dịch tài chính' | translate }}" [(visible)]="isShow"
    [modal]="true" [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'FORM.CODE' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="finCode" pInputText [(ngModel)]="itemDetail.finCode"
                            formControlName="finCode">
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Nhóm' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="finGroupId" [options]="finGroupList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.finGroupId" optionLabel="groupName" optionValue="id"
                            [filter]="true" filterBy="groupCode,groupName">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'TABLEDATA.NAME' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" id="finName" pInputText [(ngModel)]="itemDetail.finName"
                            formControlName="finName">
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Tài khoản nguồn' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="srcAcct" [options]="accountList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.srcAcct" optionLabel="name" optionValue="code"
                            [filter]="true" filterBy="code,name">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Quỹ nguồn' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="srcFund" [options]="fundList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.srcFund" optionLabel="fundName"
                            optionValue="fundCode" [filter]="true" filterBy="fundCode,fundName">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'SRC Select' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="srcSelect" [options]="selectList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.srcSelect" optionLabel="label" optionValue="value"
                            [filter]="true" filterBy="value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'SRC Select Grp' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="srcAcctGrp" [options]="groupList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.srcAcctGrp" optionLabel="name" optionValue="code"
                            [filter]="true" filterBy="code,name">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Tài khoản đích' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="dstAcct" [options]="accountList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.dstAcct" optionLabel="name" optionValue="code"
                            [filter]="true" filterBy="code,name">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Quỹ đích' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="dstFund" [options]="fundList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.dstFund" optionLabel="fundName"
                            optionValue="fundCode" [filter]="true" filterBy="fundCode,fundName">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'DST Select' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="dstSelect" [options]="selectList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.dstSelect" optionLabel="label" optionValue="value"
                            [filter]="true" filterBy="value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'DST Select Grp' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="dstAcctGrp" [options]="groupList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.dstAcctGrp" optionLabel="name" optionValue="code"
                            [filter]="true" filterBy="code,name">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Target Select' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="targetSelect" [options]="targetSelect" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.targetSelect" optionLabel="label"
                            optionValue="value" [filter]="true" filterBy="label">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-1">{{'PayType (fund only)' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="targetSelect" [options]="paymentList" [autoDisplayFirst]="false"
                            appendTo="body" [(ngModel)]="itemDetail.paytype" optionLabel="name" optionValue="code"
                            [filter]="true" filterBy="name">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'FORM.TRTYPEREF' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="finCode" pInputText [(ngModel)]="itemDetail.trTypeRef"
                            formControlName="trTypeRef">
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'FORM.TRTYPESTATUS' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="finCode" pInputText [(ngModel)]="itemDetail.TrTypeStatus"
                            formControlName="TrTypeStatus">
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer style="">
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>