import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ShipPointApiService } from '../../Services/ShipPoint-api.service';
import {WarehouseApiService} from "../../Services/Warehouse-api.service";
import {LocationApiService} from "../../Services/Location-api.service";

@Component({
    selector: 'app-shipPoint-form',
    templateUrl: './ShipPoint-form.component.html',
    styleUrls: ['./ShipPoint-form.component.css']
})
export class ShipPointFormComponent extends SecondPageEditBase implements OnInit {
    types = [
        { label: 'Thủy', value: 1 },
        { label: 'Bộ', value: 0 },
    ]

    warehouseList = []
    locationList = []

    constructor(
        protected ShipPointApiService: ShipPointApiService,
        protected warehouseApiService: WarehouseApiService,
        protected locationApiService: LocationApiService,
        protected _injector: Injector,
    ) {
        super(ShipPointApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            srcPoint: new FormControl(undefined, [Validators.required, Validators.min(0)]),
            dstPoint: new FormControl(undefined, [Validators.required, Validators.min(0)]),
            distance: new FormControl('', [Validators.min(0)]),
            type: new FormControl(''),
            status: new FormControl
        });

    }

    ngOnInit() {
        this.warehouseApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.warehouseList, rs.data, "warehouseCode,warehouseName", "warehouseCode");
            }
        })
        this.locationApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.locationList, rs.data, "code,name", "code");
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.ShipPointApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1};
            this.submitting = false;
        }
    }
}

