<p-dialog #dialog header="{{ 'Hàng gửi' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '700px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Ngày hiệu lực' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-calendar [(ngModel)]="itemDetail.validFrom" formControlName="validFrom" dateFormat="dd/mm/yy"
                            appendTo="body">
                        </p-calendar>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Số hợp đồng' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <input pInputText [(ngModel)]="itemDetail.docNumber" formControlName="docNumber">
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Khách hàng' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-10 md:col-10">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.customerId" [options]="customerList"
                            formControlName="customerId" dropdownIcon="ci ci-chevron-down-1" appendTo="body">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Hàng hóa' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.productId" [options]="productList"
                            formControlName="productId" dropdownIcon="ci ci-chevron-down-1" appendTo="body">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Đơn vị tính' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.unitId" [options]="productUnits(itemDetail.productId) ?? []"
                            formControlName="unitId" dropdownIcon="ci ci-chevron-down-1" appendTo="body">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Phí tiếp nhận' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.importFee" formControlName="importFee"></p-inputNumber>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Phí bảo quản' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.maintenanceFee"
                            formControlName="maintenanceFee"></p-inputNumber>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Phí bơm rót bộ' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.roadPumpingFee"
                            formControlName="roadPumpingFee"></p-inputNumber>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;">
                    <div class="field">
                        <div class="label mt-1">{{'Phí bơm rót thủy' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.waterwayPumpingFee"
                            formControlName="waterwayPumpingFee"></p-inputNumber>
                    </div>
                </div>

                <div class="col-2 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Phí thuê kho' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.warehouseRentalFee"
                            formControlName="warehouseRentalFee"></p-inputNumber>
                    </div>
                </div>


                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>