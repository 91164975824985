<div class="p-fluid formgrid grid">
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Chọn kho đi' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.whsrc">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Chọn kho đến' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="whdstRequirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.whdst">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Phiếu nhập kho' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.whRefReq">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Ca làm việc' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.workshiftReq">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Nhập bể' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.tankReq">
            </p-dropdown>
        </div>
    </div>
    <!--div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Xuất cho nhóm hàng hóa' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown formControlName="toProdClass" [options]="requirements"
                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                [(ngModel)]="itemDetail.toProdClass">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Xuất cho hàng hóa' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div-- class="col-8 md:col-4">
        <div class="field">
            <p-dropdown formControlName="toProd" [options]="requirements" [autoDisplayFirst]="false"
                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.toProd">
            </p-dropdown>
        </div>
    </div-->
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Số hhóa/1 TR' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="number" id="numberOfProduct" min="0" pInputText [(ngModel)]="itemDetail.numberOfProduct">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'1 PR/nhiều lần' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.allowPRRepeat">
            </p-dropdown>
        </div>
    </div>
    <!--div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Product Qualification' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div-- class="col-8 md:col-4">
        <div class="field">
            <p-dropdown formControlName="productqualification" [options]="requirements"
                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                [(ngModel)]="itemDetail.productqualification">
            </p-dropdown>
        </div>
    </div-->
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Chiết khấu theo khu vực' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="selectVal" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.transportDSTReq">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label text-medium">{{'Vùng giá' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.enablePriceRegion">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Loại giá' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="priceCategoryList" optionLabel="categoryName" optionValue="id"
                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                [(ngModel)]="itemDetail.priceCategoryId">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Cho phép sửa giá' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="priceRequiredList" [autoDisplayFirst]="true" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.priceRequired">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Số thập phân làm tròn' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="number" id="rtd" min="0" pInputText [(ngModel)]="itemDetail.rtd">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'BasePrice RTD' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="number" id="priceCodeRTD" min="0" pInputText [(ngModel)]="itemDetail.priceCodeRTD">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Nhiệt độ / Tỷ trọng' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-2 md:col-4 field">
        <p-checkbox [(ngModel)]="itemDetail.useDensity" [binary]="true" inputId="useDensity" class="mt-25">
        </p-checkbox>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'LTT / L15' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>

    </div>
    <div class="col-2 md:col-4 field">
        <p-checkbox [(ngModel)]="itemDetail.useQty2" [binary]="true" inputId="useQty2" class="mt-25">
        </p-checkbox>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Kg' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-2 md:col-4 field">
        <p-checkbox [(ngModel)]="itemDetail.useKg" [binary]="true" inputId="useKg" class="mt-25">
        </p-checkbox>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Sửa L15 / Kg' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-2 md:col-4 field">
        <p-checkbox [(ngModel)]="itemDetail.allowEditQty2" [binary]="true" inputId="allowEditQty2" class="mt-25">
        </p-checkbox>
    </div>

    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Thông tin hải quan' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.customReq">
            </p-dropdown>
        </div>
    </div>

    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Thông tin giám định' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.assessmentReq">
            </p-dropdown>
        </div>
    </div>

    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Thông tin đơn vị bán' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                        appendTo="body" [(ngModel)]="itemDetail.sellerReq">
            </p-dropdown>
        </div>
    </div>

    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Ghi chú hàng hóa' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                        appendTo="body" [(ngModel)]="itemDetail.prDescReq">
            </p-dropdown>
        </div>
    </div>

    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Tách phiếu' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                        appendTo="body" [(ngModel)]="itemDetail.tachPhieuReq">
            </p-dropdown>
        </div>
    </div>
</div>
