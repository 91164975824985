import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { DebtTypeApiService } from '../../../Services/DebtType-api.service';

@Component({
    selector: 'app-debtType-form',
    templateUrl: './DebtType-form.component.html',
    styleUrls: ['./DebtType-form.component.css']
})
export class DebtTypeFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected DebtTypeApiService: DebtTypeApiService,
        protected _injector: Injector,
    ) {
        super(DebtTypeApiService, _injector);

        this.formGroup = new FormGroup({
            shortName: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            usecase: new FormControl('', [Validators.required]),
            status: new FormControl('', [Validators.required])
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.DebtTypeApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

