import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ReportApiService } from '../../Services/Report-api.service';
import { ReportGroupApiService } from '../../Services/ReportGroup-api.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
    selector: 'app-report-form',
    templateUrl: './Report-form.component.html',
    styleUrls: ['./Report-form.component.css']
})
export class ReportFormComponent extends SecondPageEditBase implements OnInit {
    arrReportType = [];
    arrReportGroup = [];
    arrSqlType = [];
    activeIndex = 0;
    isLoading = false;
    protected _router: Router;
    constructor(
        private _route: ActivatedRoute,
        protected _ReportApiService: ReportApiService,
        protected _ReportGroupApiService: ReportGroupApiService,
        protected _injector: Injector,
    ) {
        super(_ReportApiService, _injector);
        this._router = this._injector.get(Router);
        this.formGroup = new FormGroup({
            reportCode: new FormControl('', Validators.required),
            reportName: new FormControl('', Validators.required),
            shortName: new FormControl(''),
            reportGroup: new FormControl(''),
            reportType: new FormControl(''),
            templateURL: new FormControl(''),
            sql: new FormControl(''),
            sqlType: new FormControl(''),
            tableNames: new FormControl(''),
            isLogReport: new FormControl(''),
            isCache: new FormControl(''),
            status: new FormControl(''),
        });
    }

    ngOnInit() {
        this.arrReportType = [
            { label: "Báo cáo", value: 1 },
            { label: "Biểu in", value: 2 },
        ];
        this.arrSqlType = [
            { label: "Query", value: 1 },
            { label: "Stored Procedure", value: 2 },
        ]
        this.refreshRoute(this._route.snapshot.paramMap);
        this.getData();
    }
    refreshRoute(s: ParamMap) {
        this.itemDetail.id = +(s.get("Id") || 0);
        console.log(this.itemDetail.id);
    }
    getData() {
        this.submitting = true;
        this.isLoading = true;
        this._ReportGroupApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.arrReportGroup = rs.data.map(data => {
                    return {
                        label: data.groupName,
                        value: data.id,
                    }
                })
            }
        })
        if (this.itemDetail.id > 0) {
            this._ReportApiService.getDetail(this.itemDetail.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                    this.isLoading = false;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
            this.isLoading = false;
        }
        this.activeIndex = 0;
    }
    cancel(){
        this._router.navigateByUrl(`RPT/Report`);
    }
    viewReport(){
        //console.log(`RPT/ReportViewer/${this.itemDetail.reportCode}`);
        this._router.navigateByUrl(`RPT/ReportViewer/${this.itemDetail.reportCode}`);
    }
    // onShowPopup(id: number) {
    //     this.submitting = true;
    //     this.isLoading = true;
    //     this._ReportGroupApiService.getsInUse().then(rs => {
    //         if (rs.success) {
    //             this.arrReportGroup = rs.data.map(data => {
    //                 return {
    //                     label: data.groupName,
    //                     value: data.id,
    //                 }
    //             })
    //         }
    //     })
    //     if (id > 0) {
    //         this._ReportApiService.getDetail(id).then(rs => {
    //             this.submitting = false;
    //             if (rs.success) {
    //                 this.itemDetail = rs.data;
    //                 if (this.itemDetail.colDateTime) {
    //                     this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
    //                 }
    //                 this.isLoading = false;
    //             }
    //         }, error => {
    //             this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
    //             this.submitting = false;
    //         });
    //     } else {
    //         this.itemDetail = { id: 0, status: 1 };
    //         this.submitting = false;
    //         this.isLoading = false;
    //     }
    //     this.activeIndex = 0;
    // }
    saveAsCopy() {
        this.itemDetail.id = 0;
        this.save();
    }
}

