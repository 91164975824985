import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { PriceCategoryApiService } from '../../Services/PriceCategory-api.service';

@Component({
  selector: 'app-priceCategory-form',
  templateUrl: './priceCategory-form.component.html',
  styleUrls: ['./priceCategory-form.component.scss']
})
export class PriceCageoryFormComponent extends SecondPageEditBase implements OnInit {
  constructor(
    protected _priceCategoryApiService: PriceCategoryApiService,
    protected _injector: Injector,
  ) {
    super(_priceCategoryApiService, _injector);
    this.formGroup = new FormGroup({
      categoryCode: new FormControl('', [Validators.required]),
      categoryName: new FormControl('', [Validators.required]),
      shortName: new FormControl(''),
      useToDate: new FormControl(''),
      status: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }
  onShowPopup(id: number) {
    this.submitting = true;
    if (id > 0) {
      this._priceCategoryApiService.getDetail(id).then(rs => {
        this.submitting = false;
        if (rs.success) {
          this.itemDetail = rs.data;
          if (this.itemDetail.colDateTime) {
            this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
          }
          if (this.itemDetail.startDate) {
            this.itemDetail.startDate = new Date(this.itemDetail.startDate);
          }
        }
      }, error => {
        this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
        this.submitting = false;
      });
    } else {
      this.itemDetail = { id: 0, status: 1 };
      this.submitting = false;
    }
  }
}
