import { Component, Input, OnInit } from '@angular/core';
import { requirements, selectValues, trDirections } from "../../shared";
import { TrGroupApiService } from "../../../../Services/TrGroup-api.service";
import { ProductSourceApiService } from '../../../../Services/ProductSource-api.service';
import {TrVerifyStatus} from "../../../../../TR/shared/trVerifyStatus";
@Component({
    selector: 'app-trtype-form-general',
    templateUrl: './trtype-form-general.component.html',
    styleUrls: ['./trtype-form-general.component.scss']
})
export class TrtypeFormGeneralComponent implements OnInit {
    @Input() item: any;
    get itemDetail() { return this.item; }
    directions = [-1, 1]
    trDirections = trDirections;
    requirements = requirements;
    trGroupList = [];
    productSourceList = [...selectValues];
    // orderStatus = [{ label: 'N/A', value: null }, ...(TrVerifyStatus.filter(a => a.value > 0))];

    constructor(
        private trGroupApiService: TrGroupApiService,
        private productSourceApiService: ProductSourceApiService
    ) {
    }

    ngOnInit(): void {
        this.trGroupApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.trGroupList = rs.data;
                }
            })
        this.productSourceApiService.getsInUse().then(rs=>{
            if (rs.success) {
                rs.data.forEach(item => {
                    this.productSourceList.push(
                        {value: `${item.id}`, label:item.productSourceName}
                    );
                });
            }
        })
    }
}
