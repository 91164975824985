import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { PortApiService } from '../../Services/Port-api.service';
import { CountryApiService } from '../../Services/Country-api.service';
@Component({
    selector: 'app-port-form',
    templateUrl: './port-form.component.html',
    styleUrls: ['./port-form.component.css']
})
export class PortFormComponent extends SecondPageEditBase implements OnInit {
    arrCountry: any = [];
    constructor(
        protected _countryApiService: CountryApiService,
        protected _PortApiService: PortApiService,
        protected _injector: Injector,
    ) {
        super(_PortApiService, _injector);

        this.formGroup = new FormGroup({
            portCode: new FormControl('', Validators.required),
            portName: new FormControl('', Validators.required),
            address: new FormControl('', Validators.required),
            countryId: new FormControl(''),
            status: new FormControl(''),
        });
    }

    ngOnInit() {
        this._countryApiService.getsInUse().then((rs) => {
            console.log(rs);
            if (rs.success) {
                this.convertDataToOptions(this.arrCountry, rs.data, "code,name", "code");
            }
        });
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._PortApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

