
export class HtMenu {
    id: number;
    idParent: number;
    code: string;
    title: string;
    url: string;
    icon: string;
    iconImageUrl: string;
    orderNo: number;
    idSystem: number;
    collapsible:number;
    inStartPageList:number;
    backgroundColor: string;
    isDivider: number;
    permissions: string;
    status: number;
    created: Date;
    createdBy: number;
    modified: Date;
    modifiedBy: number;
    isDeleted: boolean;
    titleParent: string;
}

