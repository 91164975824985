import { Component, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from './app.main.component';
import {
    AuthenticationService,
    CoreUser,
    HtMenuService,
    NotificationIsRead,
    NotificationService,
    NotifierService,
    PermissionService,
    SignalRService,
    UserService
} from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../core/services/language.service';
import { MenuRootService } from "../../services/menu-root.service";
import { StaffApiService } from "../../MD/Services/Staff-api.service";
import { MenuRootStorageService } from "../../services/menu-root-storage.service";
import { OrgUnitApiService } from "../../MD/Services/OrgUnit-api.service";
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AppTopBarComponent {
    constructor(
        public appMain: AppMainComponent,
        public app: AppComponent,
        private _userService: UserService,
        private _htMenuService: HtMenuService,
        public _menuRootService: MenuRootService,
        private _menuRootStorageService: MenuRootStorageService,
        public _staffService: StaffApiService,
        private _authenticationService: AuthenticationService,
        private _signalRService: SignalRService,
        private _notificationService: NotificationService,
        private _permissionService: PermissionService,
        private _router: Router,
        private _orgUnitApiService: OrgUnitApiService,
        private translate: TranslateService,
        private _languageService: LanguageService,
        private _NotifierService: NotifierService
    ) {
        this._signalRService.initNotificationHub();
    }
    staff: any = {}
    user: any = {}
    dsLanguage = [];
    activeItem: number;
    topLevelMenus = [];
    @ViewChild('searchInput') searchInputViewChild: ElementRef;
    notificationIsRead = NotificationIsRead;
    currentUser = new CoreUser;
    userId: string;
    dataSource = [];
    totalUnRead = 0;
    themeConfigActive = false;
    changepasswdActive = false;
    subMenuActive = false;
    subMenuTitle = '';
    logoUrl = 'assets/images/logo.png';
    ngOnInit() {
        this.staff.imageURL = "assets/images/avatar-default.png";
        this._userService.getCurrentUser().then(rs => {
            if (rs.typeId != 1) {
                this._authenticationService.logout();
                setTimeout(() => {
                    top.location.href = "/";
                }, 500);
            }
            if (rs.displayName) {
                this.currentUser = rs;
            }

            this._userService.getDetail(+this.currentUser.userId)
                .then(rs => {
                    if (rs.success) {
                        console.log(rs.data);
                        let user = rs.data;
                        this.user = rs.data;
                        this._orgUnitApiService.getDetail(user.branchId)
                            .then(rs => {
                                if (rs.success) {
                                    this.logoUrl = rs.data.branchLogoURL;
                                }
                            })
                    }
                })
        });
        this.getLangs();

        this._userService.getId().then(rs => {
            this.userId = rs.toString();
            this._signalRService.start(
                environment.signalrConfig.topic.notificationCreated,
                'NotificationHub',
                this.notifyTrigger.bind(this)
            );

            this._signalRService.start(
                environment.signalrConfig.topic.notificationCreated,
                'CommonHub',
                this.refreshPermissions.bind(this)
            );

            this.refreshNotification();

            this._staffService.GetByUserId(+this.userId).then(rs => {
                if (rs.success && rs.data) this.staff = rs.data;
                if (this.staff && this.staff.imageURL == null || this.staff.imageURL == "") {
                    console.log(this.user.avatar);
                    if (!this.user.avatar) {
                        this.staff.imageURL = "assets/images/avatar-default.png";
                    }
                }
            })
        });
        this._htMenuService.getTreeTableByIdParent(environment.clientDomain.idSystem, 0)
            .then(rs => {
                this.topLevelMenus = rs.data;
            })
        this._menuRootService.handler.subscribe(v => this.handleChange(v));
        this.handleChange(this._menuRootService.current)
    }

    async handleChange(v: number) {
        if (v == 0) v = 1;
        let menu = await this._htMenuService.getDetailById(v);
        if (!menu.success) return;
        let { data } = menu;
        let module = await this._htMenuService.getDetailById(data.moduleId);
        if (!module.success) return;
        this.changeMenuRoot(data.moduleId, module.data.label);
    }

    refreshPermissions() {
        this._permissionService.refreshPermissions();
    }

    notifyTrigger(data: any) {
        if (data !== null) {
            if (data.msg) {
                this._NotifierService.showSuccess(data.msg, "Bạn có thông báo mới");
            }
            if (data.userId !== null) {
                if (data.userId.indexOf(',' + this.userId + ',') !== -1) {
                    this.refreshNotification();
                }
            } else {
                this.refreshNotification();
            }
        }
    }

    changeMenuRoot(id: number, label: string, dispatchUpdate = false) {
        this.subMenuActive = true;
        this.subMenuTitle = label.toUpperCase();
        if (dispatchUpdate) {
            this._menuRootService.setParent(id);
        }
    }

    refreshNotification() {
        this._notificationService.getsMyNotification(1, 9999, this.notificationIsRead.UnRead)
            .then(result => {
                this.dataSource = result.data;
                this.totalUnRead = result.totalRecord;
                sessionStorage.setItem('numOfNotifiUnRead', this.totalUnRead.toString());
            });
    }

    readAll(event) {
        event.preventDefault();
        event.stopPropagation();

        this._notificationService.readAll().then(rs => {
            this.dataSource.forEach(element => {
                if (element.read != null) {
                    element.read.push({ userId: this.userId });
                } else {
                    element.read = [{ userId: this.userId }];
                }
            });
        });

        this.totalUnRead = 0;
    }

    readItem(item) {
        item.isRead = 1;
        this._notificationService.readMessage(item.toId).then(rs => {
            if (rs.success) {
                this.refreshNotification();
            }
        });
    }

    onSearchAnimationEnd(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
                break;
        }
    }
    goNotification() {
        this._router.navigate(['/core/notifications']);
    }

    async changeLang(lang: string) {
        var userLang = lang.match(/vi|en-US/) ? lang : 'vi';
        this.translate.use(userLang);
        await localStorage.setItem('language', userLang);
        window.location.reload();
    }

    getLangs() {
        this._languageService.getsAll().then(rs => {
            if (rs.success) {
                this.dsLanguage = rs.data;
            }
        })
    }

    protected readonly environment = environment;
}
