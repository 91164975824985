import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { Console } from 'console';
import { MasterDataApiService } from '../../Services/MasterData-api.service';
import { ProductSourceApiService } from '../../Services/ProductSource-api.service';

@Component({
    selector: 'app-MasterData-form',
    templateUrl: './MasterData-form.component.html',
    styleUrls: ['./MasterData-form.component.css']
})
export class MasterDataFormComponent extends SecondPageEditBase implements OnInit {

    arrProductsource = [];
    arrBranchId = [];
    RegexCode = "^[a-z0-9A-Z._-]{4}$";
    Regexname = "^[a-z0-9A-Z_-]{1,50}$";
    RegexAdd = "^[a-z0-9A-Z._-]{1,100}$";
    Regexshort = "^[a-z0-9A-Z._-]{1,30}$";

    constructor(
        protected _MasterDataApiService: MasterDataApiService,
        protected _injector: Injector,
        private _productSourceAPIService: ProductSourceApiService,
    ) {
        super(_MasterDataApiService, _injector);

        this.formGroup = new FormGroup({
            mdCode: new FormControl('', [Validators.required, Validators.required]),
            mdName: new FormControl('', [Validators.required]),
            mdNameVn: new FormControl('', [Validators.required]),
            columnId: new FormControl(''),
            columnCode: new FormControl(''),
            columnName: new FormControl(''),
            whereClause: new FormControl(''),
            orderByClause: new FormControl(''),
            orderNo: new FormControl(''),
            status: new FormControl('', [Validators.required]),
        });

        this.arrBranchId = [
            { label: 'Bắc giang', value: 1 },
            { label: 'Hà nội', value: 2 },
        ];
    }

    async ngOnInit() {
    }

    async onShowPopup(id: number) {

        if (this.arrProductsource.length == 0) {
            await this.getsProductSource();
        }

        this.submitting = true;

        if (id > 0) {
            this._MasterDataApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsProductSource() {
        this.arrProductsource = [];
        await this._productSourceAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProductsource, rs.data, 'productSourceName');
            }
        })
    }

}

