import { ComponentBase } from './component-base';
import { MenuItem } from 'primeng/api';
import { BaseService } from '../../services/base.service';
import { NotifierService } from '../../services/notifier.service';
import { OnInit, Injector, ViewChild, ElementRef, HostListener, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { ExportService } from '../../services/export.service';
import { AzlogComponent } from '../../components/azlog/azlog.component';

@Directive()
export abstract class SecondPageIndexBase extends ComponentBase implements OnInit {

    @ViewChild('pLog') pLog: AzlogComponent;

    query = '';
    openSearchAdv = false;
    isCheckAll = false;
    isViewActivity = false;
    isViewSetting = false;
    ids = [];
    totalRecord = 0;
    pageIndex = 1;
    pageSize = 20;
    cols = [];
    isMultiEdit = false;
    dataSource = [];
    dataExport = [];
    openSelectCheck = false;
    orderCol = '';
    isDesc = false;
    isLoading = false;
    selectedItems = [];
    isCollapsed = false;
    isIgnoreClientCache = false;
    popupSize: any = { width: '1170', height: '500' };

    openColumnList = false;
    arrStatus = [];
    status = 1;
    listItemNumberPerPage = [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
    ];

    @ViewChild('columnListContainer')
    columnListContainerElement: ElementRef;

    @ViewChild('bulkSelectContainer')
    bulkSelectContainerElement: ElementRef;

    preventTableFilterClosing: boolean;
    tableFilterDelayClosingTimer;
    isIndeterminateBulkSelection: boolean;


    // Left action block
    leftTableActionsWidth;
    leftTableActionsElements: any[];

    protected _notifierService: NotifierService;
    protected _activatedRoute: ActivatedRoute;
    protected _commonService: CommonService;
    protected _translateService: TranslateService;
    protected _exportService: ExportService;
    protected _router: Router;

    constructor(
        protected _baseService: BaseService,
        protected _injector: Injector
    ) {
        super();

        this._notifierService = this._injector.get(NotifierService);
        this._activatedRoute = this._injector.get(ActivatedRoute);
        this._commonService = this._injector.get(CommonService);
        this._translateService = this._injector.get(TranslateService);
        this._exportService = this._injector.get(ExportService);
        this._router = this._injector.get(Router);
        this.arrStatus = [
            { label: this._translateService.instant('STATUS.USED'), value: 1 },
            { label: this._translateService.instant('STATUS.UNUSED'), value: 2 },
            { label: this._translateService.instant('STATUS.DELETED'), value: -9 },
            { label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 },
        ];

        this.resetBulkSelect();
    }

    splitExportItems: MenuItem[] = [
        { label: 'Excel', icon: 'fa fa-file-excel' },
        { label: 'Word', icon: 'fa fa-file-word' }
    ];

    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (this.columnListContainerElement) {
            if (!this.columnListContainerElement.nativeElement.contains(targetElement)) {
                this.openColumnList = false;
            }
        }

        if (this.bulkSelectContainerElement) {
            if (!this.bulkSelectContainerElement.nativeElement.contains(targetElement)) {
                this.openSelectCheck = false;
            }
        }
    }

    ngOnInit(): void {
    }

    getPopupSize() {
        if (this.popupSize && this.popupSize.width) {
            if (window.innerWidth < Number(this.popupSize.width)) {
                this.popupSize.width = '100%';
            } else {
                this.popupSize.width = this.popupSize.width + 'px';
            }
        }

        this.popupSize.height = (window.innerHeight - 200) + 'px';
    }

    toggleSearh() {
        this.openSearchAdv = !this.openSearchAdv;
    }

    toggleSelectCheck(): void {
        this.openSelectCheck = !this.openSelectCheck;
    }

    getData(limit?: number) {
        this.isLoading = true;
        this.selectedItems = [];
        this.ids = [];
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderColl: this.orderCol,
            isDesc: this.isDesc
        };
        this._baseService.getsBySearch(model)
            .then(response => {
                this.dataSource = response.data;

                if (response.totalRecord || response.totalRecord === 0) {
                    this.totalRecord = response.totalRecord;
                }

                this.afterGetData();
                this.isLoading = false;
            }, error => {
                this._notifierService.showHttpUnknowError();
                this.isLoading = false;
            });
    }


    exportToExcel(fileName?: string) {
        if (fileName === null || fileName === undefined) {
            fileName = 'export';
        }
        if (this.selectedItems.length === 0) {
            this._notifierService.showWarning(this._translateService.instant('LIST.EMPTY'));
        } else {
            this._exportService.export(this.selectedItems, this.cols, fileName);
        }
    }

    async exportAllToExcel() {
        await this.onBeforeExportAllToExcel();
        if (this.dataExport.length == 0) {
            var model = {
                keyword: this.query,
                status: this.status,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                orderColl: this.orderCol,
                isDesc: this.isDesc
            };
            this._baseService.getsBySearch(model)
                .then(response => {
                    if (response.data.length === 0) {
                        this._notifierService.showWarning(this._translateService.instant('LIST.EMPTY'));
                    } else {
                        this._exportService.export(response.data, this.cols, 'export');
                    }
                }, error => {
                    this._notifierService.showHttpUnknowError();
                });
        } else {
            this._exportService.export(this.dataExport, this.cols, 'export');
        }

    }

    async onBeforeExportAllToExcel() {
    }
    delete(id = null) {
        if (id !== null) {
            this.isLoading = true;
            this._notifierService.showDeleteConfirm().then(rs => {
                this._baseService.delete(id)
                    .then(response => {
                        if (!response.success) {
                            this._notifierService.showError(response.error);
                        }
                        this.getData();
                        this._notifierService.showDeleteDataSuccess();
                        this.isLoading = false;
                    }, error => {
                        let { error: innerError } = error ?? {}
                        if (innerError) {
                            this._notifierService.showError(innerError.message, this._translateService.instant(innerError.error));
                        } else {
                            this._notifierService.showHttpUnknowError();
                        }
                        this.isLoading = false;
                    });
            });
            this.isLoading = false;
            this.selectedItems = [];
        } else {
            if (this.selectedItems.length > 0) {
                id = this.selectedItems[0].id;
                this.isLoading = true;
                this._notifierService.showDeleteConfirm().then(rs => {
                    this._baseService.delete(id)
                        .then(response => {
                            this.resetBulkSelect();
                            this.getData();
                            this._notifierService.showDeleteDataSuccess();
                            this.isLoading = false;
                        }, error => {
                            let { error: innerError } = error ?? {}
                            if (innerError) {
                                this._notifierService.showError(innerError.error);
                            } else {
                                this._notifierService.showHttpUnknowError();
                            }
                            this.isLoading = false;
                        });
                });
                this.isLoading = false;
            }
        }
    }
    deletelstMany() {
        this.isLoading = true;
        let lstId = '';
        for (const item of this.selectedItems) {
            lstId += item.id + ',';
        }
        lstId = lstId.substring(0, lstId.length - 1);
        this._baseService.deleteMany(lstId).then(rs => {
            if (rs.success) {
                this.ids = [];
                this.isMultiEdit = false;
                this.getData();
                this.resetBulkSelect();
                this._notifierService.showDeleteDataSuccess();
            }
            this.isLoading = false;
        }, error => {
            this._notifierService.showHttpUnknowError();
            this.isLoading = false;
        });
    }

    afterGetData() {

    }

    toggleSearch() {
        this.openSearchAdv = !this.openSearchAdv;
    }

    toggleColumnList() {
        this.openColumnList = !this.openColumnList;
    }

    onSearch(): void {
        this.pageIndex = 1;
        this.getData();
        // this.openSearchAdv = false;
    }

    goToPage(event: any): void {
        this.pageIndex = (event.first / event.rows) + 1;
        this.pageSize = event.rows;
        this.getData();
    }

    onNext(): void {
        this.pageIndex++;
        this.getData();
    }

    onPrev(): void {
        this.pageIndex--;
        this.getData();
    }

    onSort(event: any) {
        this.orderCol = event.field;
        this.isDesc = event.order === 1 ? true : false;
        this.getData();
    }

    onChangeSize(n?: number): void {
        if (this.pageSize === undefined) {
            this.pageSize = this.totalRecord;
        }
        if (n > 0) {
            this.pageSize = n;
        }
        this.pageIndex = 1;
        this.getData();
    }

    viewActivities(): void {
        this.isViewActivity = !this.isViewActivity;
    }

    viewSettings(): void {
        this.isViewSetting = !this.isViewSetting;
    }

    onPage(event: any): void {
        this.pageIndex = (event.first / event.rows) + 1;
        this.pageSize = event.rows;
        this.getData();
    }

    checkItem(item) {
        if (item.checked) {
            this.ids.push(item.id);
            this.selectedItems.push(item);
            item.checked = true;
        } else {
            this.selectedItems.splice(this.selectedItems.indexOf(x => x.id === item.id), 1);
            this.ids.splice(this.ids.indexOf(item.id), 1);
            item.checked = false;
        }
        this.isMultiEdit = this.ids.length > 0 ? true : false;
        this.isCheckAll = this.ids.length === this.dataSource.length ? true : false;
    }

    onCheckAll(ev) {

    }

    onSelectionChanged(event) {
        this.selectedItems = event;
    }

    containSelectItem(item) {
        return this.selectedItems.findIndex(x => x.id === item.id) > -1;
    }

    async onPopupClosed(data) {
        this.selectedItems = [];
        if (data != null && data != undefined) {
            if (data) {
                this.pageIndex = 1;
            }
            this.getData();
        }
    }

    onPrint() {
        console.log("onPrint");
    }

    onPrintE(element: string) {
    }

    convertDataToOptions(options: any[], data: any[], fieldName: string, sortCol:string = "", separate:string = " - ") {
        if (data.length > 0) {
            if(sortCol!=""){
                data.sort((a, b) =>{
                    const nameA = a[sortCol].toUpperCase();
                    const nameB = b[sortCol].toUpperCase();
                    if (nameA < nameB) {return -1;}
                    if (nameA > nameB) {return 1;}
                    return 0;
                })
            }
            data.map(item =>{
                var label = "";
                fieldName.split(",").forEach(field => {
                    label += item[field] + separate;
                });
                options.push({ label: label.slice(0, -separate.length), value: item.id })
            }
            );
        }
    }
    // table filter
    //------------------------

    closeTableFilter() {
        this.openSearchAdv = !!this.preventTableFilterClosing;
    }

    delayTableFilterClosing(delayTime: number = 100) {
        clearTimeout(this.tableFilterDelayClosingTimer);

        this.preventTableFilterClosing = true;

        this.tableFilterDelayClosingTimer = setTimeout(() => {
            this.preventTableFilterClosing = false;
        }, delayTime);
    }

    // table cols visibility
    //------------------------

    getVisibleColsNumber() {
        let items = this.cols.filter(item => item.visible);

        return items.length;
    }

    resetColsVisibility() {
        this.cols.forEach(item => item.visible = false);
    }

    // table row selection
    //------------------------

    checkSelectionState() {
        this.isCheckAll = this.selectedItems.length === 0 ? false :
            (this.selectedItems.length > 0 && this.selectedItems.length === this.dataSource.length);
        this.isMultiEdit = this.selectedItems.length > 1;


        if (this.isCheckAll == undefined) {
            this.isCheckAll = true;
        } else {
            this.isCheckAll = this.selectedItems.length === 0 ? null :
                (this.selectedItems.length > 0 && this.selectedItems.length === this.dataSource.length);

            this.isMultiEdit = this.selectedItems.length > 1;
        }
    }

    async onChangeBulkSelection() {
        if (this.isCheckAll === undefined || !this.isCheckAll) {
            this.isCheckAll = false;
            this.resetBulkSelect();
            this.selectedItems = [];
            for (let i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = false;
            }
        } else {
            this.isCheckAll = true;
            this.selectedItems = this.dataSource;
            for (let i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = true;
                this.ids.push(this.dataSource[i].id);
            }
        }
        this.checkSelectionState();
    }

    // helper
    //------------------------
    consoleLog(event) {
        console.log(event);
    }

    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    getRandomItemFromList(listItem) {
        let index = Math.floor(Math.random() * listItem.length);

        return listItem[index];
    }

    resetBulkSelect() {
        this.ids = [];
        this.selectedItems = [];
        this.isCheckAll = undefined;
        this.isMultiEdit = false;
    }
    onPrePage() {
        if (this.pageIndex > 1) {
            this.pageIndex--;
            this.getData();
        }
    }
    onNextPage() {
        if (this.pageIndex <= this.totalRecord / this.pageSize) {
            this.pageIndex++;
            this.getData();
        }
    }
    onImport() {
        console.log("onImport");
    }
    onCancelSearch() {
        this.openSearchAdv = !this.openSearchAdv;
    }
    add() { };
    edit() { };
    @HostListener('window:keydown.alt.n', ['$event'])
    keyEventAdd(event: KeyboardEvent) {
        console.log(event);
        this.add();
    }
    @HostListener('window:keydown.alt.e', ['$event'])
    keyEventEdit(event: KeyboardEvent) {
        console.log(event);
        this.edit();
    }
    @HostListener('window:keydown.F2', ['$event'])
    keyEventSearch(event: KeyboardEvent) {
        this.openSearchAdv = !this.openSearchAdv;
    }
    viewAzLog(tableName: string) {
        this.pLog.onShowPopup(tableName);
    }

    sumByField(field: string, data = this.dataSource) {
        return data.reduce((pr, c) => {
            let v = this.getFieldValue(c, field);
            return pr + v;
        }, 0) || 0;
    }

    getFieldValue(rowData, field: string) {
        switch (field) {
            default: return rowData[field];
        }
    }
}
