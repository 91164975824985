import { Component, Injector, OnInit } from "@angular/core";
import { SecondPageEditBase } from "nabit-shared";
import { TrTypeApiService } from "../../Services/TrType-api.service";
import { ReportApiService } from "../../../RPT/Services/Report-api.service";
import { TrType2ReportApiService } from "../../Services/TrType2Report-api.service";

@Component({
    selector: "app-trType2Report-form",
    templateUrl: "./TrType2Report-form.component.html",
    styleUrls: ["./TrType2Report-form.component.scss"]
})
export class TrType2ReportFormComponent extends SecondPageEditBase implements OnInit {
    activeIndex = 0;
    allReports = [];
    selectedReports = [];
    unselectedReports = [];

    constructor(
        protected TrTypeApiService: TrTypeApiService,
        protected TrType2ReportApiService: TrType2ReportApiService,
        protected ReportApiService: ReportApiService,
        protected _injector: Injector,
    ) {
        super(TrTypeApiService, _injector);
    }

    async ngOnInit() {
        await this.getData();
    }

    onShowPopup(id: number): void {
        this.submitting = true;

        if (id > 0) {
            this.TrTypeApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.TrType2ReportApiService.getsByTrCode(rs.data.trCode).then(rs => {
                        if (rs.success) {
                            let unselectedReports = [];
                            let selectedReports = [];
                            for (const report of this.allReports) {
                                if (rs.data.reportIds.includes(report.id)) {
                                    selectedReports.push(report);
                                } else {
                                    unselectedReports.push(report);
                                }
                            }

                            this.unselectedReports = unselectedReports;
                            this.selectedReports = selectedReports;
                        } else {
                            this.unselectedReports = this.allReports;
                            this.selectedReports = [];
                        }
                    }, error => {
                        this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                        this.submitting = false;
                    })
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
        this.activeIndex = 0;
    }

    onUpdate() {
        this.TrType2ReportApiService.updateMany(this.itemDetail.id, this.selectedReports.map(data => {
            return data.id;
        }))
            .then(response => {
                if (response.error?.toString() == "ERR.DATA_DUPLICATED") {
                    this._notifierService.showUpdateDataSingleFailed();
                    this.submitting = false;
                }
                else {
                    this.closePopupMethod(true);
                    this.onAfterSave(true);
                    this.submitting = false;
                }

            }, () => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    async getData() {
        await this.ReportApiService.getsInUse().then(rs => {
            this.allReports = rs.data.map(data => {
                return {
                    id: data.id,
                    reportName: data.reportName,
                    reportId: data.reportId,
                }
            });
        })
    }
}
