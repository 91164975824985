import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from '../../classes/base/second-page-index-base';
import { AzLogService } from '../../services/azlog.service';

@Component({
    selector: 'app-azlog',
    templateUrl: './azlog.component.html',
    styleUrls: ['./azlog.component.css']
})
export class AzlogComponent extends SecondPageIndexBase implements OnInit {

    isShow = false;
    tableName = '';
    actionId = 0;
    objectId = 0;
    constructor(
        protected _azLogService: AzLogService,
        protected _injector: Injector,
    ) {
        super(_azLogService, _injector);
    }

    ngOnInit() {
    }
    onShowPopup(tableName: string) {
        this.isShow = true;
        this.tableName = tableName;
        this.pageIndex = 1;
        this.pageSize = 10;
        this.getData();
    }

    getData() {
        var model = {
            tableName: this.tableName,
            actionId: this.actionId,
            objectId: this.objectId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        };
        this._azLogService.getsByTableName(model).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
                this.totalRecord = rs.totalRecord;
            };
        });
    }

    getMaxDialogHeight() {
        return (window.innerHeight - 200).toString() + 'px';
    }
}
