import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { CustomFormComponent } from '../form/custom-form.component';
import { CustomApiService } from '../../Services/Custom-api.service';

@Component({
    selector: 'app-custom-index',
    templateUrl: './custom-index.component.html',
    styleUrls: ['./custom-index.component.css']
})
export class CustomIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: CustomFormComponent;
    tabMenuItems = [];
    constructor(
        protected _customApiService: CustomApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_customApiService, _injector);
    }
    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Danh mục hải quan'), icon: 'pi pi-book', routerLink: '../Custom'},
        ];
        this.cols = [
            { field: 'customCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', },
            { field: 'customName', header: this._translateService.instant('FORM.NAME'), visible: true, width: 'auto', },
            { field: 'countryCode', header: this._translateService.instant('FORM.COUNTRYCODE'), visible: true, width: 'auto', },
            { field: 'countryName', header: this._translateService.instant('FORM.COUNTRYNAME'), visible: true, width: 'auto', },
            { field: 'address', header: this._translateService.instant('FORM.ADDRESS'), visible: true, width: 'auto', },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }
    edit(id?:number) {
        console.log(id);
        if(id!=null){
            this.pEdit.showPopup(id);
        }else{
            this.pEdit.showPopup(this.selectedItems[0].id);
        }
    }
    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            countryId: 0,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this._baseService.Find(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }
}
