import {Injectable, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {MenuRootService} from "./menu-root.service";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MenuRootStorageService {
    public cache = new Map<string, number>(
        (localStorage.getItem('url-to-root') ?? '')
            .split('\n')
            .filter(Boolean)
            .map(piece => {
                let [url, rootId] = piece.split('|');
                return <const>[url, +rootId];
            })
    );
    constructor(private router: Router, private _MenuRootService: MenuRootService) {
        router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                let { url } = e;
                if (_MenuRootService.current !== environment.clientDomain.idSystem)
                    this.cache.set(url,  _MenuRootService.current);
                this.flush();
            })

        this.cache = new Map<string, number>(
            (localStorage.getItem('url-to-root') ?? '')
                .split('\n')
                .filter(Boolean)
                .map(piece => {
                    let [url, rootId] = piece.split('|');
                    return <const>[url, +rootId];
                })
        );

        let url = router.url;
        let cachedRoot = this.cache.get(url);
        if (cachedRoot) {
            this._MenuRootService.setParent(cachedRoot)
        }
    }

    public flush() {
        localStorage.setItem('url-to-root', [...this.cache].map(kv => `${kv[0]}|${kv[1]}`).join('\n'))
    }
}
