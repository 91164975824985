import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';
import { environment } from '../../../../../src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HtMenuService extends BaseService {

    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndpoint}/HtMenu`);
    }

    getTreeTableByIdParent(idSystem: number, idParent: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTreeTableByIdParent/${idSystem}/${idParent}`;
        return this.defaultGet(url);
    }
    GetTreeListView(idParent: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTreeListView/${idParent}`;
        return this.defaultGet(url);
    }
    getDetailById(id: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetDetailById?id=${id}`;
        return this.defaultGet(url);
    }
    getStartPageByUserId(userId: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetStartPageById?userId=${userId}`;
        return this.defaultGet(url);
    }
    getTreeMenu(idPhanHe: number, status: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTreeMenu/${idPhanHe}/${status}`;
        return this.defaultGet(url);
    }
    deleteCustom(id: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/deleteCustom/${id}`;

        return this._http.put<ResponseResult>(url, { id: id })
            .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
            .toPromise();
    }

}

