import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { BankAccountApiService } from '../../Services/BankAccount-api.service';
import {CurrencyApiService} from "../../Services/Currency-api.service";
import {BankApiService} from "../../Services/Bank-api.service";

@Component({
    selector: 'app-bankAccount-form',
    templateUrl: './BankAccount-form.component.html',
    styleUrls: ['./BankAccount-form.component.css']
})
export class BankAccountFormComponent extends SecondPageEditBase implements OnInit {
    currencyList = []
    bankList = [];

    constructor(
        protected BankAccountApiService: BankAccountApiService,
        private CurrencyApiService: CurrencyApiService,
        private BankApiService: BankApiService,
        protected _injector: Injector,
    ) {
        super(BankAccountApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            unC_template: new FormControl('',),
            currencyId: new FormControl(undefined, [Validators.required, Validators.min(0)]),
            bankId: new FormControl(undefined, [Validators.required, Validators.min(0)]),
        });

    }

    ngOnInit() {
        this.CurrencyApiService.getsAll().then(rs => {
            if (rs.success) {
                this.currencyList = rs.data;
                this.currencyList.unshift({ id: -1, name: '(chưa chọn)' })
            }
        })
        this.BankApiService.getsAll().then(rs => {
            if (rs.success) {
                this.bankList = rs.data;
                this.bankList.unshift({ id: -1, name: '(chưa chọn)' })
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.BankAccountApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

