<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Kho hàng' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '640px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã kho' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="warehouseCode" pInputText [(ngModel)]="itemDetail.warehouseCode"
                            formControlName="warehouseCode" placeholder="Định dạng text, tối đa 4 ký tự ">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Nguồn hàng' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="branchId" [options]="arrProductSource" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.productSourceId"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên kho' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" id="warehouseName" pInputText [(ngModel)]="itemDetail.warehouseName"
                            formControlName="warehouseName" placeholder="Định dạng text, tối đa 30 ký tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên ngắn' | translate}}</div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" id="shortName" pInputText [(ngModel)]="itemDetail.shortName"
                            formControlName="shortName" placeholder="Định dạng text, tối đa 10 ký tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Địa chỉ' | translate}}</div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" id="address" pInputText [(ngModel)]="itemDetail.address"
                            formControlName="address" placeholder="Định dạng text, tối đa 100 ký tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Đơn vị' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="branchId" [options]="arrBranchId" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.branchId"
                            optionValue="id" optionLabel="customerName" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>