import { Component, OnInit } from '@angular/core';
import { HtMenuService } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { AppComponent } from '../../app.component';
import { MenuRootService } from "../../services/menu-root.service";

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu" [style.padding-bottom]="0">
            <li app-menuitem
                class="shadow-2"
                style="position: fixed; top: 0; left: 0; z-index: 2000; background-color: var(--menu-bg-color); width: 100%;"
                [item]='{ "code": model?.[0]?.code, "collapsible": null, icon: model?.[0]?.icon }'
                [isPinned]="true"></li>
            <li app-menuitem [style.opacity]="0.5" [item]='{ "code": model?.[0]?.code, "collapsible": null, icon: model?.[0]?.icon }' [isPinned]="true"></li>
        </ul>
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(
        public app: AppComponent,
        public _menuRoot: MenuRootService,
        private _menuService: HtMenuService
    ) { }

    ngOnInit() {
        // this.model = [
        //     {
        //         label: 'Home', icon: 'pi pi-fw pi-home',
        //         items: [
        //             { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
        //             { label: 'Client Template', icon: 'pi pi-fw pi-bars', routerLink: ['/client-template'] },
        //         ]
        //     },
        // ];
        this._menuRoot.handler.subscribe(v => {
            if (v) {
                this.getMenu(v);
            }
        })
        this.getMenu(this._menuRoot.current);
    }

    getMenu(idParent = 0) {
        let key = 'htMenu' + environment.clientDomain.idSystem.toString() + "_" + idParent.toString();
        let cacheVal = sessionStorage.getItem(key);
        cacheVal = undefined;
        if (cacheVal !== undefined && cacheVal !== null) {
            this.model = JSON.parse(cacheVal);
        } else {
            this._menuService.getTreeTableByIdParent(environment.clientDomain.idSystem, idParent).then(rs => {
                if (rs.success) {
                    this.model = rs.data;
                    sessionStorage.setItem(key, JSON.stringify(rs.data));
                }
            });
        }
    }
}
