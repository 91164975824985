import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { PumpStationApiService } from '../../Services/PumpStation-api.service';
import { TankApiService } from '../../Services/Tank-api.service';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';

@Component({
    selector: 'app-pumpStation-form',
    templateUrl: './PumpStation-form.component.html',
    styleUrls: ['./PumpStation-form.component.css']
})
export class PumpStationFormComponent extends SecondPageEditBase implements OnInit {

    arrProduct = [];
    arrBranch = [];
    arrTankType = [];
    arrTHD = [];
    arrTHDPrice = [];
    arrScadar = [];
    arrElec = [];
    arrManufacture = [];
    arrextraStatus = [];
    arrTank = [];

    RegexCode = "^[0-9]{2}$";
    RegexName = "^[a-z0-9A-Z_-]{1,30}$";
    Regextext = "^[a-z0-9A-Z_-]{1,1000}$";
    RegexNumber = "^[0-9]{1,12}$";
    Regextaxcode = "^[0-9]{10,14}$";
    RegexPhone = "^[0-9\.]{1,12}$";
    RegexEmail = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    Regexadd = "^[a-z0-9A-Z_-]{1,100}$";

    constructor(
        protected _PumpStationApiService: PumpStationApiService,
        protected _injector: Injector,
        private _tankAPIService: TankApiService,
        private _orgUnitAPIService: OrgUnitApiService,
    ) {
        super(_PumpStationApiService, _injector);

        this.formGroup = new FormGroup({
            pumpCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            pumpName: new FormControl('', [Validators.required]),

            branchId: new FormControl(''),
            tankId: new FormControl(''),
            iADeviceId: new FormControl(''),
            iANozzleId: new FormControl(''),
            iAManufacture: new FormControl(''),
            serial: new FormControl(''),
            priceFactor: new FormControl(''),
            volumeFactor: new FormControl(''),
            amountFactor: new FormControl(''),
            totalVolumeFactor: new FormControl(''),
            totalAmountFactor: new FormControl(''),
            pumpAddress: new FormControl(''),
            stationNo: new FormControl(''),
            isAuto: new FormControl(''),
            isAutoPrice: new FormControl(''),
            isViewScada: new FormControl(''),
            stopDate: new FormControl(''),
            isElec: new FormControl(''),
            remarks: new FormControl(''),
            status: new FormControl('', [Validators.required]),

        });
 
        this.getsBranch();

        this.arrTankType = [
            { label: 'Bể trụ đứng', value: 1 },
            { label: 'Bể trụ ngang', value: 2 },
        ];

        this.arrTHD = [
            { label: 'Có TĐH', value: 1 },
            { label: 'Không', value: 0 },
        ];

        this.arrTHDPrice = [
            { label: 'Có', value: 1 },
            { label: 'Không', value: 0 },
        ];

        this.arrScadar = [
            { label: 'Có', value: 1 },
            { label: 'Không', value: 0 },
        ];

        this.arrElec = [
            { label: 'Có', value: 1 },
            { label: 'Không', value: 0 },
        ];

        this.arrManufacture = [
            { label: 'Nam dương', value: 1 },
            { label: 'kienhx', value: 2 },
        ];

        this.arrextraStatus = [
            { label: 'on', value: 1 },
            { label: 'off', value: 2 },
        ];
    }

    async ngOnInit() {
    }

    async onShowPopup(id: number) {

        if (this.arrTank.length == 0) {
            await this.getsTank();
        }
        this.submitting = true;
        if (id > 0) {

            this._PumpStationApiService.getDetail(id).then(rs => {
                this.submitting = false; 
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                    if (this.itemDetail.stopDate) {
                        this.itemDetail.stopDate = new Date(this.itemDetail.stopDate);
                    }

                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsTank() {
        this.arrTank = [];
        await this._tankAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrTank, rs.data, 'tankName');
            }
        })
    }

    async getsBranch() {
        var model = {
            keyword: "",
            status: 1, 
            CustomerGroup : 0,
            BussinessType : 0,
            Branch : 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, 'customerName');
            }
        })
    }
}

