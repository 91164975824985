import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ProductUnitFormComponent } from '../form/ProductUnit-form.component';
import { ProductUnitApiService } from '../../Services/ProductUnit-api.service';  

@Component({
    selector: 'app-productUnit-index',
    templateUrl: './ProductUnit-index.component.html',
    styleUrls: ['./ProductUnit-index.component.css']
})
export class ProductUnitIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ProductUnitFormComponent; 
    tabMenuItems = [];
    constructor(
        protected _ProductUnitApiService: ProductUnitApiService, 
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_ProductUnitApiService, _injector);
    }

    async ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Hàng hóa'), icon: 'pi pi-book', routerLink: '../Product'},
            {label: this._translateService.instant('Nhóm hàng hóa'), icon: 'pi pi-book', routerLink: '../ProductGroup'},
            {label: this._translateService.instant('Đơn vị tính'), icon: 'pi pi-book', routerLink: '../ProductUnit'}
        ];
        this.cols = [
            { field: 'unitCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'unitName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'shortName', header: this._translateService.instant('TABLEDATA.SHORTNAME'), visible: true, width: 'auto', sort: true, },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },

        ];
        this.getData();  
    }

    async edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    async add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = []; 
        var model = {
            keyword: this.query,
            status: this.status,  
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._ProductUnitApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    } 

}