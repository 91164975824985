<p-dialog #dialog header="Cập nhật thông tin/{{ 'GLAccount' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '660px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="col-12 md:col-12 p-fluid formgrid grid">
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Mã' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="code" pInputText [(ngModel)]="itemDetail.code" formControlName="code">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Tên' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="name" pInputText [(ngModel)]="itemDetail.name" formControlName="name">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'accountNature' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="number" id="accountNature" pInputText [(ngModel)]="itemDetail.accountNature"
                            formControlName="accountNature" min="0">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'accountGroup' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="number" id="accountGroup" pInputText [(ngModel)]="itemDetail.accountGroup"
                            formControlName="accountGroup" min="0">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'postOnly' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="postOnly" pInputText [(ngModel)]="itemDetail.postOnly"
                            formControlName="postOnly">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'userRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="userRef" pInputText [(ngModel)]="itemDetail.userRef"
                            formControlName="userRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'kmphiRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="kmphiRef" pInputText [(ngModel)]="itemDetail.kmphiRef"
                            formControlName="kmphiRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'customerRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="customerRef" pInputText [(ngModel)]="itemDetail.customerRef"
                            formControlName="customerRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'vendorRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="vendorRef" pInputText [(ngModel)]="itemDetail.vendorRef"
                            formControlName="vendorRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'coUnitRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="coUnitRef" pInputText [(ngModel)]="itemDetail.coUnitRef"
                            formControlName="coUnitRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'departmentRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="departmentRef" pInputText [(ngModel)]="itemDetail.departmentRef"
                            formControlName="departmentRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'contractRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="contractRef" pInputText [(ngModel)]="itemDetail.contractRef"
                            formControlName="contractRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'productRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="productRef" pInputText [(ngModel)]="itemDetail.productRef"
                            formControlName="productRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'otherRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="otherRef" pInputText [(ngModel)]="itemDetail.otherRef"
                            formControlName="otherRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'vatRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="vatRef" pInputText [(ngModel)]="itemDetail.vatRef"
                            formControlName="vatRef">
                    </div>
                </div>


                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'faTrReasonRef' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="faTrReasonRef" pInputText [(ngModel)]="itemDetail.faTrReasonRef"
                            formControlName="faTrReasonRef">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2 mb-2"
            (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2 mb-2"
            (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-trash"
            [disabled]="submitting" class="p-button p-button-outlined mr-2 mb-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>