import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { PriceListApiService } from '../../Services/PriceList-api.service';
import {ProductSourceApiService} from "../../Services/ProductSource-api.service";
import {ProductApiService} from "../../Services/Product-api.service";

import {PriceCategoryApiService} from "../../Services/PriceCategory-api.service";
import {PriceRegionApiService} from "../../Services/PriceRegion-api.service";
import { ValidationHandler } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-priceList-form',
    templateUrl: './PriceList-form.component.html',
    styleUrls: ['./PriceList-form.component.css']
})
export class PriceListFormComponent extends SecondPageEditBase implements OnInit {
    productSourceList = []
    productList = []
    priceCategoryList = []
    priceRegionList = []

    isPackageList = [
        { label: 'LTT', value: 0, }
    ]

    constructor(
        protected PriceListApiService: PriceListApiService,
        protected PriceRegionApiService: PriceRegionApiService,
        protected ProductSourceApiService: ProductSourceApiService,
        protected ProductApiService: ProductApiService,
        protected PriceCategoryApiService: PriceCategoryApiService,
        protected _injector: Injector,
    ) {
        super(PriceListApiService, _injector);

        this.formGroup = new FormGroup({
            status: new FormControl(1),
            priceCategoryId: new FormControl('', [Validators.required]),
            productSourceId: new FormControl(),
            productId: new FormControl('', [Validators.required]),
            isPackage: new FormControl('1', [Validators.required]),
            validFrom: new FormControl(new Date(), [Validators.required]),
            unitPrice: new FormControl('0', [Validators.min(0)]),
            vat: new FormControl('10', [Validators.min(0)]),
            thueVAT: new FormControl('', [Validators.min(0)]),
            pxd: new FormControl('0', [Validators.min(0)]),
            trichQBO: new FormControl('0', [Validators.min(0)]),
            chiQBO: new FormControl('0', [Validators.min(0)]),
            priceRegionId: new FormControl()
        });
    }

    ngOnInit() {
        this.ProductSourceApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.productSourceList = rs.data;
            }
        })
        this.ProductApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.productList = rs.data;
            }
        })
        this.PriceCategoryApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.priceCategoryList = rs.data;
                this.itemDetail.priceCategoryId = rs.data[0].id;
            }
        })
        this.PriceRegionApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.priceRegionList = rs.data.map(r => {
                    r.displayName = r.regionCode + ' - ' + r.regionName;
                    return r;
                });
                this.itemDetail.priceRegionId = rs.data[0].id;
            }
        })
        this.itemDetail.vat = 10;
    }
    chooseProduct(e){
        this.itemDetail.vat = 10;
        var p = this.productList.find(v => v.id===e);
        console.log(p);
        switch(p?.productCode){
            case "1101001":
                this.itemDetail.pxd = 2000;
                break;
            case "1102001":
                this.itemDetail.pxd = 1900;
                break;
            case "1103001":
                this.itemDetail.pxd = 1000;
                break;
            default:
                this.itemDetail.pxd = 0;
                break;
        }
    }
    save(){
        this.itemDetail.thueVAT = (Math.round(this.itemDetail.unitPrice*100/11)/100).toFixed(2);
        this.itemDetail.basePrice = this.itemDetail.unitPrice - this.itemDetail.thueVAT - this.itemDetail.pxd;
        super.save();
    }
    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.PriceListApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.itemDetail.validFrom = new Date(rs.data.validFrom);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1, validFrom: new Date() };
            this.submitting = false;
        }
    }
}
