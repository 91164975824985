import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';

@Injectable()
export class NotificationFromService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndpoint}/NotificationFrom`);
    }
    send(notify: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/Send`;
        return this._http
            .post<ResponseResult>(url, notify)
            .toPromise();
    }
}

