import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ConsignmentFeeFormComponent } from '../form/ConsignmentFee-form.component';
import { ConsignmentFeeApiService } from '../../Services/ConsignmentFee-api.service';
import {CustomerApiService} from "../../Services/Customer-api.service";
import {ProductApiService} from "../../Services/Product-api.service";

@Component({
    selector: 'app-consignmentFee-index',
    templateUrl: './ConsignmentFee-index.component.html',
    styleUrls: ['./ConsignmentFee-index.component.css']
})
export class ConsignmentFeeIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ConsignmentFeeFormComponent;
    tabMenuItems = [];

    customerList = [];
    productList = [];

    customerId = 0;
    productId = 0;
    validFrom = new Date();

    constructor(
        protected _ConsignmentFeeApiService: ConsignmentFeeApiService,
        protected _CustomerApiService: CustomerApiService,
        protected _ProductApiService: ProductApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_ConsignmentFeeApiService, _injector);
    }

    ngOnInit() {
        this.orderCol = 'validFrom';
        this.tabMenuItems = [
            {label: this._translateService.instant('Hàng gửi'), icon: 'pi pi-book', routerLink: '../ConsignmentFee'}
        ];
        this.cols = [
            { field: 'validFrom', header: this._translateService.instant('Ngày hiệu lực'), visible: true, width: 'auto', sort: true,},
            { field: 'docNumber', header: this._translateService.instant('Số hợp đồng'), visible: true, width: 'auto', sort: true,},
            { field: 'customerId', header: this._translateService.instant('Khách hàng'), visible: true, width: 'auto', sort: true,},
            { field: 'productId', header: this._translateService.instant('Hàng hóa'), visible: true, width: 'auto', sort: true,},
            { field: 'unitId', header: this._translateService.instant('Đơn vị tính'), visible: true, width: 'auto', sort: true,},
            { field: 'importFee', header: this._translateService.instant('Phí tiếp nhận'), visible: true, width: 'auto', sort: true,},
            { field: 'roadPumpingFee', header: this._translateService.instant('Phí bơm rót bộ'), visible: true, width: 'auto', sort: true,},
            { field: 'waterwayPumpingFee', header: this._translateService.instant('Phí bơm rót thủy'), visible: true, width: 'auto', sort: true,},
            { field: 'maintenanceFee', header: this._translateService.instant('Phí bảo quản'), visible: true, width: 'auto', sort: true,},
            { field: 'warehouseRentalFee', header: this._translateService.instant('Phí thuê kho'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.loadFilters();
        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    loadFilters() {
        this._CustomerApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.customerList, rs.data, "customerCode,customerName", "customerCode");
                this.customerList.unshift({ value: -1, label: '(không lọc)' });
                this.customerList = this.customerList.slice();
            }
        })
        this._ProductApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.productList, rs.data, "productCode,productName", "productCode");
                this.productList.unshift({ value: -1, label: '(không lọc)' });
                this.productList = this.productList.slice();
            }
        })
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            customerId: this.customerId > 0 ? this.customerId : 0,
            productId: this.productId > 0 ? this.productId : 0,
            validFrom: this.validFrom
        };
        this._baseService.Find(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
