import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { CustomerGuaranteeFormComponent } from '../form/CustomerGuarantee-form.component';
import { CustomerGuaranteeApiService } from '../../Services/CustomerGuarantee-api.service';

@Component({
    selector: 'app-customerGuarantee-index',
    templateUrl: './CustomerGuarantee-index.component.html',
    styleUrls: ['./CustomerGuarantee-index.component.css']
})
export class CustomerGuaranteeIndexComponent extends SecondPageIndexBase implements OnInit {
    fromDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    )
    toDate = new Date();
    @ViewChild('pEdit') pEdit: CustomerGuaranteeFormComponent;
    tabMenuItems = [];
    constructor(
        protected _CustomerGuaranteeApiService: CustomerGuaranteeApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_CustomerGuaranteeApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Thư bảo lãnh'), icon: 'pi pi-book', routerLink: '../CustomerGuarantee'}
        ];
        this.cols = [
            { field: 'code', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'name', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'customerId', header: this._translateService.instant('Khách hàng'), visible: true, width: 'auto', sort: true,},
            { field: 'bankId', header: this._translateService.instant('Ngân hàng'), visible: true, width: 'auto', sort: true,},
            { field: 'climit', header: this._translateService.instant('Số tiền'), visible: true, width: 'auto', sort: true,},
            { field: 'dlimit', header: this._translateService.instant('Số ngày'), visible: true, width: 'auto', sort: true,},
            { field: 'contractId', header: this._translateService.instant('Số hợp đồng'), visible: true, width: 'auto', sort: true,},
            { field: 'personSign', header: this._translateService.instant('Người ký'), visible: true, width: 'auto', sort: true,},
            { field: 'uPosition', header: this._translateService.instant('Chức vụ người ký'), visible: true, width: 'auto', sort: true,},
            { field: 'dateFrom', header: this._translateService.instant('Hiệu lực từ'), visible: true, width: 'auto', sort: true,},
            { field: 'dateTo', header: this._translateService.instant('Hiệu lực đến'), visible: true, width: 'auto', sort: true,},
            { field: 'isAppraisal', header: this._translateService.instant('Thẩm định'), visible: true, width: 'auto', sort: true,},
            { field: 'type', header: this._translateService.instant('Type'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            dateFrom: this.fromDate,
            dateTo: this.toDate
        };
        this._baseService.Find(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }
}
