import { Component, Input } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { AppMainComponent } from './app.main.component';
import { AppComponent } from '../../app.component';
import { AuthenticationService, CoreUser, SignalRService, UserService } from 'nabit-shared';
import { OrgUnitApiService } from '../../MD/Services/OrgUnit-api.service';
@Component({
    selector: 'app-inline-menu',
    templateUrl: './app.inlinemenu.component.html',
    animations: [
        trigger('menu', [
            state('hiddenAnimated', style({
                height: '0px',
                paddingBottom: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
                overflow: 'visible'
            })),
            state('visible', style({
                opacity: 1,
                'z-index': 100
            })),
            state('hidden', style({
                opacity: 0,
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('visible => hidden', animate('.1s linear')),
            transition('hidden => visible', [style({ transform: 'scaleY(0.8)' }), animate('.12s cubic-bezier(0, 0, 0.2, 1)')])
        ])
    ]
})
export class AppInlineMenuComponent {

    @Input() key = "inline-menu";

    @Input() style: any;

    @Input() styleClass: string;

    active: boolean;
    currentUser = new CoreUser;
    branchName: string ="";
    constructor(
        public appMain: AppMainComponent,
        public app: AppComponent,
        private _userService: UserService,
        private _orgUnitApiService: OrgUnitApiService,
        private _authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this._userService.getCurrentUser().then(rs => {
            /*
            if (rs.typeId != 1) {
                this._authenticationService.logout();
                setTimeout(() => {
                    top.location.href = "/";
                }, 500);
            }
            */
            this.currentUser = rs;
            if(this.currentUser.avatar == null||this.currentUser.avatar==""){
                this.currentUser.avatar="assets/images/avatar-default.png";
            }
            this._orgUnitApiService.getDetail(this.currentUser.branchId).then(rs =>{
                if(rs.success) {
                    this.branchName = rs.data.shortName;
                }
            })
        });
    }

    onClick(event) {
        this.appMain.onInlineMenuClick(event, this.key);
        event.preventDefault();
    }

    get isTooltipDisabled() {
        return !(this.appMain.isSlim() && !this.appMain.isMobile());
    }

    get tabIndex() {
        return !this.appMain.inlineMenuActive ? '-1' : null;
    }

    isHorizontalActive() {
        return this.appMain.isHorizontal() && !this.appMain.isMobile();
    }
}
