import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { Console } from 'console';
// @ts-ignore
import { ProductUnitApiService } from '../../Services/ProductUnit-api.service'; 


@Component({
    selector: 'app-productUnit-form',
    templateUrl: './ProductUnit-form.component.html',
    styleUrls: ['./ProductUnit-form.component.css']
})
export class ProductUnitFormComponent extends SecondPageEditBase implements OnInit {

    checked: boolean = false;
    RegexCode = "^[0-9]{2}$";
    RegexName = "^[a-z0-9A-Z._-]{1,30}$"; 

    constructor(
        protected _productUnitApiService: ProductUnitApiService,
        protected _injector: Injector, 
    ) {
        super(_productUnitApiService, _injector);

        this.formGroup = new FormGroup({
            unitCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            unitName: new FormControl('', [Validators.required]), 
            shortName: new FormControl('', [Validators.maxLength(100)]), 
            status: new FormControl(''), 
        });

    }

    ngOnInit() {
    }

    async onShowPopup(id: number) { 

        this.submitting = true;
        if (id > 0) {
            this._productUnitApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
 

}

