<p-dialog [visible]="active" modal="true" (visibleChange)="onHide.emit()"
          [blockScroll]="true" [baseZIndex]="1000"
          header="{{ 'USERINFO.TITLE_CHANGEPASS' | translate }}"
          [style]="{'width': '400px'}">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement1"
                                    [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="col-12 md:col-12 p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                    <label for="oldPassword">{{'USERINFO.OLDPASSWORD' | translate}}</label>
                    <input type="password" pInputText [(ngModel)]="itemDetail.oldPassword"
                           formControlName="oldPassword">
                </div>
                <div class="field col-12 md:col-12">
                    <label for="newPassword">{{'USERINFO.NEWPASSWORD' | translate}}</label>
                    <input type="password" pInputText [(ngModel)]="itemDetail.newPassword"
                           formControlName="newPassword">
                </div>
                <div class="field col-12 md:col-12">
                    <label for="confirmPassword">{{'USERINFO.RENEWPASSWORD' |
                        translate}}</label>
                    <input type="password" pInputText [(ngModel)]="itemDetail.confirmPassword"
                           formControlName="confirmPassword">
                </div>
            </div>
        </form>
    </div>

    <div class="col-12 md:col-12">
        <div class="col-6 md:col-6 fr h-text-right">
            <button pButton pRipple type="button"
                    label="{{'USERINFO.BTNCHAGEPASS' | translate}}" icon="pi pi-save"
                    [disabled]="submitting" class="p-button mr-2 mb-2"
                    (click)="onChangePass()"></button>
        </div>
    </div>
</p-dialog>
