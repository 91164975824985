import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { BankAccountFormComponent } from '../form/BankAccount-form.component';
import { BankAccountApiService } from '../../Services/BankAccount-api.service';

@Component({
    selector: 'app-bankAccount-index',
    templateUrl: './BankAccount-index.component.html',
    styleUrls: ['./BankAccount-index.component.css']
})
export class BankAccountIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: BankAccountFormComponent;
    tabMenuItems = [];
    constructor(
        protected _BankAccountApiService: BankAccountApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_BankAccountApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Tài khoản ngân hàng'), icon: 'pi pi-dollar', routerLink: '../BankAccount'}
        ];
        this.cols = [
            { field: 'code', header: this._translateService.instant('Số tài khoản'), visible: true, width: 'auto', sort: true,},
            { field: 'name', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'currencyId', header: this._translateService.instant('Đơn vị tiền tệ'), visible: true, width: 'auto', sort: true,},
            { field: 'bankId', header: this._translateService.instant('Ngân hàng'), visible: true, width: 'auto', sort: true,},
            { field: 'unC_Template', header: this._translateService.instant('Template UNC'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            bankId: 0,
        };
        this._baseService.Find(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
