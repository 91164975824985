import {Component, OnInit} from '@angular/core';
import {EChartsOption} from "echarts";
import {CalendarTypeView} from "primeng/calendar";
import {ReportApiService} from "../RPT/Services/Report-api.service";
import {AuthenticationService} from "nabit-shared";

@Component({
    selector: 'app-dashboard4',
    templateUrl: './dashboard4.component.html',
    styleUrls: ['./dashboard4.component.scss']
})
export class Dashboard4Component implements OnInit {
    constructor(
        private ReportApiService: ReportApiService,
        private AuthenticationService: AuthenticationService
    ) {
    }

    from = new Date(+new Date() - 86400 * 1000);
    to = new Date();
    options : { label: string, value: CalendarTypeView }[] = [
        { label: 'Ngày', value: 'date' },
        { label: 'Tháng', value: 'month' },
        { label: 'Năm', value: 'year' }
    ]

    optionsType = [
        { label: 'Sản lượng', value: 1 },
        { label: 'Doanh thu', value: 2 }
    ]

    data : any = {};
    data2 : any = {};

    tankData : any = {};
    pumpData : any = {};
    doanhThu : any[] = [];

    filter: CalendarTypeView = 'date';
    type = 1;

    productLoading = false;

    getDate(date: Date, isEnd: boolean) {
        switch (this.filter) {
            case 'year': {
                let d = new Date(+date);
                if (isEnd) {
                    d.setMonth(11, 31);
                    d.setHours(23, 59, 59);
                } else {
                    d.setMonth(0, 1);
                    d.setHours(0, 0, 0);
                }
                return d;
            }
            case 'month': {
                let d = new Date(+date);
                if (isEnd) {
                    d.setMonth(date.getMonth() + 1);
                    d.setDate(0);
                    d.setHours(23, 59, 59)
                } else {
                    d = new Date(d.setDate(1));
                    d.setHours(0, 0, 0);
                }
                return d;
            }
            default: {
                let d = new Date(+date);
                if (isEnd) {
                    d.setHours(23, 59, 59);
                } else {
                    d.setHours(0, 0, 0)
                }
                return d;
            }
        }
    }

    get dateFormat() {
        switch (this.filter) {
            case 'month': return 'mm/yy';
            case 'year': return 'yy';
            default: return 'dd/mm/yy';
        }
    }

    changeFilter() {
        this.from = this.getDate(new Date(), false);
        this.to = this.getDate(new Date(), true);
        this.getDataSanLuong();
    }

    optionsOut: EChartsOption = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {},
        xAxis: [
            {
                type: 'category',
                data: ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5']
            }
        ],
        yAxis: {
            type: 'value'
        },
        series: []
    }

    ngOnInit(): void {
        this.getData();
        this.getData2();
    }

    getData() {
        this.example1()
            .then(rs => {
                if (rs.success) {
                    this.data = rs.data;
                }
            })
        this.example2()
            .then(rs => {
                 if (rs.success) {
                     this.data2 = rs.data;
                     this.remapChart()
                 }
            })
    }

    remapChart() {
        console.log(this.doanhThu);

        let keys = [...new Set<string>(this.doanhThu.map(r => r.trDate))].sort();
        let products = [...new Set<string>(this.doanhThu.map(r => r.productName))].sort();
        this.optionsOut.xAxis[0].data = keys;
        this.optionsOut.series = [];

        switch (this.type) {
            case 1: {


                break;
            }
        }

        console.log(this.optionsOut)

        for (let product of this.data2.dashboard3) {
            let serial = product.serial.split(',');
            let data = product.data.split(',').map(a => +a);

            let pair = data.map((d, i) => [ serial[i], d ]);
            let p = new Map<string, number>(pair);

            let k = keys.map(k => p.get(k) ?? 0);
            this.optionsOut.series.push({
                name: product.productName,
                type: 'line',
                data: k
            })
        }
    }

    getData2() {
        this.ReportApiService.ExportJson("DASHBOARD.TANK.05")
            .then(res => {
                if (res.success) {
                    let { data } = res;
                    this.pumpData = data.pumpCount[0];
                    this.tankData = data.tank[0];
                }
            })
        this.getDataSanLuong();
    }

    getDataSanLuong() {
        let date1 = this.from, date2 = this.to;
        let y1 = date1.getFullYear();
        let m1 = date1.getMonth() + 1;
        let d1 = date1.getDate();

        let y2 = date2.getFullYear();
        let m2 = date2.getMonth() + 1;
        let d2 = date2.getDate();
        if (this.filter === 'date') {
            this.productLoading = true;
            this.ReportApiService.ExportJsonWithParam("DASHBOARD.SL.NGAY", JSON.stringify({
                fromDate: `${y1}/${m1.toString().padStart(2, "0")}/${d1.toString().padStart(2, "0")}`,
                toDate: `${y2}/${m2.toString().padStart(2, "0")}/${d2.toString().padStart(2, "0")}`
            }))
                .then(rs => {
                    if (rs.success) {
                        this.doanhThu = rs.data.doanhThu;
                        this.remapChart();
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.productLoading = true;
                })
        }
    }

    example1() {
        let res = {
            "success": true,
            "data": {
                "Branchs": {
                    "NumOfPos": "1",
                    "Active": "1",
                    "Expire": "1",
                    "Locked": "0",
                    "List": [
                        {
                            "No": 1,
                            "BranchCode": "CH01.01",
                            "BranchName": "Cửa hàng xăng dầu số 01",
                            "Status": "1"
                        }
                    ]
                }
            },
            "error": null,
            "message": null,
            "status": null,
            "totalRecord": 0,
            "correlationId": null
        };

        return Promise.resolve(res);
    }

    example2() {
        let res = {
            "success": true,
            "data": {
                "dashboard3": [
                    {
                        "productCode": "1101001",
                        "productName": "Xăng RON 95-III",
                        "serial": "2024-01-01,2024-01-02,2024-01-03,2024-01-04,2024-01-05",
                        "data": "119000.00,5020000.00,1517346.00,632654.00,190000.00"
                    },
                    {
                        "productCode": "1103001",
                        "productName": "Dầu DO 0.05S-II",
                        "serial": "2024-01-01,2024-01-02,2024-01-03,2024-01-04,2024-01-05",
                        "data": "273000.00,7298000.00,2010000.00,1520000.00,94000.00"
                    }
                ]
            },
            "error": null,
            "message": null,
            "status": null,
            "totalRecord": 0,
            "correlationId": null
        };

        return Promise.resolve(res)
    }
}
