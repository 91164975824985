import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ProductSourceApiService } from '../../Services/ProductSource-api.service';

@Component({
    selector: 'app-productsource-form',
    templateUrl: './ProductSource-form.component.html',
    styleUrls: ['./ProductSource-form.component.css']
})
export class ProductSourceFormComponent extends SecondPageEditBase implements OnInit {

    constructor(
        protected _ProductSourceApiService: ProductSourceApiService,
        protected _injector: Injector,
    ) {
        super(_ProductSourceApiService, _injector);

        this.formGroup = new FormGroup({
            productSourceCode: new FormControl('', Validators.required),
            productSourceName: new FormControl(''),
            shortName: new FormControl(''),
            status: new FormControl(''),
        });
    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._ProductSourceApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

