import { Component, OnInit } from '@angular/core';
import { ReportApiService } from "../RPT/Services/Report-api.service";
import { VehicleApiService } from "../MD/Services/Vehicle-api.service";
import { getBgColor } from "./lib";
import { EChartsOption } from "echarts";

const rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`

function getParams(date1: Date, date2: Date) {
    if ((date1 === null) || (date2 === null)) {
        return "{}";
    }

    let y1 = date1.getFullYear();
    let m1 = date1.getMonth() + 1;
    let d1 = date1.getDate();

    let y2 = date2.getFullYear();
    let m2 = date2.getMonth() + 1;
    let d2 = date2.getDate();

    let params = `{fromDate:'${y1}/${m1.toString().padStart(2, "0")}/${d1.toString().padStart(2, "0")}', toDate:'${y2}/${m2.toString().padStart(2, "0")}/${d2.toString().padStart(2, "0")}', trDir:'-1'}`;
    return params;
}

function getFirstDayOfWeek(day: Date) {
    return (new Date(day.setDate(day.getDate() - day.getDay() + (day.getDay() == 0 ? -6 : 1))));
}

function getLastDayOfWeek(day: Date) {
    return (new Date(day.setDate(day.getDate() - day.getDay() + 7)));
}

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    datesDashboard1 = [];
    datesDashboard2 = [];
    datesDashboard4 = [];

    constructor(private _ReportService: ReportApiService) {
    }

    ngOnInit(): void {
        let now = new Date(Date.now());
        this.datesDashboard1[0] = new Date(now.getFullYear(), now.getMonth(), 1);
        this.datesDashboard1[1] = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        this.datesDashboard2[0] = getFirstDayOfWeek(now);
        this.datesDashboard2[1] = getLastDayOfWeek(now);

        this.datesDashboard4 = this.datesDashboard1;

        let params = getParams(this.datesDashboard1[0], this.datesDashboard1[1]);

        this._ReportService.ExportJsonWithParam("DASHBOARD.01", params)
            .then(rs => {
                this.dashboard1 = rs.data.dashboard1.sort((i1, i2) => i1.productCode.localeCompare(i2.productCode));
            });

        params = getParams(this.datesDashboard2[0], this.datesDashboard2[1]);

        this._ReportService.ExportJsonWithParam("DASHBOARD.01", params)
            .then(rs => {
                if (rs.data.dashboard02.length === 0) {
                    let options = {
                        ...this.options,
                        title: {
                            show: true,
                            textStyle: {
                                color: "#bcbcbc",
                                fontFamily: "Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                            },
                            text: "Không có dữ liệu",
                            left: "center",
                            top: "center",
                        },
                        xAxis: {
                            show: false,
                        },
                        yAxis: {
                            show: false,
                        },
                        series: [],
                    }
                    this.optionsInventory.push(options as any);
                    return;
                } else {
                    let d = rs.data.dashboard02;
                    let labels = d[0].inventoryLabel.split(", ");
                    d.map(p => {
                        let options = {
                            ...this.options,
                            xAxis: [
                                {
                                    type: "category",
                                    data: labels,
                                    boundaryGap: false
                                }
                            ],
                            legend: {
                                selectedMode: false,
                            },
                            series: {
                                name: p.productName,
                                itemStyle: { color: getBgColor(p.productCode) },
                                type: "line",
                                smooth: true,
                                data: p.inventoryArr.split(", ").map(a => +a),
                                areaStyle: {
                                    color: getBgColor(p.productCode),
                                    opacity: 0.4
                                }
                            },
                            grid: {
                                containLabel: true
                            }
                        }
                        this.optionsInventory.push(options as any);
                    });
                }
            });

        params = getParams(this.datesDashboard4[0], this.datesDashboard4[1]);

        this._ReportService.ExportJsonWithParam("DASHBOARD.04", params)
            .then(rs => {
                if (rs.success) {
                    let { dashboar3: dashboard3, product } = rs.data;
                    if (dashboard3.length === 0) {
                        let options = {
                            ...this.options,
                            title: {
                                show: true,
                                textStyle: {
                                    color: "#bcbcbc",
                                    fontFamily: "Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                                },
                                text: "Không có dữ liệu",
                                left: "center",
                                top: "center",
                            },
                            xAxis: {
                                show: false,
                            },
                            yAxis: {
                                show: false,
                            },
                            series: [],
                        }
                        this.optionsOut = options as any;
                        return;
                    }
                    let m: Map<string, string> = new Map(product.map(a => [a.productCode.toLowerCase(), a.productName]));
                    let keys = new Set<string>(dashboard3.map(obj => Object.keys(obj).filter(f => f !== "trDate")).flat());
                    let labels = dashboard3.map(a => `${new Date(a.trDate).getDate().toString().padStart(2, "0")}/${(new Date(a.trDate).getMonth() + 1).toString().padStart(2, "0")}`);
                    let options = {
                        ...this.options,
                        xAxis: [
                            {
                                type: "category",
                                data: labels,
                                boundaryGap: false
                            }
                        ],
                        series: [...keys].sort().map(key => {
                            let productCode = key.slice(1).toLowerCase();
                            let productName = m.get(productCode);
                            let bg = this.getBgColor(productCode);
                            let area = rgb2hex(bg) + '80';

                            return {
                                name: productName,
                                itemStyle: {
                                    color: bg
                                },
                                type: "line",
                                smooth: true,
                                data: dashboard3.map(a => Math.floor(a[key] / 1000)),
                                areaStyle: {
                                    color: area,
                                    opacity: 0.4
                                }
                            }
                        })
                    }

                    this.optionsOut = options as any;
                }
            });

        this._ReportService.ExportJson("DASHBOARD.02")
            .then(rs => {
                if (rs.success) {
                    let { dashboard2 } = rs.data;
                    this.vehicles = dashboard2.sort((a, b) => {
                        let aDate = new Date(a.trDate)
                        let bDate = new Date(b.trDate);
                        return (+bDate) - (+aDate);
                    })
                }
            });
    }

    onDashboard2Change() {
        if (this.datesDashboard2[0] !== null && this.datesDashboard2[1] !== null) {
            let params = getParams(this.datesDashboard2[0], this.datesDashboard2[1]);

            this._ReportService.ExportJsonWithParam("DASHBOARD.01", params)
                .then(rs => {
                    this.optionsInventory = [];
                    if (rs.data.dashboard02.length === 0) {
                        let options = {
                            ...this.options,
                            title: {
                                show: true,
                                textStyle: {
                                    color: "#bcbcbc",
                                },
                                text: "Không có dữ liệu",
                                left: "center",
                                top: "center",
                            },
                            xAxis: {
                                show: false,
                            },
                            yAxis: {
                                show: false,
                            },
                            series: [],
                        }
                        this.optionsInventory.push(options as any);
                        return;
                    } else {
                        let d = rs.data.dashboard02;
                        let labels = d[0].inventoryLabel.split(", ");
                        d.map(p => {
                            let options = {
                                ...this.options,
                                xAxis: [
                                    {
                                        type: "category",
                                        data: labels,
                                        boundaryGap: false
                                    }
                                ],
                                legend: {
                                    selectedMode: false,
                                },
                                series: {
                                    name: p.productName,
                                    itemStyle: { color: getBgColor(p.productCode) },
                                    type: "line",
                                    smooth: true,
                                    data: p.inventoryArr.split(", ").map(a => +a),
                                    areaStyle: {
                                        color: getBgColor(p.productCode),
                                        opacity: 0.4
                                    }
                                },
                                grid: {
                                    containLabel: true
                                }
                            }

                            this.optionsInventory.push(options as any);
                        });
                    }
                });
        }
    }

    onDashboard1Change() {
        if (this.datesDashboard1[0] !== null && this.datesDashboard1[1] !== null) {
            let params = getParams(this.datesDashboard1[0], this.datesDashboard1[1]);
            this._ReportService.ExportJsonWithParam("DASHBOARD.01", params)
                .then(rs => {
                    this.dashboard1 = rs.data.dashboard1.sort((i1, i2) => i1.productCode.localeCompare(i2.productCode));
                });
        }
    }

    onDashboard4Change() {
        if (this.datesDashboard4[0] !== null && this.datesDashboard4[1] !== null) {
            let params = getParams(this.datesDashboard4[0], this.datesDashboard4[1]);
            this._ReportService.ExportJsonWithParam("DASHBOARD.04", params)
                .then(rs => {
                    if (rs.success) {
                        let { dashboar3: dashboard3, product } = rs.data;
                        console.log(dashboard3);
                        if (dashboard3.length === 0) {
                            let options = {
                                ...this.options,
                                title: {
                                    show: true,
                                    textStyle: {
                                        color: "#bcbcbc",
                                    },
                                    text: "Không có dữ liệu",
                                    left: "center",
                                    top: "center",
                                },
                                xAxis: {
                                    show: false,
                                },
                                yAxis: {
                                    show: false,
                                },
                                series: [],
                            }
                            this.optionsOut = options as any;
                            return;
                        }
                        let m: Map<string, string> = new Map(product.map(a => [a.productCode.toLowerCase(), a.productName]));
                        let keys = new Set<string>(dashboard3.map(obj => Object.keys(obj).filter(f => f !== "trDate")).flat());
                        let labels = dashboard3.map(a => `${new Date(a.trDate).getDate().toString().padStart(2, "0")}/${(new Date(a.trDate).getMonth() + 1).toString().padStart(2, "0")}`);
                        let options = {
                            ...this.options,
                            xAxis: [
                                {
                                    type: "category",
                                    data: labels,
                                    boundaryGap: false
                                }
                            ],
                            series: [...keys].sort().map(key => {
                                let productCode = key.slice(1).toLowerCase();
                                let productName = m.get(productCode);
                                let bg = this.getBgColor(productCode);
                                let area = rgb2hex(bg) + '80';

                                return {
                                    name: productName,
                                    itemStyle: {
                                        color: bg
                                    },
                                    type: "line",
                                    smooth: true,
                                    data: dashboard3.map(a => Math.floor(a[key] / 1000)),
                                    areaStyle: {
                                        color: area,
                                        opacity: 0.4
                                    }
                                }
                            })
                        }

                        this.optionsOut = options as any;
                    }
                });
        }
    }

    dashboard1 = [];

    options: EChartsOption = {
        tooltip: {
            trigger: "item"
        },
        legend: {},
        xAxis: [],
        yAxis: [
            {
                type: "value"
            }
        ],
        series: []
    }

    optionsInventory: Array<EChartsOption> = [];
    optionsOut: EChartsOption = {}

    setCurrentPage($event: { page: number }) {
        this.currentPage = $event.page;
    }

    currentPage = 0;
    productCountPerPage = 3;
    vehicles = []

    chart03 = {};

    inventoryChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                ticks: {
                    "color": "rgba(0, 0, 0, 0.87)",
                },
                display: true
            },
            x: {
                ticks: {
                    minRotation: 50,
                    "color": "rgba(0, 0, 0, 0.87)",
                },
                display: true
            }
        },
        elements: {
            point: {
                radius: 0
            }
        },
    };

    getBgColor(productCode: string) {
        return getBgColor(productCode);
    }

    getDateString(d: string) {
        return new Date(d).toLocaleString("vi-VN");
    }

}
