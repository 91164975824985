import { Injectable, Injector } from "@angular/core";
import { HttpClient, } from "@angular/common/http";
import { BaseService } from "nabit-shared";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ManufactureApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/Manufacture`);
    }
}
