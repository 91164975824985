import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TrType2ReportApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/TrType2Report`);
    }

    getsAll(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetAll`;
        return this.defaultGet(apiUrl);
    }

    getsByTrCode(trCode: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByTrCode/${trCode}`;
        return this.defaultGet(apiUrl);
    }

    updateMany(trTypeId: number, reportIds: number[]): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/UpdateMany`
        return this._http.post<ResponseResult>(apiUrl, { trTypeId: trTypeId, reportIds: reportIds }).toPromise();
    }
}