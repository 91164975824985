<p-dialog #dialog [(visible)]="isShow" [autoZIndex]="true" [contentStyle]="{'max-height': getMaxDialogHeight()}"
          [maximizable]="true" [modal]="true" [styleClass]="'ag-modal force-full-width'"
          [style]="{'width': '880px'}" closeIcon="ci ci-close" header="{{ 'Cập nhật thông tin/ Cần xuất' | translate }}"
          maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">
                        {{ 'Mã vòi' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.pumpCode" formControlName="pumpCode" id="pumpCode" pInputText
                               placeholder="Định dạng text, 2 ký tự số" type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">
                        {{ 'Bể chứa' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.tankId" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrTank" appendTo="body" dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="tankId">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">
                        {{ 'Tên cần xuất ' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.pumpName" formControlName="pumpName" pInputText
                               placeholder="Định dạng text, tối đa 30 ký tự"
                               type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Số serial' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.serial" formControlName="serial" pInputText
                               placeholder="Định dạng text"
                               type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Ghi chú' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <textarea [(ngModel)]="itemDetail.remarks" [autoResize]="true" formControlName="remarks"
                                  pInputTextarea placeholder="Định dạng text"></textarea>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Trạng thái' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.status" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrStatus" appendTo="body" dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="status">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-12" style="font-weight: 450;">
                    <br>
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">
                            Thông tin tự động hoá
                        </div>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Mã thiết bị TĐH' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.iADeviceId" formControlName="iADeviceId" pInputText
                               placeholder="Định dạng text"
                               type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Nhà cung cấp TĐH' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.iAManufacture" [autoDisplayFirst]="false"
                                    [filter]="true" [options]="arrManufacture" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1" filterBy="label,value" formControlName="iAManufacture">
                        </p-dropdown>
                        <!--                        <input [(ngModel)]="itemDetail.iAManufacture" formControlName="iAManufacture" pInputText-->
                        <!--                               type="text">-->
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Mã TĐH' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.iANozzleId" formControlName="iANozzleId" pInputText
                               placeholder="Định dạng text"
                               type="text">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="cancel()" [disabled]="submitting" class="p-button p-button-outlined mr-2" icon="pi pi-trash"
                label="{{'BUTTON.CANCEL' | translate}}"
                pButton pRipple type="button"></button>
    </p-footer>
</p-dialog>
