import {Component, OnInit} from '@angular/core';
import {ReportApiService} from "../RPT/Services/Report-api.service";
import {WarehouseApiService} from "../MD/Services/Warehouse-api.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-dashboard3',
    templateUrl: './dashboard3.component.html',
    styleUrls: ['./dashboard3.component.scss']
})
export class Dashboard3Component implements OnInit {
    constructor(
        private ReportApiService: ReportApiService,
        private WarehouseApiService: WarehouseApiService,
        private sanitizer: DomSanitizer
    ) {
    }

    fromDate = new Date(+new Date().setDate(0));
    toDate = new Date();
    warehouse = [];
    warehouseList = [];

    loading = false;

    reportData : any = {};
    total = [];
    totalColumns = ['tttA95', 'tttDO', 'tcA95', 'tcDO', 'hgA95', 'hgDO', 'tctbA95', 'tctbDO'];
    totalRecord : any = {};

    hangGui = [];
    hangGuiColumns = ['tdA95', 'nA95', 'xA95', 'tcA95', 'tdDO', 'nDO', 'xDO', 'tcDO'];
    hangGuiRecord : any = {};

    hangCoTheBan = [];
    hangCoTheBanColumns = ['tdA95', 'nA95', 'xA95', 'tcA95', 'tdDO', 'nDO', 'xDO', 'tcDO'];
    hangCoTheBanRecord : any = {};

    hangGiuHo = [];
    hangGiuHoColumns = ['tdA95', 'nA95', 'xA95', 'tcA95', 'tdDO', 'nDO', 'xDO', 'tcDO'];
    hangGiuHoRecord : any = {};

    hangTTTKho = [];
    hangTTTKhoColumns = ['tdA95', 'nA95', 'xA95', 'tcA95', 'tdDO', 'nDO', 'xDO', 'tcDO'];
    hangTTTKhoRecord : any = {};

    hangVayTra = [];
    hangVayTraColumns = ['tdA95', 'nA95', 'xA95', 'tcA95', 'tdDO', 'nDO', 'xDO', 'tcDO'];
    hangVayTraRecord : any = {};

    tables = [];

    ngOnInit(): void {
        this.getFilteringData();
        this.getData();
    }

    getFilteringData() {
        this.WarehouseApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.warehouseList = rs.data;
                }
            })
    }

    pad(number: number) {
        return number.toString().padStart(2, '0');
    }

    getData() {
        this.loading = true;
        this.ReportApiService.ExportJsonWithParam("NXTTongHop", JSON.stringify({
            fromDate: `${this.fromDate.getFullYear()}-${this.pad(this.fromDate.getMonth() + 1)}-${this.pad(this.fromDate.getDate())}`,
            toDate: `${this.toDate.getFullYear()}-${this.pad(this.toDate.getMonth() + 1)}-${this.pad(this.toDate.getDate())}`,
            warehouseId: this.warehouse.map(i => i.id).join(',')
        }))
            .then(rs => {
                if (rs.success) {
                    this.reportData = rs.data;
                    this.sortTotalData();
                    this.remapData();
                } else {
                    this.loading = false;
                }
            }, () => {
                this.loading = false;
            })

    }

    groupData(dataArray: any[], columns: string[], allowHtml: boolean) {
        let total : any = {};
        let d = dataArray;
        let m = new Map<string, any[]>();
        for (let record of d) {
            let group = m.get(record.wh) ?? [];
            group.push(record);
            m.set(record.wh, group);
        }

        let m2 = new Map<any, any[]>();
        for (let [wh, rows] of m) {
            let keys = columns;

            let obj : any = { wh };

            for (let row of rows) {
                for (let key of keys) {
                    obj[key] = (obj[key] ?? 0) + row[key];
                    total[key] = (total[key] ?? 0) + row[key];
                }

                if (allowHtml) {
                    row.nguonHangHtml = this.sanitizer.bypassSecurityTrustHtml(row.nguonHang);
                    row.html = true;
                }
            }

            m2.set(obj, rows);
        }

        return {
            total,
            groups: [...m2]
        }
    }

    remapData() {
        let d = [
            <const>[this.reportData.data, this.totalColumns, 'TỒN TỔNG HỢP', false],
            <const>[this.reportData.data0, this.hangGuiColumns, 'NXT - Hàng gửi', true],
            <const>[this.reportData.data1, this.hangCoTheBanColumns, 'NXT - Hàng có thể bán', true],
            <const>[this.reportData.data2, this.hangGiuHoColumns, 'NXT - Hàng giữ hộ', true],
            <const>[this.reportData.data3, this.hangTTTKhoColumns, 'NXT - Thực tế tại kho', true],
            <const>[this.reportData.data4, this.hangVayTraColumns, 'NXT - Hàng vay trả', true],
        ];

        let out = d.map(r => {
            let [data, columns, name, allowHtml] = r;
            let t = this.groupData(data, columns, allowHtml);
            return {
                total: t.total,
                groups: t.groups,
                columns,
                name
            }
        });

        this.tables = out;
    }

    sortTotalData() {
        let t = this.groupData(this.reportData.data, this.totalColumns, false);
        this.total = t.groups;
        this.totalRecord = t.total;
    }
}
