import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadComponent} from "./upload/upload.component";
import {NabitComponentModule} from "nabit-shared";


@NgModule({
    declarations: [
        UploadComponent
    ],
    imports: [
        CommonModule,
        NabitComponentModule,
    ],
    exports: [
        UploadComponent
    ]
})
export class SharedModule {}
