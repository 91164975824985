import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseService {

    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndpoint}/NotificationTo`);
    }

    getsMyNotification(pageIndex: number, pageSize: number, isRead: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsMyNotification/${pageIndex}/${pageSize}/${isRead ?? ''}`;
        return this.defaultGet(apiUrl);
    }

    readAll(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/ReadAll`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    readMessage(id: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/ReadMessage/${id}`;
        return this._http
            .post<ResponseResult>(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
