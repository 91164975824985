import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { SecondPageIndexBase } from "nabit-shared";
import { ReportGroupApiService } from "../../Services/ReportGroup-api.service";
import { ReportGroupFormComponent } from "../form/ReportGroup-form.component";

@Component({
    selector: "app-reportGroup-index",
    templateUrl: "./ReportGroup-index.component.html",
    styleUrls: ["./ReportGroup-index.component.scss"]
})
export class ReportGroupIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild("pEdit") pEdit: ReportGroupFormComponent;
    tabMenuItems = [];

    constructor(
        protected _ReportGroupApiService: ReportGroupApiService,
        protected _injector: Injector,
    ) {
        super(_ReportGroupApiService, _injector);
    }

    ngOnInit(): void {
        this.tabMenuItems = [
            { label: this._translateService.instant('Báo cáo'), icon: 'pi pi-book', routerLink: '../Report' },
            { label: this._translateService.instant('Nhóm báo cáo'), icon: 'pi pi-book', routerLink: '../ReportGroup' },
            { label: this._translateService.instant('Danh mục lọc'), icon: 'pi pi-book', routerLink: '../MasterData' }
        ];

        this.cols = [
            { field: "groupCode", header: this._translateService.instant("Mã nhóm báo cáo"), visible: true, width: "auto", sort: true },
            { field: "groupName", header: this._translateService.instant("Tên nhóm báo cáo"), visible: true, width: "auto", sort: true },
            { field: "shortName", header: this._translateService.instant("Tên ngắn"), visible: true, width: "auto", sort: true },
            { field: "parentId", header: this._translateService.instant("TABLEDATA.parentId"), visible: true, width: "auto", sort: true },
            { field: "modified", header: this._translateService.instant("TABLEDATA.MODIFIED"), visible: false, width: "auto", sort: true, dateFormat: "dd/MM/yyyy HH:mm" },
            { field: "modifiedBy", header: this._translateService.instant("TABLEDATA.MODIFIED_BY"), visible: false, width: "auto", sort: true },
            { field: "status", header: this._translateService.instant("TABLEDATA.STATUS"), visble: true, width: "auto", sort: true },
        ]

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect;
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
        }

        this._ReportGroupApiService.getsBySearch(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }

            this.isLoading = false;
        }, _ => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        })
    }
}