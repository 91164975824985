import {Injectable, Injector} from '@angular/core';
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {BaseService, ResponseResult} from "nabit-shared";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class StorageAttachmentApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.storageEndpoint}/StorageAttachment`);
    }
}
