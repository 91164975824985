import { DateTimeProvider } from "angular-oauth2-oidc";

export class Report {
    id: number;
    reportCode: string;
    reportName: string;
    shortName: string;
    reportGroupId: number;
    reportTypeId: number;
    templateUrl: string;
    sql: string;
    sqlType: number;
    tableNames: string;
    isLogReport: boolean;
    isCache: boolean;
    status: number;
    modified: Date;
    modifiedBy: number;
};
export class ReportViewer {
    id: number;
    reportCode: string;
    reportName: string;
    fromDate: Date;
    toDate: Date;
    FilterConditions: [];
    status: number;
    modified: Date;
    modifiedBy: number;
};
export class FilterCondition{
    id: number;
    idReport: number;
    masterDataId: number;
    parameterName: string;
    status: number;
    modified: Date;
    modifiedBy: number;
}
export class reportDto {
    reportId: number;
    parameters: string;
}