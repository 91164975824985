<div class="p-formgrid grid">
    <div class="p-fluid md:col-3 col-12">
        <span class="p-float-label">
            <p-calendar [ngModel]="fromDate" [showTime]="false"
                        dateFormat="dd/mm/yy"
                        (ngModelChange)="fromDate = $event;">
            </p-calendar>
            <label>
                Từ ngày
            </label>
        </span>
    </div>
    <div class="p-fluid md:col-3 col-12">
        <span class="p-float-label">
            <p-calendar [ngModel]="toDate" [showTime]="false"
                        dateFormat="dd/mm/yy"
                        (ngModelChange)="toDate = $event;">
            </p-calendar>
            <label>
                Đến ngày
            </label>
        </span>
    </div>
    <div class="p-fluid md:col-3 col-12">
        <span class="p-float-label">
            <p-multiSelect [ngModel]="warehouse" [options]="warehouseList"
                           appendTo="body" optionLabel="warehouseName"
                           (ngModelChange)="warehouse = $event;">
            </p-multiSelect>
            <label>
                Kho hàng
            </label>
        </span>
    </div>
    <div class="col-2">
        <button pButton pRipple type="button" icon="pi pi-filter" class="w-full h-full" (click)="getData()" >
            Lọc
        </button>
    </div>
</div>

<p-table [value]="total" class="p-datatable-striped p-datatable-sm">
    <ng-template pTemplate="header">
        <tr>
            <th rowspan="2">STT</th>
            <th rowspan="2">Nguồn</th>
            <th colspan="2">
                TỒN THỰC TẾ TẠI KHO
                <br/>
                (II.4)
            </th>
            <th colspan="2">
                TỒN HÀNG GỬI (ĐANG VỀ KHO)
                <br/>
                (II.1)
            </th>
            <th colspan="2">
                HÀNG GIỮ HỘ
                <br/>
                (II.3)
            </th>
            <th colspan="2">
                HÀNG CÓ THỂ BÁN
                <br/>
                (II.2)
            </th>
        </tr>
        <tr>
            <ng-container *ngFor="let _ of [1,2,3,4]">
                <th>Xăng</th>
                <th>Dầu</th>
            </ng-container>
        </tr>
        <tr>
            <th *ngFor="let i of [1, 2, 3, 4]">
                ({{ i }})
            </th>
            <th *ngFor="let i of [3, 4, 5, 6]">
                ({{ i }})
            </th>
            <th>
                (7) = (3) + (9) - (5)
            </th>
            <th>
                (8) = (4) - (6)
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group>
        <tr class="font-bold">
            <td>

            </td>
            <td>
                {{ group[0].wh }}
            </td>
            <td *ngFor="let col of totalColumns" class="text-right">
                <ng-container *ngIf="group[0][col] > 0">
                    {{ group[0][col] | number: '1.0-2' }}
                </ng-container>
                <ng-container *ngIf="group[0][col] < 0">
                    ({{ -group[0][col] | number: '1.0-2' }})
                </ng-container>
                <ng-container *ngIf="group[0][col] === 0">
                    -
                </ng-container>
            </td>
        </tr>
        <tr *ngFor="let row of group[1]; index as idx;">
            <td>
                {{ idx + 1 }}
            </td>
            <td>
                {{ row.nguonHang }}
            </td>
            <td *ngFor="let col of totalColumns" class="text-right">
                <ng-container *ngIf="row[col] > 0">
                    {{ row[col] | number: '1.0-2' }}
                </ng-container>
                <ng-container *ngIf="row[col] < 0">
                    ({{ -row[col] | number: '1.0-2' }})
                </ng-container>
                <ng-container *ngIf="row[col] === 0">
                    -
                </ng-container>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer">
        <tr>
            <td></td>
            <td>TỔNG CỘNG</td>
            <td *ngFor="let col of totalColumns" class="text-right">
                <ng-container *ngIf="totalRecord[col] > 0">
                    {{ totalRecord[col] | number: '1.0-2' }}
                </ng-container>
                <ng-container *ngIf="totalRecord[col] < 0">
                    ({{ -totalRecord[col] | number: '1.0-2' }})
                </ng-container>
                <ng-container *ngIf="totalRecord[col] === 0">
                    -
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>

<ng-container *ngFor="let item of tables.slice(1)">
    <p-divider>
        {{ item.name }}
    </p-divider>
    <p-table [value]="item.groups" class="p-datatable-striped p-datatable-sm">
        <ng-template pTemplate="header">
            <tr>
                <th rowspan="2">STT</th>
                <th rowspan="2">Khách hàng</th>
                <th colspan="4">Xăng</th>
                <th colspan="4">Dầu</th>
            </tr>
            <tr>
                <ng-container *ngFor="let _ of [1, 2]">
                    <th>Tồn đầu</th>
                    <th>Nhập</th>
                    <th>Xuất</th>
                    <th>Tồn cuối</th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group>
            <tr class="font-bold">
                <td>

                </td>
                <td>
                    {{ group[0].wh }}
                </td>
                <td *ngFor="let col of item.columns" class="text-right">
                    <ng-container *ngIf="group[0][col] > 0">
                        {{ group[0][col] | number: '1.0-2' }}
                    </ng-container>
                    <ng-container *ngIf="group[0][col] < 0">
                        ({{ -group[0][col] | number: '1.0-2' }})
                    </ng-container>
                    <ng-container *ngIf="group[0][col] === 0">
                        -
                    </ng-container>
                </td>
            </tr>
            <tr *ngFor="let row of group[1]; index as idx;">
                <td>
                    {{ idx + 1 }}
                </td>
                <td>
                    <ng-container *ngIf="row.html">
                        <div [innerHTML]="row.nguonHangHtml"></div>
                    </ng-container>
                    <ng-container *ngIf="!row.html">
                        {{ row.nguonHang }}
                    </ng-container>
                </td>
                <td *ngFor="let col of item.columns" class="text-right">
                    <ng-container *ngIf="row[col] > 0">
                        {{ row[col] | number: '1.0-2' }}
                    </ng-container>
                    <ng-container *ngIf="row[col] < 0">
                        ({{ -row[col] | number: '1.0-2' }})
                    </ng-container>
                    <ng-container *ngIf="row[col] === 0">
                        -
                    </ng-container>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td></td>
                <td>TỔNG CỘNG</td>
                <td *ngFor="let col of item.columns" class="text-right">
                    <ng-container *ngIf="item.total[col] > 0">
                        {{ item.total[col] | number: '1.0-2' }}
                    </ng-container>
                    <ng-container *ngIf="item.total[col] < 0">
                        ({{ -item.total[col] | number: '1.0-2' }})
                    </ng-container>
                    <ng-container *ngIf="item.total[col] === 0">
                        -
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-container>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
             [fullScreen]="true" [showSpinner]="loading">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
