import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { SecondPageIndexBase } from "nabit-shared";
import { AppComponent } from "../../../app.component";
import { TrGroupFormComponent } from "../form/TrGroup-form.component";
import { TrGroupApiService } from "../../Services/TrGroup-api.service";

@Component({
    selector: 'app-trGroup-index',
    templateUrl: './TrGroup-index.component.html',
    styleUrls: ['./TrGroup-index.component.scss']
})
export class TrGroupIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild("pEdit") pEdit: TrGroupFormComponent;
    tabMenuItems = [];

    constructor(
        protected _TrGroupApiService: TrGroupApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_TrGroupApiService, _injector);
    }

    ngOnInit(): void {
        this.tabMenuItems = [
            { label: this._translateService.instant('Phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrType' },
            { label: this._translateService.instant('Nhóm phương thức N/X'), icon: 'pi pi-book', routerLink: '../TrGroup' },
            { label: this._translateService.instant('Lọc đối tượng'), icon: 'pi pi-search', routerLink: '../TrType2CustGroup' },
            { label: this._translateService.instant('Lọc báo cáo'), icon: 'pi pi-search', routerLink: '../TrType2Report' },
        ]

        this.cols = [
            { field: "trGroupCode", header: this._translateService.instant("Mã nhóm N/X"), visible: true, width: "auto", sort: true },
            { field: "trGroupName", header: this._translateService.instant("Tên nhóm N/X"), visible: true, width: "auto", sort: true },
            { field: "shortName", header: this._translateService.instant("Tên ngắn"), visible: true, width: "auto", sort: true },
            { field: "modified", header: this._translateService.instant("TABLEDATA.MODIFIED"), visible: false, width: "auto", sort: true, dateFormat: "dd/MM/yyyy HH:mm" },
            { field: "modifiedBy", header: this._translateService.instant("TABLEDATA.MODIFIED_BY"), visible: false, width: "auto", sort: true },
            { field: "status", header: this._translateService.instant("TABLEDATA.STATUS"), visible: true, width: "auto", sort: true },
        ]

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData(): void {
        this.isLoading = true;
        this.resetBulkSelect;
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
        }

        this._TrGroupApiService.getsBySearch(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }

            this.isLoading = false;
        }, _ => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        })
    }
}