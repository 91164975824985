import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class UserSettingsService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndpoint}/UserSettings`);
    }

    getUserSetting(subject: string, key: string) {
        const url = `${this.serviceUri}/GetUserSetting?subject=${encodeURIComponent(subject)}&key=${encodeURIComponent(key)}`;
        return this.defaultGet(url);
    }
}

