<div style="width: 300pt; height: 250pt;">
    <div style="position: relative; width: 300pt; height: 250pt;">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="350pt" height="250pt" viewBox="0 0 790.000000 528.000000"
             preserveAspectRatio="xMidYMid meet" style="position: absolute; top: 0; left: 0">
            <g transform="translate(0.000000,528.000000) scale(0.100000,-0.100000)"
               fill="#a4b4cb" stroke="none">
                <path d="M2600 4859 c-362 -10 -977 -42 -1037 -55 l-23 -4 -2 -298 -3 -297
                    -45 -2 c-154 -9 -509 -34 -527 -38 l-23 -5 0 284 c0 157 -4 287 -8 290 -14 8
                    -273 -43 -372 -74 -130 -40 -199 -83 -228 -140 -22 -44 -23 -51 -21 -320 1
                    -151 6 -285 10 -298 5 -12 25 -32 44 -43 l35 -21 0 -1543 c0 -1523 0 -1544
                    -20 -1550 -31 -10 -70 -63 -70 -96 0 -74 50 -113 195 -148 87 -22 274 -51 324
                    -51 l31 0 0 1851 0 1851 -67 -7 c-138 -14 -328 -53 -395 -80 -14 -5 -18 -2
                    -18 13 0 31 47 80 103 109 53 27 234 76 325 88 l52 7 0 -60 0 -61 33 -3 c32
                    -3 32 -4 35 -60 l3 -58 304 0 304 0 3 83 3 82 32 3 33 3 2 -1913 3 -1913 135
                    -7 c511 -25 951 -32 1945 -32 1271 1 1605 11 2144 65 337 34 518 73 581 126
                    26 21 30 32 30 73 0 64 -6 76 -46 101 l-34 21 0 1553 0 1553 34 22 c19 11 38
                    31 42 42 3 12 6 142 6 288 -1 290 -5 315 -58 371 -148 154 -967 261 -2284 299
                    -388 11 -1096 11 -1510 -1z m270 -133 l0 -74 -207 -6 c-115 -3 -250 -9 -300
                    -12 l-93 -6 0 76 c0 76 0 76 28 77 148 8 368 16 460 17 l112 2 0 -74z m670 -1
                    l0 -75 -300 0 -300 0 0 75 0 75 300 0 300 0 0 -75z m603 68 l67 -6 0 -75 0
                    -75 -61 7 c-34 3 -169 6 -300 6 l-239 0 0 75 0 75 233 0 c127 0 262 -3 300 -7z
                    m394 -13 c126 -6 252 -13 281 -16 l52 -5 0 -71 0 -71 -157 7 c-87 3 -222 9
                    -300 13 l-143 6 0 73 c0 67 2 74 20 74 11 0 122 -5 247 -10z m-2329 -77 l3
                    -71 -108 -6 c-59 -4 -177 -11 -262 -16 -85 -6 -171 -10 -193 -10 l-38 0 0 69
                    c0 84 -23 76 260 91 113 6 219 13 235 14 17 2 46 3 65 2 l35 -1 3 -72z m3081
                    27 c122 -11 229 -20 237 -20 11 0 14 -17 14 -71 l0 -72 -63 6 c-34 3 -169 14
                    -300 23 l-237 18 0 72 0 73 63 -5 c34 -3 163 -14 286 -24z m590 -71 c95 -16
                    209 -38 252 -50 l79 -21 0 -60 0 -61 -32 6 c-18 4 -81 15 -140 26 -59 11 -162
                    27 -230 36 -68 8 -140 18 -160 21 l-38 6 0 69 0 69 48 -6 c26 -4 125 -19 221
                    -35z m-5019 -74 c0 -59 -2 -65 -22 -69 -13 -3 -71 -14 -129 -25 -137 -27 -234
                    -60 -294 -101 l-48 -33 6 56 c3 30 13 67 23 82 34 52 144 97 341 139 59 13
                    111 21 116 19 4 -2 7 -33 7 -68z m2010 -49 l0 -84 -167 -6 c-93 -3 -228 -8
                    -300 -11 l-133 -5 0 85 0 85 48 1 c125 4 472 15 510 17 l42 2 0 -84z m670 -1
                    l0 -85 -300 0 -300 0 0 85 0 85 300 0 300 0 0 -85z m528 78 l142 -6 0 -85 0
                    -85 -82 6 c-46 4 -181 7 -300 7 l-218 0 0 85 0 85 158 0 c86 0 221 -3 300 -7z
                    m577 -18 c88 -3 175 -8 193 -11 l32 -5 0 -81 0 -81 -150 7 c-82 3 -217 9 -300
                    12 l-150 7 0 85 0 85 108 -7 c59 -3 179 -8 267 -11z m-2437 -82 l3 -81 -103
                    -6 c-57 -3 -177 -11 -268 -16 -91 -6 -180 -10 -197 -10 l-33 0 0 79 c0 96 -27
                    86 275 101 121 6 236 12 255 14 67 5 65 8 68 -81z m3087 42 c110 -8 210 -18
                    223 -20 21 -5 22 -11 22 -87 l0 -81 -63 6 c-34 3 -169 14 -300 23 l-237 18 0
                    84 0 84 78 -6 c42 -3 167 -13 277 -21z m1068 -65 c17 -30 30 -90 19 -90 -5 0
                    -32 11 -60 25 l-52 25 0 48 c0 27 3 51 6 55 10 9 71 -35 87 -63z m-428 -6 c55
                    -8 139 -25 188 -36 l87 -20 0 -80 0 -81 -32 6 c-18 4 -82 15 -141 26 -60 12
                    -166 28 -235 36 -70 9 -142 18 -159 21 l-33 5 0 83 0 83 113 -14 c61 -7 157
                    -20 212 -29z m-5075 -84 l0 -79 -37 -6 c-181 -31 -338 -77 -392 -114 -52 -36
                    -51 -37 -51 35 0 66 1 68 43 109 49 46 140 81 302 115 144 30 135 34 135 -60z
                    m2010 -63 l0 -84 -157 -6 c-87 -4 -222 -7 -300 -7 l-143 0 0 80 0 80 58 1 c63
                    2 433 14 500 17 l42 2 0 -83z m670 -2 l0 -85 -300 0 -300 0 0 85 0 85 300 0
                    300 0 0 -85z m534 78 l137 -6 -3 -81 -3 -81 -52 0 c-29 1 -163 2 -298 3 l-245
                    2 0 85 0 85 163 0 c90 0 225 -3 301 -7z m566 -18 c250 -8 230 0 230 -97 l0
                    -80 -37 6 c-21 3 -156 8 -300 12 l-263 7 0 84 0 85 98 -6 c53 -3 176 -8 272
                    -11z m-2435 -80 l0 -80 -185 -6 c-102 -4 -236 -10 -297 -13 l-113 -7 0 75 c0
                    90 -26 81 275 96 121 6 236 12 255 14 67 5 65 8 65 -79z m4142 60 l43 -26 0
                    -74 c0 -45 -4 -75 -10 -75 -6 0 -33 11 -60 25 l-50 25 0 75 c0 87 6 91 77 50z
                    m-1052 -20 c110 -8 210 -18 223 -20 20 -5 22 -11 22 -75 l0 -70 -32 0 c-18 0
                    -141 7 -273 15 -132 8 -252 15 -267 15 l-28 0 0 81 0 81 78 -6 c42 -3 167 -13
                    277 -21z m640 -71 c55 -8 139 -25 188 -36 l87 -20 0 -60 c0 -57 -1 -60 -22
                    -55 -63 15 -501 67 -564 67 -11 0 -14 18 -14 73 l0 74 113 -14 c61 -7 157 -20
                    212 -29z m412 -109 c41 -24 43 -28 43 -71 0 -50 6 -49 -80 -19 -33 11 -35 14
                    -38 63 -4 62 8 66 75 27z"/>
                <path d="M930 3830 l0 -140 305 0 305 0 0 140 0 140 -305 0 -305 0 0 -140z"/>
                <path d="M930 3485 l0 -135 305 0 305 0 0 135 0 135 -305 0 -305 0 0 -135z"/>
                <path d="M930 3140 l0 -140 305 0 305 0 0 140 0 140 -305 0 -305 0 0 -140z"/>
                <path d="M930 2795 l0 -135 305 0 305 0 0 135 0 135 -305 0 -305 0 0 -135z"/>
                <path d="M930 2455 l0 -135 305 0 305 0 0 135 0 135 -305 0 -305 0 0 -135z"/>
                <path d="M930 2110 l0 -140 305 0 305 0 0 140 0 140 -305 0 -305 0 0 -140z"/>
                <path d="M930 1765 l0 -135 305 0 305 0 0 135 0 135 -305 0 -305 0 0 -135z"/>
                <path d="M930 1420 l0 -140 305 0 305 0 0 140 0 140 -305 0 -305 0 0 -140z"/>
                <path d="M930 1075 l0 -135 305 0 305 0 0 135 0 135 -305 0 -305 0 0 -135z"/>
                <path d="M930 735 l0 -135 305 0 305 0 0 135 0 135 -305 0 -305 0 0 -135z"/>
                <path d="M932 483 l3 -48 195 -16 c107 -9 243 -19 303 -23 l107 -7 0 70 0 71
                    -306 0 -305 0 3 -47z"/>
            </g>
        </svg>
        <div>
            <div class='tankBanner P{{ productData?.productCode }}_bgColor P{{ productData?.productCode }}_fgColor' [style.text-align]="'center'">
                <div [style.padding-top]="'5px'">
                    {{ tank.tankName }}
                </div>
            </div>
            <div class='tank2' id='rulerHeight_{{ tankData?.TankId }}'></div>
            <div class='tank5 P{{ productData?.productCode }}_bgColor P{{ productData?.productCode }}_fgColor' id='tankLevelScada_{{ tankData?.TankId }}'
                 [style]="{
                    height: ((tankData?.MaxVolume - tankData?.RemainingFuelVolume) / tankData?.MaxVolume * 200) + 'px',
                    top: (tankData?.RemainingFuelVolume / tankData?.MaxVolume * 200 + 85) + 'px'
                 }">
                {{ (tankData?.MaxVolume - tankData?.RemainingFuelVolume) / tankData?.MaxVolume * 100 | number: '1.0-0' }}%
            </div>
            <div class='tank6'></div>
            <div class='tankLabel1 tankLabel'>Nhiệt độ (oC)</div>
            <input class='tankInput1 tankInput' id='tankTemp_{{ tankData?.TankId }}' value="{{ tankData?.Temperature | number: '0.0-0' }}"
                   disabled/>
            <div class='tankLabel2 tankLabel'>H hàng (mm)</div>
            <input class='tankInput2 tankInput' id='tankFuelLevel_{{ tankData?.TankId }}' value="{{ tankData?.FuelLevel | number: '0.0-1' }}" disabled/>
            <div class='tankLabel3 tankLabel'>V hàng (lít)</div>
            <input class='tankInput3 tankInput' id='tankFuelVolume_{{ tankData?.TankId }}' value="{{ tankData?.FuelVolume | number: '0.0-0' }}"
                   disabled/>
            <div class='tankLabel4 tankLabel'>V hàng (lít 15)</div>
            <input class='tankInput4 tankInput' id='tankFuelVolume15_{{ tankData?.TankId }}' value="{{ tankData?.FuelVolume15 | number: '0.0-0' }}"
                   disabled/>
            <div class='tankLabel5 tankLabel'>H Nước (mm)</div>
            <input class='tankInput5 tankInput' id='tankWaterLevel_{{ tankData?.TankId }}' value="{{ tankData?.WaterLevel | number: '0.0-0' }}"
                   disabled/>
            <div class='tankLabel6 tankLabel'>V Nước (lít)</div>
            <input class='tankInput6 tankInput' id='tankWaterVolume_{{ tankData?.TankId }}' value="{{ tankData?.WaterVolume | number: '0.0-0' }}"
                   disabled/>
            <div class='tankText1 tankText'>Trống bể (lít)</div>
            <div class='tankText2 tankText'><span
                id='tankRemainQty_{{ tankData?.TankId }}'></span>{{ tankData?.RemainingFuelVolume | number: '0.0-0' }}</div>
            <div class="tankError flex flex-row justify-content-center {{ showTankError ? 'error' : 'success' }}" [style.align-items]="'center'">
                <div class='tankStatus1 {{ showTankError ? "statusOff" : "statusOn" }}'
                     id='tankStatus_{{ tankData?.TankId }}'></div>
                <div class="tankErrorName">{{ errorName }}</div>
            </div>
        </div>
    </div>
</div>
