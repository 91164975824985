import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { FilterConditionApiService } from '../../Services/FilterCondition-api.service';
import { ReportApiService } from '../../Services/Report-api.service';
import { MasterDataApiService } from '../../../MD/Services/MasterData-api.service';
import { ActivatedRoute } from '@angular/router';
import { report } from 'process';

@Component({
    selector: 'app-filterCondition-form',
    templateUrl: './FilterCondition-form.component.html',
    styleUrls: ['./FilterCondition-form.component.css']
})
export class FilterConditionFormComponent extends SecondPageEditBase implements OnInit {
    arrReport = [];
    arrMasterData = [];
    arrSqlType = [];
    arrIsMultiple = [{ label: this._translateService.instant('CONTROL.SINGLE_SELECT'), value: 0 }, { label: this._translateService.instant('CONTROL.MULTIPLE_SELECT'), value: 1 }];
    arrIsRequired = [{ label: this._translateService.instant('CONTROL.REQUIRED'), value: 0 }, { label: this._translateService.instant('CONTROL.NOT_REQUIRED'), value: 1 }];
    reportId:any;
    constructor(
        protected _FilterConditionApiService: FilterConditionApiService,
        protected _reportApiService: ReportApiService,
        protected _masterDataApiService: MasterDataApiService,
        private _route: ActivatedRoute,
        protected _injector: Injector,
    ) {
        super(_FilterConditionApiService, _injector);

        this.formGroup = new FormGroup({
            idReport: new FormControl('', Validators.required),
            masterDataId: new FormControl('', Validators.required),
            parameterName: new FormControl(''),
            isMultiple: new FormControl(''),
            isRequired: new FormControl(''),
            status: new FormControl(''),
        });
    }
    ngOnInit() {
        this._route.paramMap.subscribe(params => {
            this.reportId  = this._route.snapshot.paramMap.get('id');
        });
        this._reportApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrReport, rs.data, 'reportCode,reportName', 'reportCode');
                console.log(this.arrReport);
            }
        }, error => {
            this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
        });
        this._masterDataApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrMasterData, rs.data, 'mdNameVn');
            }
        }, error => {
            this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
        });
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._FilterConditionApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, idReport: this.reportId, status: 1 };
            this.submitting = false;
        }
    }
}