import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { FundFormComponent } from '../form/Fund-form.component';
import { FundApiService } from '../../Services/Fund-api.service';

@Component({
    selector: 'app-fund-index',
    templateUrl: './Fund-index.component.html',
    styleUrls: ['./Fund-index.component.css']
})
export class FundIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: FundFormComponent;
    tabMenuItems = [];
    constructor(
        protected _FundApiService: FundApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_FundApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Fund'), icon: 'pi pi-book', routerLink: '../Fund'}
        ];
        this.cols = [
            { field: 'fundCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'fundName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'fundGroup', header: this._translateService.instant('FundGroup'), visible: true, width: 'auto', sort: true,},
            { field: 'belongTo', header: this._translateService.instant('belongTo'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this._FundApiService.getsAll().then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
