import { RouterModule, Routes } from "@angular/router";
import { ProductGroupIndexComponent } from "./ProductGroup/index/ProductGroup-index.component";
import { ProductIndexComponent } from "./Product/index/Product-index.component";
import { WarehouseIndexComponent } from "./Warehouse/index/Warehouse-index.component";
import { CustomerIndexComponent } from "./Customer/index/Customer-index.component";
import { VehicleIndexComponent } from "./Vehicle/index/Vehicle-index.component";
import { VehicleTypeIndexComponent } from "./VehicleType/index/vehicle-type-index.component";
import { VehicleClassIndexComponent } from "./VehicleClass/index/vehicleClass-index.component";
import { ProductSourceIndexComponent } from "./ProductSource/index/ProductSource-index.component";
import { StudentIndexComponent } from "./Student/index/Student-index.component";
import { TankIndexComponent } from "./Tank/index/Tank-index.component";
import { PumpStationIndexComponent } from "./PumpStation/index/PumpStation-index.component";
import { ScadaIndexComponent } from "./Scada/index/Scada-index.component";
import { ProductUnitIndexComponent } from "./ProductUnit/index/ProductUnit-index.component";
import { OilLossRateIndexComponent } from "./OilLossRate/index/OilLossRate-index.component";
import { CustomerGroupIndexComponent } from "./CustomerGroup/index/CustomerGroup-index.component";
import { OrgUnitIndexComponent } from "./OrgUnit/index/OrgUnit-index.component";
import { BusinessTypeIndexComponent } from "./BusinessType/index/BusinessType-index.component";
import { DepartmentIndexComponent } from "./Department/index/Department-index.component";
import { StaffIndexComponent } from "./Staff/index/Staff-index.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { MasterDataIndexComponent } from "./MasterData/index/MasterData-index.component";
import { CountryIndexComponent } from "./Country/index/Country-index.component";
import { ProvinceIndexComponent } from "./Province/index/Province-index.component";
import { CurrencyIndexComponent } from "./Currency/index/Currency-index.component";
import { PriceRegionIndexComponent } from "./PriceRegion/index/PriceRegion-index.component";
import { StockTypeIndexComponent } from "./StockType/index/StockType-index.component";
import { CreditAreaIndexComponent } from "./CreditArea/index/CreditArea-index.component";
import { ArmRuleIndexComponent } from "./ArmRule/index/ArmRule-index.component";
import { PriceCategoryIndexComponent } from "./PriceCategory/priceCategory-index/priceCategory-index.component";
import { LocationIndexComponent } from "./Location/index/Location-index.component";
import { ContractIndexComponent } from "./Contract/index/Contract-index.component";
import { BankIndexComponent } from "./Bank/index/Bank-index.component";
import { BankAccountIndexComponent } from "./BankAccount/index/BankAccount-index.component";
import { VcfAvgIndexComponent } from "./VcfAvg/index/VcfAvg-index.component";
import { TrTypeIndexComponent } from "./TrType/index/TrType-index.component";
import { ShipPointIndexComponent } from "./ShipPoint/index/ShipPoint-index.component";
import { CustomerGuaranteeIndexComponent } from "./CustomerGuarantee/index/CustomerGuarantee-index.component";
import { InventoryTypeIndexComponent } from "./InventoryType/index/InventoryType-index.component";
import { FinGroupIndexComponent } from "./FinGroup/index/FinGroup-index.component";
import { FundIndexComponent } from "./Fund/index/Fund-index.component";
import { GLAccountIndexComponent } from "./GLAccount/index/GLAccount-index.component";
import { FinTypeIndexComponent } from "./FinType/index/FinType-index.component";
import { PriceListIndexComponent } from "./PriceList/index/PriceList-index.component";
import { DiscountIndexComponent } from "./Discount/index/Discount-index.component";
import { ExchangeRateIndexComponent } from "./ExchangeRate/index/ExchangeRate-index.component";
import { ExpenseTypeIndexComponent } from "./ExpenseType/index/expenseType-index.component";
import { ExpenseItemsIndexComponent } from "./ExpenseItems/index/expenseItems-index.component";
import { CustomIndexComponent } from "./Custom/index/custom-index.component";
import { PortIndexComponent } from "./Port/index/port-index.component";
import { Dashboard2Component } from "../dashboard2/dashboard2.component";
import { TaxPolicyIndexComponent } from "./TaxPolicy/index/taxPolicy-index.component";
import { MopsPriceIndexComponent } from "./MopsPrice/index/MopsPrice-index.component";
import { StabilizationFundIndexComponent } from "./StabilizationFund/index/StabilizationFund-index.component";
import { ConsignmentFeeIndexComponent } from "./ConsignmentFee/index/ConsignmentFee-index.component";
import { TrType2CustGroupIndexComponent } from "./TrType2CustGroup/index/TrType2CustGroup-index.component";
import { TrType2ReportIndexComponent } from "./TrType2Report/index/TrType2Report-index.component";
import { TrGroupIndexComponent } from "./TrGroup/index/TrGroup-index.component";
import { TrTypeFormComponent } from "./TrType/form/TrType-form.component";
import { ContractFormComponent } from "./Contract/form/Contract-form.component";
import { DeliveryMethodIndexComponent } from "./DeliveryMethod/index/DeliveryMethod-index.component";
import { StoreIndexComponent } from "./Store/index/Store-index.component";
import { StoreFormComponent } from "./Store/form/Store-form.component";
import { PriceSettingComponent } from "./price-setting/price-setting.component";
import { POSConfigComponent } from "./POSConfig/index/POSConfig-index.component";
import { Dashboard3Component } from "../dashboard3/dashboard3.component";
import { Dashboard4Component } from "../dashboard4/dashboard4.component";
import { OrgUnitSettingIndexComponent } from "./OrgUnitSetting/index/OrgUnitSetting-index.component";
import { ManufactureIndexComponent } from "./Manufacture/index/Manufacture-index.component";
import { SoftwarePaymentIndexComponent } from "./SoftwarePayment/index/SoftwarePayment-index.component";

const routes: Routes = [
    {
        path: "DASHBOARD",
        component: DashboardComponent,
    },
    {
        path: "DASHBOARD2",
        component: Dashboard2Component,
    },
    {
        path: "Country",
        component: CountryIndexComponent,
    },
    {
        path: "Province",
        component: ProvinceIndexComponent,
    },
    {
        path: "Currency",
        component: CurrencyIndexComponent,
    },
    {
        path: "PriceRegion",
        component: PriceRegionIndexComponent
    },
    {
        path: "StockType",
        component: StockTypeIndexComponent
    },
    {
        path: "CreditArea",
        component: CreditAreaIndexComponent
    },
    {
        path: "ArmRule",
        component: ArmRuleIndexComponent
    },
    {
        path: "PriceCategory",
        component: PriceCategoryIndexComponent
    },
    {
        path: "Location",
        component: LocationIndexComponent
    },
    {
        path: "Contract/:import",
        component: ContractIndexComponent
    },
    {
        path: "Contract",
        component: ContractIndexComponent
    },
    {
        path: "ContractForm",
        component: ContractFormComponent,
    },
    {
        path: "ContractForm/:Id",
        component: ContractFormComponent,
    },
    {
        path: "Bank",
        component: BankIndexComponent
    },
    {
        path: "BankAccount",
        component: BankAccountIndexComponent
    },
    {
        path: "VcfAvg",
        component: VcfAvgIndexComponent
    },
    {
        path: "TrType",
        component: TrTypeIndexComponent
    },
    {
        path: "TrTypeForm",
        component: TrTypeFormComponent,
    },
    {
        path: "TrTypeForm/:Id",
        component: TrTypeFormComponent,
    },
    {
        path: "TrGroup",
        component: TrGroupIndexComponent
    },
    {
        path: "TrType2CustGroup",
        component: TrType2CustGroupIndexComponent
    },
    {
        path: "TrType2Report",
        component: TrType2ReportIndexComponent
    },
    {
        path: "ShipPoint",
        component: ShipPointIndexComponent
    },
    {
        path: "CustomerGuarantee",
        component: CustomerGuaranteeIndexComponent
    },
    {
        path: "InventoryType",
        component: InventoryTypeIndexComponent
    },
    {
        path: "FinGroup",
        component: FinGroupIndexComponent
    },
    {
        path: "Fund",
        component: FundIndexComponent
    },
    {
        path: "GLAccount",
        component: GLAccountIndexComponent
    },
    {
        path: "FinType",
        component: FinTypeIndexComponent
    },
    {
        path: "PriceList",
        component: PriceListIndexComponent
    },
    {
        path: "PriceList/:PriceCategoryId",
        component: PriceListIndexComponent
    },
    {
        path: "ExchRate",
        component: ExchangeRateIndexComponent
    },
    {
        path: "Discount",
        component: DiscountIndexComponent
    },
    {
        path: "Product",
        component: ProductIndexComponent
    }, {
        path: "ProductGroup",
        component: ProductGroupIndexComponent
    }, {
        path: "Warehouse",
        component: WarehouseIndexComponent
    }, {
        path: "Customer",
        component: CustomerIndexComponent
    }, {
        path: "Vehicle",
        component: VehicleIndexComponent
    }, {
        path: "VehicleType",
        component: VehicleTypeIndexComponent
    }, {
        path: "VehicleClass",
        component: VehicleClassIndexComponent
    }, {
        path: "Tank",
        component: TankIndexComponent
    }, {
        path: "PumpStation",
        component: PumpStationIndexComponent
    }, {
        path: "ProductSource",
        component: ProductSourceIndexComponent
    }, {
        path: "Student",
        component: StudentIndexComponent
    }, {
        path: "SCADA",
        component: ScadaIndexComponent
    }, {
        path: "ProductUnit",
        component: ProductUnitIndexComponent
    }, {
        path: "OilLossRate",
        component: OilLossRateIndexComponent
    }, {
        path: "CustomerGroup",
        component: CustomerGroupIndexComponent
    }, {
        path: "OrgUnit",
        component: OrgUnitIndexComponent
    }, {
        path: "BusinessType",
        component: BusinessTypeIndexComponent
    }, {
        path: "Department",
        component: DepartmentIndexComponent
    }, {
        path: "Staff",
        component: StaffIndexComponent
    }, {
        path: "MasterData",
        component: MasterDataIndexComponent
    }, {
        path: "ExpenseType",
        component: ExpenseTypeIndexComponent
    }, {
        path: "ExpenseItems",
        component: ExpenseItemsIndexComponent
    }, {
        path: "Custom",
        component: CustomIndexComponent
    }, {
        path: "Port",
        component: PortIndexComponent
    }, {
        path: "TaxPolicy",
        component: TaxPolicyIndexComponent
    }, {
        path: "StabilizationFund",
        component: StabilizationFundIndexComponent
    }, {
        path: "MopsPrice",
        component: MopsPriceIndexComponent
    }, {
        path: "ConsignmentFee",
        component: ConsignmentFeeIndexComponent
    }, {
        path: "DeliveryMethod",
        component: DeliveryMethodIndexComponent
    }, {
        path: "Store",
        component: StoreIndexComponent
    }, {
        path: "Store/:id",
        component: StoreFormComponent
    }, {
        path: "POSConfig",
        component: POSConfigComponent
    }, {
        path: "PriceSetting",
        component: PriceSettingComponent
    }, {
        path: "DASHBOARD3",
        component: Dashboard3Component
    }, {
        path: "DASHBOARD4",
        component: Dashboard4Component
    }, {
        path: "OrgUnitSetting",
        component: OrgUnitSettingIndexComponent,
    }, {
        path: "Manufacture",
        component: ManufactureIndexComponent,
    }, {
        path: "SoftwarePayment",
        component: SoftwarePaymentIndexComponent,
    }
];

export const MasterDataRoutes = RouterModule.forChild(routes);
