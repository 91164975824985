import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { SalesChanelApiService } from '../../../Services/SalesChanel-api.service';

@Component({
    selector: 'app-salesChanel-form',
    templateUrl: './SalesChanel-form.component.html',
    styleUrls: ['./SalesChanel-form.component.css']
})
export class SalesChanelFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected SalesChanelApiService: SalesChanelApiService,
        protected _injector: Injector,
    ) {
        super(SalesChanelApiService, _injector);

        this.formGroup = new FormGroup({
            name: new FormControl('', [Validators.required]),
            status: new FormControl('', [Validators.required])
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.SalesChanelApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

