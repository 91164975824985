
import { Component, Injector, OnInit } from "@angular/core";
import { SecondPageEditBase } from "nabit-shared";
import { ReportGroupApiService } from "../../Services/ReportGroup-api.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-reportGroup-form",
    templateUrl: "./ReportGroup-form.component.html",
    styleUrls: ["./ReportGroup-form.component.scss"]
})
export class ReportGroupFormComponent extends SecondPageEditBase implements OnInit {
    activeIndex = 0;

    constructor(
        protected _ReportGroupApiService: ReportGroupApiService,
        protected _injector: Injector,
    ) {
        super(_ReportGroupApiService, _injector);

        this.formGroup = new FormGroup({
            groupCode: new FormControl("", [Validators.required]),
            groupName: new FormControl("", [Validators.required]),
            shortName: new FormControl("", [Validators.required]),
            description: new FormControl(""),
            parentId: new FormControl(""),
            status: new FormControl("", [Validators.required]),
        })
    }

    ngOnInit(): void {
    }

    onShowPopup(id: number): void {
        this.submitting = true;

        if (id > 0) {
            this._ReportGroupApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, _ => {
                this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                this.submitting = false;
            })
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
        this.activeIndex = 0;
    }
}