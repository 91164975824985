<p-dialog #dialog [(visible)]="isShow" [autoZIndex]="true" [contentStyle]="{'max-height': getMaxDialogHeight()}"
          [maximizable]="true" [modal]="true" [styleClass]="'ag-modal force-full-width'"
          [style]="{'width': '880px'}" closeIcon="ci ci-close" header="{{ 'Nhà cung cấp TĐH' | translate }}"
          maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{ 'Mã nhà cung cấp' | translate }}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.manufactureCode" formControlName="manufactureCode"
                               id="manufactureCode"
                               pInputText type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{ 'Tên nhà cung cấp' | translate }}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.manufactureName" formControlName="manufactureName"
                               id="manufactureName" pInputText type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{ 'Trạng thái' | translate }}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.status" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrStatus" appendTo="body" dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="status">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="cancel()" [disabled]="submitting" class="p-button p-button-outlined mr-2"
                icon="pi pi-trash" label="{{'BUTTON.CANCEL' | translate}}"
                pButton pRipple type="button"></button>
    </p-footer>
</p-dialog>
