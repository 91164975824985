import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { TankApiService } from '../../Services/Tank-api.service';
import { ProductApiService } from '../../Services/Product-api.service';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';

@Component({
    selector: 'app-tank-form',
    templateUrl: './Tank-form.component.html',
    styleUrls: ['./Tank-form.component.css']
})
export class TankFormComponent extends SecondPageEditBase implements OnInit {

    arrProduct = [];
    arrBranch = [];
    arrTankType = [];
    arrTHD = [];
    arrManufacture = [];
    arrextraStatus = [];

    RegexCode = "^[a-z0-9A-Z._-]{1,8}$";
    RegexNumber = "^[0-9]{1,12}$";
    RegexPhone = "^[0-9\.]{1,12}$";
    RegexEmail = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

    constructor(
        protected _TankApiService: TankApiService,
        protected _injector: Injector,
        private _productAPIService: ProductApiService,
        private _orgUnitAPIService: OrgUnitApiService,
    ) {
        super(_TankApiService, _injector);

        this.formGroup = new FormGroup({
            tankCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            tankName: new FormControl(''),
            idBranch: new FormControl(''),
            productId: new FormControl(''),
            iAManufactureId: new FormControl(''),
            iADeviceId: new FormControl(''),
            iATankId: new FormControl(''),
            isAuto: new FormControl(''),
            minHeight: new FormControl(''),
            minVolume: new FormControl(''),
            maxHeight: new FormControl(''),
            maxVolume: new FormControl(''),
            minAlarmPercent: new FormControl('', [Validators.max(100)]),
            lowAlarmPercent: new FormControl('', [Validators.max(100)]),
            maxAlarmPercent: new FormControl('', [Validators.max(100)]),
            highAlarmPercent: new FormControl('', [Validators.max(100)]),
            remarks: new FormControl(''),
            vTotal: new FormControl(''),
            vExtra: new FormControl(''),
            extraStatus: new FormControl(''),
            tankType: new FormControl(''),
            fullFactor: new FormControl(''),
            order: new FormControl(''),
            created: new FormControl(''),
            logTime: new FormControl(''),
            status: new FormControl(''),
        });
 

        this.arrTankType = [
            { label: 'Bể trụ đứng', value: 1 },
            { label: 'Bể trụ ngang', value: 2 },
        ];

        this.arrTHD = [
            { label: 'Có TĐH', value: 1 },
            { label: 'Không TĐH', value: 2 },
        ];
        this.arrManufacture = [
            { label: 'Vietcis', value: 1 },
            { label: 'Nam dương', value: 2 },
            { label: 'PECO', value: 3 },
        ];

        this.arrextraStatus = [
            { label: 'on', value: 1 },
            { label: 'off', value: 2 },
        ];
    }

    async ngOnInit() {
    }

    async onShowPopup(id: number) {

        if (this.arrProduct.length == 0) {
            await this.getsProduct();
        }

        if (this.arrBranch.length == 0) {
            await this.getsBranch();
        }

        this.submitting = true;
        if (id > 0) {
            this._TankApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsProduct() {
        this.arrProduct = [];
        await this._productAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProduct, rs.data, 'productName');
            }
        })
    }

    async getsBranch() {
        var model = {
            keyword: "",
            status: 1, 
            CustomerGroup : 0,
            BussinessType : 0,
            Branch : 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, 'customerName');
            }
        })
    }
}

