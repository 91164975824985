import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BankApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/Bank`);
    }

    getsAll(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetAll`;
        return this.defaultGet(apiUrl);
    }
}
