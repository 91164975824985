<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Nhóm hàng hóa' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '640px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-12 md:col-4" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã nhóm' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.productGroupCode"
                            formControlName="productGroupCode" placeholder="Định dạng text, 3 kí tự">
                    </div>
                </div>
                <div class="col-12 md:col-4" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên nhóm' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.productGroupName"
                            formControlName="productGroupName" placeholder="Định dạng text, tối đa 30 ký tự">
                    </div>
                </div>

                <div class="col-12 md:col-4" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên ngắn' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.shortName" formControlName="shortName"
                            placeholder="Định dạng text, tối đa 10 ký tự">
                    </div>
                </div>

                <div class="col-12 md:col-4" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trạng thái' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>