import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { PriceCageoryFormComponent } from '../priceCategory-form/priceCategory-form.component';
import { PriceCategoryApiService } from '../../Services/PriceCategory-api.service';

@Component({
  selector: 'app-priceCategory-index',
  templateUrl: './priceCategory-index.component.html',
  styleUrls: ['./priceCategory-index.component.scss']
})
export class PriceCategoryIndexComponent extends SecondPageIndexBase implements OnInit {

  @ViewChild('pEdit') pEdit: PriceCageoryFormComponent;
  tabMenuItems = [];
  constructor(
    protected _priceCagegoryApiService: PriceCategoryApiService,
    protected _injector: Injector,
    public app: AppComponent,
  ) {
    super(_priceCagegoryApiService, _injector);
  }

  ngOnInit() {
    this.tabMenuItems = [
      {label: this._translateService.instant('Giá hàng hóa'), icon: 'pi pi-book', routerLink: '../PriceList'},
      {label: this._translateService.instant('Loại giá'), icon: 'pi pi-book', routerLink: '../PriceCategory'},
      {label: this._translateService.instant('Vùng giá'), icon: 'pi pi-book', routerLink: '../PriceRegion'}
  ];
    this.cols = [
      { field: 'categoryCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true, },
      { field: 'categoryName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true, },
      { field: 'shortName', header: this._translateService.instant('TABLEDATA.SHORTNAME'), visible: true, width: 'auto', sort: true, },
      { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
      { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
      { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
    ];
    this.getData();
  }
  edit(id?: number) {
    this.pEdit.showPopup(id);
}

add() {
    this.pEdit.showPopup();
}

getData() {
    this.isLoading = true;
    this.resetBulkSelect();
    this.dataSource = [];

    let model = {
        keyword: this.query,
        status: this.status,
        pageIndex: 1,
        pageSize: 999999,
        orderCol: this.orderCol,
        isDesc: this.isDesc
    };
    this._priceCagegoryApiService.GetAll().then(response => {
        if (response.success) {
            this.dataSource = response.data;
            this.totalRecord = response.totalRecord;
            this.dataExport = response.data;
        }
        this.isLoading = false;
    }, error => {
        this.isLoading = false;
        this._notifierService.showHttpUnknowError();
    });
}
}