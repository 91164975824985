import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppMainComponent } from './layouts/main-layout/app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AppModule } from './app.module';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { StartPageComponent } from './StartPage/StartPage.component';
export const routes: Routes = [
    {
        canActivate: [AuthGuardService],
        path: '', component: AppMainComponent,
        children: [
            { path: '', component: StartPageComponent },
            {
                path: 'client-template', loadChildren: () => import('./client-template/client-template.module').then(m => m.ClientTemplateModule),
            },
            {
                path: 'core', loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
            }, {
                path: 'MD', loadChildren: () => import('./MD/MD.module').then(m => m.MasterDataModule),
            }, {
                path: 'RPT', loadChildren: () => import('./RPT/RPT.module').then(m => m.RPTModule),
            }, {
                path: 'TR', loadChildren: () => import('./TR/TR.module').then(m => m.TRModule),
            }, {
                path: 'cms', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
            }, {
                path: 'Plan', loadChildren: () => import('./Plan/Plan.module').then(m => m.PlanModule)
            }, {
                path: 'FIN', loadChildren: () => import('./FIN/FIN.module').then(m => m.FINModule)
            }, {
                path: 'EInvoice', loadChildren: () => import('./EInvoice/einvoice.module').then(m => m.EInvoiceModule)
            },
        ]
    },
    { path: 'error', component: AppErrorComponent },
    { path: 'access', component: AppAccessdeniedComponent },
    { path: 'notfound', component: AppNotfoundComponent },
    {
        path: 'login', component: LoginLayoutComponent,
        children: [
            { path: '', component: AppLoginComponent }
        ]
    },
    { path: '**', redirectTo: '/notfound' },
]
export const AppRoutes: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy });
