<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Loại hình doanh nghiệp' | translate }}" [(visible)]="isShow"
    [modal]="true" [styleClass]="'ag-modal force-full-width'" [style]="{'width': '660px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Mã loại hình' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" id="code" pInputText [(ngModel)]="itemDetail.code" formControlName="code"
                        placeholder="Định dạng text, 2 kí tự số">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class='label font-medium mt-25'>{{'Tên ngắn' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" id="shortName" pInputText [(ngModel)]="itemDetail.shortName"
                        formControlName="shortName" placeholder="Định dạng text, tối đa 10 kí tự">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class='label font-medium mt-25'>{{'Tên loại hình' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10 field">
                    <input type="text" id="name" pInputText [(ngModel)]="itemDetail.name" formControlName="name"
                        placeholder="Định dạng text, tối đa 30 kí tự">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class='label font-medium mt-25'>{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-4 md:col-4 field">
                    <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>