import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { VcfAvgApiService } from '../../Services/VcfAvg-api.service';
import {ProductApiService} from "../../Services/Product-api.service";

@Component({
    selector: 'app-vcfAvg-form',
    templateUrl: './VcfAvg-form.component.html',
    styleUrls: ['./VcfAvg-form.component.css']
})
export class VcfAvgFormComponent extends SecondPageEditBase implements OnInit {
    areaList = [
        { label: 'Miền Bắc', value: 1 },
        { label: 'Miền Trung', value: 2 },
        { label: 'Miền Nam', value: 3 },
    ]

    productList = []

    constructor(
        protected VcfAvgApiService: VcfAvgApiService,
        protected ProductApiService: ProductApiService,
        protected _injector: Injector,
    ) {
        super(VcfAvgApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            productId: new FormControl('', [Validators.required]),
            vcf: new FormControl('', [Validators.min(0), Validators.max(1)]),
            dateFrom: new FormControl(''),
            dateTo: new FormControl(''),
            area: new FormControl(''),
        });

    }

    ngOnInit() {
        this.ProductApiService.GetAll().then(rs => {
            if (rs.success) {
                this.productList = rs.data;
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.VcfAvgApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.itemDetail.dateFrom = new Date(this.itemDetail.dateFrom);
                    this.itemDetail.dateTo = new Date(this.itemDetail.dateTo);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

