<p-dialog #dialog header="Lọc nhóm đối tượng" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '960px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <form #formElement [formGroup]="formGroup">
        <div class="col-12 md:col-12">
            <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
            </validation-summary>
            <p-pickList [source]="unselectedCustomerGroups" [target]="selectedCustomerGroups" [responsive]="true"
                sourceHeader="Các nhóm khách hàng" targetHeader="Đã chọn" [sourceStyle]="{height: '30rem'}"
                [targetStyle]="{height: '30rem'}" filterBy="groupName" sourceFilterPlaceholder="Tìm kiếm theo tên"
                targetFilterPlaceholder="Tìm kiếm theo tên">
                <ng-template let-cGroup pTemplate="item">
                    <div>{{ cGroup.groupName }}</div>
                </ng-template>
            </p-pickList>
        </div>
    </form>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>