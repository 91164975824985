<p-dialog #dialog header="Cập nhật thông tin/{{ 'Hình thức kiểm kê' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '660px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="col-12 md:col-12 p-fluid formgrid grid">
                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'TABLEDATA.ShortName' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="shortname" pInputText [(ngModel)]="itemDetail.shortname"
                            formControlName="shortname">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'TABLEDATA.NAME' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <input type="text" id="name" pInputText [(ngModel)]="itemDetail.name" formControlName="name">
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'isHQ' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <p-dropdown formControlName="isHQ" id="isHQ" [filter]="true" [options]="binaryOptions"
                            [(ngModel)]="itemDetail.isHQ">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'isExcessInventory' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9">
                    <div class="field">
                        <p-dropdown formControlName="isExcessInventory" id="isExcessInventory" [filter]="true"
                            [options]="binaryOptions" [(ngModel)]="itemDetail.isExcessInventory">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-3" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2 mb-2"
            (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2 mb-2"
            (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2 mb-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>