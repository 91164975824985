import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { DepartmentApiService } from '../../Services/Department-api.service';

@Component({
    selector: 'app-department-form',
    templateUrl: './Department-form.component.html'
})
export class DepartmentFormComponent extends SecondPageEditBase implements OnInit {

    arrDepartment = [];
    arrBussinessType = [];
    arridParent = [];
    arridBranch = [];

    RegexCode = "^[0-9]{10}$";
    RegexName = "^[a-z0-9A-Z._%+-]{100}$";
    RegexNumber = "^[0-9]{1,12}$";
    Regextaxcode = "^[0-9]{10,14}$";
    RegexPhone = "^[0-9\.]{1,12}$";
    RegexEmail = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    Regexadd = "^[a-z0-9A-Z.,_-]{1,100}$";

    constructor(
        protected _DepartmentApiService: DepartmentApiService,
        protected _injector: Injector,
    ) {
        super(_DepartmentApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            name: new FormControl('', [Validators.required]),
            shortName: new FormControl(''),
            description: new FormControl('', ),
            parentId: new FormControl(''),
            status: new FormControl('', [Validators.required]),
        });

    }

    ngOnInit() {
        this._DepartmentApiService.getsInUse().then(rs=>{
            if(rs.success){
                this.convertDataToOptions(this.arridParent, rs.data, "code,name", "code")
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._DepartmentApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                    if (this.itemDetail.startDate) {
                        this.itemDetail.startDate = new Date(this.itemDetail.startDate);
                    }

                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

