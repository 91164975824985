import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { MqttService } from "ngx-mqtt";
import { TankApiService } from "../../Services/Tank-api.service";
import { NozzleData, Pump, Tank, TankData } from "./Scada-interfaces";
import { environment } from '../../../../environments/environment';
import { ProductApiService } from "../../Services/Product-api.service";
import { PumpStationApiService } from '../../Services/PumpStation-api.service';

@Component({
    selector: 'app-scada-index',
    templateUrl: './Scada-index.component.html',
    styleUrls: ['./Scada-index.component.css']
})
export class ScadaIndexComponent extends SecondPageIndexBase implements OnInit {
    public tanks: Tank[] = [];
    public pumps: Pump[] = [];
    public tankData: Map<string, TankData> = new Map();
    public pumpData: Map<string, NozzleData> = new Map();
    public product: Map<number, any> = new Map();
    public disableTanks = false;
    public disablePumps = false;
    private static textDecoder = new TextDecoder();
    scadaViewOptions: any[];

    constructor(
        protected _TankApiService: TankApiService,
        protected _PumpStationApiService: PumpStationApiService,
        protected _ProductApiService: ProductApiService,
        protected _injector: Injector,
        public app: AppComponent,
        private _MqttService: MqttService
    ) {
        super(_TankApiService, _injector);
    }

    checkTankAuto(tank: any) {
        return (tank.status > 0 && tank.isAuto == 1);
    }

    checkPumpAuto(pump: any) {
        return (pump.status > 0 && pump.isAuto == 1);
    }

    ngOnInit() {
        this.scadaViewOptions = [
            { icon: 'pi pi-desktop', justify: 'Center', value: false },
            { icon: 'pi pi-list', justify: 'Center', value: true }
        ];

        this._TankApiService.GetAll().then(res => {
            this.tanks = res.data.filter(this.checkTankAuto);
            let topics = new Set(this.tanks.map(t => t.iaDeviceId));
            for (let topic of topics) {
                this._MqttService.observe(environment.mqttDomain.tankTopic.replace(/\(@DeviceId\)/g, topic)).subscribe(m => {
                    let msg = JSON.parse(ScadaIndexComponent.textDecoder.decode(m.payload)) as TankData;
                    let key = msg.DeviceId + msg.TankId.toString();
                    let newData = new Map(this.tankData);
                    newData.set(key, msg);
                    this.tankData = newData;
                })
            }

            let products = new Set(this.tanks.map(t => t.productId));
            let productDataPromise = [...products].map(async p => {
                let r = await this._ProductApiService.getDetail(p).then(rs => {
                    if (rs.success) return rs.data;
                });
                return <const>[p, r];
            })
            Promise.all(productDataPromise)
                .then(res => {
                    let a = new Map(res);
                    this.product = a;
                })
        })

        this._PumpStationApiService.GetAll().then(res => {
            this.pumps = res.data.filter(this.checkPumpAuto);
            let topics = new Set(this.pumps.map(t => t.iADeviceId));
            for (let topic of topics) {
                this._MqttService.observe(environment.mqttDomain.nozzleTopic.replace(/\@DeviceId\)/g, topic)).subscribe(m => {
                    let msg = JSON.parse(ScadaIndexComponent.textDecoder.decode(m.payload)) as NozzleData;
                    let key = msg.DeviceId + msg.NozzleId.toString();
                    let newData = new Map(this.pumpData);
                    newData.set(key, msg);
                    this.pumpData = newData;
                })
            }
        })
    }
}