
export class CoreUser {
    userId: number;
    userName: string;
    displayName: string;
    fullName: string;
    email: string;
    issuperuser: string;
    permissions: any;
    scope: any;
    avatar: string;
    position: string;
    role: number;
    gender: number;
    branchId: number;
    startPageId: number;
    typeId: number;
    constructor() {
    }

    isSuperUser(): boolean {
        return this.issuperuser === 'True';
    }
}
