import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { BusinessTypeApiService } from '../../Services/BusinessType-api.service';

@Component({
    selector: 'app-businessType-form',
    templateUrl: './BusinessType-form.component.html',
    styleUrls: ['./BusinessType-form.component.css']
})
export class BusinessTypeFormComponent extends SecondPageEditBase implements OnInit {

    arrCustomerGroup = [];
    arrBussinessType = [];
    arridParent = [];
    arridBranch = [];

    RegexCode = "^[0-9]{2}$";
    RegexName = "^[a-z0-9A-Z._%+-]{100}$";
    RegexNumber = "^[0-9]{1,12}$";
    Regextaxcode = "^[0-9]{10,14}$";
    RegexPhone = "^[0-9\.]{1,12}$";
    RegexEmail = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    Regexadd = "^[a-z0-9A-Z.,_-]{1,100}$";

    constructor(
        protected _BusinessTypeApiService: BusinessTypeApiService,
        protected _injector: Injector,
    ) {
        super(_BusinessTypeApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            name: new FormControl('', [Validators.required]),
            shortName: new FormControl(''), 
            parentId: new FormControl(''), 
            status: new FormControl('', [Validators.required]),
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._BusinessTypeApiService.getDetail(id).then(rs => {
                this.submitting = false; 
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                    if (this.itemDetail.startDate) {
                        this.itemDetail.startDate = new Date(this.itemDetail.startDate);
                    }

                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

