<div class="p-fluid formgrid grid">
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Mã' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="text" id="trCode" pInputText [(ngModel)]="itemDetail.trCode">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'TrDirection' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="trDirections" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" optionLabel="label" optionValue="value" [(ngModel)]="itemDetail.trDirection"
                [filter]="true" filterBy="label,value">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'TABLEDATA.NAME' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="text" id="name" pInputText [(ngModel)]="itemDetail.trName">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Nhóm' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="trGroupList" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" optionLabel="trGroupName" optionValue="id" [(ngModel)]="itemDetail.idTrGroup"
                [filter]="true" filterBy="trGroupCode,trGroupName">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Tr Cha' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" optionLabel="label" optionValue="value" [(ngModel)]="itemDetail.parentReq"
                [filter]="true" filterBy="label,value">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Loại Tr cha (,)' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="text" id="parentType" pInputText [(ngModel)]="itemDetail.parentType">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium">{{'Giới hạn hàng hóa theo TR cha' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.res3">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium">{{'Giới hạn số lượng theo TR cha' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.res4">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Parent Status (,)' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="text" id="parentStatus" pInputText [(ngModel)]="itemDetail.parentStatus">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'shortName' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="text" id="shortName" pInputText [(ngModel)]="itemDetail.shortName">
        </div>
    </div>

    <!--div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Chọn TR cha' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div-- class="col-8 md:col-4">
        <div class="field">
            <p-dropdown formControlName="parentSelectBox" [options]="requirements"
                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                [(ngModel)]="itemDetail.parentSelectBox">
            </p-dropdown>
        </div>
    </div-->
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'PayDirection' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="directions" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.payDirection">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'ParentDirection' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="directions" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.parentDirection">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Khách hàng' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.customerReq">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Hợp đồng' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.contractReq">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium mt-25">{{'Nguồn hàng' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="productSourceList" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.productsourceReq">
            </p-dropdown>
        </div>
    </div>

    <!--    order -->

    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium">{{'Tham chiếu đơn đặt hàng' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                appendTo="body" [(ngModel)]="itemDetail.orderReq">
            </p-dropdown>
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium">{{'Loại đơn đặt hàng tham chiếu (,)' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="text" id="orderType" pInputText [(ngModel)]="itemDetail.orderType">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium">{{'Trạng thái đơn đặt hàng' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <input type="text" id="orderStatus" pInputText [(ngModel)]="itemDetail.orderStatus">
        </div>
    </div>
    <div class="col-4 md:col-2">
        <div class="field">
            <div class="label font-medium">{{'Trạng thái đơn nhập kho' | translate}}
                <span style="color:red;">*</span>
            </div>
        </div>
    </div>
    <div class="col-8 md:col-4">
        <div class="field">
            <p-dropdown [options]="requirements" [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1"
                        appendTo="body" [(ngModel)]="itemDetail.warehouseStatusReq">
            </p-dropdown>
        </div>
    </div>
</div>
