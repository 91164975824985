import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { CustomerGuaranteeApiService } from '../../Services/CustomerGuarantee-api.service';
import {CustomerApiService} from "../../Services/Customer-api.service";
import {BankApiService} from "../../Services/Bank-api.service";
import {ContractApiService} from "../../Services/Contract-api.service";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-customerGuarantee-form',
    templateUrl: './CustomerGuarantee-form.component.html',
    styleUrls: ['./CustomerGuarantee-form.component.css']
})
export class CustomerGuaranteeFormComponent extends SecondPageEditBase implements OnInit {
    customerList = [];
    bankList = [];
    contractList = [];
    typeList = [{value:10, label:"BL có điều kiện, hủy ngang"},{value:11, label:"BL vô điều kiện, không hủy ngang"},{value:19,label:"Khác"}];
    constructor(
        protected CustomerGuaranteeApiService: CustomerGuaranteeApiService,
        protected customerApiService: CustomerApiService,
        protected bankApiService: BankApiService,
        protected contractApiService: ContractApiService,
        protected _injector: Injector,
    ) {
        super(CustomerGuaranteeApiService, _injector);

        this.formGroup = new FormGroup({
            code: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            type: new FormControl(''),
            customerId: new FormControl(''),
            bankId: new FormControl(''),
            climit: new FormControl(''),
            dlimit: new FormControl(''),
            contractId: new FormControl(''),
            personSign: new FormControl('', [Validators.required]),
            uPosition: new FormControl('', [Validators.required]),
            dateFrom: new FormControl(''),
            dateTo: new FormControl(''),
            isAppraisal: new FormControl(''),
            status: new FormControl('')
        });

    }

    ngOnInit() {
        this.bankApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.bankList = rs.data;
            }
        })
        this.customerApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.customerList = rs.data;
            }
        })
        this.contractApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.contractList = rs.data.map(r => {
                    let pipe = new DatePipe('en-US');
                    r.display = `${r.contractCode} - Hiệu lực ${pipe.transform(r.dateFrom, 'dd/MM/yyyy')}`;
                    return r;
                })
            }
        })
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.CustomerGuaranteeApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.itemDetail.dateFrom = new Date(rs.data.dateFrom);
                    this.itemDetail.dateTo = new Date(rs.data.dateTo);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

