import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { DiscountPriceApiService } from '../../Services/DiscountPrice-api.service';
import {ProductApiService} from "../../Services/Product-api.service";
import {CustomerApiService} from "../../Services/Customer-api.service";
import {WarehouseApiService} from "../../Services/Warehouse-api.service";
import {LocationApiService} from "../../Services/Location-api.service";
import {DiscountGroupApiService} from "../../Services/DiscountGroup-api.service";

@Component({
    selector: 'app-discount-form',
    templateUrl: './Discount-form.component.html',
    styleUrls: ['./Discount-form.component.css']
})
export class DiscountFormComponent extends SecondPageEditBase implements OnInit {
    productList = [{ label: '[Tất cả]', value: 0 }];
    customerList = [{ label: '[Tất cả]', value: 0 }];
    warehouseList = [{ label: '[Tất cả]', value: 0 }]
    locationList = [{ label: '[Tất cả]', value: 0 }]
    discountGroupList = [{ label: '[Tất cả]', value: 0 }]

    static discountTier = [
        { label: 'Chiết khấu vòng 1', value: 1 },
        { label: 'Chiết khấu vòng 2', value: 2 },
        { label: 'Chiết khấu vòng 3', value: 3 },
    ]

    get discountTier() { return DiscountFormComponent.discountTier }

    constructor(
        protected DiscountPriceApiService: DiscountPriceApiService,
        protected DiscountGroupApiService: DiscountGroupApiService,
        protected ProductApiService: ProductApiService,
        protected CustomerApiService: CustomerApiService,
        protected WarehouseApiService: WarehouseApiService,
        protected LocationApiService: LocationApiService,
        protected _injector: Injector,
    ) {
        super(DiscountPriceApiService, _injector);

        this.formGroup = new FormGroup({
            "periodId": new FormControl,
            "documentCode": new FormControl('', [Validators.required]),
            "discountGroupId": new FormControl,
            "customerId": new FormControl('', [Validators.required]),
            "warehouseId": new FormControl,
            "destinationId": new FormControl,
            "vehicleClassId": new FormControl,
            "productId": new FormControl,
            "discountTierId": new FormControl,
            "validFrom": new FormControl('', [Validators.required]),
            "discount": new FormControl('', [Validators.required]),
            "status": new FormControl,
        });

    }
    ngOnInit() {
        this.CustomerApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.customerList, rs.data, "customerCode,customerName", "customerCode");
            }
        })
        this.ProductApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.productList, rs.data, "productCode,productName", "productCode");
            }
        })
        this.WarehouseApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.warehouseList, rs.data, "warehouseCode,warehouseName", "warehouseCode");
            }
        })
        this.LocationApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.locationList, rs.data, "code,name", "code");
            }
        })
        this.DiscountGroupApiService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.discountGroupList, rs.data, "groupCode,groupName", "groupCode");
            }
        })
    }
    onShowPopup(id: number) {
        console.log(id);
        this.submitting = true;
        if (id > 0) {
            this.DiscountPriceApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.itemDetail.validFrom = new Date(rs.data.validFrom);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1, validFrom:new Date() };
            this.submitting = false;
        }
    }
}