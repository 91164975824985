import { Routes, RouterModule } from '@angular/router';
import { ReportIndexComponent } from './Report/index/Report-index.component';
import { ReportFormComponent } from './Report/form/Report-form.component';
import { ReportViewerComponent } from './ReportViewer/ReportViewer.component';
import { FilterConditionIndexComponent } from './FilterCondition/index/FilterCondition-index.component';
import { DocViewerComponent } from './DocViewer/DocViewer.component';
import { MasterDataIndexComponent } from '../MD/MasterData/index/MasterData-index.component';
import { ReportGroupIndexComponent } from './ReportGroup/index/ReportGroup-index.component';
const routes: Routes = [
  {
    path: 'Report',
    component: ReportIndexComponent,
  }, {
    path: 'ReportForm/:Id',
    component: ReportFormComponent,
  }, {
    path: 'ReportGroup',
    component: ReportGroupIndexComponent,
  }, {
    path: 'MasterData',
    component: MasterDataIndexComponent,
  }, {
    path: 'FilterCondition/:id',
    component: FilterConditionIndexComponent
  }, {
    path: 'ReportViewer/:ReportCode',
    component: ReportViewerComponent
  }, {
    path: 'ReportViewer/:ReportCode/:Params',
    component: ReportViewerComponent
  }, {
    path: 'DocViewer/:ReportCode/:TrId',
    component: DocViewerComponent
  }
];

export const ReportRoutes = RouterModule.forChild(routes);
