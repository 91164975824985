import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { TaxPolicyFormComponent } from '../form/taxPolicy-form.component';
import { TaxPolicyApiService } from '../../Services/TaxPolicy-api.service';

@Component({
    selector: 'app-taxPolicy-index',
    templateUrl: './taxPolicy-index.component.html',
    styleUrls: ['./taxPolicy-index.component.css']
})
export class TaxPolicyIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: TaxPolicyFormComponent;
    tabMenuItems = [];
    constructor(
        protected _taxPolicyApiService: TaxPolicyApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_taxPolicyApiService, _injector);
    }
    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Tên chức năng'), icon: 'pi pi-book', routerLink: '../client-template'},
        ];
        this.cols = [
            { field: 'validFrom', header: this._translateService.instant('TABLEDATA.validFrom'), visible: true, width: 'auto', sort: true, style:'text-align:center', dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'productName', header: this._translateService.instant('TABLEDATA.PRODUCT'), visible: true, width: 'auto', },
            { field: 'importTax', header: this._translateService.instant('TAX.IMPORTTAX'), visible: true, width: 'auto',  style:'text-align:right', textAfter:' %'},
            { field: 'specialTax', header: this._translateService.instant('TAX.SPECIALTAX'), visible: true, width: 'auto',  style:'text-align:right', textAfter:' %'},
            { field: 'importVAT', header: this._translateService.instant('TAX.IMPORTVAT'), visible: true, width: 'auto',  style:'text-align:right', textAfter:' %'},
            { field: 'vat', header: this._translateService.instant('TAX.VAT'), visible: true, width: 'auto',  style:'text-align:right', textAfter:' %'},
            { field: 'pxd', header: this._translateService.instant('TAX.PXD'), visible: true, width: 'auto',  style:'text-align:right', numberFormat: '1.0-0'},
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true,  style:'text-align:right'},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
    }

    edit(id?:number) {
        console.log(id);
        if(id!=null){
            this.pEdit.showPopup(id);
        }else{
            this.pEdit.showPopup(this.selectedItems[0].id);
        }
    }

    add() {
        this.pEdit.showPopup();
    }
    getData() {
        this.isLoading = true;
        this.selectedItems = [];
        this.ids = [];
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,
            productId: 0,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderColl: this.orderCol,
            isDesc: this.isDesc
        };
        this._baseService.Find(model)
            .then(response => {
                this.dataSource = response.data;

                if (response.totalRecord || response.totalRecord === 0) {
                    this.totalRecord = response.totalRecord;
                }

                this.afterGetData();
                this.isLoading = false;
            }, error => {
                this._notifierService.showHttpUnknowError();
                this.isLoading = false;
            });
    }

}
