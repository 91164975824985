<p-dialog #dialog header="Nhóm phương thức N/X" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '960px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <form #formElement [formGroup]="formGroup">
        <div class="col-12 md:col-12">
            <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
            </validation-summary>
        </div>
        <div class="col-12 md:col-12 p-fluid formgrid grid">
            <div class="col-4 md:col-2 field">
                <div class="label font-medium mt-25">{{'Mã nhóm' | translate}}
                    <span style="color:red;">*</span>
                </div>
            </div>
            <div class="col-8 md:col-4 field">
                <input type="text" id="trGroupCode" pInputText [(ngModel)]="itemDetail.trGroupCode"
                    formControlName="trGroupCode" placeholder="2 kí tự số">
            </div>
            <div class="col-4 md:col-2 field">
                <div class="label font-medium mt-25">{{'Tên ngắn' | translate}}
                    <span style="color:red;">*</span>
                </div>
            </div>
            <div class="col-8 md:col-4 field">
                <input type="text" id="shortName" pInputText [(ngModel)]="itemDetail.shortName"
                    formControlName="shortName" placeholder="(lớn nhất 10 kí tự chữ và số, ký tự đặc biệt ._- )">
            </div>
            <div class="col-4 md:col-2 field">
                <div class="label font-medium mt-25">{{'Tên nhóm' | translate}}
                    <span style="color:red;">*</span>
                </div>
            </div>
            <div class="col-8 md:col-10 field">
                <input type="text" id="trGroupName" pInputText [(ngModel)]="itemDetail.trGroupName"
                    formControlName="trGroupName" placeholder="lớn nhất 30 kí tự chữ và số, ký tự đặc biệt">
            </div>
            <div class="col-4 md:col-2 field">
                <div class="label font-medium mt-25">{{'Mô tả' | translate}}
                </div>
            </div>
            <div class="col-8 md:col-10 field">
                <textarea pInputTextarea [(ngModel)]="itemDetail.description" formControlName="description"
                    placeholder="Mô tả"></textarea>
            </div>
        </div>
    </form>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>