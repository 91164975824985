import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { BankFormComponent } from '../form/Bank-form.component';
import { BankApiService } from '../../Services/Bank-api.service';

@Component({
    selector: 'app-bank-index',
    templateUrl: './Bank-index.component.html',
    styleUrls: ['./Bank-index.component.css']
})
export class BankIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: BankFormComponent;
    tabMenuItems = [];
    constructor(
        protected _BankApiService: BankApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_BankApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Ngân hàng'), icon: 'pi pi-dollar', routerLink: '../Bank'}
        ];
        this.cols = [
            { field: 'code', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'name', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'address', header: this._translateService.instant('Địa chỉ'), visible: true, width: 'auto', sort: true,},
            { field: 'tel', header: this._translateService.instant('Điện thoại'), visible: true, width: 'auto', sort: true,},
            { field: 'fax', header: this._translateService.instant('Fax'), visible: true, width: 'auto', sort: true,},
            { field: 'contactPers', header: this._translateService.instant('Người liên hệ'), visible: true, width: 'auto', sort: true,},
            { field: 'swift', header: this._translateService.instant('SWIFT'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this._baseService.getsBySearch(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
