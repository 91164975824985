import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { VehicleClassFormComponent } from '../form/vehicleClass-form.component';
import { VehicleClassApiService } from '../../Services/vehicle-class-api.service';

@Component({
  selector: 'app-vehicleClass-index',
  templateUrl: './vehicleClass-index.component.html',
  styleUrls: ['./vehicleClass-index.component.scss']
})
export class VehicleClassIndexComponent extends SecondPageIndexBase implements OnInit {
  @ViewChild('pEdit') pEdit: VehicleClassFormComponent;
  tabMenuItems = [];
  constructor(
    protected _VehicleClassApiService: VehicleClassApiService,
    protected _injector: Injector,
    public app: AppComponent,
  ) {
    super(_VehicleClassApiService, _injector);
  }

  ngOnInit() {
    this.tabMenuItems = [
      { label: this._translateService.instant('Phương tiện V/C'), icon: 'pi pi-book', routerLink: '../Vehicle' },
      { label: this._translateService.instant('Loại phương tiện'), icon: 'pi pi-book', routerLink: '../VehicleType' },
      { label: this._translateService.instant('Phương thức V/C'), icon: 'pi pi-book', routerLink: '../VehicleClass' },
    ];
    this.cols = [
      { field: 'classCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true, },
      { field: 'className', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true, },
      { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
      { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
      { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
    ];

    this.getData();
  }
  edit(id?: number) {
    this.pEdit.showPopup(id);
  }

  add() {
    this.pEdit.showPopup();
  }
}