<p-dialog #dialog header="{{ 'Log hoạt động' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12 p-fluid formgrid grid">
        <div class="data-view-area">
            <div class="data-view-area__main-block">
                <div class="data-view-main-action-area">
                    <div class="data-view-main-action-area__left-block" style="flex: 1; min-width: 110px">
                    </div>
                    <div class="data-view-main-action-area__right-block">
                        <div class="mb-1">{{(pageIndex -1) * pageSize + 1}} - {{pageIndex * pageSize}}
                            {{'LIST.OFF' | translate}} {{totalRecord}}</div>

                        <button pButton pRipple type="button" icon="pi pi-angle-left" (click)="onPrePage()"
                            [disabled]="pageIndex == 1" class="p-button-rounded p-button-text mr-2 mb-2"></button>

                        <button pButton pRipple type="button" icon="pi pi-angle-right" (click)="onNextPage()"
                            [disabled]="pageIndex > totalRecord / pageSize"
                            class="p-button-rounded p-button-text mr-2 mb-2"></button>
                    </div>
                </div>
                <p-timeline [value]="dataSource" align="alternate" styleClass="customized-timeline">
                    <ng-template pTemplate="content" let-item>
                        <p-card styleClass="mb-3" [header]="item.modifiedByName"
                            [subheader]="item.modified | date : 'dd/MM/yyyy HH:mm'">
                            <p style="word-break: break-all;">
                                <ngx-json-viewer [json]="item.objectNewData" [depth]="3"></ngx-json-viewer>
                            </p>
                        </p-card>
                    </ng-template>
                </p-timeline>
            </div>
        </div>
    </div>
    <p-footer>

    </p-footer>
</p-dialog>
