<script src="price-setting.component.ts"></script>
<div class="card p-0">
    <p-tabMenu [model]="tabMenuItems"></p-tabMenu>
    <p-table [value]="pumpStationList">
        <ng-template pTemplate="caption">
            <div *ngIf="productArr" class="formgrid grid pt-2">
                <div class="col-6 md:col-2 lg:col-2 p-fluid text-center">
                    Mặt hàng
                </div>
                <div class="col-6 md:col-2 lg:col-2 p-fluid text-center">
                    Giá hiện tại
                </div>
                <div class="col-6 md:col-2 lg:col-2 p-fluid text-center">
                    Giá mới
                </div>
                <div class="col-8 md:col-4 lg:col-4 p-fluid text-center">
                    Áp dụng từ
                </div>
            </div>
            <ng-container *ngFor="let product of productArr">
                <div class="formgrid grid pt-3">
                    <div class="col-6 md:col-2 lg:col-2 p-fluid pt-2 text-center">
                        {{ product.productName }}
                    </div>
                    <div class="col-6 md:col-2 lg:col-2 p-fluid">
                        <p-inputNumber [ngModel]="product.unitPrice" disabled="true"
                                       styleClass="p-inputtext-sm" inputStyleClass="text-right"></p-inputNumber>
                    </div>
                    <div class="col-6 md:col-2 lg:col-2 p-fluid">
                        <p-inputNumber [ngModel]="product.newPrice" min="0" styleClass="p-inputtext-sm" inputStyleClass="text-right" inputId="integeronly"></p-inputNumber>
                    </div>
                    <div class="col-8 md:col-4 lg:col-4 p-fluid">
                        <p-calendar [ngModel]="product.newDate" [placeholder]="'dd/MM/yyyy HH:mm'" [showIcon]="true" [showTime]="true"
                                    styleClass="p-inputtext-sm"></p-calendar>
                    </div>
                </div>
            </ng-container>
            <div class="flex flex-column md:flex-row table-header pt-3 gap-2">
                <button (click)="applyPrice()" pButton pRipple>
                    Áp giá tự động
                </button>
                <button (click)="viewHistory = !viewHistory" pButton pRipple>
                    View giá áp
                </button>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Mã vòi bơm</th>
                <th>Mã vòi TDH</th>
                <th>Tên vòi bơm</th>
                <th>Bể chứa</th>
                <th>Mã hàng</th>
                <th>Tên hàng</th>
                <th>Giá bán</th>
                <th>Ngày hiệu lực</th>
            </tr>
        </ng-template>
        <ng-template let-data pTemplate="body">
            <tr>
                <td>
                    {{ data.pumpCode }}
                </td>
                <td>
                    {{ data.iaNozzleId }}
                </td>
                <td>
                    {{ data.pumpName }}
                </td>
                <td>
                    {{ getTank(data.tankId)?.tankName }}
                </td>
                <td>
                    {{ getProduct(data.productId)?.productCode }}
                </td>
                <td>
                    {{ getProduct(data.productId)?.productName }}
                </td>
                <td class="text-right">
                    {{ data.unitPrice | number: '1.0-2' }}
                </td>
                <td class="text-center">
                    {{ data.validFrom | date: 'dd/MM/yyyy HH:mm' }}
                </td>
            </tr>
        </ng-template>
    </p-table>


    <ng-container *ngIf="viewHistory">
        <p-divider align="left" styleClass="px-0" type="solid">
            <b>Danh sách áp giá cột bơm</b>
        </p-divider>
        <br/>
        <p-table [value]="pumpPriceHistoryGroup">
            <ng-template pTemplate="header">
                <tr>
                    <th>STT</th>
                    <th>Mã vòi bơm</th>
                    <th>Mã vòi TDH</th>
                    <th>Tên vòi bơm</th>
                    <th>Bể chứa</th>
                    <th>Mã hàng</th>
                    <th>Tên hàng</th>
                    <th>Giá bán</th>
                    <!--                <th>Ngày hiệu lực</th>-->
                    <th>Tình trạng áp</th>
                    <th>Thời gian áp</th>
                </tr>
            </ng-template>
            <ng-template let-row pTemplate="body">
                <tr class="surface-200">
                    <td></td>
                    <td [colSpan]="9">
                        <b>
                            Ngày hiệu lực : {{ row[0] | date:'dd/MM/yyyy HH:mm' }}
                        </b>
                    </td>
                </tr>
                <tr *ngFor="let data of row[1]">
                    <td>{{ data.index }}</td>
                    <td>
                        {{ getPump(data.pumpId)?.pumpCode }}
                    </td>
                    <td>
                        {{ getPump(data.pumpId)?.iaNozzleId }}
                    </td>
                    <td>
                        {{ getPump(data.pumpId)?.pumpName }}
                    </td>
                    <td>
                        {{ getTank(getPump(data.pumpId)?.tankId)?.tankName }}
                    </td>
                    <td>
                        {{ getProduct(getPump(data.pumpId)?.productId)?.productCode }}
                    </td>
                    <td>
                        {{ getProduct(getPump(data.pumpId)?.productId)?.productName }}
                    </td>
                    <td class="text-right">
                        {{ data.price | number: '1.0-2' }}
                    </td>
                    <!--                <td class="text-center">-->
                    <!--                    {{ data.priceTime | date:'dd/MM/yyyy HH:mm' }}-->
                    <!--                </td>-->
                    <td class="text-center">
                        <p-tag [severity]="data.isApGia ? 'success' : 'primary'">
                            {{ data.isApGia ? 'Đã áp giá' : 'Chưa áp giá' }}
                        </p-tag>
                    </td>
                    <td>

                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</div>
