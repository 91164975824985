import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CostPriceType, SecondPageEditBase } from 'nabit-shared';
import { ContractApiService } from '../../Services/Contract-api.service';
import { CustomerApiService } from "../../Services/Customer-api.service";
import { DebtTypeApiService } from "../../Services/DebtType-api.service";
import { PaymentDebtApiService } from "../../Services/PaymentDebt-api.service";
import { ContractTypeApiService } from "../../Services/ContractType-api.service";
import { SalesChanelApiService } from "../../Services/SalesChanel-api.service";
import { DeliveryTypeApiService } from "../../Services/DeliveryType-api.service";
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
    selector: 'app-contract-form',
    templateUrl: './Contract-form.component.html',
    styleUrls: ['./Contract-form.component.css']
})
export class ContractFormComponent extends SecondPageEditBase implements OnInit {
    customerList = [];
    debtTypes = [];
    paymentDebt = [];
    contractTypeList = [];
    salesChannelList = [];
    deliveryTypeList = [];

    @Input() isImport = false;

    costPriceTypes = [
        { id: CostPriceType.MOPS, label: 'MOPS' },
        { id: CostPriceType.Trigger, label: 'Trigger' },
        { id: CostPriceType.Other, label: 'Other' },
    ]

    quanLyHanMuc = [
        { label: 'Lấy hóa đơn', value: 1 },
        { label: 'Chứng từ', value: 0 }
    ]

    binaryOptions = [
        { label: 'Có', value: 1 },
        { label: 'Không', value: 0 },
    ]


    constructor(
        private _route: ActivatedRoute,
        protected ContractApiService: ContractApiService,
        private CustomerApiService: CustomerApiService,
        private DebtTypeApiService: DebtTypeApiService,
        private PaymentDebtApiService: PaymentDebtApiService,
        private contractTypeApiService: ContractTypeApiService,
        private salesChannelApiService: SalesChanelApiService,
        private deliveryTypeApiService: DeliveryTypeApiService,
        protected _injector: Injector,
    ) {
        super(ContractApiService, _injector);

        this.formGroup = new FormGroup({
            contractCode: new FormControl('', [Validators.required]),
            contractName: new FormControl('', [Validators.required]),
            customerId: new FormControl('', [Validators.required]),
            contractTypeId: new FormControl('', [Validators.required]),
            qtyMonthAvg: new FormControl(''),
            salesChannelId: new FormControl(),
            contactPerson: new FormControl(''),
            dateFrom: new FormControl('', [Validators.required]),
            dateTo: new FormControl('', [Validators.required]),
            description: new FormControl(''),
            circulatingState: new FormControl('',),
            creditPeriod: new FormControl(''),
            creditLimit: new FormControl(''),
            creditLimitExtend: new FormControl(''),
            creditLimitPos: new FormControl(''),
            debtType: new FormControl(),
            paymentDebt: new FormControl(),
            isQuanLyHanMuc: new FormControl(),
            isQuaTru: new FormControl(),
            isChuyenThang: new FormControl(),
            status: new FormControl('', [Validators.required]),
            deliveryTypeId: new FormControl(),
            soNgayDenHanTT: new FormControl(0),
            costPriceTypeId: new FormControl(this.costPriceTypes[0].id),
            includeBillDate: new FormControl(0)
        });
    }

    refreshRoute(s: ParamMap) {
        this.itemDetail.id = +(s.get("Id") || 0);
    }

    ngOnInit() {
        this.CustomerApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    let { data } = rs;
                    data = data.map(c => {
                        c.displayName = c.customerCode + ' - ' + c.customerName;
                        return c;
                    })
                    this.customerList = data;
                }
            })
        this.DebtTypeApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.debtTypes = rs.data;
                }
            })
        this.PaymentDebtApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.paymentDebt = rs.data;
                }
            })
        this.contractTypeApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.contractTypeList = rs.data;
                }
            })
        this.salesChannelApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.salesChannelList = rs.data;
                }
            })
        this.deliveryTypeApiService.getsInUse()
            .then(rs => {
                if (rs.success) {
                    this.deliveryTypeList = rs.data;
                }
            })
        this.refreshRoute(this._route.snapshot.paramMap);
        this.getData();
    }

    onBeforeSave(): boolean | void {
        if (!this.itemDetail.listContractDetails) {
            this.itemDetail.listContractDetails = [];
        }
        if (!this.itemDetail.listContractExtendPay) {
            this.itemDetail.listContractExtendPay = [];
        }
    }

    getData() {
        this.submitting = true;
        if (this.itemDetail.id > 0) {
            this.ContractApiService.getDetail(this.itemDetail.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.itemDetail.dateFrom = new Date(rs.data.dateFrom);
                    if (this.itemDetail.dateTo) {
                        this.itemDetail.dateTo = new Date(rs.data.dateTo);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1, dateFrom: new Date(), isImportContract: +this.isImport };
            if (!this.isImport) {
                this.itemDetail.dateTo = new Date();
            }
            this.submitting = false;
        }

        this.formGroup.controls.dateTo.setValidators(this.isImport ? [] : [Validators.required]);
    }

    save() {
        if (!(this.itemDetail.costPriceTypeId > 0)) {
            this.itemDetail.costPriceTypeId = this.costPriceTypes[0].id;
        }
        super.save();
    }

    cancel() {
        history.back();
    }
}

