import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { Console } from 'console';
// @ts-ignore
import { ProductApiService } from '../../Services/Product-api.service';
import { ProductGroupApiService } from '../../Services/ProductGroup-api.service';


@Component({
    selector: 'app-product-form',
    templateUrl: './Product-form.component.html',
    styleUrls: ['./Product-form.component.css']
})
export class ProductFormComponent extends SecondPageEditBase implements OnInit {

    arrProductGroup = [];
    arrStandardQty = [];
    arrPackage = [];
    arrQuality = [];
    arrCorrectionTable = [];

    checked: boolean = false;
    RegexCode = "^[a-z0-9A-Z._-]{1,7}$";
    RegexName = "^[a-z0-9A-Z._-]{1,100}$";
    Regexnumber = "^[0-9]{1,100}$";

    constructor(
        protected _productApiService: ProductApiService,
        protected _injector: Injector,
        private _productGroupAPIService: ProductGroupApiService,
    ) {
        super(_productApiService, _injector);

        this.formGroup = new FormGroup({
            productCode: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            productName: new FormControl('', [Validators.required]),
            productGroup: new FormControl('', [Validators.required]),
            shortName: new FormControl('', [Validators.maxLength(100)]),
            package: new FormControl(''),
            standardQty: new FormControl(''),
            unit: new FormControl(''),
            unitInPackage: new FormControl(''),

            correctionTable: new FormControl(''),

            lossIn_Percent: new FormControl(''),
            lossOut_Percent: new FormControl(''),
            lossStore_Percent: new FormControl(''),
            imagesURL: new FormControl(''),
            oddUnit: new FormControl(''),
            oddUnitInPackage: new FormControl(''),
            quality: new FormControl(''),
            bonusFactor: new FormControl(''),
            useQty2: new FormControl(''),
            isPetrol: new FormControl(''),

            status: new FormControl(''),
            description: new FormControl('', [Validators.maxLength(100)]),
        });

        this.arrCorrectionTable = [
            { label: 'XDS', value: "1" },
            { label: 'DMN', value: "2" },
        ];

        this.arrQuality = [
            { label: 'Loại A', value: 1 },
        ];

        this.arrStandardQty = [
            { label: 'L15', value: 1 },
            { label: 'KG', value: 2 },
        ];

        this.arrPackage = [
            { label: 'Lit', value: 1 },
            { label: 'Lit 15', value: 2 },
            { label: 'KG', value: 3 },
            { label: 'Chiếc', value: 4 },
            { label: 'Hộp', value: 5 },
            { label: 'Can', value: 6 },
            { label: 'Lon', value: 7 },
            { label: 'Thùng', value: 8 },
            { label: 'Phuy', value: 9 },
            { label: 'Tấn', value: 10 },
            { label: 'Xô', value: 11 },
            { label: 'Phi', value: 12 },
            { label: 'Tuýp', value: 13 },
            { label: 'Gói', value: 14 },
            { label: 'Đồng', value: 15 },
            { label: 'Ngày', value: 16 },
            { label: 'Tháng', value: 17 },
            { label: 'Năm', value: 18 },
            { label: 'Chai', value: 19 },
            { label: 'Cái', value: 20 },
            { label: 'Bộ', value: 21 },
            { label: 'Mét', value: 22 },
            { label: 'Bình', value: 23 },
            { label: 'Lít', value: 24 },
        ];
    }

    ngOnInit() {
    }

    async onShowPopup(id: number) {

        if (this.arrProductGroup.length == 0) {
            await this.getsProductGroup();
        }

        this.submitting = true;
        if (id > 0) {
            this._productApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsProductGroup() {
        this.arrProductGroup = [];
        let model = {
            "keyword": "",
            "status": 1,
            "pageIndex": 1,
            "pageSize": 999999,
            "orderCol": "",
            "isDesc": true
        }
        await this._productGroupAPIService.Find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProductGroup, rs.data, 'productGroupName');
            }
        })
    }


}

