import { SecondPageEditBase } from "nabit-shared";
import { Component, Injector, OnInit } from "@angular/core";
import { OrgUnitSettingApiService } from "../../Services/OrgUnitSetting-api.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-orgUnitSetting-form",
    templateUrl: "./OrgUnitSetting-form.component.html",
    styleUrls: ["./OrgUnitSetting-form.component.css"]
})
export class OrgUnitSettingFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected _OrgUnitSettingApiService: OrgUnitSettingApiService,
        protected _injector: Injector,
    ) {
        super(_OrgUnitSettingApiService, _injector);

        this.formGroup = new FormGroup({
            idOrgUnit: new FormControl("", [Validators.required]),
            settingName: new FormControl("", [Validators.required]),
            settingParam: new FormControl("", [Validators.required]),
            settingValue: new FormControl("", [Validators.required]),
            status: new FormControl("", [Validators.required]),
        });
    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._OrgUnitSettingApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;

                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                this.submitting = false;
            });
        } else {
            this.itemDetail = {id: 0, status: 1};
            this.submitting = false;
        }
    }
}
