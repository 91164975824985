<p-table (onPage)="onPage($event)" (onSort)="onSort($event)" [columns]="cols" [customSort]="true"
         [lazy]="true" [loading]="isLoading"
         [paginator]="true" [rows]="pageSize" [showCurrentPageReport]="true" [totalRecords]="totalRecord"
         [value]="dataSource" responsiveLayout="scroll" styleClass=" p-datatable-customers p-datatable-striped p-datatable-sm">
    <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between table-header pt-1">
            <div>
                <button (click)="add()" class="p-button-primary mr-1" icon="pi pi-plus" label="{{'BUTTON.ADD' | translate}}" pButton
                        pRipple type="button"></button>
                <button (click)="deletelstMany()" [disabled]="selectedItems && !selectedItems.length" class="p-button-danger" icon="ci ci-delete"
                        pButton pRipple
                        pTooltip="{{'BUTTON.DELETE' | translate }}" type="button"></button>
            </div>
            <div class="formgrid grid">
                <div (clickOutside)="closeTableFilter()" [class.-show-clear]="query"
                     class="ag-search-box -size-small -has-search-adv ag-custom-overlay-panel-wrapper">
                    <input (keyup.enter)="onSearch()" [(ngModel)]="query" class="ag-search-box__input"
                           placeholder="{{'LIST.SEARCH_PLACEHODE' | translate}}" type="text"/>
                    <span class="ci ci-search ag-search-box__search-icon"></span>
                    <span (click)="query = ''" class="ci ci-close ag-search-box__clear-icon" tabindex="0"></span>
                    <span (click)="openSearchAdv = !openSearchAdv" class="ag-search-box__adv-toggle ci ci-chevron-down-1"
                          tabindex="0"></span>

                    <div [hidden]="!openSearchAdv"
                         class="ag-custom-overlay-panel -bottom-left ag-table-filter-dropdown p-3"
                         style="min-width: 320px;">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 p-fluid">
                                <div class="p-float-label">
                                    <p-dropdown [(ngModel)]="status" [filter]="true" [options]="arrStatus"
                                                id="search_Status"></p-dropdown>
                                    <label for="search_Status">{{ 'status' | translate }}</label>
                                </div>

                                <div class="p-float-label">
                                    <p></p>
                                    <p-dropdown [(ngModel)]="productId" [filter]="true" [options]="arrProduct"
                                                id="search_Product">
                                    </p-dropdown>
                                    <label for="search_Product">{{ 'Hàng hóa' | translate }}</label>
                                </div>

                                <div class="p-float-label">
                                    <p></p>
                                    <p-dropdown [(ngModel)]="isAuto" [filter]="true" [options]="arrTHD" id="search_THD">
                                    </p-dropdown>
                                    <label for="search_THD">{{ 'Có TĐH' | translate }}</label>
                                </div>

                                <div class="p-float-label">
                                    <p></p>
                                    <p-dropdown [(ngModel)]="idBranch" [filter]="true" [options]="arrBranch"
                                                id="search_Branch">
                                    </p-dropdown>
                                    <label for="search_Branch">{{ 'Đơn vị' | translate }}</label>
                                </div>

                            </div>
                            <div class="col-6 md:col-6 text-left">
                                <button (click)="onCancelSearch()" class="p-button-danger mr-1" icon="pi pi-trash" label="{{'BUTTON.SEARCH_CANCEL' | translate}}"
                                        pButton pRipple
                                        type="button"></button>
                            </div>
                            <div class="col-6 md:col-6 text-right">
                                <button (click)="onSearch()" class="p-button-info" icon="pi pi-search" label="{{'BUTTON.SEARCH' | translate}}"
                                        pButton pRipple type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <button (click)="visibleCols.toggle($event)" class="p-button-rounded p-button-text" icon="ci ci-data-1" pButton pRipple
                        type="button"></button>
                <p-overlayPanel #visibleCols [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'0ms'"
                                appendTo="body" styleClass="ag-overlay-menu style-2">
                    <div class="ag-overlay-menu__title">
                        <span (click)="resetColsVisibility()" class="ag-overlay-menu__clear-btn">
                            {{ getVisibleColsNumber() }}
                            <span class="ag-overlay-menu__clear-icon ci ci-close"></span>
                        </span>

                        <span class="__text">
                            {{ 'LIST.COLS_SELECTED' | translate }}
                        </span>
                    </div>

                    <div *ngFor="let col of cols" [class.-activated]="col.visible"
                         class="ag-overlay-menu__item-wrapper">
                        <div class="ag-overlay-menu__item">
                            <p-checkbox [(ngModel)]="col.visible" [label]="col.header" binary="true">
                            </p-checkbox>
                        </div>
                    </div>
                </p-overlayPanel>
            </div>
        </div>
    </ng-template>
    <ng-template let-columns pTemplate="header">
        <tr>
            <th class="text-center" style="width: 3rem">
                <p-triStateCheckbox (onChange)="onChangeBulkSelection()" [(ngModel)]="isCheckAll">
                </p-triStateCheckbox>
            </th>
            <ng-container *ngFor="let col of columns">
                <th *ngIf="col.sort" [hidden]="!col.visible" [pSortableColumn]="col.field">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th *ngIf="!col.sort" [hidden]="!col.visible">
                    {{ col.header }}
                </th>
            </ng-container>
            <th class="text-center" style="width: 8rem"></th>
        </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
        <tr [class.ag-tr-status-danger]="rowData.status === 3" [class.ag-tr-status-success]="rowData.status === 1"
            [class.ag-tr-status-warning]="rowData.status === 2" [class.ag-tr-status]="rowData.status">
            <td class="text-center">
                <p-checkbox (onChange)="checkSelectionState()" [(ngModel)]="selectedItems" [value]="rowData"
                            name="group">
                </p-checkbox>
            </td>
            <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field">
                <span *ngSwitchCase="'modified'">
                    {{ rowData.modified | date:'dd/MM/yyyy HH:mm' }}
                </span>
                <span *ngSwitchCase="'vTotal'">
                    {{ rowData.vTotal | number : '1.0-2' }}
                </span>
                <span *ngSwitchCase="'vExtra'">
                    {{ rowData.vExtra | number : '1.0-2' }}
                </span>

                <!-- nhóm hàng hóa -->
                <span *ngSwitchCase="'productId'">
                    <div [ngSwitch]="rowData.productId">
                        <div *ngFor=" let i of arrProduct">
                            <div *ngSwitchCase="i.value">
                                {{ i.label }}
                            </div>
                        </div>
                    </div>
                </span>
                <span *ngSwitchCase="'modifiedBy'">
                    <div [ngSwitch]="rowData.modifiedBy">
                        <div *ngSwitchCase="1">
                            root
                        </div>
                    </div>
                </span>
                <span *ngSwitchCase="'status'">
                    <div [ngSwitch]="rowData.status">
                        <div *ngSwitchCase="'1'">
                            <i _ngcontent-kmb-c131="" class="pi true-icon pi-check-circle text-green-500"></i>
                        </div>
                        <div *ngSwitchCase="'2'">
                            <i _ngcontent-kmb-c131="" class="pi false-icon pi-times-circle text-pink-500"></i>
                        </div>
                    </div>
                </span>
                <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
            </td>
            <td class="text-center">
                <button (click)="edit(rowData.id)" class="p-button-sm p-button-rounded p-button-primary mr-1" icon="pi pi-pencil" pButton
                        pRipple></button>
                <button (click)="delete(rowData.id)" class="p-button-sm p-button-rounded p-button-danger" icon="pi pi-trash" pButton
                        pRipple></button>
            </td>
        </tr>
    </ng-template>
</p-table>
<app-store-tank-form #pEdit (closePopup)="onPopupClosed($event)"><app-store-tank-form>
