<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Dải HĐ' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '880px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'idBranchConfig' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="idBranchConfig" [options]="arrBranchConfig"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.idBranchConfig" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'invoiceSerial' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="invoiceSerial" pInputText [(ngModel)]="itemDetail.invoiceSerial"
                            formControlName="invoiceSerial">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'invoicePattern' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="invoicePattern" pInputText [(ngModel)]="itemDetail.invoicePattern"
                            formControlName="invoicePattern">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'businessId' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="businessId" pInputText [(ngModel)]="itemDetail.businessId"
                            formControlName="businessId">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'trTypes' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="trTypes" pInputText [(ngModel)]="itemDetail.trTypes"
                            formControlName="trTypes">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'validFrom' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-calendar [showIcon]="true" [(ngModel)]="itemDetail.validFrom" formControlName="validFrom"
                            appendTo="body" icon="ci ci-calendar" dateFormat="dd/mm/yy" [showTime]="false">
                        </p-calendar>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'startNumber' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="number" id="startNumber" pInputText [(ngModel)]="itemDetail.startNumber"
                            formControlName="startNumber">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'endNumber' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="number" id="endNumber" pInputText [(ngModel)]="itemDetail.endNumber"
                            formControlName="endNumber">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>