import { Component, Injector, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SecondPageEditBase } from "nabit-shared";
import { ManufactureApiService } from "../../Services/Manufacture-api.service";

@Component({
    selector: "app-manufacture-form",
    templateUrl: "./Manufacture-form.component.html",
    styleUrls: ["./Manufacture-form.component.css"]
})
export class ManufactureFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected ManufactureApiService: ManufactureApiService,
        protected _injector: Injector,
    ) {
        super(ManufactureApiService, _injector);

        this.formGroup = new FormGroup({
            manufactureCode: new FormControl("", [Validators.required]),
            manufactureName: new FormControl("", [Validators.required]),
            status: new FormControl("", [Validators.required])
        });

    }

    ngOnInit() {
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this.ManufactureApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

