import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { CustomerFormComponent } from '../form/Customer-form.component';
import { CustomerApiService } from '../../Services/Customer-api.service';
import { CustomerGroupApiService } from '../../Services/CustomerGroup-api.service';
import { BusinessTypeApiService } from '../../Services/BusinessType-api.service';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';

@Component({
    selector: 'app-customer-index',
    templateUrl: './Customer-index.component.html',
    styleUrls: ['./Customer-index.component.css']
})
export class CustomerIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: CustomerFormComponent;

    arrCustomerGroup = [];
    idCustomerGroup = 0;
    idBussinessType = 0;
    idBranch = 0;
    arrBussinessType = [];
    arrBranch = [];
    tabMenuItems = [];

    constructor(
        protected _CustomerApiService: CustomerApiService,
        protected _injector: Injector,
        public app: AppComponent,
        public _customerGroupAPIService: CustomerGroupApiService,
        public _businessTypeAPIService: BusinessTypeApiService,
        private _orgUnitAPIService: OrgUnitApiService,
    ) {
        super(_CustomerApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Khách hàng'), icon: 'pi pi-book', routerLink: '../Customer'},
            {label: this._translateService.instant('Nhóm khách hàng'), icon: 'pi pi-book', routerLink: '../CustomerGroup'},
            {label: this._translateService.instant('Loại hình kinh doanh'), icon: 'pi pi-book', routerLink: '../BusinessType'}
        ];

        this.cols = [
            { field: 'customerCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'customerName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'address', header: this._translateService.instant('TABLEDATA.ADDRESS'), visible: true, width: 'auto', sort: true,},
            { field: 'taxNumber', header: this._translateService.instant('TABLEDATA.TAXNUMBER'), visible: true, width: 'auto', sort: true,},
            { field: 'idCustomerGroup', header: this._translateService.instant('TABLEDATA.CustomerGroup'), visible: false, width: 'auto', sort: true,},
            { field: 'email', header: this._translateService.instant('TABLEDATA.Email'), visible: true, width: 'auto', sort: true,},
            { field: 'idBussinessType', header: this._translateService.instant('TABLEDATA.BussinessType'), visible: false, width: 'auto', sort: true,},
            { field: 'idBranch', header: this._translateService.instant('TABLEDATA.Branch'), visible: false, width: 'auto', sort: true,},

            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getsCustomerGroup();

        this.getsBusinessType();
        
        this.getsBranch();

        this.getData();


    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status, 
            CustomerGroup : this.idCustomerGroup,
            BussinessType : this.idBussinessType,
            Branch : this.idBranch,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._CustomerApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    } 

    async getsCustomerGroup() {
        var model = {
            keyword: "",
            status: 1,  
            pageIndex: 1,
            pageSize: 20,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this.arrCustomerGroup = [];
        this.arrCustomerGroup = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._customerGroupAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrCustomerGroup, rs.data, 'groupName');
            }
        })
    }

    async getsBusinessType() {
        var model = {
            keyword: "",
            status: 1,  
            pageIndex: 1,
            pageSize: 20,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this.arrBussinessType = [];
        this.arrBussinessType = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._businessTypeAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBussinessType, rs.data, 'name');
            }
        })
    }

    async getsBranch() {
        var model = {
            keyword: "",
            status: 1, 
            CustomerGroup : 0,
            BussinessType : 0,
            Branch : 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, 'customerName');
            }
        })
    }
}