<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Khách hàng' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Mã khách hàng' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" id="customerCode" pInputText [(ngModel)]="itemDetail.customerCode"
                        formControlName="customerCode" placeholder="Lớn nhất 7 kí tự chữ và số, ký tự đặc biệt ._-">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Tên ngắn' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.shortName" formControlName="shortName">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Tên khách hàng' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.customerName" formControlName="customerName"
                        placeholder="Lớn nhất 100 kí tự chữ và số, ký tự đặc biệt ._-">
                </div>
                <div class="col-4 md:col-2 font-medium">
                    <div class="label mt-25">{{'Địa chỉ' | translate}}</div>
                </div>
                <div class="col-8 md:col-10 field">
                    <textarea pInputTextarea [(ngModel)]="itemDetail.address" formControlName="address"
                        placeholder="Địa chỉ" [autoResize]="true"></textarea>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Mã số thuế' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.taxNumber" formControlName="taxNumber"
                        placeholder="Mã số thuế (bắt buộc)">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Nhóm khách' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="idCustomerGroup" [options]="arrCustomerGroup"
                        [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                        [(ngModel)]="itemDetail.idCustomerGroup" [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field" style="font-weight: 450;margin: 2px 0px 0px 0px;">
                    <div class="label font-medium mt-25">{{'Loại hình DN' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="idBussinessType" [options]="arrBussinessType"
                        [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                        [(ngModel)]="itemDetail.idBussinessType" [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Email' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.email" formControlName="email"
                        placeholder="Địa chỉ email của bạn example@gmail.com, @domail.com.vn ">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Mã bưu chính' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.postCode" formControlName="postCode">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Số điện thoại' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.tel" formControlName="tel"
                        placeholder="Số điện thoại">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Số fax' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.fax" formControlName="fax"
                        placeholder="Số fax">
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Trực thuộc' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="idParent" [options]="arridParent" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idParent"
                        optionLabel="customerName" optionValue="id" [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Chi nhánh' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="idBranch" [options]="arridBranch" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idBranch"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Ngày bắt đầu' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-calendar [showIcon]="true" [(ngModel)]="itemDetail.startDate" formControlName="startDate"
                        appendTo="body" icon="ci ci-calendar" dateFormat="dd/mm/yy" placeholder="dd/mm/yyy"
                        showTime="true" hourFormat="24">
                    </p-calendar>
                </div>
                <div class="col-4 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-8 md:col-4 field">
                    <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>