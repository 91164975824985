let cacheBg = new Map<string, string>();
let cacheFg = new Map<string, string>();

function getBgColor(productCode: string) {
    if (cacheBg.has(productCode)) {
        return cacheBg.get(productCode);
    }
    let colorClass = `P${productCode}_bgColor`;
    for (let s of Array.from(document.styleSheets)) {
        try {
            let rules = Array.from(s.cssRules);
            let r = rules.filter(r => r instanceof CSSStyleRule)
                .map(r => r as CSSStyleRule)
                .find(r => r.selectorText.includes(colorClass));
            if (r) {
                let bg = r.style.backgroundColor;
                cacheBg.set(productCode, bg);
                return bg;
            }
        } catch {}
    }
    return `rgb(0,0,0)`;
}

function getFgColor(productCode: string) {
    if (cacheFg.has(productCode)) {
        return cacheFg.get(productCode);
    }

    let colorClass = `P${productCode}_fgColor`;
    for (let s of Array.from(document.styleSheets)) {
        let rules = Array.from(s.cssRules);
        let r = rules.filter(r => r instanceof CSSStyleRule)
            .map(r => r as CSSStyleRule)
            .find(r => r.selectorText.includes(colorClass));
        if (r) {
            let fg = r.style.color;
            cacheFg.set(productCode, fg);
            return fg;
        }
    }
    return `rgb(0,0,0)`;
}


export { getBgColor, getFgColor };
