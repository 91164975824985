<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Cửa hàng' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '880px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã cửa hàng' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="customerCode" pInputText [(ngModel)]="itemDetail.customerCode"
                            formControlName="customerCode" placeholder="Định dạng text, tối đa 7 kí tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã số thuế' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.taxNumber" formControlName="taxNumber"
                            placeholder="Mã số thuế">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.customerName"
                            formControlName="customerName">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 0px 0px 0px 0px;">
                    <div class="label mt-1">{{'Ngày bắt đầu hoạt động' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-calendar [showIcon]="true" [(ngModel)]="itemDetail.startDate" formControlName="startDate"
                            appendTo="body" icon="ci ci-calendar" dateFormat="dd/mm/yy" placeholder="dd/mm/yyy"
                            showTime="true" hourFormat="24">
                        </p-calendar>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên ngắn' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.shortName" formControlName="shortName">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">
                        {{'Nhóm' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [options]="arrCustomerGroup" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idCustomerGroup"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">
                        {{'Vùng giá' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="priceRegionId" [options]="arridPriceRegion"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.priceRegionId" optionValue="id" optionLabel="priceRegionid"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Loại hình DN' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="idBussinessType" [options]="arrBussinessType"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.idBussinessType" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trực thuộc' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="idParent" [options]="arridParent" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idParent"
                            optionValue="id" optionLabel="customerName" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Địa chỉ' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.address" formControlName="address">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Cửa hàng trưởng' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.manager" formControlName="manager">
                    </div>
                </div>
                <!-- <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tỉnh thành' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.address" formControlName="address">
                    </div>
                </div> -->
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tel' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.tel" formControlName="tel">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Fax' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.fax" formControlName="fax">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">
                        {{'Trạng thái' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id != 0" [disabled]="submitting" class="p-button p-button mr-2 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>