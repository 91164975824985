import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { DiscountFormComponent } from '../form/Discount-form.component';
import { DiscountPriceApiService } from '../../Services/DiscountPrice-api.service';
import {CustomerApiService} from "../../Services/Customer-api.service";
import {ProductGroupApiService} from "../../Services/ProductGroup-api.service";
import {WarehouseApiService} from "../../Services/Warehouse-api.service";
import {DiscountGroupApiService} from "../../Services/DiscountGroup-api.service";

@Component({
    selector: 'app-discount-index',
    templateUrl: './Discount-index.component.html',
    styleUrls: ['./Discount-index.component.css']
})
export class DiscountIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: DiscountFormComponent;
    tabMenuItems = [];
    listTime = []; chosenListTime = '';
    customerList = []; chosenCustomerId?: number;
    productGroupList = []; chosenProductGroupId?: number;
    warehouseList = []; chosenWarehouseId?: number;
    discountGroupList = []; chosenDiscountGroupId?: number;

    constructor(
        protected _DiscountApiService: DiscountPriceApiService,
        protected _DiscountGroupApiService: DiscountGroupApiService,
        protected _CustomerApiService: CustomerApiService,
        protected _ProductGroupApiService: ProductGroupApiService,
        protected _WarehouseApiService: WarehouseApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_DiscountApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Chiết khấu'), icon: 'pi pi-dollar', routerLink: '../Discount'}
        ];
        this.cols = [
            { field: 'validFrom', header: this._translateService.instant('Ngày hiệu lực'), visible: true, width: 'auto', sort: true,},
            { field: 'customerName', header: this._translateService.instant('Khách hàng'), visible: true, width: 'auto', sort: true,},
            { field: 'warehouseName', header: this._translateService.instant('Kho hàng'), visible: true, width: 'auto', sort: true,},
            { field: 'productName', header: this._translateService.instant('Hàng hóa'), visible: true, width: 'auto', sort: true,},
            { field: 'discount', header: this._translateService.instant('Mức chiết khấu'), visible: true, width: 'auto', sort: true, textAlign:'right'},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        console.log(id);
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    async getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        if (this.listTime.length === 0) {
            await this._DiscountApiService.GetListTime().then(rs => {
                if (rs.success) {
                    this.listTime = rs.data.map(r => {
                        return {
                            label: new Date(r).toLocaleString('vi-VN'),
                            value: r
                        }
                    });
                    if (!this.chosenListTime) {
                        this.chosenListTime = this.listTime[0]?.value ?? '';
                    }
                }
            })
        }

        if (this.customerList.length === 0) {
            await this._CustomerApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.customerList = rs.data;
                    this.customerList.unshift({ id: 0, customerName: '(chưa chọn)' })
                }
            })
        }

        if (this.productGroupList.length === 0) {
            await this._ProductGroupApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.productGroupList = rs.data;
                    this.productGroupList.unshift({ id: 0, shortName: '(chưa chọn)' })
                }
            })
        }

        if (this.warehouseList.length === 0) {
            await this._WarehouseApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.warehouseList = rs.data;
                    this.warehouseList.unshift({ id: 0, warehouseName: '(chưa chọn)' })
                }
            })
        }

        if (this.discountGroupList.length === 0) {
            await this._DiscountGroupApiService.getsInUse().then(rs => {
                if (rs.success) {
                    this.discountGroupList = rs.data;
                    this.discountGroupList.unshift({ id: 0, shortName: '(chưa chọn)' })
                }
            })
        }

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: this.orderCol,
            isDesc: this.isDesc,
            validFrom: this.chosenListTime,
            "discountGroupId": this.chosenDiscountGroupId ?? 0,
            "customerId": this.chosenCustomerId ?? 0,
            "vehicleClassId": 0,
            "productGroupId": this.chosenProductGroupId ?? 0,
            "warehouseId": this.chosenWarehouseId ?? 0,
            "discountTierId": 0,
        };
        this._baseService.Find(model).then(response => {
            if (response.success) {
                this.dataSource = response.data.map(r => {
                    r.total = r.unitPrice + r.thueVAT + r.pxd;
                    return r;
                });
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
