import { Component, Injector, OnInit } from "@angular/core";
import { SecondPageEditBase } from "nabit-shared";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TrGroupApiService } from "../../Services/TrGroup-api.service";

@Component({
    selector: 'app-trGroup-form',
    templateUrl: './TrGroup-form.component.html',
    styleUrls: ['./TrGroup-form.component.scss']
})
export class TrGroupFormComponent extends SecondPageEditBase implements OnInit {
    activeIndex = 0;
    constructor(
        protected _TrGroupApiService: TrGroupApiService,
        protected _injector: Injector,
    ) {
        super(_TrGroupApiService, _injector);

        this.formGroup = new FormGroup({
            trGroupCode: new FormControl("", [Validators.required]),
            trGroupName: new FormControl("", [Validators.required]),
            shortName: new FormControl("", [Validators.required]),
            description: new FormControl(""),
        })
    }

    ngOnInit() {
    }

    onShowPopup(id: number): void {
        this.submitting = true;

        if (id > 0) {
            this._TrGroupApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, _ => {
                this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                this.submitting = false;
            })
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
        this.activeIndex = 0;
    }
}