<p-dialog #dialog [(visible)]="isShow" [autoZIndex]="true" [contentStyle]="{'max-height': getMaxDialogHeight()}"
          [maximizable]="true" [modal]="true" [styleClass]="'ag-modal force-full-width'"
          [style]="{'width': '880px'}" closeIcon="ci ci-close" header="{{ 'Cập nhật thông tin/ Bể chứa' | translate }}"
          maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Mã bể' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.tankCode" formControlName="tankCode" id="tankCode" pInputText
                               placeholder="4 ký tự (VD: TK01)" type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Tên bể' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.tankName" formControlName="tankName" id="tankName" pInputText
                               placeholder="Định dạng text, tối đa 30 ký tự" type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Hàng hóa' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.productId" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrProduct" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="productId">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Đơn vị' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.idBranch" [autoDisplayFirst]="false" [disabled]="true"
                                    [filter]="true" [options]="arrBranch"
                                    appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1" filterBy="label,value"
                                    formControlName="idBranch">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Thể tích' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.vTotal" [minFractionDigits]="2" [min]="0"
                                       formControlName="vTotal" mode="decimal" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Thể tích ống' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.vExtra" [minFractionDigits]="2" [min]="0"
                                       formControlName="vExtra" mode="decimal" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Loại bể' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.tankType" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrTankType" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="tankType">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Trạng thái' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.status" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrStatus" appendTo="body" dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="status">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-12" style="font-weight: 450;">
                    <br>
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">
                            Thông tin tự động hoá
                        </div>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Áp dụng TĐH' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.isAuto" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrTHD" appendTo="body" dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="isAuto">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Nhà cung cấp TĐH' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.iaManufactureId" [autoDisplayFirst]="false"
                                    [filter]="true" [options]="arrManufacture" appendTo="body"
                                    dropdownIcon="ci ci-chevron-down-1" filterBy="label,value"
                                    formControlName="iaManufactureId">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Mã thiết bị TĐH' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.iADeviceId" formControlName="iADeviceId" pInputText
                               placeholder="Định dạng text"
                               type="text">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Mã TĐH bể' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.iATankId" formControlName="iATankId" pInputText
                               placeholder="Định dạng text"
                               type="text">
                    </div>
                </div>

                <div class="col-12 md:col-12" style="font-weight: 450;">
                    <br>
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">
                            Thông tin cảnh báo
                        </div>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 0px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Chiều cao tối thiểu (mm)' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.minHeight" [minFractionDigits]="2"
                                       formControlName="minHeight"
                                       mode="decimal" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 0px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Dung tích tối thiểu (lít)' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.minVolume" [minFractionDigits]="2"
                                       formControlName="minVolume"
                                       mode="decimal" placeholder="Định dạng số">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Dung tích tối đa (lít)' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.maxVolume" [minFractionDigits]="2"
                                       formControlName="maxVolume"
                                       mode="decimal" placeholder="Định dạng số ">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 0px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Cảnh báo mức hàng thấp (%)' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.minAlarmPercent" [max]="100"
                                       [minFractionDigits]="2" [min]="0" formControlName="minAlarmPercent"
                                       mode="decimal"
                                       placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 0px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Cảnh báo hết hàng (%)' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.lowAlarmPercent" [max]="100"
                                       [minFractionDigits]="2" [min]="0" formControlName="lowAlarmPercent"
                                       mode="decimal"
                                       placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 0px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Cảnh báo mức hàng cao' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.maxAlarmPercent" [max]="100"
                                       [minFractionDigits]="2" [min]="0" formControlName="maxAlarmPercent"
                                       mode="decimal"
                                       placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 0px 0px 0px 0px;">
                    <div class="label font-medium mt-1">{{ 'Cảnh báo tràn bể (%)' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber [(ngModel)]="itemDetail.highAlarmPercent" [max]="100"
                                       [minFractionDigits]="2" [min]="0" formControlName="highAlarmPercent"
                                       mode="decimal"
                                       placeholder="Định dạng số %">
                        </p-inputNumber>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="cancel()" [disabled]="submitting" class="p-button p-button-outlined mr-2" icon="pi pi-times"
                label="{{'BUTTON.CANCEL' | translate}}"
                pButton pRipple type="button"></button>
    </p-footer>
</p-dialog>
