import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { SecondPageIndexBase } from "nabit-shared";
import { ManufactureApiService } from "../../Services/Manufacture-api.service";
import { ManufactureFormComponent } from "../form/Manufacture-form.component";

@Component({
    selector: "app-manufacture-index",
    templateUrl: "./Manufacture-index.component.html",
    styleUrls: ["./Manufacture-index.component.css"]
})
export class ManufactureIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild("pEdit") pEdit: ManufactureFormComponent;
    tabMenuItems = [];

    constructor(
        protected _ManufactureApiService: ManufactureApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_ManufactureApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant("Nhà cung cấp TĐH"), icon: "pi pi-database", routerLink: "../Manufacture" }
        ];
        this.cols = [
            {
                field: "manufactureCode",
                header: this._translateService.instant("Mã nhà cung cấp TĐH"),
                visible: true,
                width: "auto",
                sort: true,
            },
            {
                field: "manufactureName",
                header: this._translateService.instant("TABLEDATA.NAME"),
                visible: true,
                width: "auto",
                sort: true,
            },
            {
                field: "modified",
                header: this._translateService.instant("TABLEDATA.MODIFIED"),
                visible: false,
                width: "auto",
                sort: true,
                dateFormat: "dd/MM/yyyy HH:mm"
            },
            {
                field: "modifiedBy",
                header: this._translateService.instant("TABLEDATA.MODIFIED_BY"),
                visible: false,
                width: "auto",
                sort: true,
            },
            { field: "status", header: this._translateService.instant("TABLEDATA.STATUS"), visible: true, width: "auto", sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        this._baseService.getsInUse().then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
