import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { StudentFormComponent } from '../form/Student-form.component';
import { StudentApiService } from '../../Services/Student-api.service';

@Component({
    selector: 'app-student-index',
    templateUrl: './Student-index.component.html',
    styleUrls: ['./Student-index.component.css']
})
export class StudentIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: StudentFormComponent;

    constructor(
        protected _StudentApiService: StudentApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_StudentApiService, _injector);
    }

    ngOnInit() {

        this.cols = [
            { field: 'studentCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', },
            { field: 'studentName', header: this._translateService.instant('FORM.TITLE'), visible: true, width: 'auto', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
            { field: 'doB', header: this._translateService.instant('STUDENT.DOB'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy' },
            { field: 'imageUrl', header: this._translateService.instant('STUDENT.IMAGE_URL'), visible: true, width: 'auto' },
            { field: 'mobile', header: this._translateService.instant('STUDENT.MOBILE'), visible: true, width: 'auto' },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto' },
        ];
        this.getData();
    }

    edit() {
        this.pEdit.showPopup(this.selectedItems[0].id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.dataSource = [];
        this.isLoading = true;
        this.resetBulkSelect();
        this._StudentApiService.GetAll()
            .then(response => {
                if (response.success) {
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }
}
