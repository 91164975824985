<p-dialog #dialog header="{{ 'Cập nhật thông tin' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '860px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                    <label for="code">{{'TABLEDATA.validFrom' | translate}}</label>
                    <p-calendar [showIcon]="true" [showTime]="true" appendTo="body" icon="ci ci-calendar"
                        [(ngModel)]="itemDetail.validFrom" formControlName="validFrom" dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyy">
                    </p-calendar>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="status">{{'TABLEDATA.PRODUCT' | translate}}</label>
                    <p-dropdown [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                        [options]="arrProduct" [(ngModel)]="itemDetail.productId" formControlName="productId"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="code">{{'TAX.IMPORTTAX' | translate}}</label>
                    <p-inputNumber inputStyleClass="text-right" [min]="0" [maxFractionDigits]="2"
                        [(ngModel)]="itemDetail.importTax" formControlName="importTax">
                    </p-inputNumber>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="code">{{'TAX.SPECIALTAX' | translate}}</label>
                    <p-inputNumber inputStyleClass="text-right" [min]="0" [maxFractionDigits]="2"
                        [(ngModel)]="itemDetail.specialTax" formControlName="specialTax">
                    </p-inputNumber>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="code">{{'TAX.IMPORTVAT' | translate}}</label>
                    <p-inputNumber inputStyleClass="text-right" [min]="0" [maxFractionDigits]="2"
                        [(ngModel)]="itemDetail.importVAT" formControlName="importVAT">
                    </p-inputNumber>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="code">{{'TAX.VAT' | translate}}</label>
                    <p-inputNumber inputStyleClass="text-right" [min]="0" [maxFractionDigits]="2"
                        [(ngModel)]="itemDetail.vat" formControlName="vat">
                    </p-inputNumber>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="code">{{'TAX.PXD' | translate}}</label>
                    <p-inputNumber inputStyleClass="text-right" [min]="0" [maxFractionDigits]="2"
                        [(ngModel)]="itemDetail.pxd" formControlName="pxd">
                    </p-inputNumber>
                </div>
                <div class="field col-4 md:col-4">
                    <label for="status">{{'FORM.STATUS' | translate}}</label>
                    <p-dropdown [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                        [options]="arrStatus" [(ngModel)]="itemDetail.status" formControlName="status" [filter]="true"
                        filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_CONTINUE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="save()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button float-right"
            (click)="saveAsCopy()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.DELETE' | translate}}" icon="pi pi-trash"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2 float-right"
            (click)="delete()"></button>
    </p-footer>
</p-dialog>