import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CorePublicService } from '../services/core-public.service';

@Injectable()
export class MultiTranslateHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient,
        private _corePublicService: CorePublicService
    ) { }


    public getTranslation(lang: string = 'vi') {
        if(!lang) lang='vi';
        return forkJoin(
            this._corePublicService.getLocalization(lang).then(rs => {
                if (rs.success) {
                    localStorage.setItem(`language.${lang}`, JSON.stringify(rs.data));
                    return rs.data;
                }
            })
        ).pipe(map(response => {
            var result = response[0].reduce(function (map, obj) {
                map[obj.code] = obj.translationText;
                return map;
            }, {});
            return result;
        }));
    }
}

