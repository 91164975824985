import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCodeModule } from './layouts/main-layout/app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './layouts/main-layout/app.main.component';
import { AppConfigComponent } from './layouts/main-layout/app.config.component';
import { AppMenuComponent } from './layouts/main-layout/app.menu.component';
import { AppMenuitemComponent } from './layouts/main-layout/app.menuitem.component';
import { AppInlineMenuComponent } from './layouts/main-layout/app.inlinemenu.component';
import { AppTopBarComponent } from './layouts/main-layout/app.topbar.component';
import { AppBreadcrumbComponent } from './layouts/main-layout/app.breadcrumb.component';
import { AppFooterComponent } from './layouts/main-layout/app.footer.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { MenuService } from './layouts/main-layout/app.menu.service';
import { AppBreadcrumbService } from './layouts/main-layout/app.breadcrumb.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuicklinkModule } from 'ngx-quicklink';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { NabitComponentModule, CorePublicService, MultiTranslateHttpLoader, SendAccessTokenInterceptor } from 'nabit-shared';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppRoutes } from './app-routing.module';
import { AuthGuardService } from './services/auth-guard.service';
import { ToastModule } from 'primeng/toast';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { InternationalizationModule } from './internationalization/internationalization.module';
import { MasterDataModule } from './MD/MD.module';
import { RPTModule } from './RPT/RPT.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { CoreModule } from "./core/core.module";
import { ChangePasswdComponent } from "./layouts/main-layout/changepasswd.component";
import { TankTruckComponent } from './dashboard/tank-truck/tank-truck.component';
import { StartPageComponent } from './StartPage/StartPage.component';
import { Dashboard2Component } from './dashboard2/dashboard2.component';
import { NgxEchartsModule } from "ngx-echarts";
import { Dashboard3Component } from './dashboard3/dashboard3.component';
import { Dashboard4Component } from './dashboard4/dashboard4.component';
export function createTranslateLoader(http: HttpClient, corePublicService: CorePublicService) {
    return new MultiTranslateHttpLoader(http, corePublicService);
}
interface NgxSpinnerConfig {
    type?: string;
}
@NgModule({
    imports: [
        MasterDataModule,
        BrowserModule,
        FormsModule,
        RPTModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AppCodeModule,
        NabitComponentModule,
        ClickOutsideModule,
        QuicklinkModule,
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        InternationalizationModule.forRoot({ locale_id: 'en-US' }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, CorePublicService]
            }
        }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.apiDomain.gateway],
                sendAccessToken: false
            }
        }),
        NgxEchartsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineMenuComponent,
        AppTopBarComponent,
        AppBreadcrumbComponent,
        AppFooterComponent,
        AppLoginComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        DashboardComponent,
        LoginLayoutComponent,
        ChangePasswdComponent,
        TankTruckComponent,
        StartPageComponent,
        Dashboard2Component,
        Dashboard3Component,
        Dashboard4Component
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy }, MenuService, AppBreadcrumbService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SendAccessTokenInterceptor,
            multi: true
        },
        MessageService,
        ConfirmationService,
        AuthGuardService
    ],
    exports: [
        TankTruckComponent,
        AppBreadcrumbComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
