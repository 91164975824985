<div class="card p-0">
    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="{{reportName}}" leftIcon="pi pi-book">
            <div class="p-formgrid grid" *ngIf="!embed">
                <div class="p-fluid col-12 md:col-7 lg:col-5">
                    <div class="p-formgrid grid">
                        <div class="p-fluid col-4">
                            <p-dropdown styleClass="p-inputtext-sm" class="p-inputtext-sm" [options]="monthList"
                                optionValue="id" optionLabel="label" [(ngModel)]="selectedMonth"
                                [autoDisplayFirst]="true" (onChange)='changeMonth($event)'
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [filter]="true">
                            </p-dropdown>
                        </div>
                        <div class="p-fluid col-4">
                            <span class="p-float-label">
                                <p-calendar (ngModelChange)="this.from = $event;" [(ngModel)]="from" [showTime]="false"
                                    dateFormat="dd/mm/yy" inputStyleClass="p-inputtext-sm w-10rem" [showIcon]="true"
                                    inputId="from-picker"></p-calendar>
                                <label>Từ ngày</label>
                            </span>
                        </div>
                        <div class="p-fluid col-4">
                            <span class="p-float-label">
                                <p-calendar (ngModelChange)="this.to = $event;" [(ngModel)]="to" [showTime]="false"
                                    dateFormat="dd/mm/yy" inputStyleClass="p-inputtext-sm w-10rem" [showIcon]="true"
                                    inputId="to-picker"></p-calendar>
                                <label>Đến ngày</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-5 lg:col-7">
                    <span class="p-buttonset">
                        <button pButton pRipple type="button" icon="pi pi-filter" label=" {{'Lọc' | translate}}"
                            (click)="viewFilter()" class="p-button-outlined p-button-primary"></button>
                        <button pButton pRipple type="button" icon="pi pi-sync" label=" {{'Xem' | translate}}"
                            (click)="loadReport()" class="p-button-outlined p-button-primary"
                            style="margin-left: -1px;"></button>
                        <button *ngIf="isSuperAdmin()" pButton pRipple type="button" icon="pi pi-sync"
                            label=" {{'Debug' | translate}}" (click)="debugReport()"
                            class="p-button-outlined p-button-primary" style="margin-left: -1px;"></button>
                        <button *ngIf="isSuperAdmin()" pButton pRipple type="button" icon="pi pi-sync"
                            label=" {{'Edit' | translate}}" (click)="editReport()"
                            class="p-button-outlined p-button-primary" style="margin-left: -1px;"></button>
                        <button pButton pRipple type="button" icon="pi pi-file-pdf" label=" {{'pdf' | translate}}"
                            (click)="exportPdf()" class="p-button-outlined p-button-primary"
                            style="margin-left: -1px"></button>
                        <button pButton pRipple type="button" icon="pi pi-file-excel" label=" {{'Excel' | translate}}"
                            (click)="exportExcel()" class="p-button-outlined p-button-primary"
                            style="margin-left: -1px"></button>
                    </span>
                </div>
            </div>
            <div [hidden]="hiddenFilter">
                <div class="col-12 p-0 mt-1">
                    <div class="p-fluid p-formgrid grid">
                        <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2" *ngFor="let item of itemDetail.filterConditions">
                            <span *ngIf="item.isMultiple==1" class="p-float-label">
                                <p-multiSelect styleClass="p-inputtext-sm" class="p-inputtext-sm"
                                    [options]="item.filterData" optionValue="id" optionLabel="dataToFilter"
                                    [(ngModel)]="item.selectedValue" display="chip">
                                </p-multiSelect>
                                <label>{{item.mdNameVn}}</label>
                            </span>
                            <span *ngIf="item.isMultiple!=1" class="p-float-label">
                                <p-dropdown styleClass="p-inputtext-sm w-full" class="p-inputtext-sm"
                                    [options]="item.filterData" optionValue="id" optionLabel="dataToFilter"
                                    [(ngModel)]="item.selectedValue" [autoDisplayFirst]="item.isRequired"
                                    dropdownIcon="ci ci-chevron-down-1" appendTo="body" [filter]="true">
                                </p-dropdown>
                                <label>{{item.mdNameVn}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0 mt-2 flex flex-wrap overflow-auto rpt-viewer">
                <div [innerHtml]="htmlContent" class="mx-auto"></div>
            </div>
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                [fullScreen]="false" [showSpinner]="isLoading">
                <p style="color: white"> Loading... </p>
            </ngx-spinner>
        </p-tabPanel>
    </p-tabView>
</div>
