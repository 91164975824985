import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { OilLossRateFormComponent } from '../form/OilLossRate-form.component';
import { OilLossRateApiService } from '../../Services/OilLossRate-api.service'; 
import { ProductApiService } from '../../Services/Product-api.service'; 
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';

@Component({
    selector: 'app-oilLossRate-index',
    templateUrl: './OilLossRate-index.component.html',
    styleUrls: ['./OilLossRate-index.component.css']
})
export class OilLossRateIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: OilLossRateFormComponent; 

    arrProduct = [];
    arrBranch = [];
    productId = 0;
    branchId = 0;
    tabMenuItems = [];
    constructor(
        protected _OilLossRateApiService: OilLossRateApiService, 
        protected _injector: Injector,
        public app: AppComponent,
        private _productAPIService: ProductApiService,
        private _orgUnitAPIService: OrgUnitApiService,
    ) {
        super(_OilLossRateApiService, _injector);
    }

    async ngOnInit() {
        this.tabMenuItems = [ 
            {label: this._translateService.instant('Hao hụt'), icon: 'pi pi-book', routerLink: '../OilLossRate'}
        ];
        this.cols = [
            { field: 'docNum', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'validFrom', header: this._translateService.instant('TABLEDATA.validFrom'), visible: true, width: 'auto', sort: true,},
            { field: 'branchId', header: this._translateService.instant('TABLEDATA.BRANCH'), visible: false, width: 'auto', sort: true,},
            { field: 'productId', header: this._translateService.instant('TABLEDATA.PRODUCT'), visible: true, width: 'auto', sort: true,},
            { field: 'percentHH_In', header: this._translateService.instant('TABLEDATA.lossIn_Percent'), visible: true, width: 'auto', sort: true,},
            { field: 'percentHH_Out', header: this._translateService.instant('TABLEDATA.lossOut_Percent'), visible: true, width: 'auto', sort: true,},
            { field: 'percentHH_Store', header: this._translateService.instant('TABLEDATA.lossStore_Percent'), visible: true, width: 'auto', sort: true,},
            { field: 'percentHH_Clean', header: this._translateService.instant('TABLEDATA.percentHH_Clean'), visible: true, width: 'auto', sort: true,},
            { field: 'lossType', header: this._translateService.instant('TABLEDATA.lossType'), visible: false, width: 'auto', sort: true,},
            { field: 'tankType', header: this._translateService.instant('TABLEDATA.tankType'), visible: false, width: 'auto', sort: true, },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: false, width: 'auto', sort: true, },

        ];
        this.getsProduct();  
        this.getData();  
        this.getsBranch();
    }

    async edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    async add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = []; 
        var model = {
            keyword: this.query,
            status: this.status,  
            Product: this.productId,
            Branch: this.branchId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._OilLossRateApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    } 

    async getsProduct() {
        this.arrProduct = [];
        this.arrProduct = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._productAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProduct, rs.data, 'productName');
            }
        })
    }

    async getsBranch() {
        var model = {
            keyword: "",
            status: 1, 
            CustomerGroup : 0,
            BussinessType : 0,
            Branch : 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, 'customerName');
            }
        })
    }

}