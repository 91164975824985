import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Tank, TankData} from "../../Scada-interfaces";
import {SecondPageIndexBase} from "nabit-shared";
import {TankApiService} from "../../../../Services/Tank-api.service";
import {DecimalPipe} from "@angular/common";

@Component({
    selector: 'app-scada-tank-table',
    templateUrl: './scada-tank-table.component.html',
    styleUrls: ['./scada-tank-table.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ScadaTankTableComponent extends SecondPageIndexBase implements OnInit, OnChanges {
    @Input() tanks: Tank[];
    @Input() tankData: Map<string, TankData> = new Map();
    constructor(
        protected _TankApiService: TankApiService,
        protected _injector: Injector
    ) {
        super(_TankApiService, _injector);
    }

    remapDataSource() {
        this.dataSource = this.tanks.map((tank, i) => {
            let data = this.tankData.get(tank.iaDeviceId + tank.iaTankId) || {} as TankData;

            let fuelPercentage = ((1 - (data.RemainingFuelVolume / tank.vTotal)) * 100).toFixed(0) + '%';
            if (fuelPercentage === 'NaN%') fuelPercentage = '';

            let waterPercentage = (data.WaterVolume / tank.vTotal * 100).toFixed(0) + '%';
            if (waterPercentage === 'NaN%') waterPercentage = '';

            let remainingPercentage = (data.RemainingFuelVolume / tank.vTotal * 100).toFixed(0) + '%';
            if (remainingPercentage === 'NaN%') remainingPercentage = '';

            return {
                ...tank,
                ...data,
                Num: i + 1,
                Temperature: new DecimalPipe('en-US').transform(data.Temperature, '1.0-2'),
                FuelLevel: new DecimalPipe('en-US').transform(data.FuelLevel, '1.0-2'),
                FuelVolume15: new DecimalPipe('en-US').transform(data.FuelVolume15, '1.0-2'),
                FuelVolume: new DecimalPipe('en-US').transform(data.FuelVolume, '1.0-2'),
                FuelPercentage: fuelPercentage,
                WaterLevel: new DecimalPipe('en-US').transform(data.WaterLevel, '1.0-2'),
                WaterVolume: new DecimalPipe('en-US').transform(data.WaterVolume, '1.0-2'),
                WaterPercentage: waterPercentage,
                RemainingFuelVolume: new DecimalPipe('en-US').transform(data.RemainingFuelVolume, '1.0-2'),
                RemainingPercentage: remainingPercentage,
                fillPercentage: (1 - (data.RemainingFuelVolume / tank.vTotal)) * 100
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        this.remapDataSource();
    }

    ngOnInit(): void {
        this.cols = [
            { field: 'Num', header: this._translateService.instant('STT'), visible: true, width: 'auto', spanned: true, align: 'center' },
            { field: 'tankName', header: this._translateService.instant('Tên bể'), visible: true, width: 'auto', spanned: true },
            { field: 'Temperature', header: this._translateService.instant('Nhiệt độ'), visible: true, width: 'auto', align: 'right' },
            { field: 'FuelLevel', header: this._translateService.instant('Mức (mm)'), visible: true, width: 'auto', align: 'right' },
            { field: 'FuelVolume', header: this._translateService.instant('Lượng (L)'), visible: true, width: 'auto', align: 'right' },
            { field: 'FuelVolume15', header: this._translateService.instant('Lượng (L15)'), visible: true, width: 'auto', align: 'right' },
            { field: 'FuelPercentage', header: this._translateService.instant('%'), visible: true, width: 'auto', align: 'right' },
            { field: 'WaterLevel', header: this._translateService.instant('Mức (mm)'), visible: true, width: 'auto', align: 'right' },
            { field: 'WaterVolume', header: this._translateService.instant('Lượng (L)'), visible: true, width: 'auto', align: 'right' },
            { field: 'WaterPercentage', header: this._translateService.instant('%'), visible: true, width: 'auto', align: 'right' },
            // { field: 'per', header: this._translateService.instant('Mức (mm)'), visible: true, width: 'auto', align: 'right' },
            { field: 'RemainingFuelVolume', header: this._translateService.instant('Lượng (L)'), visible: true, width: 'auto', align: 'right' },
            { field: 'RemainingPercentage', header: this._translateService.instant('%'), visible: true, width: 'auto', align: 'right' },
        ];
    }
}
