import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CorePublicService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndpoint}/CorePublic`);
    }

    resetPassword(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/resetPassword`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    getLocalization(lang: string): Promise<ResponseResult> {
        if(!lang) lang = 'vi';
        const apiUrl = `${environment.apiDomain.coreEndpoint}/Language/GetLocalization/vi`;
        return this.defaultGet(apiUrl);
    }
}
