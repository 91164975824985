import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MasterDataRoutes } from "./MD.routing";
import { NabitComponentModule } from "nabit-shared";
import { ProductIndexComponent } from "./Product/index/Product-index.component";
import { ProductFormComponent } from "./Product/form/Product-form.component";
import { ProductGroupFormComponent } from "./ProductGroup/form/ProductGroup-form.component";
import { ProductGroupIndexComponent } from "./ProductGroup/index/ProductGroup-index.component";
import { WarehouseFormComponent } from "./Warehouse/form/Warehouse-form.component";
import { WarehouseIndexComponent } from "./Warehouse/index/Warehouse-index.component";
import { CustomerFormComponent } from "./Customer/form/Customer-form.component";
import { CustomerIndexComponent } from "./Customer/index/Customer-index.component";
import { OrgUnitFormComponent } from "./OrgUnit/form/OrgUnit-form.component";
import { OrgUnitIndexComponent } from "./OrgUnit/index/OrgUnit-index.component";
import { CustomerGroupFormComponent } from "./CustomerGroup/form/CustomerGroup-form.component";
import { CustomerGroupIndexComponent } from "./CustomerGroup/index/CustomerGroup-index.component";
import { VehicleFormComponent } from "./Vehicle/form/Vehicle-form.component";
import { VehicleIndexComponent } from "./Vehicle/index/Vehicle-index.component";
import { VehicleTypeFormComponent } from "./VehicleType/form/vehicle-type-form.component";
import { VehicleTypeIndexComponent } from "./VehicleType/index/vehicle-type-index.component";
import { VehicleClassFormComponent } from "./VehicleClass/form/vehicleClass-form.component";
import { VehicleClassIndexComponent } from "./VehicleClass/index/vehicleClass-index.component";
import { ProductSourceFormComponent } from "./ProductSource/form/ProductSource-form.component";
import { ProductSourceIndexComponent } from "./ProductSource/index/ProductSource-index.component";
import { TankFormComponent } from "./Tank/form/Tank-form.component";
import { TankIndexComponent } from "./Tank/index/Tank-index.component";
import { PumpStationFormComponent } from "./PumpStation/form/PumpStation-form.component";
import { PumpStationIndexComponent } from "./PumpStation/index/PumpStation-index.component";
import { ProductUnitFormComponent } from "./ProductUnit/form/ProductUnit-form.component";
import { ProductUnitIndexComponent } from "./ProductUnit/index/ProductUnit-index.component";
import { StaffFormComponent } from "./Staff/form/Staff-form.component";
import { StaffIndexComponent } from "./Staff/index/Staff-index.component";
import { DepartmentFormComponent } from "./Department/form/Department-form.component";
import { DepartmentIndexComponent } from "./Department/index/Department-index.component";
import { BusinessTypeFormComponent } from "./BusinessType/form/BusinessType-form.component";
import { BusinessTypeIndexComponent } from "./BusinessType/index/BusinessType-index.component";
import { OilLossRateFormComponent } from "./OilLossRate/form/OilLossRate-form.component";
import { OilLossRateIndexComponent } from "./OilLossRate/index/OilLossRate-index.component";
import { CKEditorModule } from "ngx-ckeditor";
import { ClickOutsideModule } from "ng-click-outside";
import { StudentFormComponent } from "./Student/form/Student-form.component";
import { StudentIndexComponent } from "./Student/index/Student-index.component";
import { MqttModule } from "ngx-mqtt";
import { ScadaIndexComponent } from "./Scada/index/Scada-index.component";
import { environment } from "../../environments/environment";
import { ScadaTankComponent } from "./Scada/index/Scada-tank/index/Scada-tank.component";
import { ScadaTankLogTableComponent } from "./Scada/index/Scada-tank-log-table/Scada-tank-log-table.component";
import { ScadaTankTableComponent } from "./Scada/index/Scada-tank/table/scada-tank-table.component";
import { MasterDataIndexComponent } from "./MasterData/index/MasterData-index.component";
import { MasterDataFormComponent } from "./MasterData/form/MasterData-form.component";
import { CountryIndexComponent } from "./Country/index/Country-index.component";
import { CountryFormComponent } from "./Country/form/Country-form.component";
import { ProvinceIndexComponent } from "./Province/index/Province-index.component";
import { ProvinceFormComponent } from "./Province/form/Province-form.component";
import { CurrencyIndexComponent } from "./Currency/index/Currency-index.component";
import { CurrencyFormComponent } from "./Currency/form/Currency-form.component";
import { PriceRegionIndexComponent } from "./PriceRegion/index/PriceRegion-index.component";
import { PriceRegionFormComponent } from "./PriceRegion/form/PriceRegion-form.component";
import { StockTypeIndexComponent } from "./StockType/index/StockType-index.component";
import { StockTypeFormComponent } from "./StockType/form/StockType-form.component";
import { CreditAreaIndexComponent } from "./CreditArea/index/CreditArea-index.component";
import { CreditAreaFormComponent } from "./CreditArea/form/CreditArea-form.component";
import { ArmRuleIndexComponent } from "./ArmRule/index/ArmRule-index.component";
import { ArmRuleFormComponent } from "./ArmRule/form/ArmRule-form.component";
import { PricingGroupIndexComponent } from "./PricingGroup/index/PricingGroup-index.component";
import { PricingGroupFormComponent } from "./PricingGroup/form/PricingGroup-form.component";
import { LocationIndexComponent } from "./Location/index/Location-index.component";
import { LocationFormComponent } from "./Location/form/Location-form.component";
import { ContractIndexComponent } from "./Contract/index/Contract-index.component";
import { ContractFormComponent } from "./Contract/form/Contract-form.component";
import { ContractTypeIndexComponent } from "./Contract/ContractType/contractType-index/contractType-index.component";
import { ContractTypeFormComponent } from "./Contract/ContractType/contractType-form/contractType-form.component";
import { DebtTypeIndexComponent } from "./Contract/DebtType/index/DebtType-index.component";
import { DebtTypeFormComponent } from "./Contract/DebtType/form/DebtType-form.component";
import { SalesChanelIndexComponent } from "./Contract/SalesChanel/index/SalesChanel-index.component";
import { SalesChanelFormComponent } from "./Contract/SalesChanel/form/SalesChanel-form.component";
import { BankIndexComponent } from "./Bank/index/Bank-index.component";
import { BankFormComponent } from "./Bank/form/Bank-form.component";
import { BankAccountIndexComponent } from "./BankAccount/index/BankAccount-index.component";
import { BankAccountFormComponent } from "./BankAccount/form/BankAccount-form.component";
import { VcfAvgIndexComponent } from "./VcfAvg/index/VcfAvg-index.component";
import { VcfAvgFormComponent } from "./VcfAvg/form/VcfAvg-form.component";
import { TrTypeIndexComponent } from "./TrType/index/TrType-index.component";
import { TrTypeFormComponent } from "./TrType/form/TrType-form.component";
import { ShipPointIndexComponent } from "./ShipPoint/index/ShipPoint-index.component";
import { ShipPointFormComponent } from "./ShipPoint/form/ShipPoint-form.component";
import { CustomerGuaranteeIndexComponent } from "./CustomerGuarantee/index/CustomerGuarantee-index.component";
import { CustomerGuaranteeFormComponent } from "./CustomerGuarantee/form/CustomerGuarantee-form.component";
import { InventoryTypeIndexComponent } from "./InventoryType/index/InventoryType-index.component";
import { InventoryTypeFormComponent } from "./InventoryType/form/InventoryType-form.component";
import { FinGroupIndexComponent } from "./FinGroup/index/FinGroup-index.component";
import { FinGroupFormComponent } from "./FinGroup/form/FinGroup-form.component";
import { FundIndexComponent } from "./Fund/index/Fund-index.component";
import { FundFormComponent } from "./Fund/form/Fund-form.component";
import { GLAccountIndexComponent } from "./GLAccount/index/GLAccount-index.component";
import { GLAccountFormComponent } from "./GLAccount/form/GLAccount-form.component";
import { FinTypeIndexComponent } from "./FinType/index/FinType-index.component";
import { FinTypeFormComponent } from "./FinType/form/FinType-form.component";
import { PriceListFormComponent } from "./PriceList/form/PriceList-form.component";
import { PriceListIndexComponent } from "./PriceList/index/PriceList-index.component";
import { PriceCageoryFormComponent } from "./PriceCategory/priceCategory-form/priceCategory-form.component";
import { PriceCategoryIndexComponent } from "./PriceCategory/priceCategory-index/priceCategory-index.component";
import { DiscountIndexComponent } from "./Discount/index/Discount-index.component";
import { DiscountFormComponent } from "./Discount/form/Discount-form.component";
import { TrtypeFormGeneralComponent } from "./TrType/components/general/trtype-form-general/trtype-form-general.component";
import { TrtypeFormProductComponent } from "./TrType/components/general/trtype-form-product/trtype-form-product.component";
import { ExchangeRateIndexComponent } from "./ExchangeRate/index/ExchangeRate-index.component";
import { ExchangeRateFormComponent } from "./ExchangeRate/form/ExchangeRate-form.component";
import { ExpenseTypeFormComponent } from "./ExpenseType/form/expenseType-form.component";
import { ExpenseTypeIndexComponent } from "./ExpenseType/index/expenseType-index.component";
import { ExpenseItemsFormComponent } from "./ExpenseItems/form/expenseItems-form.component";
import { ExpenseItemsIndexComponent } from "./ExpenseItems/index/expenseItems-index.component";
import { CustomFormComponent } from "./Custom/form/custom-form.component";
import { CustomIndexComponent } from "./Custom/index/custom-index.component";
import { PortFormComponent } from "./Port/form/port-form.component";
import { PortIndexComponent } from "./Port/index/port-index.component";
import { TaxPolicyFormComponent } from "./TaxPolicy/form/taxPolicy-form.component";
import { TaxPolicyIndexComponent } from "./TaxPolicy/index/taxPolicy-index.component";
import { MopsPriceFormComponent } from "./MopsPrice/form/MopsPrice-form.component";
import { MopsPriceIndexComponent } from "./MopsPrice/index/MopsPrice-index.component";
import { StabilizationFundFormComponent } from "./StabilizationFund/form/StabilizationFund-form.component";
import { StabilizationFundIndexComponent } from "./StabilizationFund/index/StabilizationFund-index.component";
import { NgxEchartsModule } from "ngx-echarts";
import { ConsignmentFeeIndexComponent } from "./ConsignmentFee/index/ConsignmentFee-index.component";
import { ConsignmentFeeFormComponent } from "./ConsignmentFee/form/ConsignmentFee-form.component";
import { TrType2CustGroupIndexComponent } from "./TrType2CustGroup/index/TrType2CustGroup-index.component";
import { TrType2CustGroupFormComponent } from "./TrType2CustGroup/form/TrType2CustGroup-form.component";
import { TrType2ReportIndexComponent } from "./TrType2Report/index/TrType2Report-index.component";
import { TrType2ReportFormComponent } from "./TrType2Report/form/TrType2Report-form.component";
import { TrGroupIndexComponent } from "./TrGroup/index/TrGroup-index.component";
import { TrGroupFormComponent } from "./TrGroup/form/TrGroup-form.component";
import { SharedModule } from "../shared/shared.module";
import { ScadaPumpComponent } from "./Scada/index/Scada-pump/index/Scada-pump.component";
import { ScadaPumpTableComponent } from "./Scada/index/Scada-pump/table/scada-pump-table.component";
import { DeliveryMethodIndexComponent } from "./DeliveryMethod/index/DeliveryMethod-index.component";
import { DeliveryMethodFormComponent } from "./DeliveryMethod/form/DeliveryMethod-form.component";
import { StoreIndexComponent } from "./Store/index/Store-index.component";
import { StorePopupComponent } from "./Store/popup/Store-popup.component";
import { StoreFormComponent } from "./Store/form/Store-form.component";
import { StoreTankIndexComponent } from "./Store/tank/index/Store-tank-index.component";
import { StoreTankFormComponent } from "./Store/tank/form/Store-tank-form.component";
import { StorePumpFormComponent } from "./Store/pump/form/Store-pump-form.component";
import { StorePumpIndexComponent } from "./Store/pump/index/Store-pump-index.component";
import { StoreStaffFormComponent } from "./Store/staff/form/Store-staff-form-component";
import { StoreStaffIndexComponent } from "./Store/staff/index/Store-staff-index.component";
import { PriceSettingComponent } from "./price-setting/price-setting.component";
import { POSConfigComponent } from "./POSConfig/index/POSConfig-index.component";
import { StoreInvoiceIndexComponent } from "./Store/invoice/index/Store-invoice-index.component";
import { StoreInvoiceFormComponent } from "./Store/invoice/form/Store-invoice-form.component";
import { OrgUnitSettingIndexComponent } from "./OrgUnitSetting/index/OrgUnitSetting-index.component";
import { OrgUnitSettingFormComponent } from "./OrgUnitSetting/form/OrgUnitSetting-form.component";
import { ManufactureIndexComponent } from "./Manufacture/index/Manufacture-index.component";
import { ManufactureFormComponent } from "./Manufacture/form/Manufacture-form.component";
import { SoftwarePaymentFormComponent } from "./SoftwarePayment/form/SoftwarePayment-form.component";
import { SoftwarePaymentIndexComponent } from "./SoftwarePayment/index/SoftwarePayment-index.component";
import {Driver2VehicleFormComponent} from "./Driver2Vehicle/form/Driver2Vehicle-form.component";
import {Driver2VehicleIndexComponent} from "./Driver2Vehicle/index/Driver2Vehicle-index.component";

@NgModule({
    imports: [
        CommonModule,
        MasterDataRoutes,
        NabitComponentModule,
        CKEditorModule,
        ClickOutsideModule,
        MqttModule.forRoot({
            url: environment.mqttDomain.mqttEndpoint
        }),
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import("echarts"), // or import('./path-to-my-custom-echarts')
        }),
        SharedModule,
    ],
    declarations: [
        ProductIndexComponent,
        ProductFormComponent,
        ProductGroupFormComponent,
        ProductGroupIndexComponent,
        WarehouseFormComponent,
        WarehouseIndexComponent,
        CustomerFormComponent,
        CustomerIndexComponent,
        OrgUnitFormComponent,
        OrgUnitIndexComponent,
        CustomerGroupFormComponent,
        CustomerGroupIndexComponent,
        VehicleFormComponent,
        VehicleIndexComponent,
        TankFormComponent,
        TankIndexComponent,
        PumpStationFormComponent,
        PumpStationIndexComponent,
        ProductSourceFormComponent,
        ProductSourceIndexComponent,
        ProductUnitFormComponent,
        ProductUnitIndexComponent,
        DepartmentFormComponent,
        DepartmentIndexComponent,
        StaffFormComponent,
        StaffIndexComponent,
        BusinessTypeFormComponent,
        BusinessTypeIndexComponent,
        OilLossRateFormComponent,
        OilLossRateIndexComponent,
        StudentFormComponent,
        StudentIndexComponent,
        ScadaIndexComponent,
        ScadaTankComponent,
        ScadaTankLogTableComponent,
        ScadaTankTableComponent,
        ScadaPumpComponent,
        ScadaPumpTableComponent,
        MasterDataIndexComponent,
        MasterDataFormComponent,
        CountryIndexComponent,
        CountryFormComponent,
        ProvinceIndexComponent,
        ProvinceFormComponent,
        CurrencyIndexComponent,
        CurrencyFormComponent,
        PriceRegionIndexComponent,
        PriceRegionFormComponent,
        StockTypeIndexComponent,
        StockTypeFormComponent,
        CreditAreaIndexComponent,
        CreditAreaFormComponent,
        ArmRuleIndexComponent,
        ArmRuleFormComponent,
        VehicleTypeIndexComponent,
        VehicleTypeFormComponent,
        VehicleClassIndexComponent,
        VehicleClassFormComponent,
        PricingGroupIndexComponent,
        PricingGroupFormComponent,
        LocationIndexComponent,
        LocationFormComponent,
        ContractIndexComponent,
        ContractFormComponent,
        ContractTypeIndexComponent,
        ContractTypeFormComponent,
        DebtTypeIndexComponent,
        DebtTypeFormComponent,
        SalesChanelIndexComponent,
        SalesChanelFormComponent,
        BankIndexComponent,
        BankFormComponent,
        BankAccountIndexComponent,
        BankAccountFormComponent,
        VcfAvgIndexComponent,
        VcfAvgFormComponent,
        TrTypeIndexComponent,
        TrTypeFormComponent,
        ShipPointIndexComponent,
        ShipPointFormComponent,
        CustomerGuaranteeIndexComponent,
        CustomerGuaranteeFormComponent,
        InventoryTypeIndexComponent,
        InventoryTypeFormComponent,
        FinGroupIndexComponent,
        FinGroupFormComponent,
        FundIndexComponent,
        FundFormComponent,
        GLAccountIndexComponent,
        GLAccountFormComponent,
        FinTypeIndexComponent,
        FinTypeFormComponent,
        PriceListFormComponent,
        PriceListIndexComponent,
        PriceCategoryIndexComponent,
        PriceCageoryFormComponent,
        DiscountIndexComponent,
        DiscountFormComponent,
        TrtypeFormGeneralComponent,
        TrtypeFormProductComponent,
        ExchangeRateIndexComponent,
        ExchangeRateFormComponent,
        ExpenseTypeFormComponent,
        ExpenseTypeIndexComponent,
        ExpenseItemsFormComponent,
        ExpenseItemsIndexComponent,
        CustomFormComponent,
        CustomIndexComponent,
        PortFormComponent,
        PortIndexComponent,
        TaxPolicyFormComponent,
        TaxPolicyIndexComponent,
        MopsPriceFormComponent,
        MopsPriceIndexComponent,
        StabilizationFundFormComponent,
        StabilizationFundIndexComponent,
        ConsignmentFeeIndexComponent,
        ConsignmentFeeFormComponent,
        TrType2CustGroupIndexComponent,
        TrType2CustGroupFormComponent,
        TrType2ReportIndexComponent,
        TrType2ReportFormComponent,
        TrGroupIndexComponent,
        TrGroupFormComponent,
        DeliveryMethodFormComponent,
        DeliveryMethodIndexComponent,
        StoreIndexComponent,
        StorePopupComponent,
        StoreFormComponent,
        StoreTankIndexComponent,
        StoreTankFormComponent,
        StorePumpIndexComponent,
        StorePumpFormComponent,
        StoreStaffIndexComponent,
        StoreStaffFormComponent,
        StoreInvoiceIndexComponent,
        StoreInvoiceFormComponent,
        PriceSettingComponent,
        POSConfigComponent,
        OrgUnitSettingIndexComponent,
        OrgUnitSettingFormComponent,
        ManufactureIndexComponent,
        ManufactureFormComponent,
        SoftwarePaymentIndexComponent,
        SoftwarePaymentFormComponent,
        Driver2VehicleFormComponent,
        Driver2VehicleIndexComponent
    ], exports: [VehicleFormComponent, ScadaTankTableComponent, ScadaTankComponent]
})
export class MasterDataModule {
}
