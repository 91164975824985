import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { VehicleFormComponent } from '../form/Vehicle-form.component';
import { VehicleApiService } from '../../Services/Vehicle-api.service';

@Component({
    selector: 'app-vehicle-index',
    templateUrl: './Vehicle-index.component.html',
    styleUrls: ['./Vehicle-index.component.css']
})
export class VehicleIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild('pEdit') pEdit: VehicleFormComponent;
    arrVehicleTypeId = [];
    arrVehicleClassId = [];
    vehicleTypeId = 0;
    vehicleClassId = 0;
    tabMenuItems = [];
    constructor(
        protected _VehicleApiService: VehicleApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_VehicleApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [ 
            {label: this._translateService.instant('Phương tiện V/C'), icon: 'pi pi-book', routerLink: '../Vehicle'}, 
            {label: this._translateService.instant('Loại phương tiện'), icon: 'pi pi-book', routerLink: '../VehicleType'}, 
            {label: this._translateService.instant('Phương thức V/C'), icon: 'pi pi-book', routerLink: '../VehicleClass'}, 
        ];
        this.cols = [
            { field: 'vehicleCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'vehicleName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'numberPlate', header: this._translateService.instant('TABLEDATA.NumberPlate'), visible: true, width: 'auto', sort: true,},
            { field: 'driverName', header: this._translateService.instant('TABLEDATA.driverName'), visible: false, width: 'auto', sort: true,  dateFormat: 'dd/MM/yyyy HH:mm'},
            { field: 'vehicleTypeName', header: this._translateService.instant('TABLEDATA.vehicleTypeName'), visible: true, width: 'auto', sort: true,  dateFormat: 'dd/MM/yyyy HH:mm'},
            { field: 'vehicleClassName', header: this._translateService.instant('TABLEDATA.vehicleClassName'), visible: true, width: 'auto', sort: true,  dateFormat: 'dd/MM/yyyy HH:mm'},
            { field: 'inspectionExpire', header: this._translateService.instant('TABLEDATA.InspectionExpire'), visible: false, width: 'auto', sort: true,  dateFormat: 'dd/MM/yyyy HH:mm'},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true,},
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true,},
        ];

        this.arrVehicleTypeId = [
            { label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 },
            { label: 'Xe kamaz', value: 1 },
            { label: 'Xe Hyundai', value: 2 },
            { label: 'Xe Hino', value: 2 },
        ];

        this.arrVehicleClassId = [
            { label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 },
            { label: 'Tàu biển', value: 1 },
            { label: 'Xe Container', value: 2 },
            { label: 'Xe bồn', value: 3 },
            { label: 'Xà lan', value: 4 },
        ];

        this.getData();

        
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = []; 
        var model = {
            keyword: this.query,
            status: this.status, 
            vehicleTypeId: this.vehicleTypeId,
            vehicleClassId: this.vehicleClassId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._VehicleApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }
}