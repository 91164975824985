import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ExchangeRateApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/ExchRate`);
    }

    getsByDate(bankId: number, currencyId: number, atDate: Date) {
        let a = {
            bankId,
            currencyId,
            atDate
        }
        let apiUrl = `${this.serviceUri}/GetsByDate`;

        return this._http.post(apiUrl, a)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise() as Promise<ResponseResult>;
    }
}
