import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { TrTypeApiService } from '../../Services/TrType-api.service';
import { PriceCategoryApiService } from "../../Services/PriceCategory-api.service";
import { requirements } from "../components/shared";
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
    selector: 'app-trType-form',
    templateUrl: './TrType-form.component.html',
    styleUrls: ['./TrType-form.component.css']
})

export class TrTypeFormComponent extends SecondPageEditBase implements OnInit {
    directions = [-1, 1]
    requirements = requirements;
    activeIndex = 0;

    fundOptions = [
        { label: "N/A", value: "0" },
        { label: "Default", value: "1" },
        { label: "Manual", value: "2" },
    ]

    priceCategoryList = []

    constructor(
        private _route: ActivatedRoute,
        protected TrTypeApiService: TrTypeApiService,
        protected PriceCategoryApiService: PriceCategoryApiService,
        protected _injector: Injector,
    ) {
        super(TrTypeApiService, _injector);

        this.formGroup = new FormGroup({
            idTrGroup: new FormControl(''),
            trCode: new FormControl(''),
            trName: new FormControl(''),
            SubSystemId: new FormControl(''),
            trDirection: new FormControl(''),
            parentReq: new FormControl(''),
            parentType: new FormControl(''),
            payDirection: new FormControl('', []),
            parentDirection: new FormControl(''),
            useFund: new FormControl(''),
            priceCategory: new FormControl(''),         //Check lại priceCategoryId
            trCurrency: new FormControl(''),
            tranMan: new FormControl(),                 //Trước là CustAgent
            custDelegationReq: new FormControl(),          //Cũ: custInvoice
            customerReq: new FormControl(''),
            custAgentReq: new FormControl(),               //Check lại custAgentReq
            invoiceReq: new FormControl(),              //Cũ: custAgen => Thông tin HĐ riêng
            vehicleReq: new FormControl(''),            //Cũ: useTransport
            shippingReq: new FormControl(''),
            restrictTransportToContract: new FormControl(),
            numberOfProduct: new FormControl('', [Validators.min(0)]),
            productqualification: new FormControl(),
            priceRequired: new FormControl(),
            caseReq: new FormControl(),
            rtd: new FormControl('', [Validators.min(0)]),
            autoDiscount: new FormControl(),
            bonusFactor: new FormControl(),
            whsrc: new FormControl(),
            whdst: new FormControl(),
            toProdClass: new FormControl(),
            toProd: new FormControl(),
            invoiceSerialReq: new FormControl(),
            invoiceNumberReq: new FormControl(),
            workshiftReq: new FormControl(),
            paymentFormReq: new FormControl(),
            allowForeignCurrency: new FormControl(),
            allowUseAP: new FormControl(),
            cashOnly: new FormControl(),
            res1: new FormControl(),
            res2: new FormControl(),
            res3: new FormControl(),
            res4: new FormControl(),
            parentSelectBox: new FormControl(),
            paymentSerial: new FormControl(),
            saleschannelReq: new FormControl(),
            contractReq: new FormControl(''),
            useDensity: new FormControl(''),
            useQty2: new FormControl(''),
            useKg: new FormControl(''),
            allowEditQty2: new FormControl(''),
            clientTrCode: new FormControl(''),
            productsourceReq: new FormControl(''),
            transportDSTReq: new FormControl(''),
            importTaxReq: new FormControl(''),
            specialTaxReq: new FormControl(''),
            otherTaxReq: new FormControl(''),
            tankReq: new FormControl(''),
            whRefReq: new FormControl(''),
            secondFund: new FormControl(''),
            parentStatus: new FormControl(''),
            shortName: new FormControl(''),
            enablePriceRegion: new FormControl(''),
            costPriceReq: new FormControl(''),
            allowPRRepeat: new FormControl(''),
            priceCodeRTD: new FormControl(''),
            isOrder: new FormControl(''),
            isStore: new FormControl(''),
            isInvoice: new FormControl(''),
            isSale: new FormControl(''),
            isRevenue: new FormControl(''),
            isFIN: new FormControl(''),
            isClosingStock: new FormControl(''),
            useRemainQty: new FormControl(''),
            status: new FormControl(''),
            attachmentReq: new FormControl('')
        });

    }

    async ngOnInit() {
        this.refreshRoute(this._route.snapshot.paramMap);
        await this.getData();
    }

    refreshRoute(s: ParamMap) {
        this.itemDetail.id = +(s.get("Id") || 0);
    }

    cancel() {
        history.back();
    }

    async getData() {
        await this.PriceCategoryApiService.getsInUse()
            .then(rs => {
                this.priceCategoryList = rs.data;
            })
        this.submitting = true;
        if (this.itemDetail.id > 0) {
            this.TrTypeApiService.getDetail(this.itemDetail.id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
        this.activeIndex = 0;
    }
}

