import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportRoutes } from './RPT.routing';
import { NabitComponentModule } from 'nabit-shared';
import { ReportFormComponent } from './Report/form/Report-form.component';
import { ReportIndexComponent } from './Report/index/Report-index.component';
import { ReportViewerComponent } from './ReportViewer/ReportViewer.component';
import { FilterConditionFormComponent } from './FilterCondition/form/FilterCondition-form.component';
import { FilterConditionIndexComponent } from './FilterCondition/index/FilterCondition-index.component';
import { DocViewerComponent } from './DocViewer/DocViewer.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReportGroupIndexComponent } from './ReportGroup/index/ReportGroup-index.component';
import { ReportGroupFormComponent } from './ReportGroup/form/ReportGroup-form.component';
@NgModule({
    imports: [
        CommonModule,
        ReportRoutes,
        NabitComponentModule,
        NgxSpinnerModule
    ],
    exports: [
        DocViewerComponent,
        ReportViewerComponent
    ],
    declarations: [
        ReportFormComponent,
        ReportIndexComponent,
        ReportViewerComponent,
        FilterConditionFormComponent,
        FilterConditionIndexComponent,
        DocViewerComponent,
        ReportGroupIndexComponent,
        ReportGroupFormComponent,
    ]
})
export class RPTModule { }
