import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { Console } from 'console';
// @ts-ignore
import { OilLossRateApiService } from '../../Services/OilLossRate-api.service';
import { ProductApiService } from '../../Services/Product-api.service';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';


@Component({
    selector: 'app-oilLossRate-form',
    templateUrl: './OilLossRate-form.component.html',
    styleUrls: ['./OilLossRate-form.component.css']
})
export class OilLossRateFormComponent extends SecondPageEditBase implements OnInit {

    checked: boolean = false;
    RegexCode = "^[a-z0-9A-Z]{8}$";
    RegexName = "^[a-z0-9A-Z._-]{1,30}$";
    arrProduct = [];
    arrBranch = [];

    constructor(
        protected _OilLossRateApiService: OilLossRateApiService,
        protected _injector: Injector,
        private _productAPIService: ProductApiService,
        private _orgUnitAPIService: OrgUnitApiService,
    ) {
        super(_OilLossRateApiService, _injector);

        this.formGroup = new FormGroup({
            docNum: new FormControl('', [Validators.required, Validators.pattern(this.RegexCode)]),
            lossType: new FormControl(''),
            validFrom: new FormControl(''),
            branchId: new FormControl('', [Validators.required]),
            productId: new FormControl('', [Validators.required]),
            percentHH_In: new FormControl(''),
            percentHH_Out: new FormControl(''),
            percentHH_Store: new FormControl(''),
            percentHH_Clean: new FormControl(''),
            tankType: new FormControl(''),
            status: new FormControl(''),
        });
    }

    ngOnInit() {
    }

    async onShowPopup(id: number) {
        if (this.arrProduct.length == 0) {
            await this.getsProduct();
        }

        if (this.arrBranch.length == 0) {
            await this.getsBranch();
        }
        this.submitting = true;
        if (id > 0) {
            this._OilLossRateApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }

                    if (this.itemDetail.validFrom) {
                        this.itemDetail.validFrom = new Date(this.itemDetail.validFrom);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }

    async getsProduct() {
        await this._productAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.arrProduct = [];
                this.convertDataToOptions(this.arrProduct, rs.data, 'productName');
            }
        })
    }

    async getsBranch() {
        var model = {
            keyword: "",
            status: 1,
            CustomerGroup : 0,
            BussinessType : 0,
            Branch : 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: "",
            isDesc: true
        }
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, 'customerName');
            }
        })
    }

}

