import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, CoreUser, HtMenuService, UserService } from 'nabit-shared';
import { MenuRootService } from '../services/menu-root.service';

@Component({
  selector: 'app-StartPage',
  templateUrl: './StartPage.component.html',
  styleUrls: ['./StartPage.component.scss']
})
export class StartPageComponent implements OnInit {

  currentUser: CoreUser = new CoreUser();
  constructor(
    private router: Router,
    protected _userService: UserService,
    protected _menuService: HtMenuService,
    protected _menuRootService:MenuRootService,
    private _authenticationService: AuthenticationService,
  ) { 
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  async ngOnInit() {
    let menu = await this._menuService.getDetailById(this.currentUser.startPageId).then(rs => {
        if (rs.success) return rs.data;
    });
    if (menu) {
        let module = menu.menuLevel
            .split(';')
            .filter(Boolean)
            [1];
        this._menuRootService.setParent(+module);
    }
    if (menu?.url) {
      top.location.href = '#' + menu?.url;
    }
  }
}