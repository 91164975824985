import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService, ResponseResult } from "nabit-shared";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class OrgUnitSettingApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/OrgUnitSetting`);
    }

    GetsBySettingFilter(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsBySettingFilter`;
        return this._http.post<ResponseResult>(apiUrl, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
