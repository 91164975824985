import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { ShipPointFormComponent } from '../form/ShipPoint-form.component';
import { ShipPointApiService } from '../../Services/ShipPoint-api.service';

@Component({
    selector: 'app-shipPoint-index',
    templateUrl: './ShipPoint-index.component.html',
    styleUrls: ['./ShipPoint-index.component.css']
})
export class ShipPointIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: ShipPointFormComponent;
    tabMenuItems = [];
    constructor(
        protected _ShipPointApiService: ShipPointApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_ShipPointApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Cung đường'), icon: 'pi pi-book', routerLink: '../ShipPoint'}
        ];
        this.cols = [
            { field: 'code', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'name', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'srcPoint', header: this._translateService.instant('Điểm đầu'), visible: true, width: 'auto', sort: true,},
            { field: 'dstPoint', header: this._translateService.instant('Điểm cuối'), visible: true, width: 'auto', sort: true,},
            { field: 'distance', header: this._translateService.instant('Khoảng cách'), visible: true, width: 'auto', sort: true,},
            { field: 'type', header: this._translateService.instant('Loại'), visible: true, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        let model = {
            keyword: this.query,
            status: this.status,
            pageIndex: 1,
            pageSize: 999999,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this._baseService.Find(model).then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
