import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { WarehouseFormComponent } from '../form/Warehouse-form.component';
import { WarehouseApiService } from '../../Services/Warehouse-api.service';
import { ProductSourceApiService } from '../../Services/ProductSource-api.service';

@Component({
    selector: 'app-warehouse-index',
    templateUrl: './Warehouse-index.component.html',
    styleUrls: ['./Warehouse-index.component.css']
})
export class WarehouseIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: WarehouseFormComponent;
    arrBranchId = [];
    arrProductsource = [];
    productSourceId = 0;
    branchId = 0;
    tabMenuItems = [];

    constructor(
        protected _WarehouseApiService: WarehouseApiService,
        protected _injector: Injector,
        public app: AppComponent,
        private _productSourceAPIService: ProductSourceApiService,
    ) {
        super(_WarehouseApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Kho hàng'), icon: 'pi pi-book', routerLink: '../Warehouse'}, 
        ];
        this.cols = [
            { field: 'warehouseCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'warehouseName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'shortName', header: this._translateService.instant('TABLEDATA.SHORTNAME'), visible: true, width: 'auto', sort: true,},
            { field: 'address', header: this._translateService.instant('TABLEDATA.ADDRESS'), visible: false, width: 'auto', sort: true,},
            { field: 'branchId', header: this._translateService.instant('TABLEDATA.BRANCHID'), visible: true, width: 'auto', sort: true,},
            { field: 'productSourceId', header: this._translateService.instant('TABLEDATA.PRODUCTSOURCEID'), visible: false, width: 'auto', sort: true,},
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true,},
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];
        this.getData();
        this.getsProductSource();
        this.arrBranchId = [
            { label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 },
            { label: 'Bắc giang', value: 1 },
            { label: 'Hà nội', value: 2 },
        ];
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    async getsProductSource() {
        this.arrProductsource = [];
        this.arrProductsource = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._productSourceAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProductsource, rs.data, 'productSourceName');
            }
        })
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = []; 
        var model = {
            keyword: this.query,
            status: this.status, 
            productSourceId: this.productSourceId,
            branchId: this.branchId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._WarehouseApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

}
