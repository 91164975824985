<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Thanh toán thuê bao' | translate }}" [(visible)]="isShow"
    [modal]="true" [styleClass]="'ag-modal force-full-width'" [style]="{'width': '640px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="col-12 md:col-12 p-fluid formgrid grid">
                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Cửa hàng' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="orgUnitId" [options]="arrOrgUnit" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [ngModel]="itemDetail.orgUnitId"
                            (ngModelChange)="itemDetail.orgUnitId = $event; changeOrgUnit($event)"
                            [disabled]="doingSaved"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Ngày thanh toán' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-calendar formControlName="paymentDate"
                            [ngModel]="itemDetail.paymentDate"
                            (ngModelChange)="itemDetail.paymentDate = $event; updateNewExpiredDate()"
                            dateFormat="dd/mm/yy" showIcon="true"></p-calendar>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Số tiền thanh toán' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-inputNumber formControlName="paymentAmount"
                            [(ngModel)]="itemDetail.paymentAmount"></p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Số ngày gia hạn' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-inputNumber formControlName="additionDay"
                            [ngModel]="itemDetail.additionDay"
                            (ngModelChange)="itemDetail.additionDay = $event; updateNewExpiredDate()"></p-inputNumber>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Ngày gia hạn cũ' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-calendar formControlName="oldExpiredDate"
                            [ngModel]="itemDetail.oldExpiredDate"
                            dateFormat="dd/mm/yy"></p-calendar>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Ngày gia hạn mới' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-calendar formControlName="newExpiredDate"
                            [ngModel]="itemDetail.newExpiredDate"
                            dateFormat="dd/mm/yy"></p-calendar>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-12 md:col-8">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="true"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-trash"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>
