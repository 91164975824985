import {RequirementType, SelectType, TrDirection} from "nabit-shared";

const requirements = [
    { label: "N/A", value: RequirementType.NotAvailable },
    { label: "Optional", value: RequirementType.Optional },
    { label: "Required", value: RequirementType.Required },
    { label: "Fund", value: RequirementType.Fund },
    { label: "Batch", value: RequirementType.Batch },
]
const selectValues : {label:string, value:string}[] = [
    { label: "N/A", value: SelectType.NotAvailable },
    { label: "[Default]", value: SelectType.Default },
    { label: "[Manual]", value: SelectType.Manual },
]
const trDirections = [
    { label: 'Xuất', value: TrDirection.Export },
    { label: 'Nhập', value: TrDirection.Import },
]

export { requirements, trDirections, selectValues };
