import { Output, EventEmitter, ViewChild, Injector, ElementRef, OnInit, HostListener, Directive, AfterViewInit } from '@angular/core';
import { ComponentBase } from './component-base';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationSummaryComponent } from '../../components/validation-summary/validation-summary.component';
import { NotifierService } from '../../services/notifier.service';
import { BaseService } from '../../services/base.service';
import { Dialog } from 'primeng/dialog';
import { CommonService } from '../../services/common.service';
import { CoreUser } from '../../models/core-user';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export class SecondPageEditBase extends ComponentBase implements OnInit, AfterViewInit {

    @ViewChild('validationSummary') validationSummary: ValidationSummaryComponent;
    @ViewChild('dialog') dialog: Dialog;
    @ViewChild('formElement') formElement: ElementRef;

    isShow = false;
    submitting = false;
    continueAdding = true;
    itemDetail: any = {};

    get doingSaved() {
        return this.itemDetail.id > 0;
    }

    popupHeader = '';
    popupId: string;
    formGroup: FormGroup = new FormGroup({});
    arrStatus = [];

    currentUser: CoreUser = new CoreUser();
    protected _translateService: TranslateService;
    protected _notifierService: NotifierService;
    protected _authenticationService: AuthenticationService;
    closePopupData = true;

    @Output() closePopup = new EventEmitter<any>();

    constructor(
        protected _baseService: BaseService,
        protected _injector: Injector,

    ) {
        super();
        this._authenticationService = this._injector.get(AuthenticationService);
        this._notifierService = this._injector.get(NotifierService);
        this._translateService = this._injector.get(TranslateService);
        this.popupId = this._injector.get(CommonService).guid();
        this.currentUser = this._authenticationService.getCurrentUser();
        this.arrStatus = [
            { label: this._translateService.instant('STATUS.USED'), value: 1 },
            { label: this._translateService.instant('STATUS.UNUSED'), value: -1 },
        ];
    }
    ngAfterViewInit(): void {
        const input = this.formElement?.nativeElement.querySelector('input');
        if (input) {
            input.focus();
        }
    }

    ngOnInit(): void {
        this.continueAdding = true;
    }

    convertDataToOptions(options: any[], data: any[], fieldName: string, sortCol:string = "", separate:string = " - ") {
        if (data.length > 0) {
            if(sortCol!=""){
                data.sort((a, b) =>{
                    const nameA = a[sortCol].toUpperCase();
                    const nameB = b[sortCol].toUpperCase();
                    if (nameA < nameB) {return -1;}
                    if (nameA > nameB) {return 1;}
                    return 0;
                })
            }
            options.length = 0;
            data.map(item =>{
                var label = "";
                fieldName.split(",").forEach(field => {
                    label += item[field] + separate;
                });
                options.push({ label: label.slice(0, -separate.length), value: item.id })
            }
            );
        }
    }
    saveAndClose() {
        this.continueAdding = false;
        this.closePopupData = null;
        this.save();
    }
    save() {
        this.submitting = true;
        new Promise(r => setTimeout(r, 0))
            .then(() => this.internalSave())
    }

    internalSave() {
        if (this.formGroup.invalid) {
            this.showValidateMessage();
            this.scrollToTop();
            return;
        }
        const rs1 = this.onBeforeSave();
        if ((typeof rs1 === 'boolean' && rs1) || typeof rs1 !== 'boolean') {
            if (this.itemDetail['id']) {
                this.onUpdate();
            } else {
                this.onInsert();
            }
        } else {
            this.submitting = false;
        }
        const input = this.formElement?.nativeElement?.querySelector('input');
        if (input) {
            input.focus();
        }
    }

    saveAsCopy() {
        this.itemDetail.id = 0;
        this.continueAdding = false;
        this.closePopupData = null;
        this.save();
    }

    showValidateMessage() {
        this.validationSummary?.showValidationSummary();
        this.submitting = false;
    }

    onBeforeSave(): boolean | void {
        return true;
    }

    onAfterSave(isUpdate: boolean = true) {
        if (isUpdate) {
            this._notifierService.showUpdateDataSuccess();
        } else {
            this._notifierService.showInsertDataSuccess();
        }
    }

    async showPopup(data = null) {
        this.isShow = true;
        this.continueAdding = true;
        this.validationSummary?.resetErrorMessages();
        await this.onShowPopup(data);
        setTimeout(() => {
            const input = this.formElement.nativeElement.querySelector('input');
                if (input) {
                    input.focus();
            }
        }, 500);
    }

    onShowPopup(data: any) {

        this.resetForm();

        this.onBeforeShowPopUp();

        if (data) {
            this._baseService.getDetail(data)
                .then(async response => {
                    this.itemDetail = response.data;
                    this.onAfterShowPopUp();
                }, () => {
                    this._notifierService.showHttpUnknowError();
                });
        }else{
            this.itemDetail = { id:0 };
        }
    }

    onReset() {}

    onAfterShowPopUp() {

    }

    onBeforeShowPopUp() {

    }

    closePopupMethod(data: any) {
        this.isShow = false;
        this.closePopup.next(data);
    }

    getMaxWidthDialog() {
        return (window.innerHeight - 200).toString() + 'px';
    }

    onInsert() {

        this._baseService.post(this.itemDetail)
            .then(response => {
                if (response.error?.toString() == "ERR.DATA_DUPLICATED") {
                    if(response.message.toString() != "")
                    {
                        this._notifierService.showDuplicateData(response.message.toString().split("Œ¿")[0].toString(), response.message.toString().split("Œ¿")[1].toString());
                    }
                    else
                        this._notifierService.showInsertDataFailed();
                    this.submitting = false;
                }
                else {
                    this.itemDetail.id = response.data;
                    if (this.continueAdding) {
                        this.resetForm();
                    } else {
                        this.closePopupMethod(true);
                    }
                    this.onAfterSave(false);
                    this.submitting = false;
                }

            }, () => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }

    onUpdate() {
        this._baseService.put(this.itemDetail['id'].toString(), this.itemDetail)
            .then(response => {
                if (response.error?.toString() == "ERR.DATA_DUPLICATED") {
                    this._notifierService.showUpdateDataSingleFailed();
                    this.submitting = false;
                }
                else {
                    this.closePopupMethod(true);
                    this.onAfterSave(true);
                    this.submitting = false;
                }

            }, () => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    cancel() {
        this.closePopupMethod(true);
    }

    resetForm() {
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        this.itemDetail = { id: 0, status: 1 };
        this.submitting = false;
    }

    scrollToTop() {
        if (this.formElement) {
            setTimeout(() => {
                this.formElement.nativeElement.scrollIntoView();
            }, 500);
        }
    }

    onInitEditor(event: any) {
        const commonService = <CommonService>this._injector.get(CommonService);
        commonService.addTabDisabledHandler(event);
    }

    isValid() {
        if (this.formGroup.invalid) {
            this.showValidateMessage();
            return this.formGroup.invalid;
        }

        return this.formGroup.valid;
    }

    delete() {
        this._notifierService.showDeleteConfirm().then(() => {
            this._baseService.delete(this.itemDetail.id)
                .then(() => {
                    this.closePopupMethod({});
                    this._notifierService.showDeleteDataSuccess();
                }, () => {
                    this._notifierService.showDeleteDataError();
                });
        });
    }

    getMaxDialogHeight() {
        return (window.innerHeight - 200).toString() + 'px';
    }

    @HostListener('window:keydown.alt.s', ['$event'])
    keyEventN(event: KeyboardEvent) {
        this.save();
    }

    cancelEdit() {
        history.back();
    }
}
