import { Component, Injector, Input, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService, SecondPageIndexBase } from "nabit-shared";
import { StorePumpFormComponent } from "../form/Store-pump-form.component";
import { PumpStationApiService } from "../../../Services/PumpStation-api.service";
import { AppComponent } from "../../../../app.component";
import { OrgUnitApiService } from "../../../Services/OrgUnit-api.service";
import { TankApiService } from "../../../Services/Tank-api.service";
import { ProductApiService } from "../../../Services/Product-api.service";

@Component({
    selector: "app-store-pump-index",
    templateUrl: "./Store-pump-index.component.html",
    styleUrls: ["./Store-pump-index.component.css"]
})
export class StorePumpIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild("pEdit") pEdit: StorePumpFormComponent;

    arrBranch = [];
    arrTank = [];
    arrTHD = [];
    arrProduct = [];
    tankId = 0;
    @Input() branchId = 0;
    tabMenuItems = [];

    constructor(
        protected _PumpStationApiService: PumpStationApiService,
        protected _injector: Injector,
        public app: AppComponent,
        private _orgUnitAPIService: OrgUnitApiService,
        private _tankAPIService: TankApiService,
        private _authenticationService: AuthenticationService,
        private _productAPIService: ProductApiService,
    ) {
        super(_PumpStationApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant("Cần xuất"), icon: "pi pi-book", routerLink: "../PumpStation" },
        ];

        this.cols = [
            { field: "pumpCode", header: this._translateService.instant("FORM.CODE"), visible: true, width: "auto", sort: true, },
            { field: "pumpName", header: this._translateService.instant("Tên vòi"), visible: true, width: "auto", sort: true, },
            { field: "tankId", header: this._translateService.instant("TABLEDATA.TANKID"), visible: true, width: "auto", sort: true, },
            { field: "branchId", header: this._translateService.instant("TABLEDATA.BRANCH"), visible: false, width: "auto", sort: true, },
            { field: "isAuto", header: this._translateService.instant("TABLEDATA.isAuto"), visible: true, width: "auto", sort: true, },
            {
                field: "modified",
                header: this._translateService.instant("TABLEDATA.MODIFIED"),
                visible: false,
                width: "auto",
                sort: true,
                dateFormat: "dd/MM/yyyy HH:mm"
            },
            {
                field: "modifiedBy",
                header: this._translateService.instant("TABLEDATA.MODIFIED_BY"),
                visible: false,
                width: "auto",
                sort: true,
            },
            { field: "status", header: this._translateService.instant("TABLEDATA.STATUS"), visible: true, width: "auto", sort: true, },
        ];
        this.branchId = this._authenticationService.getCurrentUser().branchId;
        this.getsTank();
        this.getsBranch();
        this.getsProduct();
        this.getData();

        this.arrTHD = [
            { label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 },
            { label: "Có TĐH", value: 1 },
            { label: "Không", value: 2 },
        ];
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
        this.pEdit.pumpAmount = this.totalRecord + 1;
    }

    getData() {
        const branchId = this._authenticationService.getCurrentUser().branchId;
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        this._PumpStationApiService.getsByBranchId(branchId)
            .then(response => {
                if (response.success) {
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

    async getsTank() {
        this.arrTank = [];
        this.arrTank = [{ label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 }];
        await this._tankAPIService.getsByBranchId(this.branchId).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrTank, rs.data, "tankName");
            }
        });
    }

    async getsBranch() {
        let model = {
            keyword: "",
            status: 1,
            CustomerGroup: 0,
            BussinessType: 0,
            Branch: 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        };
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, "customerName");
            }
        });
    }

    async getsProduct() {
        this.arrProduct = [];
        this.arrProduct = [{ label: this._translateService.instant("LIST.DROPDOWN_ALL"), value: 0 }];
        await this._productAPIService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrProduct, rs.data, "productName");
            }
        });
    }
}
