import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NozzleData, Pump } from "../../Scada-interfaces";
import { getBgColor, getFgColor } from "../../../../../dashboard/lib";

const CONNECTED = '000';
const NOCONNECTION = '---';
const ErrorList = [
    { code: CONNECTED, name: '' },
    { code: NOCONNECTION, name: 'Mất kết nối thiết bị TĐH' },
    { code: "A01", name: "Kết nối vòi bơm timeout" },
    { code: "A02", name: "Mất kết nối vòi bơm" },
    { code: "E01", name: "Lỗi khởi tạo thẻ nhớ" },
    { code: "E02", name: "Lỗi đọc cấu hình từ thẻ nhớ" },
    { code: "E03", name: "Lỗi ghi giao dịch vào thư mục log" },
    { code: "E04", name: "Lỗi ghi giao dịch vào thư mục đồng bộ Sync" },
    { code: "E05", name: "Lỗi ghi hồ sơ giá vào thẻ nhớ" },
    { code: "E06", name: "Lỗi đọc giao dịch từ thư mực Sync" },
    { code: "E07", name: "Lỗi đọc hồ sơ giá từ thẻ nhớ" },
];
const NozzleStateList = [
    { code: 1, name: "GÁC CẦN" },
    { code: 2, name: "NHẤC CẦN" },
    { code: 3, name: "ĐANG BƠM" },
    { code: 4, name: "BƠM XONG" },
    { code: 5, name: "KHOÁ VÒI" },
    { code: 9, name: "ERROR" },
];

@Component({
    selector: 'app-scada-pump',
    templateUrl: './Scada-pump.component.html',
    styleUrls: ['./Scada-pump.component.css']
})
export class ScadaPumpComponent implements OnInit, OnChanges {
    @Input() pump: Pump;
    @Input() pumpData?: NozzleData;
    @Input() productData?: any;
    public showPumpError: boolean = false;
    public errorCode?: string;
    public errorName?: string;
    public pumpState?: string;

    constructor() {
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.pumpData) {
            this.showPumpError = true;
            this.errorCode = NOCONNECTION;
            this.errorName = ErrorList.find(e => e.code === this.errorCode).name;
            return;
        }
        if (this.pumpData.AlertCode === CONNECTED) {
            this.showPumpError = false;
            this.errorCode = CONNECTED;
            this.errorName = 'Bình thường';
            this.pumpState = NozzleStateList.find(s => s.code === this.pumpData.NozzleState).name;
        } else {
            this.showPumpError = true;
            let err = ErrorList.find(e => e.code === this.pumpData.AlertCode);
            if (!err) {
                this.errorCode = this.pumpData.AlertCode;
                this.errorName = "Lỗi chưa định nghĩa!";
            } else {
                this.errorCode = err.code;
                this.errorName = err.name;
            }
        }
    }

    getBgColor() {
        return getBgColor(this.productData?.productCode);
    }

    getFgColor() {
        return getFgColor(this.productData?.productCode);
    }
}
