import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { CustomApiService } from '../../Services/Custom-api.service';
import { CountryApiService } from '../../Services/Country-api.service';
@Component({
    selector: 'app-custom-form',
    templateUrl: './custom-form.component.html',
    styleUrls: ['./custom-form.component.css']
})
export class CustomFormComponent extends SecondPageEditBase implements OnInit {
    arrCountry: any = [];
    constructor(
        protected _countryApiService: CountryApiService,
        protected _customApiService: CustomApiService,
        protected _injector: Injector,
    ) {
        super(_customApiService, _injector);

        this.formGroup = new FormGroup({
            customCode: new FormControl('', Validators.required),
            customName: new FormControl('', Validators.required),
            shortName: new FormControl(''),
            address: new FormControl(''),
            countryId: new FormControl(''),
            status: new FormControl(''),
        });
    }

    ngOnInit() {
        this._countryApiService.getsInUse().then((rs) => {
            console.log(rs);
            if (rs.success) {
                this.convertDataToOptions(this.arrCountry, rs.data, "code,name", "code");
                console.log(this.arrCountry);
            }
        });
    }

    onShowPopup(id: number) {
        this.submitting = true;
        if (id > 0) {
            this._customApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    if (this.itemDetail.colDateTime) {
                        this.itemDetail.colDateTime = new Date(this.itemDetail.colDateTime);
                    }
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
    }
}

