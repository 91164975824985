<div class="grid col-12 ml-0">
    <div class="col-12 md:col-6">
        <div class="card overview-box">
            <div class="flex align-items-center muted-text pb-2">
                <i class="pi pi-book"></i>
                <h6 class="m-0 pl-2">Tồn kho</h6>
            </div>
            <div echarts [options]="options"></div>
        </div>
    </div>
    <div class="col-12 md:col-6">
        <div class="card overview-box">
            <div class="flex align-items-center muted-text pb-2">
                <i class="pi pi-book"></i>
                <h6 class="m-0 pl-2">Công nợ</h6>
            </div>
            <div echarts [options]="options4"></div>
        </div>
    </div>
</div>

<div class="grid col-12 ml-0">
    <div class="col-12 md:col-6">
        <div class="card overview-box">
            <div class="flex align-items-center muted-text justify-content-between pb-2">
                <div class="flex align-items-center">
                    <i class="pi pi-book"></i>
                    <h6 class="m-0 pl-2">Sản lượng xuất</h6>
                </div>
                <div class="flex align-items-center gap-3">
                    <div>Sản lượng</div>
                    <p-inputSwitch></p-inputSwitch>
                    <div>Doanh thu</div>
                </div>
            </div>
            <div echarts [options]="optionsOut"></div>
        </div>
    </div>
    <div class="col-12 md:col-6">
        <div class="card overview-box">
            <div class="flex align-items-center muted-text justify-content-between pb-2">
                <div class="flex align-items-center">
                    <i class="pi pi-book"></i>
                    <h6 class="m-0 pl-2">Sản lượng nhập</h6>
                </div>
                <div class="flex align-items-center gap-3">
                    <div>Sản lượng</div>
                    <p-inputSwitch></p-inputSwitch>
                    <div>Doanh thu</div>
                </div>
            </div>
            <div echarts [options]="optionsIn"></div>
        </div>
    </div>
</div>
