import { Component, Injector, OnInit } from "@angular/core";
import { SecondPageEditBase } from "nabit-shared";
import { TrTypeApiService } from "../../Services/TrType-api.service";
import { CustomerGroupApiService } from "../../Services/CustomerGroup-api.service";
import { TrType2CustGroupApiService } from "../../Services/TrType2CustGroupApi.service";

@Component({
    selector: "app-trType2CustGroup-form",
    templateUrl: "./TrType2CustGroup-form.component.html",
    styleUrls: ["./TrType2CustGroup-form.component.scss"]
})
export class TrType2CustGroupFormComponent extends SecondPageEditBase implements OnInit {
    activeIndex = 0;
    allCustomerGroups = [];
    selectedCustomerGroups = [];
    unselectedCustomerGroups = [];

    constructor(
        protected TrTypeApiService: TrTypeApiService,
        protected TrType2CustGroupApiService: TrType2CustGroupApiService,
        protected CustomerGroupApiService: CustomerGroupApiService,
        protected _injector: Injector,
    ) {
        super(TrTypeApiService, _injector);
    }

    async ngOnInit() {
        await this.getData();
    }

    onShowPopup(id: number): void {
        this.submitting = true;

        if (id > 0) {
            this.TrTypeApiService.getDetail(id).then(rs => {
                this.submitting = false;
                if (rs.success) {
                    this.itemDetail = rs.data;
                    this.TrType2CustGroupApiService.getsByTrCode(rs.data.trCode).then(rs => {
                        if (rs.success) {
                            let unselectedCustomerGroups = [];
                            let selectedCustomerGroups = [];
                            for (const customerGroup of this.allCustomerGroups) {
                                if (rs.data.customerGroupIds.includes(customerGroup.id)) {
                                    selectedCustomerGroups.push(customerGroup);
                                } else {
                                    unselectedCustomerGroups.push(customerGroup);
                                }
                            }

                            this.unselectedCustomerGroups = unselectedCustomerGroups;
                            this.selectedCustomerGroups = selectedCustomerGroups;
                        }
                    }, error => {
                        this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                        this.submitting = false;
                    })
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant("MESSAGE.NOT_FOUND_ERROR"));
                this.submitting = false;
            });
        } else {
            this.itemDetail = { id: 0, status: 1 };
            this.submitting = false;
        }
        this.activeIndex = 0;
    }

    onUpdate() {
        this.TrType2CustGroupApiService.updateMany(this.itemDetail.id, this.selectedCustomerGroups.map(data => {
            return data.id;
        }))
            .then(response => {
                if (response.error?.toString() == "ERR.DATA_DUPLICATED") {
                    this._notifierService.showUpdateDataSingleFailed();
                    this.submitting = false;
                }
                else {
                    this.closePopupMethod(true);
                    this.onAfterSave(true);
                    this.submitting = false;
                }

            }, () => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    async getData() {
        await this.CustomerGroupApiService.getsInUse().then(rs => {
            this.allCustomerGroups = rs.data.map(data => {
                return {
                    id: data.id,
                    groupName: data.groupName,
                }
            });
        })
    }
}
