<p-dialog #dialog header="Cập nhật mới/{{ 'MD.BankAccount' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label">{{'MD.Bank' | translate}}
                            <span style="color:red;">*</span>
                            <div class="sublabel">{{'MD.Bank' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <p-dropdown appendTo="body" formControlName="bankId" [options]="bankList"
                            [(ngModel)]="itemDetail.bankId" autoDisplayFirst="false" optionLabel="name"
                            optionValue="id">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label">{{'Số tài khoản' | translate}}
                            <span style="color:red;">*</span>
                            <div class="sublabel">{{'Account' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="code" pInputText [(ngModel)]="itemDetail.code" formControlName="code"
                            placeholder="Định dạng text">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label">{{'Đơn vị tiền tệ' | translate}}
                            <span style="color:red;">*</span>
                            <div class="sublabel">{{'Currency' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown appendTo="body" formControlName="currencyId" [options]="currencyList"
                            [(ngModel)]="itemDetail.currencyId" autoDisplayFirst="false" optionLabel="name"
                            optionValue="id">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label">{{'Tên tài khoản' | translate}}
                            <span style="color:red;">*</span>
                            <div class="sublabel">{{'Account Name' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" id="name" pInputText [(ngModel)]="itemDetail.name" formControlName="name"
                            placeholder="Định dạng text, tối đa 30 kí tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label">{{'Trạng thái' | translate}}
                            <div class="sublabel">{{'Status' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-4 md:col-2">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button float-right mr-0"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>