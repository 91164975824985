import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class TRApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.trEndpoint}/TR`);
    }

    GetByTrType(trType: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetByTrType/${trType}`;
        return this.defaultGet(apiUrl);
    }

    GetLastestOrderNumberByTrType(trType: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetLastestOrderNumberByTrType/${trType}`;
        return this.defaultGet(apiUrl);
    }

    GetTrList(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    InsertMulti(tr: any[]) {
        const apiUrl = `${this.serviceUri}/InsertMulti`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, tr).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetByChildParentTr(tr: any) {
        const apiUrl = `${this.serviceUri}/GetByChild`;
        const options = {
            trChild: tr,
            listCustomerId: [],
            listProductId: [],
            listWarehouseId: [],
            isByTrType: false,
            fromDate: new Date(+new Date() - 1000 * 86400 * 365 * 20), toDate: new Date(+new Date() + 1000 * 86400 * 365 * 20)
        }
        return this._http
            .post<ResponseResult>(`${apiUrl}`, options).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetTrParentList(model: any) {
        const apiUrl = `${this.serviceUri}/GetTrParentList`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetImportContractList(model: any) {
        const apiUrl = `${this.serviceUri}/GetImportContractList`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetByContractId(contractId: number) {
        const apiUrl = `${this.serviceUri}/GetByContractId/${contractId}`;
        return this.defaultGet(apiUrl);
    }

    GetByChild(trTypeChild: string,
        customerIds: number[] = [], productIds: number[] = [], warehouseIds: number[] = [],
        fromDate = new Date(+new Date() - 1000 * 86400 * 365 * 20), toDate = new Date(),
    ) {
        const apiUrl = `${this.serviceUri}/GetByChild`;
        const options = {
            trChild: {
                TrType: trTypeChild
            },
            listCustomerId: customerIds,
            listProductId: productIds,
            listWarehouseId: warehouseIds,
            isByTrType: true,
            fromDate, toDate
        }
        return this._http
            .post<ResponseResult>(`${apiUrl}`, options).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetTrParentListByFinId(id: number,
        fromDate = new Date(+ new Date() - 1000 * 86400 * 365 * 20), toDate = new Date()) {
        const apiUrl = `${this.serviceUri}/GetTrParentListByFinId/`
        const options = {
            fromDate: fromDate,
            toDate: toDate,
            finTranId: id,
            branchId: 0,
        }

        return this._http.post<ResponseResult>(`${apiUrl}`, options).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetByListId(ids: number[]) {
        const apiUrl = `${this.serviceUri}/GetByListId/`;

        return this._http.post<ResponseResult>(`${apiUrl}`, ids).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    Verify(listTr: any[], isApproved: boolean, isDone: boolean) {
        const options = {
            listTr,
            isApproved, isDone
        };
        const apiUrl = `${this.serviceUri}/Verify`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, options).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetTrParentListByTrId(
        fromDate: Date, toDate: Date, trId: number, branchId: number, customerIds: string = "", warehouseIds: string = "", productIds: string = "",
        pageSize = 20,
        pageIndex = 1
    ) {
        let model = {
            "fromDate": fromDate,
            "toDate": toDate,
            "trId": trId,
            "branchId": branchId,
            "customerIds": customerIds,
            "warehouseIds": warehouseIds,
            "productIds": productIds,
            pageSize, pageIndex
        }
        const apiUrl = `${this.serviceUri}/GetTrParentListByTrId`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetTagList() {
        const apiUrl = `${this.serviceUri}/GetTagList`;
        return this.defaultGet(apiUrl);
    }
}
