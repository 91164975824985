<p-dialog #dialog header="{{ 'MD.PriceList' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="formgrid grid">
                <div class="field grid col-12 md:col-6">
                    <div class="label font-medium mt-25 col-12 md:col-3">{{'Hiệu lực' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-calendar formControlName="validFrom" showTime="true" [(ngModel)]="itemDetail.validFrom"
                            appendTo="body" icon="ci ci-calendar" dateFormat="dd/mm/yy" showIcon="true" hourFormat="24">
                        </p-calendar>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Loại giá' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-dropdown formControlName="priceCategoryId" [options]="priceCategoryList"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.priceCategoryId" optionValue="id" optionLabel="categoryName"
                            [filter]="true" filterBy="categoryCode,categoryName">
                        </p-dropdown>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="label font-medium mt-2 col-12 md:col-3">{{'Nguồn hàng' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-dropdown formControlName="productSourceId" [options]="productSourceList"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.productSourceId" optionValue="id" optionLabel="productSourceName"
                            [filter]="true" filterBy="productSourceName">
                        </p-dropdown>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="label font-medium mt-25 col-12 md:col-3">{{'Vùng giá' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-dropdown formControlName="priceRegionId" [options]="priceRegionList"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.priceRegionId" optionValue="id" optionLabel="displayName"
                            [filter]="true" filterBy="displayName">
                        </p-dropdown>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Hàng hóa' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-dropdown formControlName="productId" [options]="productList" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.productId"
                            optionValue="id" optionLabel="productName" [filter]="true" filterBy="productName"
                            (ngModelChange)="chooseProduct($event);">
                        </p-dropdown>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'ĐVT' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-dropdown formControlName="isPackage" [options]="isPackageList" [autoDisplayFirst]="true"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.isPackage"
                            [filter]="true" filterBy="label font-medium mt-25" >
                        </p-dropdown>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Phí BVMT' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-inputNumber inputStyleClass="text-right" formControlName="pxd" [min]="0"
                            [(ngModel)]="itemDetail.pxd" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'VAT %' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-inputNumber inputStyleClass="text-right" formControlName="vat" [min]="0"
                            [(ngModel)]="itemDetail.vat" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </div>
                </div>
                <!--div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Thuế VAT' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-inputNumber inputStyleClass="text-right" formControlName="thueVAT" [min]="0"
                            [(ngModel)]="itemDetail.thueVAT" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </div>
                </div-->
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Giá sau thuế' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-inputNumber inputStyleClass="text-right" formControlName="unitPrice" [min]="0"
                            [(ngModel)]="itemDetail.unitPrice" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Trích QBO' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-inputNumber inputStyleClass="text-right" formControlName="trichQBO" [min]="0"
                            [(ngModel)]="itemDetail.trichQBO" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Chi QBO' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-inputNumber inputStyleClass="text-right" formControlName="chiQBO" [min]="0"
                            [(ngModel)]="itemDetail.chiQBO" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="field grid col-12 md:col-6">
                    <div class="col-12 md:col-3 label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                    <div class="col-12 md:col-9 p-fluid">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            [disabled]="submitting" class="p-button" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>
