import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SendAccessTokenInterceptor implements HttpInterceptor {

    constructor(public authenService: AuthenticationService) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var accessToken = this.authenService.getAccessToken();
        if(accessToken!=null){
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authenService.getAccessToken()}`
                }
            });
        }
        return next.handle(request);
    }
}
