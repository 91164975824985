<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Cán bộ nhân viên' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Mã nhân viên' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input type="text" id="staffCode" pInputText [(ngModel)]="itemDetail.staffCode"
                            formControlName="staffCode" placeholder="Định dạng text, tối đa 7 ký tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'User' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="userId" [options]="arrUserId" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.userId"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Tên nhân viên' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.staffName" formControlName="staffName"
                            placeholder="Định dạng text tối đa 100 kí tự">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Chức danh' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="positionId" [options]="arrPositionId" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.positionId"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Phòng ban' | translate}}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="departmentId" [options]="arrDepartmentId"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.departmentId" [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>


                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Email' | translate}}</div>
                </div>
                <div class="col-8 md:col-10">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.email" formControlName="email"
                            placeholder="Địa chỉ email của bạn example@gmail.com, @domail.com.vn ">
                    </div>
                </div>

                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Số điện thoại' | translate}}</div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <input type="text" pInputText [(ngModel)]="itemDetail.mobile" formControlName="mobile"
                            placeholder="Số điện thoại">
                    </div>
                </div>

                <!-- <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Chi Nhánh' | translate}}</div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="idBranch" [options]="arridBranch" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idBranch"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div> -->
                <div class="col-12 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-12 md:col-12" style="font-weight: 450;">
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">Thông tin Chức danh (Tại kho)</div>
                    </div>
                </div>
                <div class="col-12 md:col-1" style="text-align: center;">
                    <p-checkbox formControlName="isManager" [(ngModel)]="itemDetail.isManager" [binary]="true"
                        inputId="binary" placeholder="Chức danh" class="mt-1">
                    </p-checkbox>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;">
                    <div class="field">
                        <div class="label mt-1">{{'Phụ trách kho' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-1" style="text-align: center;">
                    <p-checkbox formControlName="isSaler" [(ngModel)]="itemDetail.isSaler" [binary]="true"
                        inputId="binary" placeholder="Chức danh" class="mt-1">
                    </p-checkbox>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;">
                    <div class="field">
                        <div class="label">{{'Nhân viên viết phiếu' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-1" style="text-align: center;">
                    <p-checkbox formControlName="isSealer" [(ngModel)]="itemDetail.isSealer" [binary]="true"
                        inputId="binary" placeholder="Chức danh" class="mt-1">
                    </p-checkbox>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;">
                    <div class="field">
                        <div class="label mt-1">{{'Nhân viên niêm' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-1" style="text-align: center;">
                    <p-checkbox formControlName="isWorker" [(ngModel)]="itemDetail.isWorker" [binary]="true"
                        inputId="binary" placeholder="Chức danh" class="mt-1">
                    </p-checkbox>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;">
                    <div class="field">
                        <div class="label">{{'Nhân viên đóng cần' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-1" style="text-align: center;">
                    <p-checkbox formControlName="isGuard" [(ngModel)]="itemDetail.isGuard" [binary]="true"
                        inputId="binary" placeholder="Chức danh" class="mt-1">
                    </p-checkbox>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;">
                    <div class="field">
                        <div class="label mt-1">{{'Bảo vệ' | translate}}</div>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button float-right mr-0"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>