import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { ContractTypeApiService } from '../../../Services/ContractType-api.service';

@Component({
  selector: 'app-contractType-form',
  templateUrl: './contractType-form.component.html',
  styleUrls: ['./contractType-form.component.scss']
})
export class ContractTypeFormComponent extends SecondPageEditBase implements OnInit {

  constructor(
    protected _ContractTypeApiService: ContractTypeApiService,
    protected _injector: Injector,
  ) { 
    super(_ContractTypeApiService, _injector);

    this.formGroup = new FormGroup({
        typeCode: new FormControl('', [Validators.required]),
        typeName: new FormControl('', [Validators.required]),
        shortName: new FormControl('', [Validators.required]),
        status: new FormControl('', [Validators.required])
    });

  }

  ngOnInit() {
  }
  onShowPopup(id: number) {
    this.submitting = true;
    if (id > 0) {
        this._ContractTypeApiService.getDetail(id).then(rs => {
            this.submitting = false;
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        }, error => {
            this._notifierService.showWarning(this._translateService.instant('MESSAGE.NOT_FOUND_ERROR'));
            this.submitting = false;
        });
    } else {
        this.itemDetail = { id: 0, status: 1 };
        this.submitting = false;
    }
}
}
