<div style="width: 241px; height: 300px; margin-bottom: 20px;">
    <div style="position: relative; width: 241px; height: 300px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="241px" height="274px" viewBox="0 0 874 999"
            preserveAspectRatio="xMidYMid meet" style="position: absolute; top: 0; left: 0; margin-left: 20px;">
            <path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M874,717 l-874,0 0,188 874,0 0,-188z" />
            <path style="fill:#4c4c4c" d="M7,858 l859,0 0,39 -859,0 0,-39z" />
            <path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M124,0 l625,0 0,312 -625,0 0,-312z" />
            <path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M218,952 l-94,-16 0,-250 94,-15 0,281z" />
            <path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M218,624 l437,0 0,375 -437,0 0,-375z" />
            <path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M655,952 l94,-16 -1,-250 -93,-15 0,281z" />
            <path style="fill:#000;stroke:#4c4c4c;stroke-width:2" d="M249,530 l375,0 0,-125 -375,0 0,125z" />
            <path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M343,405 l187,0 0,-93 -187,0 0,93z" />
            <path style="fill:#7f7f7f;stroke:#4c4c4c;stroke-width:2" d="M312,624 l249,0 0,-94 -249,0 0,94z" />
            <path style="fill:#333;stroke:#4c4c4c;stroke-width:2" d="M187,30 l499,0 0,250 -499,0 0,-250z" />
            <path style="fill:#ff9" d="M249,109 l375,0 0,93 -375,0 0,-93z" />
            <path style="fill:#000" d="M631,109 l-7,-7 -375,0 0,14 375,0 -7,-7 14,0 0,-7 -7,0z" />
            <path style="fill:#000" d="M624,209 l7,-7 0,-93 -14,0 0,93 7,-7 0,14 7,0 0,-7z" />
            <path style="fill:#000" d="M242,202 l7,7 375,0 0,-14 -375,0 7,7 -14,0 0,7 7,0z" />
            <path style="fill:#000" d="M249,102 l-7,7 0,93 14,0 0,-93 -7,7 0,-14 -7,0 0,7z" />
            <path style="fill:#ccc;stroke:#4c4c4c;stroke-width:2" d="M327,624 l0,-16 47,-62 125,0 47,62 0,16 -219,0z" />
            <circle style="fill:#666;stroke:#4c4c4c;stroke-width:2" cx="717" cy="30" r="15" />
            <circle style="fill:#666" cx="717" cy="280" r="15" />
            <circle style="fill:#666" cx="156" cy="30" r="16" />
            <circle style="fill:#666" cx="156" cy="280" r="16" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M717,46 l0,-31" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M733,30 l-32,0" />
            <circle style="fill:none;stroke:#4c4c4c;stroke-width:2" cx="717" cy="280" r="15" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M717,296 l0,-31" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M733,280 l-32,0" />
            <circle style="fill:none;stroke:#4c4c4c;stroke-width:2" cx="156" cy="30" r="16" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M156,46 l0,-31" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M171,30 l-31,0" />
            <circle style="fill:none;stroke:#4c4c4c;stroke-width:2" cx="156" cy="280" r="16" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M156,296 l0,-31" />
            <path style="fill:none;stroke:#4c4c4c;stroke-width:2" d="M171,280 l-31,0" />
            <path style="fill:none;stroke:#7f7f7f;stroke-width:2" d="M249,421 l375,0" />
            <path style="fill:none;stroke:#7f7f7f;stroke-width:2" d="M624,514 l-375,0" />
            <path style="fill:#4c4c4c" d="M132,874 l78,0 0,70 -78,-16 0,-54z" />
            <path style="fill:#4c4c4c" d="M741,874 l-78,0 0,70 78,-16 0,-54z" />
        </svg>
        <div>
            <div class="pumpBanner" [style.text-align]="'center'">
                <div [style.padding-top]="'5px'">{{ pump.pumpName }}</div>
            </div>
            <div class="pumpState" [style.text-align]="'center'">
                <div>{{ pumpState }}</div>
            </div>
            <div class="pumpPreset" [style.text-align]="'center'">
                <div style="margin-top: 2px;"><b>{{ pumpData?.PresetValue | number: "1.0-2" }}</b></div>
            </div>
            <div class="pumpVolume" [style.text-align]="'center'">
                <div><b>{{ pumpData?.Volume | number: "1.0-2" }}</b></div>
            </div>
            <div class="pumpTotal" [style.text-align]="'center'">
                <div><b>{{ pumpData?.TotalVol | number: "1.0-2" }}</b></div>
            </div>
            <div class="flex flex-row justify-content-center align-items-center pumpStatus {{ showPumpError ? 'error' : 'success' }}"
                [style.text-align]="'center'">
                <div class="pumpStatusIcon {{ showPumpError ? 'statusOff' : 'statusOn'}}"></div>
                <div [style.padding]="'5px'">{{ errorName }}</div>
            </div>
        </div>
    </div>
</div>