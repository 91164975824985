import {Component, Injector, OnInit} from '@angular/core';
import {SecondPageEditBase} from 'nabit-shared';
import {OrgUnitApiService} from '../../Services/OrgUnit-api.service';
import {CustomerGroupApiService} from '../../Services/CustomerGroup-api.service';
import {BusinessTypeApiService} from '../../Services/BusinessType-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PriceRegionApiService} from '../../Services/PriceRegion-api.service';

@Component({
    selector: 'app-posConfig-index',
    templateUrl: './POSConfig-index.component.html',
    styleUrls: ['./POSConfig-index.component.css'],
})
export class POSConfigComponent extends SecondPageEditBase implements OnInit {
    arrCustomerGroup = [];
    arrBussinessType = [];
    arridPriceRegion = [];
    arridParent = [];
    arridBranch = [];

    constructor(
        protected _OrgUnitApiService: OrgUnitApiService,
        protected _injector: Injector,
        protected _customerGroupAPIService: CustomerGroupApiService,
        protected _router: Router,
        protected _route: ActivatedRoute,
        public _businessTypeAPIService: BusinessTypeApiService,
        public _priceRegionAPIService: PriceRegionApiService,
    ) {
        super(_OrgUnitApiService, _injector);
    }

    ngOnInit(): void {
        Promise.all([
            this.getsCustomerGroup(),
            this.getsBusinessType(),
            this.getsPriceRegion(),
        ]);
        this.getData();
    }

    getData() {
        const user = this._authenticationService.getCurrentUser();
        this.itemDetail.id = user.branchId;
        this._OrgUnitApiService.getDetail(this.itemDetail.id).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        });
    }

    async getsCustomerGroup() {
        var model = {
            keyword: '',
            status: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: '',
            isDesc: true
        };
        this.arrCustomerGroup = [];
        await this._customerGroupAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrCustomerGroup, rs.data, 'groupName');
            }
        });
    }

    async getsBusinessType() {
        var model = {
            keyword: '',
            status: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: '',
            isDesc: true
        };
        this.arrBussinessType = [];
        await this._businessTypeAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBussinessType, rs.data, 'name');
            }
        });
    }

    async getsPriceRegion() {
        this.arridPriceRegion = [];
        await this._priceRegionAPIService.getsInUse().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arridPriceRegion, rs.data, 'regionName');
            }
        });
    }

}
