import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Report, FilterCondition } from '../Models/Report';
import { CommonService } from 'nabit-shared';

@Injectable({
    providedIn: 'root'
})
export class FilterConditionApiService extends BaseService {
    constructor(
        http: HttpClient,
        injector: Injector) {
        super(http, injector, `${environment.apiDomain.reportEndpoint}/FilterCondition`);
    }
    GetByReportId(idReport: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetByReportId/${idReport}`;
        return this.defaultGet(apiUrl);
    }
}