<div class="grid">
    <div class="col-12">
        <div class="card">
            <p-toolbar>
                <ng-template pTemplate="left">
                    <h5 class="page-title">
                        {{ 'Quản lý bơm' | translate }}
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <p-selectButton [options]="scadaViewOptions" [(ngModel)]="disablePumps" optionLabel="icon"
                        optionValue="value" styleClass="p-button-icon-only">
                        <ng-template let-item>
                            <i [class]="item.icon"></i>
                        </ng-template>
                    </p-selectButton>
                </ng-template>
            </p-toolbar>
            <div *ngIf="disablePumps">
                <app-scada-pump-table [pumpData]="pumpData" [pumps]="pumps">
                </app-scada-pump-table>
            </div>
            <div *ngIf="!disablePumps" class="sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid">
                <ng-container *ngFor="let pump of pumps">
                    <app-scada-pump [pump]="pump" [pumpData]="pumpData.get(pump.iADeviceId + pump.iANozzleId)">
                    </app-scada-pump>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toolbar>
                <ng-template pTemplate="left">
                    <h5 class="page-title">
                        {{ 'Quản lý bể' | translate }}
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <p-selectButton [options]="scadaViewOptions" [(ngModel)]="disableTanks" optionLabel="icon"
                        optionValue="value" styleClass="p-button-icon-only">
                        <ng-template let-item>
                            <i [class]="item.icon"></i>
                        </ng-template>
                    </p-selectButton>
                </ng-template>
            </p-toolbar>
            <div *ngIf="disableTanks">
                <app-scada-tank-table [tankData]="tankData" [tanks]="tanks">
                </app-scada-tank-table>
            </div>
            <div *ngIf="!disableTanks" class="sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid">
                <ng-container *ngFor="let tank of tanks">
                    <app-scada-tank [tank]="tank" [productData]="product.get(tank.productId)"
                        [tankData]="tankData.get(tank.iaDeviceId + tank.iaTankId)">
                    </app-scada-tank>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <app-scada-tank-log-table [tank]="tanks"></app-scada-tank-log-table>
        </div>
    </div>
</div>