import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { CustomerGroupFormComponent } from '../form/CustomerGroup-form.component';
import { CustomerGroupApiService } from '../../Services/CustomerGroup-api.service';

@Component({
    selector: 'app-customerGroup-index',
    templateUrl: './CustomerGroup-index.component.html',
    styleUrls: ['./CustomerGroup-index.component.css']
})
export class CustomerGroupIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: CustomerGroupFormComponent; 
    tabMenuItems = [];
    constructor(
        protected _CustomerGroupApiService: CustomerGroupApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_CustomerGroupApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Khách hàng'), icon: 'pi pi-book', routerLink: '../Customer'},
            {label: this._translateService.instant('Nhóm khách hàng'), icon: 'pi pi-book', routerLink: '../CustomerGroup'},
            {label: this._translateService.instant('Loại hình kinh doanh'), icon: 'pi pi-book', routerLink: '../BusinessType'}
        ];
        this.cols = [
            { field: 'groupCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true,},
            { field: 'groupName', header: this._translateService.instant('TABLEDATA.NAME'), visible: true, width: 'auto', sort: true,},
            { field: 'shortName', header: this._translateService.instant('TABLEDATA.ShortName'), visible: true, width: 'auto', sort: true,},
            { field: 'description', header: this._translateService.instant('TABLEDATA.Description'), visible: true, width: 'auto', sort: true,},
            { field: 'parentId', header: this._translateService.instant('TABLEDATA.parentId'), visible: false, width: 'auto', sort: true,},
            
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();


    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,  
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._CustomerGroupApiService.find(model)
            .then(response => {
                if (response.success) { 
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

}