import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { SoftwarePaymentFormComponent } from '../form/SoftwarePayment-form.component';
import { SoftwarePaymentApiService } from '../../Services/SoftwarePayment-api.service';
import {OrgUnitApiService} from "../../Services/OrgUnit-api.service";

@Component({
    selector: 'app-software-payment-index',
    templateUrl: './SoftwarePayment-index.component.html',
    styleUrls: ['./SoftwarePayment-index.component.css']
})
export class SoftwarePaymentIndexComponent extends SecondPageIndexBase implements OnInit {
    @ViewChild("pEdit") pEdit: SoftwarePaymentFormComponent;
    tabMenuItems = [];
    orgUnitList = [];

    constructor(
        protected _SoftwarePaymentApiService: SoftwarePaymentApiService,
        protected OrgUnitApiService: OrgUnitApiService,
        protected _injector: Injector,
        public app: AppComponent,
    ) {
        super(_SoftwarePaymentApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant('Thanh toán'), icon: 'pi pi-book', routerLink: '../SoftwarePayment' }
        ];
        this.cols = [
            { field: 'orgUnitId', header: this._translateService.instant('Cửa hàng'), visible: true, width: 'auto', sort: true, },
            { field: 'paymentDate', header: this._translateService.instant('Ngày thanh toán'), visible: true, width: 'auto', sort: true, align: 'center' },
            { field: 'paymentAmount', header: this._translateService.instant('Số tiền thanh toán'), visible: true, width: 'auto', sort: true, align: 'right' },
            { field: 'additionDay', header: this._translateService.instant('Số ngày gia hạn'), visible: true, width: 'auto', sort: true, align: 'right' },
            { field: 'oldExpiredDate', header: this._translateService.instant('Ngày hết hạn cũ'), visible: true, width: 'auto', sort: true, align: 'center' },
            { field: 'newExpiredDate', header: this._translateService.instant('Ngày hết hạn mới'), visible: true, width: 'auto', sort: true, align: 'center' },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getData();
        this.getMappingData();
    }

    getOrg(id) {
        return this.orgUnitList.find(r => r.id === id);
    }

    getMappingData() {
        this.OrgUnitApiService.GetAll()
            .then(rs => {
                if (rs.success) {
                    this.orgUnitList = rs.data;
                }
            })
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];

        this._baseService.getsInUse().then(response => {
            if (response.success) {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
                this.dataExport = response.data;
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this._notifierService.showHttpUnknowError();
        });
    }

}
