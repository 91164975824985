<p-dialog #dialog header="Cập nhật thông tin/{{ 'Tỷ giá'| translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Ngân hàng' | translate}}</div>
                    </div>
                </div>
                <div class="col-4 md:col-10">
                    <div class="field">
                        <p-dropdown formControlName="bankId" [options]="bankList" optionValue="id" optionLabel="name"
                            [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                            [(ngModel)]="itemDetail.bankId" [filter]="true" filterBy="code,name">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Ngày hiệu lực' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-calendar showIcon="true" [(ngModel)]="itemDetail.validFrom" formControlName="validFrom"
                            appendTo="body" dateFormat="dd/mm/yy">
                        </p-calendar>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Tiền tệ' | translate}}</div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="currencyId" [options]="currencyList" [autoDisplayFirst]="false"
                            optionValue="id" optionLabel="code" [filter]="true" filterBy="code,name"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.currencyId">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Tỷ giá mua' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber id="buyingRate" [(ngModel)]="itemDetail.buyingRate"
                            (ngModelChange)="itemDetail.buyingRate = $event; updatePrice()"
                            formControlName="buyingRate"></p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Tỷ giá bán' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber id="sellingRate" [(ngModel)]="itemDetail.sellingRate"
                            (ngModelChange)="itemDetail.sellingRate = $event; updatePrice()"
                            formControlName="sellingRate"></p-inputNumber>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                    </div>
                </div>
                <div class="col-4 md:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Tỷ giá trung bình' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-inputNumber formControlName="averageRate" [ngModel]="itemDetail.averageRate"
                            [disabled]="true"></p-inputNumber>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
    </p-footer>
</p-dialog>