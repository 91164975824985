import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from 'nabit-shared';
import { VehicleClassApiService } from '../../Services/vehicle-class-api.service';


@Component({
  selector: 'app-vehicleClass-form',
  templateUrl: './vehicleClass-form.component.html',
  styleUrls: ['./vehicleClass-form.component.scss']
})
export class VehicleClassFormComponent extends SecondPageEditBase implements OnInit {

  constructor(
    protected VehicleClassApiService: VehicleClassApiService,
    protected _injector: Injector,
  ) { 
    super(VehicleClassApiService, _injector);

    this.formGroup = new FormGroup({
        classCode: new FormControl('', [Validators.required]),
        className: new FormControl('', [Validators.required]),
        shortName: new FormControl(''),
        status: new FormControl(''),
    });
  }
  ngOnInit() {
  }
}