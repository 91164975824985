import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NozzleData, Pump } from "../../Scada-interfaces";
import { SecondPageIndexBase } from "nabit-shared";
import { DecimalPipe } from "@angular/common";
import { PumpStationApiService } from '../../../../Services/PumpStation-api.service';

@Component({
    selector: 'app-scada-pump-table',
    templateUrl: './scada-pump-table.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ScadaPumpTableComponent extends SecondPageIndexBase implements OnInit, OnChanges {
    @Input() pumps: Pump[];
    @Input() pumpData: Map<string, NozzleData> = new Map();
    constructor(
        protected _PumpStationApiService: PumpStationApiService,
        protected _injector: Injector
    ) {
        super(_PumpStationApiService, _injector);
    }

    remapDataSource() {
        this.dataSource = this.pumps.map((pump, i) => {
            let data = this.pumpData.get(pump.iADeviceId + pump.iANozzleId) || {} as NozzleData;

            return {
                ...pump,
                ...data,
                Num: i + 1,
                NozzleState: data.NozzleState,
                PresetValue: data.PresetValue,
                PresetType: data.PresetType,
                Volume: new DecimalPipe("en-US").transform(data.Volume, "1.0-2"),
                TotalVol: new DecimalPipe("en-US").transform(data.TotalVol, "1.0-2"),
                AlertCode: data.AlertCode,
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        this.remapDataSource();
    }

    ngOnInit(): void {
        this.cols = [
            { field: "Num", header: this._translateService.instant('STT'), visible: true, width: 'auto', align: 'center' },
            { field: "pumpName", header: this._translateService.instant('Tên bể'), visible: true, width: 'auto', },
            { field: "NozzleState", header: this._translateService.instant("Trạng thái cò bơm"), visible: true, width: "auto", },
            { field: "PresetType", header: this._translateService.instant("Loại lượng đặt"), visible: true, width: "auto", },
            { field: "PresetValue", header: this._translateService.instant("Lượng đặt"), visible: true, width: "auto", align: "right", },
            { field: "Volume", header: this._translateService.instant("Số lượng hiện tại"), visible: true, width: "auto", align: "right", },
            { field: "TotalVol", header: this._translateService.instant("Tổng số lượng"), visible: true, width: "auto", align: "right", },
        ];
    }
}
