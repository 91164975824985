<p-dialog #dialog [(visible)]="isShow" [autoZIndex]="true" [contentStyle]="{'max-height': getMaxDialogHeight()}"
          [maximizable]="true" [modal]="true" [styleClass]="'ag-modal force-full-width'"
          [style]="{'width': '880px'}" closeIcon="ci ci-close"
          header="{{ 'Cập nhật thông tin/ Cấu hình cửa hàng' | translate }}"
          maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{ 'Cửa hàng' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.idOrgUnit" formControlName="idOrgUnit" id="idOrgUnit"
                               pInputText
                               placeholder="Định dạng text, tối đa 7 kí tự" type="text">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{ 'Nhóm cấu hình' | translate }}</div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.settingName" formControlName="settingName" pInputText
                               type="text">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{ 'Cấu hình' | translate }}
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.settingParam" formControlName="settingParam" pInputText
                               type="text">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{ 'Giá trị' | translate }}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <input [(ngModel)]="itemDetail.settingValue" formControlName="settingValue" pInputText
                               type="text">
                    </div>
                </div>
                <div class="col-4 md:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="label mt-1">{{ 'Trạng thái' | translate }}
                    </div>
                </div>
                <div class="col-8 md:col-4">
                    <div class="field">
                        <p-dropdown [(ngModel)]="itemDetail.status" [autoDisplayFirst]="false" [filter]="true"
                                    [options]="arrStatus" appendTo="body" dropdownIcon="ci ci-chevron-down-1"
                                    filterBy="label,value" formControlName="status">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="saveAndClose()" *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2"
                icon="pi pi-save"
                label="{{'BUTTON.SAVE' | translate}}" pButton pRipple
                type="button"></button>
        <button (click)="cancel()" [disabled]="submitting" class="p-button p-button-outlined mr-2" icon="pi pi-times"
                label="{{'BUTTON.CANCEL' | translate}}"
                pButton pRipple type="button"></button>
        <button (click)="saveAsCopy()" *ngIf="itemDetail.id != 0" [disabled]="submitting"
                class="p-button p-button mr-2 float-right" icon="pi pi-copy"
                label="{{'BUTTON.SAVE_AS_COPY' | translate}}" pButton pRipple
                type="button"></button>
    </p-footer>
</p-dialog>
