import {Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SecondPageEditBase, UserService} from "nabit-shared";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {passwordPattern} from "../../config/password.config";

@Component({
    selector: 'app-changepasswd',
    templateUrl: './changepasswd.component.html'
})
export class ChangePasswdComponent extends SecondPageEditBase implements OnInit {
    formGroup: FormGroup = new FormGroup({});
    @ViewChild('formElement1') formElement1: ElementRef;
    invalid = false;

    @Input() active = false;
    @Output() onHide = new EventEmitter();

    constructor(
        private _userService: UserService,
        protected _injector: Injector
    ) {
        super(_userService, _injector);
    }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            oldPassword: new FormControl('', [Validators.required]),
            newPassword: new FormControl('', [Validators.required, Validators.pattern(passwordPattern)]),
            confirmPassword: new FormControl('', [Validators.required]),
        });
        this.getUserData();
    }

    getUserData() {
        this.submitting = true;
        this._userService.getDetail(this.currentUser.userId).then(async rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
            this.submitting = false;
        });
    }

    onChangePass() {
        if (this.itemDetail.newPassword !== this.itemDetail.confirmPassword) {
            this.formGroup.controls['newPassword'].setErrors({ 'nomatch': true });
            this._notifierService.showError(this._translateService.instant('Users.ErrPasswordNotMatch'));
            this.formGroup.setErrors({ 'invalid': true });
            this.invalid = true;
            this.showValidateMessage();
            return;
        }

        this._userService.changePassword(this.itemDetail)
            .then(response => {
                this.closePopupMethod(true);
                this.onAfterSave(true);
                this.submitting = false;
                this.formGroup.reset();
            }, error => {
                if (error.error.error === 'PASSWORD_NOT_MATCHING') {
                    this.invalid = true;
                    this.formGroup.controls['oldPassword'].setErrors({ 'incorrect': true });
                    this.formGroup.setErrors({ 'invalid': true });
                    this._notifierService.showWarning(this._translateService.instant('Users.ErrorOldPassword'));
                    this.showValidateMessage();

                } else {
                    this._notifierService.showUpdateDataFailed();
                }
                this.submitting = false;
            });
    }
}
