import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { SecondPageIndexBase } from 'nabit-shared';
import { PumpStationFormComponent } from '../form/PumpStation-form.component';
import { PumpStationApiService } from '../../Services/PumpStation-api.service';
import { OrgUnitApiService } from '../../Services/OrgUnit-api.service';
import { TankApiService } from '../../Services/Tank-api.service';

@Component({
    selector: 'app-pumpStation-index',
    templateUrl: './PumpStation-index.component.html',
    styleUrls: ['./PumpStation-index.component.css']
})
export class PumpStationIndexComponent extends SecondPageIndexBase implements OnInit {

    @ViewChild('pEdit') pEdit: PumpStationFormComponent;

    arrBranch = [];
    arrTank = [];
    tankId = 0;
    branchId = 0;
    tabMenuItems = [];

    constructor(
        protected _PumpStationApiService: PumpStationApiService,
        protected _injector: Injector,
        public app: AppComponent,
        private _orgUnitAPIService: OrgUnitApiService,
        private _tankAPIService: TankApiService,
    ) {
        super(_PumpStationApiService, _injector);
    }

    ngOnInit() {
        this.tabMenuItems = [
            { label: this._translateService.instant('Cần xuất'), icon: 'pi pi-book', routerLink: '../PumpStation' },
        ];

        this.cols = [
            { field: 'pumpCode', header: this._translateService.instant('FORM.CODE'), visible: true, width: 'auto', sort: true, },
            { field: 'pumpName', header: this._translateService.instant('FORM.TITLE'), visible: true, width: 'auto', sort: true, },
            { field: 'tankId', header: this._translateService.instant('TABLEDATA.TANKID'), visible: true, width: 'auto', sort: true, },
            { field: 'branchId', header: this._translateService.instant('TABLEDATA.BRANCH'), visible: true, width: 'auto', sort: true, },
            { field: 'isAuto', header: this._translateService.instant('TABLEDATA.isAuto'), visible: true, width: 'auto', sort: true, },

            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: false, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedBy', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: false, width: 'auto', sort: true, },
            { field: 'status', header: this._translateService.instant('TABLEDATA.STATUS'), visible: true, width: 'auto', sort: true, },
        ];

        this.getsTank();
        this.getsBranch();
        this.getData();
    }

    edit(id?: number) {
        this.pEdit.showPopup(id);
    }

    add() {
        this.pEdit.showPopup();
    }

    getData() {
        this.isLoading = true;
        this.resetBulkSelect();
        this.dataSource = [];
        var model = {
            keyword: this.query,
            status: this.status,
            tankId: this.tankId,
            branchId: this.branchId,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this._PumpStationApiService.find(model)
            .then(response => {
                if (response.success) {
                    this.dataSource = response.data;
                    this.totalRecord = response.totalRecord;
                    this.dataExport = response.data;
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

    async getsTank() {
        this.arrTank = [];
        this.arrTank = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._tankAPIService.GetAll().then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrTank, rs.data, 'tankName');
            }
        })
    }

    async getsBranch() {
        var model = {
            keyword: "",
            status: 1,
            CustomerGroup: 0,
            BussinessType: 0,
            Branch: 0,
            pageIndex: 1,
            pageSize: 20,
            orderCol: this.orderCol,
            isDesc: this.isDesc
        }
        this.arrBranch = [];
        this.arrBranch = [{ label: this._translateService.instant('LIST.DROPDOWN_ALL'), value: 0 }];
        await this._orgUnitAPIService.find(model).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.arrBranch, rs.data, 'customerName');
            }
        })
    }

}