import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProductApiService} from '../Services/Product-api.service';
import {PumpStationApiService} from '../Services/PumpStation-api.service';
import {TankApiService} from '../Services/Tank-api.service';
import {PumpPriceApiService} from '../Services/PumpPrice-api.service';
import {AuthenticationService, CoreUser, NotifierService} from 'nabit-shared';
import {PriceListApiService} from '../Services/PriceList-api.service';

@Component({
    selector: 'app-price-setting',
    templateUrl: './price-setting.component.html',
    styleUrls: ['./price-setting.component.scss']
})
export class PriceSettingComponent implements OnInit {
    viewHistory = false;
    tabMenuItems = [];
    productList = new Map();
    productArr = [];
    pumpStationList = [];
    tankList = new Map();
    pumpPriceHistory = [];
    pumpPriceHistoryGroup: [string, any[]][] = [];
    private user: CoreUser;

    constructor(
        private _translateService: TranslateService,
        private ProductApiService: ProductApiService,
        private PumpStationApiService: PumpStationApiService,
        private PumpPriceApiService: PumpPriceApiService,
        private TankApiService: TankApiService,
        private PriceListApiService: PriceListApiService,
        private _notifierService: NotifierService,
        private _authenticationService: AuthenticationService
    ) {
        this.user = _authenticationService.getCurrentUser();
    }

    async ngOnInit() {
        this.tabMenuItems = [
            {label: this._translateService.instant('Áp giá'), icon: 'pi pi-book', routerLink: '../PriceSetting'},
            // {label: this._translateService.instant('Hồ sơ giá'), icon: 'pi pi-book', routerLink: '../PriceList'},
        ];

        await this.getData();
        await this.getPrice();
    }

    getTank(id) {
        return this.tankList.get(id);
    }

    getProduct(id) {
        return this.productList.get(id);
    }

    getPump(id) {
        return this.pumpStationList.find(f => f.id === id);
    }

    async getPrice() {
        let newArr = [];
        for (const product of this.productArr) {
            let newData = {};
            await this.PriceListApiService.GetPriceBL(new Date(Date.now()), product.id).then(rs => {
                    if (rs.success) {
                        newData = {unitPrice: rs.data.unitPrice, ...product};
                    }
                }
            );
            newArr.push(newData);
        }

        this.productArr = newArr;
    }

    async getData() {
        await Promise.all([
            this.ProductApiService.getsInUse()
                .then(rs => {
                    if (rs.success) {
                        this.productArr = rs.data.map(r => {
                            return {...r, newDate: new Date(Date.now()), newPrice: 0}
                        });
                        this.productList = new Map(rs.data.map(r => [r.id, r]));
                    }
                }),
            this.PumpStationApiService.getsWithPrice(+this.user.branchId)
                .then(rs => {
                    if (rs.success) {
                        this.pumpStationList = rs.data;
                    }
                }),
            this.TankApiService.getsInUse()
                .then(rs => {
                    if (rs.success) {
                        this.tankList = new Map(rs.data.map(r => [r.id, r]));
                    }
                }),
            this.PumpPriceApiService.getsInUse()
                .then(rs => {
                    if (rs.success) {
                        this.pumpPriceHistory = rs.data;
                        this.sortHistory(rs.data);
                    }
                }),
        ]);
    }

    sortHistory(data: any[]) {
        let m = new Map<string, any[]>;
        for (let row of data) {
            let p = row.priceTime;
            let group = m.get(p) ?? [];
            group.push(row);
            m.set(p, group);
        }

        for (let row of m.values()) {
            row.sort((a, b) => a.id - b.id);
        }

        this.pumpPriceHistoryGroup = [...m];
        this.pumpPriceHistoryGroup.sort((a, b) => b[0].localeCompare(a[0]));
        let i = 1;
        for (let [_, rows] of this.pumpPriceHistoryGroup) {
            for (let r of rows) {
                r.index = i++;
            }
        }
    }

    async applyPrice() {
        let promises = this.productArr.map(product => {
            let newPriceModel = {
                periodId: 0,
                priceCategoryId: 1,
                productSourceId: 0,
                productId: product.id,
                isPackage: 0,
                validFrom: product.newDate,
                basePrice: (product.newPrice - (product.newPrice / 11)).toFixed(2),
                vat: 10,
                thueVAT: (product.newPrice / 11).toFixed(2),
                pxd: 0,
                trichQBO: 0,
                chiQBO: 0,
            };

            return this.PriceListApiService.post(newPriceModel);
        })

        await Promise.all(promises);

        this.PumpPriceApiService.pumpPriceApply()
            .then(rs => {
                if (rs.success) {
                    this._notifierService.showSuccess('Áp giá thành công!');
                }
            }, error => {
                this._notifierService.showHttpUnknowError();
            });
    }
}
