import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TankLogApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.integratedAutomationDataEndpoint}/TankLog`);
    }
    GetByTankId({
        tankId = undefined,
        page = 0,
        fromDate = new Date(+new Date() - 86400 * 1000), // 1 day
        toDate = new Date()
    }): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByTankId`;
        let body : any = {
            "page": page,
            "fromDate": fromDate.toJSON(),
            "toDate": toDate.toJSON()
        };
        if (tankId) body.tankId = tankId;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, body).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetLatestByTank(tankId: number) {
        const apiUrl = `${this.serviceUri}/GetLatestByTankId/${tankId}`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, {}).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetLastestByTankAndTime(tankId: number, toDate: Date) {
        const apiUrl = `${this.serviceUri}/GetLastestByTankAndTime`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, {
                tankId,
                toDate
            }).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
