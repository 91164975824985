import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, ResponseResult } from 'nabit-shared';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TankApiService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.masterDataEndpoint}/Tank`);
    }
    GetAll(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetAll`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, null).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    find(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    GetByTrType(code: string) {
        const apiUrl = `${this.serviceUri}/GetByTrType/${code}`;
        return this.defaultGet(apiUrl);
    }

    GetVolume(tankId: number, heightMM: number) {
        const apiUrl = `${this.serviceUri}/GetTankVolume/${tankId}/${heightMM}`;
        return this.defaultGet(apiUrl);
    }

    getsByBranchId(id: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByBranchId/${id}`;
        return this._http.get<ResponseResult>(apiUrl).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
