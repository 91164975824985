import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Tank, TankData} from "../../Scada-interfaces";
import {getBgColor, getFgColor} from "../../../../../dashboard/lib";

const CONNECTED = 'A0';
const NOCONNECTION = '---';
const ErrorList = [
    { code: CONNECTED, name: '' },
    { code: NOCONNECTION, name: 'Mất kết nối thiết bị TĐH'},
    { code: 'A1', name: 'Mức thể tích nhiên liệu cao'},
    { code: 'A2', name: 'Mức thể tích nhiên liệu thấp'},
    { code: 'A3', name: 'Chiều cao mức nước cao'},
    { code: 'E4', name: 'Cảnh báo mức mức tỷ trọng cao'},
    { code: 'E5', name: 'Cảnh báo cài đặt PROBE TYPE hoặc tín hiệu cài đặt offset nhiên liệu, nước bị sai'},
    { code: 'E6', name: 'Không phát hiện que đo'},
    { code: 'E7', name: 'Cảnh báo không phát hiện được phao nước'},
    { code: 'E8', name: 'Cảnh báo không phát hiện được phao nhiên liệu'},
    { code: 'E9', name: 'Cảnh báo do không cài đặt que nhưng vẫn nhận được tín hiệu của que đo'},
    { code: 'E10', name: 'Mới khởi động'},
    { code: 'E11', name: 'Do phép tính trừ thể tích bị sai (khai báo que đo)'},
    { code: 'E12', name: 'Cấu hình sai phao tỷ trọng'},
    { code: 'E19', name: 'Cài đặt gradient sai'},
    { code: 'E20', name: 'Lỗi SYNC que đo'},
    { code: 'E21', name: 'Cài đặt sai phao (Phao nhiên liệu, nước, tỉ trọng)'},
    { code: 'E22', name: 'Lỗi cài đặt sai chiều cao của que đo'},
    { code: 'E23', name: 'Lỗi cài đặt offset của nhiên liệu sai'},
    { code: 'E24', name: 'Lỗi cài đặt offset của nước sai'},
    { code: 'E25', name: 'Lỗi cài đặt offset của phao tỉ trọng sai'},
    { code: 'E26', name: 'Lỗi tỉ trọng quá lớn'},
    { code: 'E27', name: 'Lỗi cài đặt offset của nhiệt độ sai'}
];

@Component({
    selector: 'app-scada-tank',
    templateUrl: './Scada-tank.component.html',
    styleUrls: ['./Scada-tank.component.css']
})
export class ScadaTankComponent implements OnInit, OnChanges {
    @Input() tank: Tank;
    @Input() tankData?: TankData;
    @Input() productData?: any;
    public showTankError: boolean = false;
    public errorCode?: string;
    public errorName?: string;

    constructor() {
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.tankData)
        {
            this.showTankError = true;
            this.errorCode = NOCONNECTION;
            this.errorName = ErrorList.find(e => e.code === this.errorCode).name;
            return;
        }
        if (this.tankData.AlertCode === CONNECTED) {
            this.showTankError = false;
            this.errorCode = CONNECTED;
            this.errorName = 'Bình thường';
        } else {
            this.showTankError = true;
            let err = ErrorList.find(e => e.code === this.tankData.AlertCode);
            if (!err) {
                this.errorCode = this.tankData.AlertCode;
                this.errorName = "Lỗi chưa định nghĩa!";
            } else {
                this.errorCode = err.code;
                this.errorName = err.name;
            }
        }
    }

    getBgColor() {
        return getBgColor(this.productData?.productCode);
    }

    getFgColor() {
        return getFgColor(this.productData?.productCode);
    }
}
