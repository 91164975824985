<p-dialog #dialog header="Cập nhật thông tin/{{ 'Cung đường'| translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-12 md:col-3 lg:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Mã cung đường' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-4">
                    <div class="field">
                        <input type="text" id="code" pInputText [(ngModel)]="itemDetail.code" formControlName="code"
                            placeholder="Định dạng text, tối đa 5 kí tự">
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Loại cung đường' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-4">
                    <div class="field">
                        <p-dropdown formControlName="type" [options]="types" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.type">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Tên cung đường' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-9 lg:col-10">
                    <div class="field">
                        <input type="text" id="name" pInputText [(ngModel)]="itemDetail.name" formControlName="name"
                            placeholder="Định dạng text, tối đa 30 kí tự">
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Điểm đầu'| translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-4">
                    <div class="field">
                        <p-dropdown formControlName="srcPoint" [options]="locationList" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.srcPoint">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Điểm cuối'| translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-4">
                    <div class="field">
                        <p-dropdown formControlName="dstPoint" [options]="locationList" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.dstPoint">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Khoảng cách' | translate}}
                            <span style="color:red;">*</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-4">
                    <div class="field">
                        <input type="number" id="distance" pInputText [(ngModel)]="itemDetail.distance"
                            formControlName="distance" class="text-right">
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-2" style="font-weight: 450;margin: 8px 0px 0px 0px;">
                    <div class="field">
                        <div class="label mt-1">{{'Trạng thái' | translate}}</div>
                    </div>
                </div>
                <div class="col-12 md:col-3 lg:col-4">
                    <div class="field">
                        <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                            dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                            [filter]="true" filterBy="label,value">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>