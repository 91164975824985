<form #formElement [formGroup]="formGroup">
    <div class="card p-0">
        <div class="col-12 md:col-12">
            <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
            </validation-summary>
        </div>
        <p-tabView [(activeIndex)]="activeIndex">
            <p-tabPanel header="Thông tin chung">
                <!--Thông tin chung-->
                <app-trtype-form-general [item]="itemDetail"></app-trtype-form-general>
            </p-tabPanel>
            <p-tabPanel header="Hàng hóa">
                <!--Hàng hóa-->
                <app-trtype-form-product [item]="itemDetail"></app-trtype-form-product>
            </p-tabPanel>
            <p-tabPanel header="Hóa đơn">
                <!--    Hóa đơn -->
                <div class="p-fluid formgrid grid">
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Ngày / số HĐ' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="invoiceNumberReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.invoiceNumberReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Mẫu & ký hiệu HĐ' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="invoiceSerialReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.invoiceSerialReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium">{{'Thông tin hóa đơn riêng' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="custAgentReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.custAgentReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Thuế nhập khẩu' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="importTaxReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.importTaxReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Thuế tiêu thụ đặc biệt' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="specialTaxReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.specialTaxReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Thuế khác' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="otherTaxReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.otherTaxReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Fund 2' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="secondFund" [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.secondFund">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Giá vốn xuất thẳng' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="costPriceReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.costPriceReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Quỹ thu/chi' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="useFund" [options]="fundOptions" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.useFund">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Đơn vị tiền tệ' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <input type="text" id="trCurrency" pInputText [(ngModel)]="itemDetail.trCurrency"
                                formControlName="trCurrency">
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Loại hình công nợ' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <input type="number" id="paymentSerial" min="0" pInputText
                                [(ngModel)]="itemDetail.paymentSerial" formControlName="paymentSerial">
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Kênh bán hàng' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="saleschannelReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.saleschannelReq">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="TT vận chuyển">
                <div class="p-fluid formgrid grid">
                    <!--    Thông tin vận chuyển -->
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Ph.tiện vận chuyển' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="vehicleReq" [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.vehicleReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Hao hụt vận chuyển' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="shippingReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.shippingReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'TranMan' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <input type="text" id="tranMan" pInputText [(ngModel)]="itemDetail.tranMan"
                                formControlName="tranMan">
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Giấy giới thiệu' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="custDelegationReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.custDelegationReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium">{{'Yêu cầu theo phương tiện của khách' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="restrictTransportToContract" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.restrictTransportToContract">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Mã vụ việc' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="caseReq" [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.caseReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Tính thù lao bổ sung' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="autoDiscount" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.autoDiscount">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Bonus Factor' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <input type="number" id="bonusFactor" min="0" pInputText
                                [(ngModel)]="itemDetail.bonusFactor" formControlName="bonusFactor">
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Thu chi của TR' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="paymentFormReq" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.paymentFormReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'allowForeignCurrency' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="allowForeignCurrency" [options]="requirements"
                                [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.allowForeignCurrency">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'allowUseAP' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="allowUseAP" [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.allowUseAP">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'cashOnly' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="cashOnly" [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.cashOnly">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Phiếu bảo hành' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="res1" [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.res1">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium">{{'Thêm được khách mới' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="res2" [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.res2">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Cấu hình">
                <div class="p-fluid formgrid grid">
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Đơn hàng?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="isOrder" [(ngModel)]="itemDetail.isOrder" [binary]="true"
                            inputId="isOrder" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Qua kho?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="isStore" [(ngModel)]="itemDetail.isStore" [binary]="true"
                            inputId="isOrder" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Hóa đơn?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="isInvoice" [(ngModel)]="itemDetail.isInvoice" [binary]="true"
                            inputId="isOrder" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Bán hàng?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="isSale" [(ngModel)]="itemDetail.isSale" [binary]="true"
                            inputId="isSale" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Tính doanh thu?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="isRevenue" [(ngModel)]="itemDetail.isRevenue" [binary]="true"
                            inputId="isRevenue" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Hạch toán?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="isFIN" [(ngModel)]="itemDetail.isFIN" [binary]="true"
                            inputId="isFIN" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Dư cuối kỳ?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="isClosingStock" [(ngModel)]="itemDetail.isClosingStock"
                            [binary]="true" inputId="isClosingStock" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'UseRemainQty?' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 md:col-4 field">
                        <p-checkbox formControlName="useRemainQty" [(ngModel)]="itemDetail.useRemainQty" [binary]="true"
                            inputId="useRemainQty" class="mt-25">
                        </p-checkbox>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Client TrCode' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <input type="text" id="clientTrCode" pInputText [(ngModel)]="itemDetail.clientTrCode"
                                formControlName="clientTrCode">
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Đính kèm' | translate}}
                                <span style="color:red;">*</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 md:col-4">
                        <div class="field">
                            <p-dropdown [options]="requirements" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                                [(ngModel)]="itemDetail.attachmentReq" formControlName="attachmentReq">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-4 md:col-2">
                        <div class="field">
                            <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-4 md:col-4">
                        <div class="field">
                            <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                                [filter]="true" filterBy="label,value">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <div class="p-formgrid grid flex flex-row pt-2 justify-content-between">
                <div class="col-12 md:col-8 flex flex-row gap-2">
                    <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
                        *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2"
                        (click)="saveAndClose()"></button>
                    <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
                        *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2"
                        (click)="saveAndClose()"></button>
                    <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
                        [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
                </div>
                <div class="p-2">
                    <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}"
                        icon="pi pi-copy" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                        class="p-button mr-0 float-right" (click)="saveAsCopy()"></button>
                </div>
            </div>
        </p-tabView>
    </div>
</form>