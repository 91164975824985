<p-dialog #dialog header="{{ 'Cập nhật thông tin/ Hàng hóa' | translate }}" [(visible)]="isShow" [modal]="true"
    [styleClass]="'ag-modal force-full-width'" [style]="{'width': '800px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true"
    [contentStyle]="{'max-height': getMaxDialogHeight()}" [autoZIndex]="true">
    <div class="col-12 md:col-12">
        <form #formElement [formGroup]="formGroup">
            <div class="col-12 md:col-12">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="col-12 md:col-2">
                    <div class="label font-medium mt-25">{{'Mã hàng hóa' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <input type="text" id="productCode" pInputText [(ngModel)]="itemDetail.productCode"
                        formControlName="productCode" placeholder="Lớn nhất 7 kí tự chữ và số, ký tự đặc biệt ._-">
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Nhóm hàng hóa' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="productGroup" [options]="arrProductGroup" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.productGroup"
                        [filter]="true" filterBy="label,value" placeholder="Bắt buộc">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Tên hàng hóa' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-10 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.productName" formControlName="productName"
                        placeholder="Lớn nhất 100 kí tự chữ và số, ký tự đặc biệt ._-">
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Tên ngắn' | translate}}
                        <span style="color:red;">*</span>
                    </div>
                </div>
                <div class="col-12 md:col-4 field">
                    <input type="text" pInputText [(ngModel)]="itemDetail.shortName" formControlName="shortName"
                        placeholder="định dạng chuỗi ký tự">
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Phẩm chất' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="quality" [options]="arrQuality" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.quality"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Bao bì' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="package" [options]="arrPackage" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.package"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'ĐVT trong bao bì' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="unit" [options]="arrPackage" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.unit"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'ĐVT2' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="oddUnit" [options]="arrPackage" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.oddUnit"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>

                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'SL ĐVT2' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-inputNumber formControlName="oddUnitInPackage" [(ngModel)]="itemDetail.oddUnitInPackage"
                        placeholder="SL ĐVT2">
                    </p-inputNumber>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Mô tả' | translate}}</div>
                </div>
                <div class="col-12 md:col-10 field">
                    <textarea pInputTextarea [(ngModel)]="itemDetail.description" formControlName="description"
                        placeholder="Mô tả"></textarea>
                </div>
                <div class="col-12" style="font-weight: 450;">
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">Tỷ lệ hao hụt định mức</div>
                    </div>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'HH Nhập' | translate}}</div>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="p-inputgroup">
                        <p-inputNumber formControlName="lossIn_Percent" [(ngModel)]="itemDetail.lossIn_Percent"
                            inputStyleClass="text-right" mode="decimal" [minFractionDigits]="2" [min]="0" [max]="100">
                        </p-inputNumber>
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-percentage"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'HH Xuất' | translate}}</div>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="p-inputgroup">
                        <p-inputNumber formControlName="lossOut_Percent" [(ngModel)]="itemDetail.lossOut_Percent"
                            inputStyleClass="text-right" mode="decimal" [minFractionDigits]="2" [min]="0" [max]="100">
                        </p-inputNumber>
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-percentage"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'HH tồn chứa' | translate}}</div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <p-inputNumber formControlName="lossStore_Percent" [(ngModel)]="itemDetail.lossStore_Percent"
                            inputStyleClass="text-right" mode="decimal" [minFractionDigits]="2" [min]="0" [max]="100">
                        </p-inputNumber>
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-percentage"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-12" style="font-weight: 450;">
                    <div class="row-sep">
                        <div class="render"></div>
                        <div class="render-text">Thông tin khác</div>
                    </div>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Lượng quy chuẩn' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="standardQty" [options]="arrStandardQty" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.standardQty"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-1" style="text-align: center;">
                    <p-checkbox formControlName="useQty2" [(ngModel)]="itemDetail.useQty2" [binary]="true"
                        inputId="binary" placeholder="Số lượng 2">
                    </p-checkbox>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Số lượng 2' | translate}}</div>
                </div>
                <div class="col-12 md:col-1 field" style="text-align: center;">
                    <p-checkbox formControlName="isPetrol" [(ngModel)]="itemDetail.isPetrol" [binary]="true"
                        inputId="binary" class="mt-25">
                    </p-checkbox>
                </div>
                <div class="col-12 md:col-2 field" style="font-weight: 450;">
                    <div class="label font-medium mt-25">{{'XDS' | translate}}</div>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'VCF table' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="correctionTable" [options]="arrCorrectionTable"
                        [autoDisplayFirst]="false" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                        [(ngModel)]="itemDetail.correctionTable" [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-2 field">
                    <div class="label font-medium mt-25">{{'Trạng thái' | translate}}</div>
                </div>
                <div class="col-12 md:col-4 field">
                    <p-dropdown formControlName="status" [options]="arrStatus" [autoDisplayFirst]="false"
                        dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.status"
                        [filter]="true" filterBy="label,value">
                    </p-dropdown>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id == 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-save"
            *ngIf="itemDetail.id > 0" [disabled]="submitting" class="p-button mr-2" (click)="saveAndClose()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.CANCEL' | translate}}" icon="pi pi-times"
            [disabled]="submitting" class="p-button p-button-outlined mr-2" (click)="cancel()"></button>
        <button pButton pRipple type="button" label="{{'BUTTON.SAVE_AS_COPY' | translate}}" icon="pi pi-copy"
            *ngIf="itemDetail.id != 0" [disabled]="submitting" class="p-button p-button mr-0 float-right"
            (click)="saveAsCopy()"></button>
    </p-footer>
</p-dialog>