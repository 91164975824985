<ng-container *ngIf="!item.disabled">
    <a *ngIf="isPinned" [ngClass]="item.class" class="pointer-events-none" [pTooltip]="item.code | translate"
        [tooltipDisabled]="true" disabled="true">
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span [style.font-weight]="'bold'" class="layout-menuitem-text">{{item.code | translate}}</span>
        <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass"
            *ngIf="item.badge && !root">{{item.badge}}</span>
    </a>
    <ng-container *ngIf="!isPinned">
        <a [attr.href]="item.url || null" (click)="itemClick($event)"
            *ngIf="!item.routerLink || item.items && this.collapsible" (keydown.enter)="itemClick($event)"
            [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple
            [pTooltip]="item.code | translate" [tooltipDisabled]="active || !(root && app.isSlim() && !app.isMobile())">
            <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{item.code | translate}}</span>
            <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass"
                *ngIf="item.badge && !root">{{item.badge}}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items && !this.forceActive"></i>
        </a>
        <div *ngIf="item.routerLink && item.items && !this.collapsible && !root" style="padding: 0.75rem;">
            <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text" style="font-weight: bold; margin-left: 0.75rem;">{{item.code |
                translate}}</span>
            <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass"
                *ngIf="item.badge && !root">{{item.badge}}</span>
        </div>
        <a (click)="itemClick($event)" *ngIf="item.routerLink && !item.items" [routerLink]="item.routerLink"
            routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}"
            [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple
            [pTooltip]="item.code | translate" [tooltipDisabled]="active || !(root && app.isSlim() && !app.isMobile())">
            <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{item.code | translate}}</span>
            <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass"
                *ngIf="item.badge && !root">{{item.badge}}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items && !this.forceActive"></i>
        </a>
        <ul *ngIf="(item.items && root) || (item.items && active) || (item.items && !this.collapsible)"
            [@children]="root ? 'visible' : !this.collapsible ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'">
            <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
                <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
            </ng-template>
        </ul>
    </ng-container>
</ng-container>